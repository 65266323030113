import React, { useContext } from 'react'
import { I18n } from 'galarm-config'
import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  Layer,
  Text,
  TextInput
} from 'grommet'
import UserContext from './UserContext'
import { Close, Edit } from 'grommet-icons'
import { updateProfile } from 'firebase/auth'

const YourInformation = () => {
  const { user } = useContext(UserContext)

  const [editedName, setEditedName] = React.useState(user.displayName)

  const [showEditName, setShowEditName] = React.useState(false)
  const displayEditName = () => setShowEditName(true)
  const hideEditName = () => setShowEditName(false)

  const editName = () => {
    updateProfile(user, { displayName: editedName })
  }

  return (
    <Box pad="small" width={{ max: 'large' }} flex={{ shrink: 0 }}>
      <Heading margin="small" level={3}>
        {I18n.t('myAccount')}
      </Heading>
      <Card background="textBackgroundColor" margin="small" pad="small">
        <CardBody>
          <Box direction="row" align="center">
            <Text margin="xsmall">
              <Text weight={500}>{I18n.t('name') + ': '}</Text>
              {user.displayName}
            </Text>
            <Button
              icon={<Edit color="darkTint" />}
              onClick={displayEditName}
            />
          </Box>
          <Text margin="xsmall">
            <Text weight={500}>{I18n.t('email') + ': '}</Text>
            {user.email}
          </Text>
        </CardBody>
      </Card>
      {showEditName && (
        <Layer
          background="lightTint"
          onEsc={hideEditName}
          onClickOutside={hideEditName}>
          <Box pad="medium" gap="small">
            <Heading level={3}>{I18n.t('editName')}</Heading>
            <TextInput
              value={editedName}
              onChange={event => setEditedName(event.target.value)}
            />
            <Button label={I18n.t('save')} onClick={editName} />
            <Box style={{ position: 'absolute', top: 20, right: 20 }}>
              <Close onClick={hideEditName} />
            </Box>
          </Box>
        </Layer>
      )}
    </Box>
  )
}

export default YourInformation
