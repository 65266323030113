import { Box, Button, Text } from 'grommet'
import React from 'react'

const AlertDialog = ({ alertConfiguration }) => {
  return (
    <Box margin="small">
      {alertConfiguration.title && (
        <Text size="large" weight="bold" margin="small">
          {alertConfiguration.title}
        </Text>
      )}
      {alertConfiguration.message && (
        <Text margin="small" style={{ whiteSpace: 'pre-line' }}>
          {alertConfiguration.message}
        </Text>
      )}
      <Box direction="row" justify="end" gap="medium" margin="small">
        {alertConfiguration.buttons.map((button, index) => {
          return (
            <Button
              key={index}
              label={button.text}
              onClick={() => button.onClick()}
            />
          )
        })}
      </Box>
    </Box>
  )
}

export default AlertDialog
