import { FormField } from 'grommet'
import React from 'react'
import { ThemeContext } from 'styled-components'

const FormFieldWithNoBorder = props => {
  return (
    <ThemeContext.Extend
      value={{
        formField: {
          border: {
            position: 'none'
          }
        }
      }}>
      <FormField {...props} />
    </ThemeContext.Extend>
  )
}

export default FormFieldWithNoBorder
