import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  Text,
  TextArea,
  TextInput,
  ThemeContext
} from 'grommet'
import React, { useContext, useState } from 'react'
import GlobalConfig from '../GlobalConfig'
import { Constants, I18n } from 'galarm-config'
import UserContext from './UserContext'
import { useLocation } from 'react-router-dom'
import { getFunctions, httpsCallable } from 'firebase/functions'

const Feedback = ({ onClose }) => {
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')
  const { user } = useContext(UserContext)

  const location = useLocation()

  const title = location.state?.title || I18n.t('feedback')
  const fieldTitle = location.state?.title
    ? I18n.t('integrationDetails')
    : I18n.t('feedback')
  const fieldPlaceholder = location.state?.title
    ? I18n.t('enterIntegrationDetails')
    : I18n.t('enterFeedback')

  const [feedback, setFeedback] = useState('')

  const onChangeFeedback = event => setFeedback(event.target.value)

  const validateFeedback = () => {
    let validationMessages = []
    if (feedback === '') {
      validationMessages.push(I18n.t('specifyFeedback'))
    }
    return validationMessages
  }

  const onSend = async () => {
    const validationMessages = validateFeedback()
    if (validationMessages.length > 0) {
      GlobalConfig.showAlert(
        I18n.t('cantSendFeedback'),
        validationMessages.join('\n')
      )
      return
    }

    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: I18n.t('sendingFeedback'),
      closeable: false
    })

    const functions = getFunctions()
    const submitGalarmEnterpriseFeedback = httpsCallable(
      functions,
      'submitGalarmEnterpriseFeedback'
    )
    const result = await submitGalarmEnterpriseFeedback({
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      feedback,
      enterpriseAccountId
    })

    GlobalConfig.hideProgress()

    if (result.status === 'failure') {
      GlobalConfig.showAlert(I18n.t('cantSendFeedback'), result.error)
      return
    }

    onClose()
  }

  return (
    <Box pad="small" gap="small" width="large">
      <Heading margin="small" level={3}>
        {title}
      </Heading>
      <Card background="textBackgroundColor" pad="medium">
        <CardBody gap="small">
          <Box>
            <Text size="small" margin={{ bottom: '5px' }}>
              {fieldTitle}
            </Text>
            <ThemeContext.Extend
              value={{
                textArea: {
                  extend: {
                    height: '200px'
                  }
                }
              }}>
              <TextArea
                placeholder={fieldPlaceholder}
                value={feedback}
                onChange={onChangeFeedback}
                margin={{ horizontal: '10px' }}
                resize="vertical"
              />
            </ThemeContext.Extend>
          </Box>
          <Box>
            <Text size="small" margin={{ bottom: '5px' }}>
              {I18n.t('emailId')}
            </Text>
            <TextInput disabled flex value={user.email} />
          </Box>
          <Button
            alignSelf="center"
            primary
            label={I18n.t('submit')}
            onClick={onSend}
            margin={{ top: 'small' }}
            style={{ color: 'white' }}
          />
        </CardBody>
      </Card>
    </Box>
  )
}

export default Feedback
