import { I18n } from 'galarm-config'
import { Box, Heading } from 'grommet'
import React from 'react'

const NoMatch = () => {
  return (
    <Box align="center" justify="center">
      <Heading level={4} margin="medium">
        {I18n.t('urlNotFound')}
      </Heading>
    </Box>
  )
}

export default NoMatch
