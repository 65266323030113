import { createTypes } from 'reduxsauce'

export default createTypes(`
  SET_ACCOUNT_INFO
  SET_MEMBERS
  ADD_MEMBER
  EDIT_MEMBER
  REMOVE_MEMBER
  ADD_TEAM
  EDIT_TEAM
  REMOVE_TEAM
  ADD_ALERT
  EDIT_ALERT
  REMOVE_ALERT
  ADD_SUBSCRIPTION_KEY
  EDIT_SUBSCRIPTION_KEY
  REMOVE_SUBSCRIPTION_KEY
  SET_TEAMS
  SET_ALERTS
  SET_SUBSCRIPTION_KEYS
  SET_ROTATIONS
  SET_INTEGRATION_CONFIGS
  SET_SUBSCRIPTION
  SET_PRODUCT
  ADD_ROTATION
  EDIT_ROTATION
  REMOVE_ROTATION

  BATCH_ACTIONS
  RESET_APP
`)
