import { Constants, I18n } from 'galarm-config'
import { Box, Heading, Layer, Text } from 'grommet'
import { Close, LinkNext } from 'grommet-icons'
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import RevokeMembersKey from './RevokeMembersKey'
import UserContext from './UserContext'
import { useNavigate } from 'react-router-dom'

const FixSubscription = () => {
  const accountInfo = useSelector(state => state.accountInfo)
  const { userRoles } = useContext(UserContext)
  const navigate = useNavigate()

  const [showMembers, setShowMembers] = useState(false)
  const displayMembers = () => setShowMembers(true)
  const hideMembers = () => setShowMembers(false)

  const openBillingPortal = () => {
    navigate('/app/billing', {
      state: { customerId: accountInfo.subscriptionData.customerId }
    })
  }

  const subscriptionKeys = accountInfo.subscriptionKeys
  const subscriptionData = accountInfo.subscriptionData

  const numSeats = subscriptionData.quantity
  const numSeatsAssigned = subscriptionKeys.filter(key => key.assignedTo).length

  const extraKeysAssigned = numSeatsAssigned - numSeats

  const showFixSubscriptionOptions = [
    Constants.ENTERPRISE_USER_ROLES.OWNER,
    Constants.ENTERPRISE_USER_ROLES.ADMIN
  ].some(role => userRoles.includes(role))
  console.log('Rendering FixSubscription')

  return (
    <Layer>
      <Box margin="medium">
        <Heading level={4}>{I18n.t('fixSubscription')}</Heading>
        <Text>
          {I18n.t('fixSubscriptionDescription', { numSeats, numSeatsAssigned })}
        </Text>
        {showFixSubscriptionOptions ? (
          <Box gap="small" pad="small">
            <Text>{I18n.t('fixSubscriptionChooseOption')}</Text>
            <Box
              direction="row"
              gap="small"
              align="center"
              onClick={displayMembers}>
              <Text size="xlarge">{Constants.BULLET_UNICODE}</Text>
              <Text>
                {I18n.t('unassignKeysFromMembers', { extraKeysAssigned })}
              </Text>
              <LinkNext color="primary" />
            </Box>
            <Box
              direction="row"
              gap="small"
              align="center"
              onClick={openBillingPortal}>
              <Text size="xlarge">{Constants.BULLET_UNICODE}</Text>
              <Text>
                {I18n.t('addMoreSeatsToSubscription', { extraKeysAssigned })}
              </Text>
              <LinkNext color="primary" />
            </Box>
          </Box>
        ) : (
          <Text>{I18n.t('fixSubscriptionContactAdmin')}</Text>
        )}
      </Box>
      {showMembers && (
        <Layer
          position="center"
          onClickOutside={hideMembers}
          onEsc={hideMembers}>
          <Box pad="medium">
            <RevokeMembersKey onClose={hideMembers} />
            <Box style={{ position: 'absolute', top: 20, right: 20 }}>
              <Close onClick={hideMembers} />
            </Box>
          </Box>
        </Layer>
      )}
    </Layer>
  )
}

export default FixSubscription
