import React from 'react'
import { Box, Heading, Text } from 'grommet'
// import OpenIncidents from './OpenIncidents'
// import RecentlyOpenedIncidents from './RecentlyOpenedIncidents'
// import RecentlyClosedIncidents from './RecentlyClosedIncidents'
// import IncidentsCalendar from './IncidentsCalendar'
// import IncidentsChart from './IncidentsChart'
// import IncidentsDistribution from './IncidentsDistribution'
import { useSelector } from 'react-redux'
import GetStarted from './GetStarted'
import { I18n } from 'galarm-config'
import IncidentsTable from './IncidentsTable'

const Dashboard = () => {
  // const size = useContext(ResponsiveContext)

  const setupComplete = useSelector(
    state => state.accountInfo.initialSetupMarkers?.setupComplete
  )
  const hasMembers = useSelector(state => state.accountInfo.members.length > 0)
  const hasAlerts = useSelector(state => state.accountInfo.alerts.length > 0)

  console.log('Rendering Dashboard')

  // Show the GetStarted Wizard here also because the enterprise data
  // is loaded in RequireActiveSubscription which is required for
  // assigning keys to members
  if (!setupComplete) {
    return (
      <Box width="large">
        <Heading
          level={3}
          margin={{ top: 'medium', left: 'medium', bottom: 'none' }}>
          {I18n.t('welcomeToGalarm')}
        </Heading>
        <Text color="darkTint" margin="medium">
          {I18n.t('startupWizardDescription')}
        </Text>
        <GetStarted enterpriseAccountData={{ hasMembers, hasAlerts }} />
      </Box>
    )
  }

  // const smallDevice = size === 'small'
  // const mediumDevice = size === 'medium'
  return (
    // <Box
    //   direction={smallDevice || mediumDevice ? 'column' : 'row'}
    //   gap="medium"
    //   overflow="auto">
    //   <Box
    //     direction={smallDevice ? 'column' : 'row'}
    //     gap="medium"
    //     flex={{ shrink: smallDevice || mediumDevice ? 0 : 1 }}>
    //     <Box gap="medium">
    //       <OpenIncidents />
    //       <RecentlyOpenedIncidents />
    //       <RecentlyClosedIncidents />
    //     </Box>
    //     <Box>
    //       <IncidentsChart />
    //       <IncidentsDistribution />
    //     </Box>
    //   </Box>
    //   <Box flex={{ shrink: smallDevice || mediumDevice ? 0 : 1 }}>
    //     <IncidentsTable />
    //   </Box>
    // </Box>
    <Box overflow="auto">
      <IncidentsTable />
    </Box>
  )
}

export default Dashboard
