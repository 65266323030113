import { Box } from 'grommet'
import React from 'react'
import Organizations from './Organizations'

const ChooseOrganization = () => {
  return (
    <Box overflow="auto">
      <Organizations />
    </Box>
  )
}

export default ChooseOrganization
