import React, { useState, useEffect } from 'react'
import { Grid, Box, Layer, Text, ResponsiveContext } from 'grommet'
import GlobalConfig from '../GlobalConfig'
import { Constants, I18n } from 'galarm-config'
import { Outlet } from 'react-router-dom'
import GalarmEnterpriseHeader from './GalarmEnterpriseHeader'
import ProgressDialog from './ProgressDialog'
import AlertDialog from './AlertDialog'
import { Store } from 'react-notifications-component'
import { useResize } from './useResize'
import ResponsiveCompanyLogo from './ResponsiveCompanyLogo'

const Layout = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  const size = React.useContext(ResponsiveContext)
  const windowSize = useResize()

  useEffect(() => {
    // Update network status
    const handleOnline = () => {
      setIsOnline(true)
    }

    const handleOffline = () => {
      setIsOnline(false)
    }

    // Listen to the online status
    window.addEventListener('online', handleOnline)

    // Listen to the offline status
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  const defaultAlertConfiguration = {
    title: '',
    message: '',
    buttons: [],
    cancelable: false
  }
  const [alertConfiguration, setAlertConfiguration] = useState(
    defaultAlertConfiguration
  )
  const showAlert = alertConfiguration.title || alertConfiguration.message

  GlobalConfig.showAlert = (
    title = '',
    message = '',
    buttons,
    cancelable = true
  ) => {
    let finalButtons = []

    if (!buttons || buttons.length === 0) {
      finalButtons = [
        {
          text: I18n.t('ok'),
          onClick: GlobalConfig.hideAlert
        }
      ]
    } else {
      finalButtons = buttons.map(button => ({
        text: button.text,
        onClick: () => {
          if (button.onPress) {
            button.onPress()
          }
          GlobalConfig.hideAlert()
        }
      }))
    }
    setAlertConfiguration({
      title: title,
      message: message,
      buttons: finalButtons,
      cancelable
    })
  }
  GlobalConfig.hideAlert = () =>
    setAlertConfiguration(defaultAlertConfiguration)

  const defaultProgressConfiguration = {
    state: '',
    message: '',
    closeable: false
  }
  const [progressConfiguration, setProgressConfiguration] = useState(
    defaultProgressConfiguration
  )
  const showProgress = progressConfiguration.message
  GlobalConfig.showProgress = setProgressConfiguration
  GlobalConfig.hideProgress = () =>
    setProgressConfiguration(defaultProgressConfiguration)

  const showTransientAlert = ({
    message,
    type = 'success',
    duration = Constants.AlertDurations.SHORT,
    position = Constants.AlertPositions.BOTTOM,
    onPress
  }) => {
    Store.addNotification({
      message: message,
      type: type,
      insert: 'top',
      container: `${position}-right`,
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: duration * 1000
      },
      onRemoval: onPress
    })
  }
  GlobalConfig.showTransientAlert = showTransientAlert

  const firstColumnWidths = {
    small: '80',
    medium: '200',
    large: '200',
    xlarge: '200'
  }
  const firstColumnWidth = firstColumnWidths[size]

  const mainWidth = windowSize.width - firstColumnWidth

  return (
    <Box fill>
      <Grid
        fill
        columns={[firstColumnWidth + 'px', mainWidth + 'px']}
        rows={['100px', 'flex']}
        //[column,row] coordinates for start and end of areas
        areas={[
          { name: 'companyLogo', start: [0, 0], end: [1, 0] },
          { name: 'header', start: [1, 0], end: [1, 0] },
          { name: 'app', start: [0, 1], end: [1, 1] }
        ]}>
        <Box
          gridArea="companyLogo"
          justify="center"
          align="center"
          background="lightPrimary">
          <ResponsiveCompanyLogo />
        </Box>
        <Box
          gridArea="header"
          justify="center"
          align="end"
          background="lightPrimary">
          <GalarmEnterpriseHeader />
        </Box>
        <Box gridArea="app" background="lightTint">
          <Outlet />
        </Box>
      </Grid>
      {showProgress && (
        <Layer
          background="lightTint"
          onEsc={
            progressConfiguration.closeable
              ? GlobalConfig.hideProgress
              : undefined
          }
          onClickOutside={
            progressConfiguration.closeable
              ? GlobalConfig.hideProgress
              : undefined
          }>
          <ProgressDialog progressConfiguration={progressConfiguration} />
        </Layer>
      )}
      {showAlert && (
        <Layer
          background="lightTint"
          onEsc={
            alertConfiguration.cancelable ? GlobalConfig.hideAlert : undefined
          }
          onClickOutside={
            alertConfiguration.cancelable ? GlobalConfig.hideAlert : undefined
          }>
          <AlertDialog alertConfiguration={alertConfiguration} />
        </Layer>
      )}
      {!isOnline && (
        <Layer background="lightTint">
          <Text margin="medium">{I18n.t('youAreOffline')}</Text>
        </Layer>
      )}
    </Box>
  )
}

export default Layout
