import { I18n } from 'galarm-config'
import { Box, Heading, Text } from 'grommet'
import React from 'react'
import Linkify from 'react-linkify'

const NextStepsAfterSetup = () => {
  return (
    <Box pad="small" gap="small" flex={{ shrink: 0 }}>
      <Heading margin="none" level={3}>
        {I18n.t('nextSteps')}
      </Heading>
      <Text>{I18n.t('firstTimeSetupComplete')}</Text>
      <Text>
        {' - '}
        <Text weight={600}>{I18n.t('testTheAlert')}</Text>
        {': '}
        <Linkify options={{ target: '_blank' }}>
          {I18n.t('testTheAlertInstruction', {
            link: 'https://enterprise.galarm.app/getting-started#step-5-testing-the-alert'
          })}
        </Linkify>
      </Text>
      <Text>
        {' - '}
        <Text weight={600}>{I18n.t('integrateTheAlert')}</Text>
        {': '}
        <Linkify options={{ target: '_blank' }}>
          {I18n.t('integrateTheAlertInstruction', {
            link: 'https://enterprise.galarm.app/guides/integrate-alerts'
          })}
        </Linkify>
      </Text>
      <Text>
        {' - '}
        <Text weight={600}>{I18n.t('createMoreAlerts')}</Text>
        {': '}
        <Linkify options={{ target: '_blank' }}>
          {I18n.t('createMoreAlertsInstruction', {
            link: 'https://enterprise.galarm.app/guides/create-alerts'
          })}
        </Linkify>
      </Text>
      <Text>
        {' - '}
        <Text weight={600}>{I18n.t('addMoreMembers')}</Text>
        {': '}
        <Linkify options={{ target: '_blank' }}>
          {I18n.t('addMoreMembersInstruction', {
            link: 'https://enterprise.galarm.app/guides/add-members'
          })}
        </Linkify>
      </Text>
      <Text>
        {' - '}
        <Text weight={600}>{I18n.t('createTeams')}</Text>
        {': '}
        <Linkify options={{ target: '_blank' }}>
          {I18n.t('createTeamsInstruction', {
            link: 'https://enterprise.galarm.app/guides/create-teams'
          })}
        </Linkify>
      </Text>
    </Box>
  )
}

export default NextStepsAfterSetup
