import { createReducer } from 'reduxsauce'
import ActionTypes from '../actions/types'

const INITIAL_STATE = {
  id: '',
  name: '',
  owner: '',
  subscriptionData: {},
  initialSetupMarkers: {},
  subscriptionKeys: [],
  members: [],
  teams: [],
  rotations: [],
  integrationConfigs: [],
  alerts: [],
  subscription: null,
  product: null,
  incidentPrefixes: []
}

const setAccountInfo = (state, action) => {
  const {
    id = '',
    name = '',
    owner = '',
    subscriptionData = {},
    initialSetupMarkers,
    incidentPrefixes = []
  } = action.payload.accountInfo

  return {
    ...state,
    id,
    name,
    owner,
    subscriptionData,
    initialSetupMarkers,
    incidentPrefixes
  }
}

const setMembers = (state, action) => ({
  ...state,
  members: action.payload.members
})

const addMember = (state, action) => {
  const newMember = action.payload.member
  const newMembers = state.members.slice()
  const existingMemberIndex = state.members.findIndex(
    member => member.id === newMember.id
  )

  if (existingMemberIndex !== -1) {
    newMembers.splice(existingMemberIndex, 1, newMember)
  } else {
    newMembers.push(newMember)
  }

  return {
    ...state,
    members: newMembers
  }
}

const editMember = (state, action) => {
  const editedMember = action.payload.member
  const newMembers = state.members.slice()
  const existingMemberIndex = state.members.findIndex(
    member => member.id === editedMember.id
  )

  if (existingMemberIndex === -1) {
    console.error('Unable to find member while editing member', editedMember)
    return state
  }

  newMembers.splice(existingMemberIndex, 1, editedMember)

  return {
    ...state,
    members: newMembers
  }
}

const removeMember = (state, action) => {
  const removedMember = action.payload.member
  const newMembers = state.members.slice()
  const existingMemberIndex = state.members.findIndex(
    member => member.id === removedMember.id
  )

  if (existingMemberIndex === -1) {
    console.error('Unable to find member while removing member', removedMember)
    return state
  }

  newMembers.splice(existingMemberIndex, 1)

  return {
    ...state,
    members: newMembers
  }
}

const addTeam = (state, action) => {
  const newTeam = action.payload.team
  const newTeams = state.teams.slice()
  const existingTeamIndex = state.teams.findIndex(
    team => team.id === newTeam.id
  )

  if (existingTeamIndex !== -1) {
    newTeams.splice(existingTeamIndex, 1, newTeam)
  } else {
    newTeams.push(newTeam)
  }

  return {
    ...state,
    teams: newTeams
  }
}

const editTeam = (state, action) => {
  const editedTeam = action.payload.team
  const newTeams = state.teams.slice()
  const existingTeamIndex = state.teams.findIndex(
    team => team.id === editedTeam.id
  )

  if (existingTeamIndex === -1) {
    console.error('Unable to find team while editing team', editedTeam)
    return state
  }

  newTeams.splice(existingTeamIndex, 1, editedTeam)

  return {
    ...state,
    teams: newTeams
  }
}

const removeTeam = (state, action) => {
  const removedTeam = action.payload.team
  const newTeams = state.teams.slice()
  const existingTeamIndex = state.teams.findIndex(
    team => team.id === removedTeam.id
  )

  if (existingTeamIndex === -1) {
    console.error('Unable to find team while removing team', removedTeam)
    return state
  }

  newTeams.splice(existingTeamIndex, 1)

  return {
    ...state,
    teams: newTeams
  }
}

const addRotation = (state, action) => {
  const newRotation = action.payload.rotation
  const newRotations = state.rotations.slice()
  const existingRotationIndex = state.rotations.findIndex(
    rotation => rotation.id === newRotation.id
  )

  if (existingRotationIndex !== -1) {
    newRotations.splice(existingRotationIndex, 1, newRotation)
  } else {
    newRotations.push(newRotation)
  }

  return {
    ...state,
    rotations: newRotations
  }
}

const editRotation = (state, action) => {
  const editedRotation = action.payload.rotation
  const newRotations = state.rotations.slice()
  const existingRotationIndex = state.rotations.findIndex(
    rotation => rotation.id === editedRotation.id
  )

  if (existingRotationIndex === -1) {
    console.error(
      'Unable to find rotation while editing rotation',
      editedRotation
    )
    return state
  }

  newRotations.splice(existingRotationIndex, 1, editedRotation)

  return {
    ...state,
    rotations: newRotations
  }
}

const removeRotation = (state, action) => {
  const removedRotation = action.payload.rotation
  const newRotations = state.rotations.slice()
  const existingRotationIndex = state.rotations.findIndex(
    rotation => rotation.id === removedRotation.id
  )

  if (existingRotationIndex === -1) {
    console.error(
      'Unable to find rotation while removing rotation',
      removedRotation
    )
    return state
  }

  newRotations.splice(existingRotationIndex, 1)

  return {
    ...state,
    rotations: newRotations
  }
}

const addSubscriptionKey = (state, action) => {
  const newSubscriptionKey = action.payload.subscriptionKey
  const newSubscriptionKeys = state.subscriptionKeys.slice()
  const existingSubscriptionKeyIndex = state.subscriptionKeys.findIndex(
    subscriptionKey => subscriptionKey.id === newSubscriptionKey.id
  )

  if (existingSubscriptionKeyIndex !== -1) {
    newSubscriptionKeys.splice(
      existingSubscriptionKeyIndex,
      1,
      newSubscriptionKey
    )
  } else {
    newSubscriptionKeys.push(newSubscriptionKey)
  }

  return {
    ...state,
    subscriptionKeys: newSubscriptionKeys
  }
}

const editSubscriptionKey = (state, action) => {
  const editedSubscriptionKey = action.payload.subscriptionKey
  const newSubscriptionKeys = state.subscriptionKeys.slice()
  const existingSubscriptionKeyIndex = state.subscriptionKeys.findIndex(
    subscriptionKey => subscriptionKey.id === editedSubscriptionKey.id
  )

  if (existingSubscriptionKeyIndex === -1) {
    console.error(
      'Unable to find subscription key while editing subscription key',
      editedSubscriptionKey
    )
    return state
  }

  newSubscriptionKeys.splice(
    existingSubscriptionKeyIndex,
    1,
    editedSubscriptionKey
  )

  return {
    ...state,
    subscriptionKeys: newSubscriptionKeys
  }
}

const removeSubscriptionKey = (state, action) => {
  const removedSubscriptionKey = action.payload.subscriptionKey
  const newSubscriptionKeys = state.subscriptionKeys.slice()
  const existingSubscriptionKeyIndex = state.subscriptionKeys.findIndex(
    subscriptionKey => subscriptionKey.id === removedSubscriptionKey.id
  )

  if (existingSubscriptionKeyIndex === -1) {
    console.error(
      'Unable to find subscription key while removing subscription key',
      removedSubscriptionKey
    )
    return state
  }

  newSubscriptionKeys.splice(existingSubscriptionKeyIndex, 1)

  return {
    ...state,
    subscriptionKeys: newSubscriptionKeys
  }
}

const addAlert = (state, action) => {
  const newAlert = action.payload.alert
  const newAlerts = state.alerts.slice()
  const existingAlertIndex = state.alerts.findIndex(
    alert => alert.id === newAlert.id
  )

  if (existingAlertIndex !== -1) {
    newAlerts.splice(existingAlertIndex, 1, newAlert)
  } else {
    newAlerts.push(newAlert)
  }

  return {
    ...state,
    alerts: newAlerts
  }
}

const editAlert = (state, action) => {
  const editedAlert = action.payload.alert
  const newAlerts = state.alerts.slice()
  const existingAlertIndex = state.alerts.findIndex(
    alert => alert.id === editedAlert.id
  )

  if (existingAlertIndex === -1) {
    console.error('Unable to find alert while editing alert', editedAlert)
    return state
  }

  newAlerts.splice(existingAlertIndex, 1, editedAlert)

  return {
    ...state,
    alerts: newAlerts
  }
}

const removeAlert = (state, action) => {
  const removedAlert = action.payload.alert
  const newAlerts = state.alerts.slice()
  const existingAlertIndex = state.alerts.findIndex(
    alert => alert.id === removedAlert.id
  )

  if (existingAlertIndex === -1) {
    console.error('Unable to find alert while removing alert', removedAlert)
    return state
  }

  newAlerts.splice(existingAlertIndex, 1)

  return {
    ...state,
    alerts: newAlerts
  }
}

const setTeams = (state, action) => ({
  ...state,
  teams: action.payload.teams
})

const setAlerts = (state, action) => ({
  ...state,
  alerts: action.payload.alerts
})

const setSubscriptionKeys = (state, action) => ({
  ...state,
  subscriptionKeys: action.payload.subscriptionKeys
})

const setRotations = (state, action) => ({
  ...state,
  rotations: action.payload.rotations
})

const setIntegrationConfigs = (state, action) => ({
  ...state,
  integrationConfigs: action.payload.integrationConfigs
})

const setSubscription = (state, action) => ({
  ...state,
  subscription: action.payload.subscription
})

const setProduct = (state, action) => ({
  ...state,
  product: action.payload.product
})

const ACTION_HANDLERS = {
  [ActionTypes.SET_ACCOUNT_INFO]: setAccountInfo,
  [ActionTypes.SET_MEMBERS]: setMembers,
  [ActionTypes.ADD_MEMBER]: addMember,
  [ActionTypes.EDIT_MEMBER]: editMember,
  [ActionTypes.REMOVE_MEMBER]: removeMember,
  [ActionTypes.ADD_TEAM]: addTeam,
  [ActionTypes.EDIT_TEAM]: editTeam,
  [ActionTypes.REMOVE_TEAM]: removeTeam,
  [ActionTypes.ADD_ROTATION]: addRotation,
  [ActionTypes.EDIT_ROTATION]: editRotation,
  [ActionTypes.REMOVE_ROTATION]: removeRotation,
  [ActionTypes.ADD_ALERT]: addAlert,
  [ActionTypes.EDIT_ALERT]: editAlert,
  [ActionTypes.REMOVE_ALERT]: removeAlert,
  [ActionTypes.ADD_SUBSCRIPTION_KEY]: addSubscriptionKey,
  [ActionTypes.EDIT_SUBSCRIPTION_KEY]: editSubscriptionKey,
  [ActionTypes.REMOVE_SUBSCRIPTION_KEY]: removeSubscriptionKey,
  [ActionTypes.SET_TEAMS]: setTeams,
  [ActionTypes.SET_ALERTS]: setAlerts,
  [ActionTypes.SET_SUBSCRIPTION_KEYS]: setSubscriptionKeys,
  [ActionTypes.SET_ROTATIONS]: setRotations,
  [ActionTypes.SET_INTEGRATION_CONFIGS]: setIntegrationConfigs,
  [ActionTypes.SET_SUBSCRIPTION]: setSubscription,
  [ActionTypes.SET_PRODUCT]: setProduct
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
