import { Constants, I18n } from 'galarm-config'
import { Box, Card, Heading, Text } from 'grommet'
import React from 'react'

const FirstAlertInstructions = () => {
  return (
    <Box pad="small" gap="small" align="center">
      <Heading margin="small" level={3}>
        {I18n.t('firstAlertInstructions')}
      </Heading>
      <Card
        width="large"
        background="textBackgroundColor"
        pad="medium"
        gap="small">
        <Box direction="row" gap="small">
          <Text weight={'bold'}>{Constants.BULLET_UNICODE}</Text>
          <Text>
            {"Create organization account if you haven't already created one."}
          </Text>
        </Box>
        <Box direction="row" gap="small">
          <Text weight={'bold'}>{Constants.BULLET_UNICODE}</Text>
          <Text>
            {'Go to the Members tab on the side bar and add your members.'}
          </Text>
        </Box>
        <Box direction="row" gap="small">
          <Text weight={'bold'}>{Constants.BULLET_UNICODE}</Text>
          <Text>
            {'Tap on the three-dot menu icon for each member and assign a key.'}
          </Text>
        </Box>
        <Box direction="row" gap="small">
          <Text weight={'bold'}>{Constants.BULLET_UNICODE}</Text>
          <Text>
            {'Go to the Alerts tab on the side bar and tap on the plus icon.'}
          </Text>
        </Box>
        <Box direction="row" gap="small">
          <Text weight={'bold'}>{Constants.BULLET_UNICODE}</Text>
          <Text>
            {
              'Specify the name of the alert. This could be the name of the resource that this alert will be monitoring.'
            }
          </Text>
        </Box>
        <Box direction="row" gap="small">
          <Text weight={'bold'}>{Constants.BULLET_UNICODE}</Text>
          <Text>
            {
              'Specify escalation policy for the alert. The escalation policy is the order in which the members will be notified when the alert is triggered. Members who have been assigned a key can be added to the escalation policy. You can specify multiple levels of escalation. For example, you can specify that at the time of incident, alert "member A". If the incident is not acknowledged for half hour, then alert "member B" and so on.'
            }
          </Text>
        </Box>
        <Box direction="row" gap="small">
          <Text weight={'bold'}>{Constants.BULLET_UNICODE}</Text>
          <Text>
            {
              'You can optionally create teams by choosing the Teams tab on the sidebar. You can then add members to the teams and specify the teams in the escalation policy.'
            }
          </Text>
        </Box>
        <Box direction="row" gap="small">
          <Text weight={'bold'}>{Constants.BULLET_UNICODE}</Text>
          <Text>
            {
              'You can integrate the alert with various platforms. Tap on the three-dot menu icon for the alert and choose "View Code" to see instructions on how to integrate with different platforms. Currently, you can integrate the alert with AWS SNS, Datadog, and using REST API.'
            }
          </Text>
        </Box>
      </Card>
    </Box>
  )
}

export default FirstAlertInstructions
