import { I18n } from 'galarm-config'
import { Box, Button, Heading, Text } from 'grommet'
import React from 'react'

const NumSeatsHelper = ({ planType, onClose }) => {
  return (
    <Box pad="small">
      <Box border="bottom">
        <Heading
          level={4}
          margin={{ vertical: 'small' }}
          alignSelf="center"
          border>
          {I18n.t('numSeats')}
        </Heading>
      </Box>
      <Text margin="small">
        {planType === 'enterprise'
          ? I18n.t('numEnterpriseSeatsHelper')
          : I18n.t('numBusinessSeatsHelper')}
      </Text>
      <Box primary alignSelf="center" label={I18n.t('ok')} />
      <Box border="top" direction="row" justify="center">
        <Button margin={'small'} label={I18n.t('ok')} onClick={onClose} />
      </Box>
    </Box>
  )
}

export default NumSeatsHelper
