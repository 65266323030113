import { I18n, colors } from 'galarm-config'
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Text
} from 'grommet'
import { Checkmark, FormNext, FormPrevious, LinkNext } from 'grommet-icons'
import { max } from 'lodash'
import React, { useEffect, useState } from 'react'
import Members from './Members'
import Alerts from './Alerts'
import { doc, onSnapshot, updateDoc } from 'firebase/firestore'
import GlobalConfig from '../GlobalConfig'
import NextStepsAfterSetup from './NextStepsAfterSetup'

const GetStarted = ({ enterpriseAccountData }) => {
  const [initialSetupMarkers, setInitialSetupMarkers] = useState({
    membersAdded: false,
    setupComplete: false
  })

  const { hasMembers, hasAlerts } = enterpriseAccountData

  const [currentStep, setCurrentStep] = useState(1)

  useEffect(() => {
    if (!hasMembers || !initialSetupMarkers?.membersAdded) {
      setCurrentStep(1)
    } else if (!hasAlerts) {
      setCurrentStep(2)
    } else {
      setCurrentStep(3)
    }
  }, [enterpriseAccountData, initialSetupMarkers])

  useEffect(() => {
    const enterpriseAccountId = window.localStorage.getItem(
      'enterpriseAccountId'
    )

    if (enterpriseAccountId) {
      console.log('GetStarted', enterpriseAccountId)
      const unsubscribe = onSnapshot(
        doc(
          GlobalConfig.firestoreDb,
          'enterpriseAccounts',
          enterpriseAccountId
        ),
        enterpriseAccountDoc => {
          console.log('GetStarted', enterpriseAccountDoc.data())
          if (enterpriseAccountDoc.exists()) {
            const enterpriseAccount = enterpriseAccountDoc.data()
            setInitialSetupMarkers(enterpriseAccount.initialSetupMarkers)
          }
        }
      )

      return () => {
        unsubscribe()
      }
    }
  }, [enterpriseAccountData])

  const onMembersAdded = async () => {
    const enterpriseAccountId = window.localStorage.getItem(
      'enterpriseAccountId'
    )
    await updateDoc(
      doc(GlobalConfig.firestoreDb, 'enterpriseAccounts', enterpriseAccountId),
      {
        initialSetupMarkers: {
          ...initialSetupMarkers,
          membersAdded: true
        }
      }
    )

    setCurrentStep(2)
  }

  const onSetupComplete = async () => {
    const enterpriseAccountId = window.localStorage.getItem(
      'enterpriseAccountId'
    )
    await updateDoc(
      doc(GlobalConfig.firestoreDb, 'enterpriseAccounts', enterpriseAccountId),
      {
        initialSetupMarkers: {
          ...initialSetupMarkers,
          setupComplete: true
        }
      }
    )
  }

  const goToPrev = () => {
    setCurrentStep(max([1, currentStep - 1]))
  }

  console.log('GetStarted', enterpriseAccountData, initialSetupMarkers)

  return (
    <Card background="light-3" margin={{ horizontal: 'small' }}>
      <CardHeader pad="medium" direction="row" align="center" gap="small">
        <Box align="center" gap="small" onClick={() => setCurrentStep(1)}>
          <Box
            height="30px"
            width="30px"
            round
            align="center"
            justify="center"
            background={currentStep === 1 ? colors.green : 'mediumTint'}>
            {hasMembers ? <Checkmark color="white" /> : null}
          </Box>
          <Text size="small">{I18n.t('addMembers')}</Text>
        </Box>
        <LinkNext color="darkTint" />
        <Box align="center" gap="small" onClick={() => setCurrentStep(2)}>
          <Box
            height="30px"
            width="30px"
            round
            align="center"
            justify="center"
            background={currentStep === 2 ? colors.green : 'mediumTint'}>
            {hasAlerts ? <Checkmark color="white" /> : null}
          </Box>
          <Text size="small">{I18n.t('addAlert')}</Text>
        </Box>
        <LinkNext color="darkTint" />
        <Box align="center" gap="small" onClick={() => setCurrentStep(3)}>
          <Box
            height="30px"
            width="30px"
            round
            align="center"
            justify="center"
            background={currentStep === 3 ? colors.green : 'mediumTint'}
          />
          <Text size="small">{I18n.t('nextSteps')}</Text>
        </Box>
      </CardHeader>
      <CardBody pad="medium" background="white">
        {currentStep === 1 && (
          <Box gap="small">
            <Members />
            <Text
              margin={{ left: 'medium' }}
              size="small"
              color="darkTint"
              style={{ fontStyle: 'italic' }}>
              {I18n.t('clickNextAfterAddingMembers')}
            </Text>
          </Box>
        )}
        {currentStep === 2 && (
          <Box gap="small">
            <Alerts />
          </Box>
        )}
        {currentStep === 3 && (
          <Box gap="small" overflow="auto">
            <NextStepsAfterSetup />
            <Text
              margin={{ left: 'medium' }}
              size="small"
              color="darkTint"
              style={{ fontStyle: 'italic' }}>
              {I18n.t('clickDoneAfterAddingAlerts')}
            </Text>
          </Box>
        )}
      </CardBody>
      <CardFooter
        pad={{ horizontal: 'small' }}
        background="light-2"
        direction="row"
        justify="between">
        <Button icon={<FormPrevious />} hoverIndicator onClick={goToPrev} />
        {currentStep === 1 && hasMembers ? (
          <Button
            icon={<FormNext />}
            label={I18n.t('next')}
            hoverIndicator
            reverse
            onClick={onMembersAdded}
          />
        ) : currentStep === 3 ? (
          <Button
            icon={<FormNext />}
            label={I18n.t('done')}
            hoverIndicator
            reverse
            onClick={onSetupComplete}
          />
        ) : (
          <Box />
        )}
      </CardFooter>
    </Card>
  )
}

export default GetStarted
