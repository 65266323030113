export const createCloudFunctionUrl = functionName => {
  if (__DEV__) {
    return `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/${functionName}`
  }
  return `https://${functionName}-${process.env.REACT_APP_CLOUD_FUNCTION_HASH}-uc.a.run.app`
}

export const createCloudFunctionUrlWithBasicAuth = (
  functionName,
  username,
  password
) => {
  if (__DEV__) {
    return `http://${username}:${password}@${
      process.env.REACT_APP_CLOUD_FUNCTION_URL.split('//')[1]
    }/${functionName}`
  }
  return `https://${username}:${password}@${functionName}-${process.env.REACT_APP_CLOUD_FUNCTION_HASH}-uc.a.run.app`
}
