import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from '../reducers'
import thunk from 'redux-thunk'
import { reactotronConfig } from 'galarm-config'

export default function configureStore() {
  let storeEnhancers = []

  storeEnhancers.push(applyMiddleware(thunk))

  if (__DEV__) {
    const reactotronEnhancer = reactotronConfig.createEnhancer()
    storeEnhancers.push(reactotronEnhancer)
  }

  const store = createStore(rootReducer, compose(...storeEnhancers))
  return { store }
}
