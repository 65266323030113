import { I18n } from 'galarm-config'
import { Button, Heading, Box } from 'grommet'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const NoOrganization = () => {
  const navigate = useNavigate()

  const onCreateOrganization = () => {
    navigate('/app/buy')
  }

  return (
    <Box>
      <Heading level={4} margin="medium">
        {I18n.t('youAreNotPartOfAnyOrganization')}
      </Heading>
      <Button
        margin={{ horizontal: 'medium', vertical: 'small' }}
        alignSelf="start"
        primary
        style={{ color: 'white' }}
        onClick={onCreateOrganization}
        label={I18n.t('createOrganization')}
      />
    </Box>
  )
}

export default NoOrganization
