import { combineReducers } from 'redux'
import appState from './appState'
import accountInfo from './accountInfo'
import ActionTypes from '../actions/types'

const appReducer = combineReducers({
  appState,
  accountInfo
})

const rootReducer = (state, action) => {
  if (action.type === ActionTypes.BATCH_ACTIONS) {
    return action.actions.reduce(appReducer, state)
  }

  if (action.type === ActionTypes.RESET_APP) {
    // Don't reset the appState as it only contains the phone
    // state which doesn't change when the app is reset.
    state = Object.assign({}, { appState: state.appState })
  }

  return appReducer(state, action)
}

export default rootReducer
