import ActionTypes from './types'
import { Constants, I18n } from 'galarm-config'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { logEvent } from 'firebase/analytics'
import GlobalConfig from '../GlobalConfig'

const resetApp = () => ({
  type: ActionTypes.RESET_APP
})

const setAccountInfo = accountInfo => ({
  type: ActionTypes.SET_ACCOUNT_INFO,
  payload: {
    accountInfo
  }
})

const setSubscription = subscription => ({
  type: ActionTypes.SET_SUBSCRIPTION,
  payload: {
    subscription
  }
})

const setProduct = product => ({
  type: ActionTypes.SET_PRODUCT,
  payload: {
    product
  }
})

const setMembers = members => ({
  type: ActionTypes.SET_MEMBERS,
  payload: {
    members
  }
})

const addMember = member => ({
  type: ActionTypes.ADD_MEMBER,
  payload: {
    member
  }
})

const editMember = member => ({
  type: ActionTypes.EDIT_MEMBER,
  payload: {
    member
  }
})

const removeMember = member => ({
  type: ActionTypes.REMOVE_MEMBER,
  payload: {
    member
  }
})

const addRotation = rotation => ({
  type: ActionTypes.ADD_ROTATION,
  payload: {
    rotation
  }
})

const editRotation = rotation => ({
  type: ActionTypes.EDIT_ROTATION,
  payload: {
    rotation
  }
})

const removeRotation = rotation => ({
  type: ActionTypes.REMOVE_ROTATION,
  payload: {
    rotation
  }
})

const addTeam = team => ({
  type: ActionTypes.ADD_TEAM,
  payload: {
    team
  }
})

const editTeam = team => ({
  type: ActionTypes.EDIT_TEAM,
  payload: {
    team
  }
})

const removeTeam = team => ({
  type: ActionTypes.REMOVE_TEAM,
  payload: {
    team
  }
})

const addAlert = alert => ({
  type: ActionTypes.ADD_ALERT,
  payload: {
    alert
  }
})

const editAlert = alert => ({
  type: ActionTypes.EDIT_ALERT,
  payload: {
    alert
  }
})

const removeAlert = alert => ({
  type: ActionTypes.REMOVE_ALERT,
  payload: {
    alert
  }
})

const addSubscriptionKey = subscriptionKey => ({
  type: ActionTypes.ADD_SUBSCRIPTION_KEY,
  payload: {
    subscriptionKey
  }
})

const editSubscriptionKey = subscriptionKey => ({
  type: ActionTypes.EDIT_SUBSCRIPTION_KEY,
  payload: {
    subscriptionKey
  }
})

const removeSubscriptionKey = subscriptionKey => ({
  type: ActionTypes.REMOVE_SUBSCRIPTION_KEY,
  payload: {
    subscriptionKey
  }
})

// eslint-disable-next-line no-unused-vars
const closeIncident = (incident, user) => async (dispatch, getState) => {
  GlobalConfig.showProgress({
    state: Constants.ProgressStates.IN_PROGRESS,
    message: I18n.t('closingIncident'),
    closeable: false
  })

  const functions = getFunctions()
  const handleAlertIncidentAcknowledged = httpsCallable(
    functions,
    'handleAlertIncidentAcknowledgedHttps'
  )
  await handleAlertIncidentAcknowledged({
    uid: user.uid,
    username: user.displayName,
    enterpriseAccountId: incident.enterpriseAccountId,
    alertId: incident.alertId,
    incidentId: incident.id,
    acknowledgedAt: Date.now(),
    acknowledgedBy: user.uid
  })

  GlobalConfig.hideProgress()

  logEvent(
    GlobalConfig.analytics,
    Constants.UserAnalyticsEvents.CLOSE_INCIDENT_FROM_UI,
    {}
  )
}

export default {
  resetApp,
  setAccountInfo,
  setSubscription,
  setProduct,
  setMembers,
  addMember,
  editMember,
  removeMember,
  addTeam,
  editTeam,
  removeTeam,
  addAlert,
  editAlert,
  removeAlert,
  addSubscriptionKey,
  editSubscriptionKey,
  removeSubscriptionKey,
  addRotation,
  editRotation,
  removeRotation,
  closeIncident
}
