import GlobalConfig from '../GlobalConfig'
import { I18n } from 'galarm-config'
import { Box, Button, Layer, Menu, Text } from 'grommet'
import React, { useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import UserContext from './UserContext'
import { doc, getDoc } from 'firebase/firestore'
import { useSelector } from 'react-redux'
import { getAuth, signOut } from 'firebase/auth'
import Feedback from './Feedback'
import { Close } from 'grommet-icons'

const GalarmEnterpriseHeader = () => {
  const { user, userRoles } = useContext(UserContext)
  const accountInfo = useSelector(state => state.accountInfo)

  const [showContactSupport, setShowContactSupport] = useState(false)
  const hideContactSupport = () => setShowContactSupport(false)
  const displayContactSupport = () => setShowContactSupport(true)

  const navigate = useNavigate()
  const location = useLocation()

  const onLogOut = async () => {
    const auth = getAuth()
    await signOut(auth)
    window.localStorage.removeItem('enterpriseAccountId')
    navigate('/login', { replace: true })
  }

  const onLogin = () => {
    navigate('/login', { replace: true })
  }

  const onChangePassword = async () => {
    navigate('/changePassword')
  }

  const onGoToAccount = () => {
    navigate('/app/account')
  }

  const onNewOrganization = () => {
    const enterpriseUser = getDoc(
      doc(GlobalConfig.firestoreDb, 'enterpriseUsers', user.uid)
    ).then(userSnapshot => userSnapshot.data())

    // This is due to a Firestore limitation on the size of the array
    if (enterpriseUser?.organizations?.length >= 10) {
      GlobalConfig.showTransientAlert({
        message: I18n.t('maxOrganizationsReached'),
        type: 'error'
      })
    }
    navigate('/app/buy')
    return
  }

  return (
    <Box align="center" justify="end" pad="small" direction="row">
      {!location.pathname.includes('login') &&
        !location.pathname.includes('signup') &&
        !location.pathname.includes('getStarted') &&
        (user ? (
          <Box align="end">
            <Box direction="row" align="center" gap="medium">
              <Link
                style={{
                  color: 'blue',
                  textDecoration: 'none'
                }}
                to="/app"
                rel="noopener noreferrer">
                {I18n.t('home')}
              </Link>
              {user && (
                <Text onClick={displayContactSupport} color="blue">
                  {I18n.t('contactSupport')}
                </Text>
              )}
              <Menu
                label={I18n.t('welcomeUser', {
                  name: user.displayName || 'User'
                })}
                items={[
                  [
                    {
                      label: I18n.t('myAccount'),
                      onClick: onGoToAccount
                    },
                    {
                      label: I18n.t('newOrganization'),
                      onClick: onNewOrganization
                    }
                  ],
                  [
                    {
                      label: I18n.t('changePassword'),
                      onClick: onChangePassword
                    },
                    { label: I18n.t('logOut'), onClick: onLogOut }
                  ]
                ]}
              />
            </Box>
            {!!accountInfo.name && userRoles.length >= 1 && (
              <Text
                color="darkTint"
                size="small"
                alignSelf="end"
                margin={{ horizontal: 'small' }}>
                {I18n.t('loggedIntoOrganizationAccount', {
                  organizationName: accountInfo.name,
                  role: userRoles.join(', ')
                })}
              </Text>
            )}
          </Box>
        ) : (
          <Button
            primary
            label={I18n.t('login')}
            style={{ color: 'white' }}
            onClick={onLogin}
          />
        ))}
      {showContactSupport && (
        <Layer
          background="lightTint"
          onEsc={hideContactSupport}
          onClickOutside={hideContactSupport}
          margin="medium">
          <Box margin="medium">
            <Feedback onClose={hideContactSupport} />
            <Box style={{ position: 'absolute', top: 20, right: 20 }}>
              <Close onClick={hideContactSupport} />
            </Box>
          </Box>
        </Layer>
      )}
    </Box>
  )
}

export default GalarmEnterpriseHeader
