import { Box, Button, Form, FormField, Heading, TextInput } from 'grommet'
import React, { useState } from 'react'
import ErrorProvider from './ErrorProvider'
import GlobalConfig from '../GlobalConfig'
import { Constants, I18n } from 'galarm-config'
import { stringNotEmpty } from '../utils/validations'
import ReactSelect from 'react-select'
import { StringUtils } from 'galarm-shared'
import FormFieldWithAllBorders from './FormFieldWithAllBorders'
import { collection, doc, setDoc, updateDoc } from 'firebase/firestore'
import { logEvent } from 'firebase/analytics'
import { SecondaryText } from 'web-components'

const EditTeam = ({ isEdit, team, members, onClose }) => {
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')

  const INITIAL_STATE = isEdit
    ? {
        id: team.id,
        name: team.name,
        members: team.members
      }
    : {
        id: doc(
          collection(
            GlobalConfig.firestoreDb,
            'enterpriseAccounts',
            enterpriseAccountId,
            'teams'
          )
        ).id,
        name: '',
        members: []
      }

  const [value, setValue] = useState(INITIAL_STATE)

  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const onMembersChange = selectedMembers => {
    setValue({
      ...value,
      members: selectedMembers.map(member => member.id)
    })
  }

  const onEditTeam = async ({ value: team }) => {
    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: isEdit ? I18n.t('editingTeam') : I18n.t('addingTeam'),
      closeable: false
    })
    try {
      if (isEdit) {
        await updateDoc(
          doc(
            GlobalConfig.firestoreDb,
            'enterpriseAccounts',
            enterpriseAccountId,
            'teams',
            team.id
          ),
          { ...team }
        )
      } else {
        await setDoc(
          doc(
            GlobalConfig.firestoreDb,
            'enterpriseAccounts',
            enterpriseAccountId,
            'teams',
            team.id
          ),
          { ...team }
        )
      }
      GlobalConfig.hideProgress()

      logEvent(
        GlobalConfig.analytics,
        isEdit
          ? Constants.UserAnalyticsEvents.EDIT_TEAM
          : Constants.UserAnalyticsEvents.ADD_TEAM,
        {}
      )
      onClose()
    } catch (error) {
      console.error(error)
      addError(error.message)
      GlobalConfig.hideProgress()
    }
  }

  const membersWithLicense = members.filter(member => member.subscriptionKey)

  const selectedMembers = value.members.map(memberId => {
    return members.find(member => member.id === memberId)
  })

  const customStyles = {
    control: provided => ({
      ...provided,
      border: 'none'
    })
  }

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Box align="center" flex={{ shrink: 0 }}>
        <Heading level={4}>
          {isEdit ? I18n.t('editTeam') : I18n.t('addTeam')}
        </Heading>
        <Box
          background="white"
          pad="small"
          round="small"
          margin="medium"
          width="large">
          <Form
            value={value}
            onChange={nextValue => setValue(nextValue)}
            validate="submit"
            onSubmit={teamData => {
              onEditTeam(teamData)
            }}>
            <FormField
              htmlFor="name"
              name="name"
              label={
                <SecondaryText weight={500}>{I18n.t('name')}</SecondaryText>
              }
              validate={stringNotEmpty.bind(null, I18n.t('name'))}>
              <TextInput
                id="name"
                name="name"
                placeholder={I18n.t('enterTeamName')}
              />
            </FormField>
            <FormFieldWithAllBorders
              htmlFor="members"
              name="members"
              label={
                <SecondaryText weight={500}>{I18n.t('members')}</SecondaryText>
              }
              validate={members => {
                if (members.length === 0) {
                  return I18n.t('selectAtLeastOneMember')
                }
              }}>
              <ReactSelect
                id="members"
                name="members"
                styles={customStyles}
                isMulti
                options={membersWithLicense}
                getOptionLabel={member =>
                  StringUtils.createDisplayName(
                    member.firstName,
                    member.lastName
                  )
                }
                getOptionValue={member => member.id}
                onChange={onMembersChange}
                value={selectedMembers}
              />
            </FormFieldWithAllBorders>
            <Box direction="row" gap="medium" justify="center" margin="medium">
              <Button
                type="submit"
                primary
                style={{ color: 'white' }}
                label={isEdit ? I18n.t('save') : I18n.t('add')}
              />
            </Box>
          </Form>
        </Box>
      </Box>
    </ErrorProvider>
  )
}

export default EditTeam
