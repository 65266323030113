import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  Layer,
  List,
  Menu,
  Text
} from 'grommet'
import React, { useContext, useState } from 'react'
import GlobalConfig from '../GlobalConfig'
import { Constants, I18n } from 'galarm-config'
import { Add, Close, MoreVertical } from 'grommet-icons'
import EditTeam from './EditTeam'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import ErrorProvider from './ErrorProvider'
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where
} from 'firebase/firestore'
import WithRoles from './WithRoles'
import UserContext from './UserContext'
import { logEvent } from 'firebase/analytics'

const TeamSummary = ({ item, index, onEditTeam, onDeleteTeam, userRoles }) => {
  const menuItems = [
    { label: I18n.t('edit'), onClick: () => onEditTeam(item) },
    { label: I18n.t('delete'), onClick: () => onDeleteTeam(item) }
  ]

  return (
    <Box
      direction="row"
      justify="between"
      align="center"
      background={index % 2 ? 'lightTint' : 'white'}
      pad="small">
      <Text margin="small">{item.name}</Text>
      <WithRoles
        requiredRoles={[
          Constants.ENTERPRISE_USER_ROLES.OWNER,
          Constants.ENTERPRISE_USER_ROLES.ADMIN
        ]}
        assignedRoles={userRoles}>
        <Menu
          dropBackground="lightTint"
          alignSelf="start"
          size="small"
          icon={<MoreVertical color="textColor" />}
          items={menuItems}
        />
      </WithRoles>
    </Box>
  )
}

const Teams = () => {
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')
  const { userRoles } = useContext(UserContext)

  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const [showEditTeamDialog, setShowEditTeamDialog] = useState({})
  const displayAddTeamDialog = () =>
    setShowEditTeamDialog({ isEdit: false, team: {} })
  const displayEditTeamDialog = team =>
    setShowEditTeamDialog({ isEdit: true, team })
  const hideEditTeamDialog = () => setShowEditTeamDialog({})

  const members = useSelector(state => state.accountInfo.members)
  const teams = useSelector(state => state.accountInfo.teams)

  const onEditTeam = team => {
    const teamToEdit = {
      ...team,
      members: Object.values(team.members)
    }

    displayEditTeamDialog(teamToEdit)
  }

  const findAlertsAssignedToTeam = async team => {
    const alerts = []
    const teamAssigneeQuery = query(
      collection(
        GlobalConfig.firestoreDb,
        'enterpriseAccounts',
        enterpriseAccountId,
        'alerts'
      ),
      where('assignees', 'array-contains', team.id)
    )
    const teamAssigneeSnapshot = await getDocs(teamAssigneeQuery)
    teamAssigneeSnapshot.forEach(alertDoc => {
      alerts.push(alertDoc.data())
    })

    return { alerts }
  }

  const onDeleteTeam = async team => {
    try {
      GlobalConfig.showProgress({
        state: Constants.ProgressStates.IN_PROGRESS,
        message: I18n.t('deletingTeam'),
        closeable: false
      })

      const { alerts } = await findAlertsAssignedToTeam(team)

      if (alerts.length > 0) {
        throw new Error(
          I18n.t('cantDeleteTeamAssignedToAlerts', {
            alerts: alerts.join(', ')
          })
        )
      }

      await deleteDoc(
        doc(
          GlobalConfig.firestoreDb,
          'enterpriseAccounts',
          enterpriseAccountId,
          'teams',
          team.id
        )
      )

      GlobalConfig.hideProgress()

      logEvent(
        GlobalConfig.analytics,
        Constants.UserAnalyticsEvents.DELETE_TEAM,
        {}
      )
    } catch (error) {
      console.error(error)
      addError(error.message)
      GlobalConfig.hideProgress()
    }
  }

  const sortedTeams = teams.sort((team1, team2) =>
    team1.name.localeCompare(team2.name)
  )

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Box pad="small" gap="small" width="large">
        <Box direction="row" justify="between" flex={{ shrink: 0 }}>
          <Heading margin="small" level={3}>
            {I18n.t('teams')}
          </Heading>
          <WithRoles
            requiredRoles={[
              Constants.ENTERPRISE_USER_ROLES.OWNER,
              Constants.ENTERPRISE_USER_ROLES.ADMIN
            ]}
            assignedRoles={userRoles}>
            <Button
              primary
              icon={<Add color="white" />}
              label={I18n.t('team')}
              style={{ color: 'white' }}
              onClick={displayAddTeamDialog}
            />
          </WithRoles>
        </Box>
        <Card background="textBackgroundColor">
          <CardBody margin="none" overflow="auto">
            {teams.length === 0 ? (
              <Text margin="small">
                {I18n.t('noTeamsConfiguredInEnterpriseAccount')}
              </Text>
            ) : (
              <List border={false} pad="none" margin="none" data={sortedTeams}>
                {(item, index) => (
                  <TeamSummary
                    item={item}
                    index={index}
                    onEditTeam={onEditTeam}
                    onDeleteTeam={onDeleteTeam}
                    userRoles={userRoles}
                  />
                )}
              </List>
            )}
          </CardBody>
        </Card>
      </Box>
      {!isEmpty(showEditTeamDialog) && (
        <Layer
          background="lightTint"
          onEsc={hideEditTeamDialog}
          onClickOutside={hideEditTeamDialog}
          margin="medium">
          <Box margin="medium">
            <EditTeam
              isEdit={showEditTeamDialog.isEdit}
              team={showEditTeamDialog.team}
              members={members}
              onClose={hideEditTeamDialog}
            />
            <Box style={{ position: 'absolute', top: 20, right: 20 }}>
              <Close onClick={hideEditTeamDialog} />
            </Box>
          </Box>
        </Layer>
      )}
    </ErrorProvider>
  )
}

export default Teams
