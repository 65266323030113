import React from 'react'

const UserContext = React.createContext({
  user: null,
  userLoaded: false,
  userRoles: [],
  setUserRoles: () => {
    console.log('called dummy setUserRoles')
  }
})

export default UserContext
