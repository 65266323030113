import React, { useContext, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  Layer,
  List,
  Menu,
  Text
} from 'grommet'
import GlobalConfig from '../GlobalConfig'
import { Constants, I18n } from 'galarm-config'
import { Add, Checkmark, Close, MoreVertical } from 'grommet-icons'
import EditMember from './EditMember'
import { StringUtils } from 'galarm-shared'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import ErrorProvider from './ErrorProvider'
import {
  arrayRemove,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDocs,
  query,
  updateDoc,
  where
} from 'firebase/firestore'
import UserContext from './UserContext'
import WithRoles from './WithRoles'
import { update } from 'firebase/database'
import { logEvent } from 'firebase/analytics'
import { SecondaryText } from 'web-components'

const MemberSummary = ({
  item,
  index,
  onAssignKey,
  onRevokeKey,
  onEditMember,
  onRemoveMember,
  userRoles
}) => {
  const menuItems = [
    { label: I18n.t('edit'), onClick: () => onEditMember(item) },
    { label: I18n.t('delete'), onClick: () => onRemoveMember(item) }
  ]

  if (item.subscriptionKey) {
    menuItems.unshift({
      label: I18n.t('revokeKeyFromMember'),
      onClick: () => onRevokeKey(item)
    })
  } else {
    menuItems.unshift({
      label: I18n.t('assignKeyToMember'),
      onClick: () => onAssignKey(item)
    })
  }

  return (
    <Box
      direction="row"
      justify="between"
      align="center"
      background={index % 2 ? 'lightTint' : 'white'}
      pad="small">
      <Text margin="small">
        {StringUtils.createDisplayName(item.firstName, item.lastName)}
      </Text>
      <Box direction="row" align="center" gap="small">
        {item.subscriptionKey && <Checkmark />}
        <WithRoles
          requiredRoles={[
            Constants.ENTERPRISE_USER_ROLES.OWNER,
            Constants.ENTERPRISE_USER_ROLES.ADMIN
          ]}
          assignedRoles={userRoles}>
          <Menu
            dropBackground="lightTint"
            alignSelf="start"
            size="small"
            icon={<MoreVertical color="textColor" />}
            items={menuItems}
          />
        </WithRoles>
      </Box>
    </Box>
  )
}

const Members = () => {
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')
  const { userRoles } = useContext(UserContext)

  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    console.log('Clearing error:', errorToClear)
    console.log('Errors:', errors)
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const [showEditMemberDialog, setShowEditMemberDialog] = useState({})
  const displayAddMemberDialog = () =>
    setShowEditMemberDialog({ isEdit: false, member: {} })
  const displayEditMemberDialog = member =>
    setShowEditMemberDialog({ isEdit: true, member: member })
  const hideEditMemberDialog = () => setShowEditMemberDialog({})

  const members = useSelector(state => state.accountInfo.members)

  const subscriptionKeys = useSelector(
    state => state.accountInfo.subscriptionKeys
  )
  const assignedSubscriptionKeys = subscriptionKeys.filter(
    subscriptionKey => subscriptionKey.assignedTo
  )
  const unassignedSubscriptionKeys = subscriptionKeys.filter(
    subscriptionKey => !subscriptionKey.assignedTo
  )

  const onAssignKeyToMember = member => {
    const memberId = member.id
    const memberAppUid = member.appUid

    const numUnassignedSubs = unassignedSubscriptionKeys.length
    if (numUnassignedSubs <= 0) {
      GlobalConfig.showAlert(
        I18n.t('cantAssignKeyToMember'),
        I18n.t('noEnterpriseLicenseKeyAvailable')
      )
      return
    }

    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: I18n.t('assigningKeyToMember'),
      closeable: false
    })

    const firebaseUpdateObj = {}

    const subscriptionKey = unassignedSubscriptionKeys.pop()

    firebaseUpdateObj[
      'userInfos/' +
        memberAppUid +
        '/enterpriseSubscriptions/' +
        enterpriseAccountId
    ] = true

    return updateDoc(
      doc(
        GlobalConfig.firestoreDb,
        'enterpriseAccounts',
        enterpriseAccountId,
        'subscriptionKeys',
        subscriptionKey.id
      ),
      { assignedTo: memberId }
    )
      .then(() => {
        return updateDoc(
          doc(
            GlobalConfig.firestoreDb,
            'enterpriseAccounts',
            enterpriseAccountId,
            'members',
            memberId
          ),
          { subscriptionKey: subscriptionKey.id }
        )
      })
      .then(() => {
        return update(GlobalConfig.rootFirebaseRef, firebaseUpdateObj)
      })
      .then(() => {
        GlobalConfig.hideProgress()
        logEvent(
          GlobalConfig.analytics,
          Constants.UserAnalyticsEvents.ASSIGN_KEY_TO_MEMBER,
          {}
        )
      })
      .catch(error => {
        console.error(error)
        addError(error.message)
        GlobalConfig.hideProgress()
      })
  }

  const onRevokeKeyFromMember = async member => {
    try {
      GlobalConfig.showProgress({
        state: Constants.ProgressStates.IN_PROGRESS,
        message: I18n.t('revokingKeyFromMember'),
        closeable: false
      })
      await revokeKeyFromMember(member)
      GlobalConfig.hideProgress()
      logEvent(
        GlobalConfig.analytics,
        Constants.UserAnalyticsEvents.REVOKE_KEY_FROM_MEMBER,
        {}
      )
    } catch (error) {
      console.error(error)
      addError(error.message)
      GlobalConfig.hideProgress()
    }
  }

  const revokeKeyFromMember = async member => {
    const { teams, alerts } = await findTeamsAndAlertsAssignedToMember(member)

    if (teams.length > 0 || alerts.length > 0) {
      throw new Error(
        I18n.t('cantRevokeKeyFromMemberAssignedToTeamsOrAlerts', {
          teams:
            teams.length > 0
              ? teams.map(team => team.name).join(', ')
              : I18n.t('none'),
          alerts:
            alerts.length > 0
              ? alerts.map(alert => alert.name).join(', ')
              : I18n.t('none')
        })
      )
    }

    const memberId = member.id
    const memberAppUid = member.appUid
    const subscriptionKey = subscriptionKeys.find(
      subscriptionKey => subscriptionKey.assignedTo === memberId
    )

    // Return if not subscription key assigned to member
    if (!subscriptionKey) {
      console.log('No subscription key assigned to member')
      return
    }

    const firebaseUpdateObj = {}
    firebaseUpdateObj[
      'userInfos/' +
        memberAppUid +
        '/enterpriseSubscriptions/' +
        enterpriseAccountId
    ] = null

    return updateDoc(
      doc(
        GlobalConfig.firestoreDb,
        'enterpriseAccounts',
        enterpriseAccountId,
        'subscriptionKeys',
        subscriptionKey.id
      ),
      {
        assignedTo: deleteField()
      }
    )
      .then(() => {
        return updateDoc(
          doc(
            GlobalConfig.firestoreDb,
            'enterpriseAccounts',
            enterpriseAccountId,
            'members',
            memberId
          ),
          { subscriptionKey: deleteField() }
        )
      })
      .then(() => {
        return update(GlobalConfig.rootFirebaseRef, firebaseUpdateObj)
      })
  }

  const onEditMember = member => {
    displayEditMemberDialog(member)
  }

  const findTeamsAndAlertsAssignedToMember = async member => {
    const teams = [],
      alerts = []
    const teamMemberQuery = query(
      collection(
        GlobalConfig.firestoreDb,
        'enterpriseAccounts',
        enterpriseAccountId,
        'teams'
      ),
      where('members', 'array-contains', member.id)
    )
    const teamMemberSnapshot = await getDocs(teamMemberQuery)
    teamMemberSnapshot.forEach(doc => {
      teams.push(doc.data())
    })

    const memberAssigneeQuery = query(
      collection(
        GlobalConfig.firestoreDb,
        'enterpriseAccounts',
        enterpriseAccountId,
        'alerts'
      ),
      where('assignees', 'array-contains', member.id)
    )
    const memberAssigneeSnapshot = await getDocs(memberAssigneeQuery)
    memberAssigneeSnapshot.forEach(doc => {
      alerts.push(doc.data())
    })

    console.log('Teams assigned to member:', teams)
    console.log('Alerts assigned to member:', alerts)
    return { teams, alerts }
  }

  const onRemoveMember = async member => {
    try {
      GlobalConfig.showProgress({
        state: Constants.ProgressStates.IN_PROGRESS,
        message: I18n.t('removingMember'),
        closeable: false
      })

      const { teams, alerts } = await findTeamsAndAlertsAssignedToMember(member)

      if (teams.length > 0 || alerts.length > 0) {
        throw new Error(
          I18n.t('cantRemoveMemberAssignedToTeamsOrAlerts', {
            teams: teams.length > 0 ? teams.join(', ') : I18n.t('none'),
            alerts: alerts.length > 0 ? alerts.join(', ') : I18n.t('none')
          })
        )
      }

      GlobalConfig.showProgress({
        state: Constants.ProgressStates.IN_PROGRESS,
        message: I18n.t('revokingKeyFromMember'),
        closeable: false
      })

      await revokeKeyFromMember(member)

      await deleteDoc(
        doc(
          GlobalConfig.firestoreDb,
          'enterpriseAccounts',
          enterpriseAccountId,
          'members',
          member.id
        )
      )

      await updateDoc(
        doc(GlobalConfig.firestoreDb, 'enterpriseUsers', member.id),
        {
          organizations: arrayRemove(enterpriseAccountId)
        }
      )
      GlobalConfig.hideProgress()
      logEvent(
        GlobalConfig.analytics,
        Constants.UserAnalyticsEvents.REMOVE_MEMBER,
        {}
      )
    } catch (error) {
      console.error(error)
      addError(error.message)
      GlobalConfig.hideProgress()
    }
  }

  const sortedMembers = members.sort((member1, member2) => {
    const member1Name = StringUtils.createDisplayName(
      member1.firstName,
      member1.lastName
    )
    const member2Name = StringUtils.createDisplayName(
      member2.firstName,
      member2.lastName
    )

    return member1Name.localeCompare(member2Name)
  })

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Box pad="small" gap="small" width="large">
        <Box direction="row" justify="between" flex={{ shrink: 0 }}>
          <Heading margin="small" level={3}>
            {I18n.t('members')}
          </Heading>
          <WithRoles
            requiredRoles={[
              Constants.ENTERPRISE_USER_ROLES.OWNER,
              Constants.ENTERPRISE_USER_ROLES.ADMIN
            ]}
            assignedRoles={userRoles}>
            <Button
              primary
              icon={<Add color="white" />}
              label={I18n.t('member')}
              style={{ color: 'white' }}
              onClick={displayAddMemberDialog}
            />
          </WithRoles>
        </Box>
        <Box pad={{ left: 'small' }}>
          <SecondaryText>
            {I18n.t('membersDescription', {
              numMembers: members.length,
              subscriptionKeysCount: subscriptionKeys.length,
              assignedSubscriptionKeysCount: assignedSubscriptionKeys.length,
              unassignedSubscriptionKeysCount: unassignedSubscriptionKeys.length
            })}
          </SecondaryText>
        </Box>
        <Card background="textBackgroundColor">
          <CardBody margin="none" overflow="auto">
            {members.length === 0 ? (
              <Text margin="small" style={{ whiteSpace: 'pre-line' }}>
                {I18n.t('noMembersConfiguredInEnterpriseAccount')}
              </Text>
            ) : (
              <List
                border={false}
                pad="none"
                margin="none"
                data={sortedMembers}>
                {(item, index) => (
                  <MemberSummary
                    item={item}
                    index={index}
                    onAssignKey={onAssignKeyToMember}
                    onRevokeKey={onRevokeKeyFromMember}
                    onEditMember={onEditMember}
                    onRemoveMember={onRemoveMember}
                    userRoles={userRoles}
                  />
                )}
              </List>
            )}
          </CardBody>
        </Card>
      </Box>
      {!isEmpty(showEditMemberDialog) && (
        <Layer
          background="lightTint"
          onEsc={hideEditMemberDialog}
          onClickOutside={hideEditMemberDialog}
          margin="medium">
          <Box margin="medium">
            <EditMember
              isEdit={showEditMemberDialog.isEdit}
              member={showEditMemberDialog.member}
              onAssignKey={onAssignKeyToMember}
              onClose={hideEditMemberDialog}
            />
            <Box style={{ position: 'absolute', top: 20, right: 20 }}>
              <Close onClick={hideEditMemberDialog} />
            </Box>
          </Box>
        </Layer>
      )}
    </ErrorProvider>
  )
}

export default Members
