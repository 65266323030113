import {
  Box,
  Heading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text
} from 'grommet'
import React, { useEffect, useState } from 'react'
import GlobalConfig from '../GlobalConfig'
import { I18n } from 'galarm-config'
import { StringUtils } from 'galarm-shared'
import DateTimeUtils from '../utils/DateTimeUtils'
import { useSelector } from 'react-redux'
import { collection, onSnapshot, query, where } from 'firebase/firestore'

const ViewIncidentEscalation = ({ incident }) => {
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')

  const [triggers, setTriggers] = useState([])

  const members = useSelector(state => state.accountInfo.members)

  useEffect(() => {
    const alarmsForIncidentQuery = query(
      collection(
        GlobalConfig.firestoreDb,
        'enterpriseAccounts',
        enterpriseAccountId,
        'alerts',
        incident.alertId,
        'alarms'
      ),
      where('incidentId', '==', incident.id)
    )

    const unsubscribe = onSnapshot(alarmsForIncidentQuery, querySnapshot => {
      const incidentTriggers = []
      querySnapshot.forEach(doc => {
        const alarm = doc.data()
        const alarmDate = alarm.date
        const alarmMembers = alarm.members
        const resolvedAssignees = alarmMembers.map(alarmMember => {
          const member = members.find(item => item.id === alarmMember.id)
          return {
            id: member.id,
            name: StringUtils.createDisplayName(
              member.firstName,
              member.lastName
            )
          }
        })
        incidentTriggers.push({
          date: alarmDate,
          assignees: resolvedAssignees
        })
      })
      setTriggers(incidentTriggers)
    })

    return () => unsubscribe()
  }, [])

  return (
    <Box align="center">
      <Heading level={4}>
        {I18n.t('escalationForIncidentsForAlert', {
          alertName: incident.alertName,
          incidentDate: DateTimeUtils.getDateTimeAsString(incident.timestamp)
        })}
      </Heading>
      <Box
        overflow="auto"
        background="white"
        pad="medium"
        round="small"
        margin="medium"
        width="large"
        gap="medium">
        {triggers.length === 0 && (
          <Box pad="small">
            <Text pad="small">{I18n.t('noTriggersForIncident')}</Text>
          </Box>
        )}
        {triggers.length > 0 && (
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope="col" border="bottom">
                  {I18n.t('date')}
                </TableCell>
                <TableCell scope="col" border="bottom">
                  {I18n.t('assignees')}
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {triggers.map((trigger, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell scope="row">
                      <Text weight={500}>
                        {DateTimeUtils.getDateTimeAsString(trigger.date)}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text>
                        {trigger.assignees
                          .map(assignee => assignee.name)
                          .join(', ')}
                      </Text>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        )}
      </Box>
    </Box>
  )
}

export default ViewIncidentEscalation
