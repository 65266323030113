import { FormField } from 'grommet'
import React from 'react'
import { ThemeContext } from 'styled-components'

const FormFieldWithAllBorders = props => {
  return (
    <ThemeContext.Extend
      value={{
        formField: {
          border: {
            side: 'all'
          }
        }
      }}>
      <FormField {...props} />
    </ThemeContext.Extend>
  )
}

export default FormFieldWithAllBorders
