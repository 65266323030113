import { I18n } from 'galarm-config'
import { Box, FormField, TextInput } from 'grommet'
import React from 'react'
import { SecondaryText } from 'web-components'

const EditHoursRotation = ({ value, onUpdateRotation }) => {
  const onChangeNumHours = event => {
    onUpdateRotation({
      ...value,
      rotationParams: {
        numHoursInShift: event.target.value
      }
    })
  }

  return (
    <Box>
      <FormField
        htmlFor="rotationParams.numHoursInShift"
        name="rotationParams.numHoursInShift"
        label={
          <SecondaryText weight={500}>
            {I18n.t('numberOfHoursInRotation')}
          </SecondaryText>
        }
        // validate={stringNotEmpty.bind(null, I18n.t('numberOfHoursInRotation'))}
      >
        <TextInput
          id="rotationParams.numHoursInShift"
          name="rotationParams.numHoursInShift"
          placeholder={I18n.t('specify')}
          onChange={onChangeNumHours}
          value={value.rotationParams.numHoursInShift}
        />
      </FormField>
    </Box>
  )
}

export default EditHoursRotation
