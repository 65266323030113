import { I18n } from 'galarm-config'
import { Box, Button, Form, FormField, Heading, TextInput } from 'grommet'
import React, { useState } from 'react'
import { stringNotEmpty } from '../utils/validations'
import { SecondaryText } from 'web-components'

const ProvideOrganizationInformation = ({ onCreateOrganizationAccount }) => {
  const [value, setValue] = useState({
    name: ''
  })

  const onSubmit = ({ value }) => {
    onCreateOrganizationAccount(value)
  }

  return (
    <Box align="center">
      <Heading level={4} margin={{ left: 'medium' }}>
        {I18n.t('tellUsAboutYourOrganization')}
      </Heading>
      <Box
        background="white"
        pad="small"
        round="small"
        margin="medium"
        width="large">
        <Form
          value={value}
          onChange={nextValue => setValue(nextValue)}
          validate="submit"
          onSubmit={onSubmit}>
          <FormField
            flex
            htmlFor="name"
            name="name"
            label={<SecondaryText weight={500}>{I18n.t('name')}</SecondaryText>}
            validate={stringNotEmpty.bind(null, I18n.t('name'))}>
            <TextInput id="name" name="name" />
          </FormField>
          <Box direction="row" gap="medium" justify="center" margin="medium">
            <Button
              type="submit"
              primary
              style={{ color: 'white' }}
              label={I18n.t('next')}
            />
          </Box>
        </Form>
      </Box>
    </Box>
  )
}

export default ProvideOrganizationInformation
