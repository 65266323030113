import './App.css'
import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Layout from './components/Layout'
import BuySubscription from './components/BuySubscription'
import Signup from './components/Signup'
import RequireAuth from './components/RequireAuth'
import Login from './components/Login'
import UserContext from './components/UserContext'
import Alerts from './components/Alerts'
import Dashboard from './components/Dashboard'
import NoOrganization from './components/NoOrganization'
import ChooseOrganization from './components/ChooseOrganization'
import RequireActiveSubscription from './components/RequireActiveSubscription'
import Account from './components/Account'
import NoMatch from './components/NoMatch'
import Members from './components/Members'
import Teams from './components/Teams'
import VerifyEmail from './components/VerifyEmail'
import ChangeOrganizationOwner from './components/ChangeOrganizationOwner'
import ResetTempPassword from './components/ResetTempPassword'
import ChangePassword from './components/ChangePassword'
import FirstAlertInstructions from './components/FirstAlertInstructions'
import Feedback from './components/Feedback'
import ForgotPassword from './components/ForgotPassword'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import BillingPortal from './components/BillingPortal'
import AppHomePage from './components/AppHomePage'
import Integrations from './components/Integrations'
import { Constants } from 'galarm-config'
import { WebUtils } from 'galarm-shared'
import { Grommet } from 'grommet'
import Rotations from './components/Rotations'
import EnterpriseAppLayout from './components/EnterpriseAppLayout'
import BusinessAppLayout from './components/BusinessAppLayout'
// import HomePage from './components/HomePage'

function App() {
  const [user, setUser] = useState(null)
  const [userLoaded, setUserLoaded] = useState(false)
  const [userRoles, setUserRoles] = useState([])

  const colorScheme = Constants.COLOR_SCHEMES.LIGHT
  const theme = WebUtils.getGrommetTheme(colorScheme)

  useEffect(() => {
    const auth = getAuth()
    const unsubscribeFromAuthChanges = onAuthStateChanged(auth, async user => {
      if (!user) {
        setUser(null)
        setUserRoles([])
        setUserLoaded(true)
      } else {
        console.log('logged in user', user.uid)
        setUser(user)
        setUserRoles([])
        setUserLoaded(true)
      }
    })
    return () => {
      unsubscribeFromAuthChanges()
    }
  }, [])

  return (
    <Grommet full theme={theme}>
      <UserContext.Provider
        value={{ user, userLoaded, userRoles, setUserRoles }}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Signup />} />
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="verifyEmail" element={<VerifyEmail />} />
            <Route path="changePassword" element={<ChangePassword />} />
            <Route path="resetTempPassword" element={<ResetTempPassword />} />
            <Route path="forgotPassword" element={<ForgotPassword />} />
            <Route path="integrations" element={<Integrations />} />
            <Route path="getStarted" element={<FirstAlertInstructions />} />
            <Route path="app" element={<RequireAuth />}>
              <Route index element={<AppHomePage />} />
              <Route path="buy" element={<BuySubscription />} />
              <Route path="noorg" element={<NoOrganization />} />
              <Route path="chooseorg" element={<ChooseOrganization />} />
              <Route path="account" element={<Account />} />
              <Route path="feedback" element={<Feedback />} />
              <Route path="org" element={<RequireActiveSubscription />}>
                <Route path="enterprise" element={<EnterpriseAppLayout />}>
                  <Route index element={<Dashboard />}></Route>
                  <Route path="members" element={<Members />}></Route>
                  <Route path="teams" element={<Teams />}></Route>
                  <Route path="rotations" element={<Rotations />}></Route>
                  <Route path="alerts" element={<Alerts />}></Route>
                </Route>
                <Route path="business" element={<BusinessAppLayout />}>
                  <Route index element={<Members />}></Route>
                  <Route path="members" element={<Members />}></Route>
                </Route>
                <Route
                  path="changeowner"
                  element={<ChangeOrganizationOwner />}></Route>
              </Route>
              <Route path="billing" element={<BillingPortal />} />
            </Route>
          </Route>
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </UserContext.Provider>
    </Grommet>
  )
}

export default App
