import { Constants, I18n } from 'galarm-config'
import { Box, Button, Form, FormField, Heading, Text, TextInput } from 'grommet'
import React, { useContext, useEffect, useState } from 'react'
import UserContext from './UserContext'
import { useNavigate } from 'react-router-dom'
import { getFunctions, httpsCallable } from 'firebase/functions'
import GlobalConfig from '../GlobalConfig'
import { isNumber } from '../utils/validations'
import { getAuth, signOut } from 'firebase/auth'

const VerifyEmail = () => {
  const { user } = useContext(UserContext)
  const navigate = useNavigate()

  const [value, setValue] = useState({ emailVerificationCode: '' })

  const onResend = async () => {
    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: I18n.t('sendingEmailVerificationLink'),
      closeable: false
    })
    const functions = getFunctions()
    const sendEmailVerificationLink = httpsCallable(
      functions,
      'sendEmailVerificationLink'
    )
    await sendEmailVerificationLink({ email: user.email })
    GlobalConfig.hideProgress()
  }

  useEffect(() => {
    if (user?.emailVerified) {
      navigate('/app', { replace: true })
    }
  }, [user])

  const onVerifyAccount = async ({ value }) => {
    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: I18n.t('verifyingCode'),
      closeable: false
    })

    try {
      const functions = getFunctions()
      const verifyEmail = httpsCallable(functions, 'verifyEmail')
      await verifyEmail({
        uid: user.uid,
        emailVerificationCode: parseInt(value.emailVerificationCode)
      })
      GlobalConfig.hideProgress()
      signOut(getAuth())
      GlobalConfig.showAlert(
        I18n.t('accountVerified'),
        I18n.t('accountVerifiedMessage')
      )
      navigate('/login', { replace: true })
    } catch (error) {
      GlobalConfig.showProgress({
        state: Constants.ProgressStates.ERROR,
        message: error.message,
        closeable: true
      })
      console.log(error)
    }
  }

  return (
    <Box justify="center" align="center" gap="small">
      <Heading margin="small" level={3}>
        {I18n.t('verifyAccount')}
      </Heading>
      <Box
        width="large"
        margin={{ horizontal: 'large' }}
        round="small"
        pad="small">
        <Text>{I18n.t('verifyAccountMessage')}</Text>
      </Box>
      <Form
        value={value}
        onChange={nextValue => setValue(nextValue)}
        validate="submit"
        onSubmit={onVerifyAccount}>
        <Box
          margin={{ horizontal: 'large' }}
          gap="small"
          justify="center"
          align="center">
          <FormField
            htmlFor="emailVerificationCode"
            name="emailVerificationCode"
            validate={isNumber.bind(
              null,
              I18n.t('code'),
              value.emailVerificationCode
            )}>
            <TextInput
              id="emailVerificationCode"
              name="emailVerificationCode"
              placeholder={I18n.t('verificationCode')}
            />
          </FormField>
          <Button
            type="submit"
            primary
            style={{ color: 'white' }}
            label={I18n.t('verifyAccount')}
          />
          <Box onClick={onResend} alignSelf="center" margin="small">
            <Text color="primary">{I18n.t('resendCode')}</Text>
          </Box>
        </Box>
      </Form>
    </Box>
  )
}

export default VerifyEmail
