import { I18n } from 'galarm-config'
import { FormField, TextInput } from 'grommet'
import React from 'react'
import { SecondaryText } from 'web-components'

const EditWeeksRotations = ({ value, onUpdateRotation }) => {
  const onChangeNumWeeks = event => {
    onUpdateRotation({
      ...value,
      rotationParams: {
        numWeeksInShift: event.target.value
      }
    })
  }

  return (
    <FormField
      htmlFor="rotationParams.numWeeksInShift"
      name="rotationParams.numWeeksInShift"
      label={
        <SecondaryText weight={500}>
          {I18n.t('numberOfWeeksInRotation')}
        </SecondaryText>
      }
      // validate={stringNotEmpty.bind(null, I18n.t('numberOfWeeksInRotation'))}
    >
      <TextInput
        id="rotationParams.numWeeksInShift"
        name="rotationParams.numWeeksInShift"
        placeholder={I18n.t('specify')}
        onChange={onChangeNumWeeks}
        value={value.rotationParams.numWeeksInShift}
      />
    </FormField>
  )
}

export default EditWeeksRotations
