import {
  Box,
  Heading,
  Layer,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text
} from 'grommet'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import GlobalConfig from '../GlobalConfig'
import { I18n } from 'galarm-config'
import { StringUtils } from 'galarm-shared'
import DateTimeUtils from '../utils/DateTimeUtils'
import { Close, MoreVertical } from 'grommet-icons'
import { isEmpty, uniqWith } from 'lodash'
import ViewIncidentEscalation from './ViewIncidentEscalation'
import { useDispatch, useSelector } from 'react-redux'
import { collection, onSnapshot, query } from 'firebase/firestore'
import UserContext from './UserContext'
import ActionCreators from '../actions/creators'
import IncidentNotes from './IncidentNotes'

function incidentsReducer(state, action) {
  switch (action.type) {
    case 'add':
      return {
        incidents: uniqWith(
          [action.incident].concat(state.incidents),
          (item1, item2) => item1.id === item2.id
        )
      }
    case 'remove':
      return {
        incidents: state.incidents.filter(
          incident => incident.id !== action.incident.id
        )
      }
    default:
      throw new Error('Unknown action type in incidentsReducer ' + action.type)
  }
}

const ViewIncidents = ({ alert, filter = 'none' }) => {
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')

  const globalDispatch = useDispatch()

  const { user } = useContext(UserContext)

  const { id: alertId, name: alertName, assignees: alertAssignees } = alert

  const [showIncidentMenu, setShowIncidentMenu] = useState(false)

  const members = useSelector(state => state.accountInfo.members)

  const [state, dispatch] = useReducer(incidentsReducer, { incidents: [] })

  const [showIncidentEscalationDialog, setShowIncidentEscalationDialog] =
    useState({})
  const displayIncidentEscalationDialog = incident =>
    setShowIncidentEscalationDialog({ incident })
  const hideIncidentEscalationDialog = () => setShowIncidentEscalationDialog({})

  const [showIncidentNotesDialog, setShowIncidentNotesDialog] = useState({})
  const displayIncidentNotesDialog = incident =>
    setShowIncidentNotesDialog({ incident })
  const hideIncidentNotesDialog = () => setShowIncidentNotesDialog({})

  useEffect(() => {
    if (alertAssignees.find(assignee => assignee.id === user.id)) {
      setShowIncidentMenu(true)
    }

    const incidentsQuery = query(
      collection(
        GlobalConfig.firestoreDb,
        'enterpriseAccounts',
        enterpriseAccountId,
        'alerts',
        alertId,
        'incidents'
      )
    )

    const unsubscribe = onSnapshot(incidentsQuery, incidentsSnapshot => {
      incidentsSnapshot.forEach(incidentSnapshot => {
        const incident = incidentSnapshot.data()
        if (
          (filter === 'acknowledged' && !incident.acknowledgements) ||
          filter === 'none'
        ) {
          dispatch({ type: 'add', incident: incidentSnapshot.data() })
        } else if (filter === 'acknowledged' && incident.acknowledgements) {
          dispatch({ type: 'remove', incident: incidentSnapshot.data() })
        }
      })
    })

    return () => unsubscribe()
  }, [])

  const getIncidentAcknowledgementString = ack => {
    let acknowledgedString
    const { by: acknowledgedBy, at: acknowledgedAt } = ack
    if (acknowledgedBy === 'system') {
      const dateString = DateTimeUtils.getDateTimeAsString(acknowledgedAt)
      acknowledgedString = I18n.t('alertIncidentRecovered', {
        dateString
      })
    } else {
      let memberName
      const acknowledgedByMember = members.find(
        item => item.id === acknowledgedBy || item.appUid === acknowledgedBy
      )
      if (acknowledgedByMember) {
        memberName = StringUtils.createDisplayName(
          acknowledgedByMember.firstName,
          acknowledgedByMember.lastName
        )
      } else if (acknowledgedBy === user.uid) {
        memberName = user.displayName
      } else {
        memberName = I18n.t('unknown')
      }

      const dateString = DateTimeUtils.getDateTimeAsString(acknowledgedAt)
      acknowledgedString = I18n.t('alertIncidentAcknowledged', {
        memberName,
        dateString
      })
    }
    return acknowledgedString
  }

  const sortedIncidents = state.incidents.sort((incident1, incident2) => {
    if (incident1.timestamp < incident2.timestamp) {
      return 1
    } else if (incident1.timestamp > incident2.timestamp) {
      return -1
    } else {
      return 0
    }
  })

  const onCloseIncident = async incident => {
    globalDispatch(ActionCreators.closeIncident(incident))
  }

  const getMenuItemsForIncident = incident => {
    const menuItems = [
      {
        label: I18n.t('viewIncidentNotes'),
        onClick: () => displayIncidentNotesDialog(incident)
      },
      {
        label: I18n.t('viewIncidentEscalation'),
        onClick: () => displayIncidentEscalationDialog(incident)
      }
    ]

    // Only show close incident if it is not already closed
    if (!incident.closedTimestamp) {
      menuItems.push({
        label: I18n.t('closeIncident'),
        onClick: () => onCloseIncident(incident)
      })
    }

    return menuItems
  }

  const isIncidentValid = incident => 'alertId' in incident

  return (
    <Box align="center">
      <Heading level={4}>{I18n.t('incidentsForAlert', { alertName })}</Heading>
      <Box
        overflow="auto"
        background="white"
        pad="medium"
        round="small"
        margin="medium"
        width="large"
        gap="medium">
        {sortedIncidents.length === 0 && (
          <Box pad="small">
            <Text pad="small">{I18n.t('noIncidentsForAlert')}</Text>
          </Box>
        )}
        {sortedIncidents.length > 0 && (
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope="col" border="bottom">
                  {I18n.t('timestamp')}
                </TableCell>
                <TableCell scope="col" border="bottom">
                  {I18n.t('acknowledged')}
                </TableCell>
                <TableCell scope="col" border="bottom" />
              </TableRow>
            </TableHeader>
            <TableBody>
              {sortedIncidents.map((incident, index) => {
                const acknowledged = incident.acknowledgements?.length > 0
                let acknowledgedString = I18n.t('no')
                if (acknowledged) {
                  acknowledgedString = incident.acknowledgements
                    .map(ack => getIncidentAcknowledgementString(ack))
                    .join(', ')
                }
                return (
                  <TableRow key={index}>
                    <TableCell scope="row" size="1/3" border="bottom">
                      <Text weight={500}>
                        {DateTimeUtils.getDateTimeAsString(incident.timestamp)}
                      </Text>
                    </TableCell>
                    <TableCell size="1/2" border="bottom">
                      <Text>{acknowledgedString}</Text>
                    </TableCell>
                    {showIncidentMenu && (
                      <TableCell border="bottom">
                        <Menu
                          dropBackground="lightTint"
                          alignSelf="start"
                          size="small"
                          icon={<MoreVertical color="textColor" />}
                          items={getMenuItemsForIncident(incident)}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        )}
      </Box>
      {!isEmpty(showIncidentEscalationDialog) &&
        isIncidentValid(showIncidentEscalationDialog.incident) && (
          <Layer
            background="lightTint"
            onEsc={hideIncidentEscalationDialog}
            onClickOutside={hideIncidentEscalationDialog}>
            <Box margin="medium">
              <ViewIncidentEscalation
                incident={showIncidentEscalationDialog.incident}
                onClose={hideIncidentEscalationDialog}
              />
              <Box style={{ position: 'absolute', top: 20, right: 20 }}>
                <Close onClick={hideIncidentEscalationDialog} />
              </Box>
            </Box>
          </Layer>
        )}
      {!isEmpty(showIncidentNotesDialog) &&
        isIncidentValid(showIncidentNotesDialog.incident) && (
          <Layer
            background="lightTint"
            onEsc={hideIncidentNotesDialog}
            onClickOutside={hideIncidentNotesDialog}>
            <Box margin="medium">
              <IncidentNotes
                incident={showIncidentNotesDialog.incident}
                onClose={hideIncidentNotesDialog}
              />
              <Box style={{ position: 'absolute', top: 20, right: 20 }}>
                <Close onClick={hideIncidentNotesDialog} />
              </Box>
            </Box>
          </Layer>
        )}
    </Box>
  )
}

export default ViewIncidents
