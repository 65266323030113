import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Box } from 'grommet'
import GlobalConfig from '../GlobalConfig'
import { Navigate } from 'react-router-dom'
import UserContext from './UserContext'
import { collection, doc, onSnapshot, query } from 'firebase/firestore'
import GetStarted from './GetStarted'
import BuySubscription from './BuySubscription'

function enterpriseAccountDataReducer(state, action) {
  switch (action.type) {
    case 'updateHasSubscription':
      return {
        ...state,
        hasSubscription: action.hasSubscription
      }
    case 'updateHasMembers':
      return {
        ...state,
        hasMembers: action.hasMembers
      }
    case 'updateHasAlerts':
      return {
        ...state,
        hasAlerts: action.hasAlerts
      }
    default:
      throw new Error(
        'Unknown action type in enterpriseAccountDataReducer ' + action.type
      )
  }
}

const AppHomePage = () => {
  const { user } = useContext(UserContext)

  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')

  const [initialSetupComplete, setInitialSetupComplete] = useState(false)

  const [state, dispatch] = useReducer(enterpriseAccountDataReducer, {
    hasSubscription: false,
    hasMembers: false,
    hasAlerts: false
  })

  useEffect(() => {
    let unsubscribeMembers, unsubscribeAlerts, unsubscribeEnterpriseAccount

    if (enterpriseAccountId) {
      console.log(
        'Setting up members and alerts listeners',
        enterpriseAccountId
      )

      unsubscribeEnterpriseAccount = onSnapshot(
        doc(
          GlobalConfig.firestoreDb,
          'enterpriseAccounts',
          enterpriseAccountId
        ),
        doc => {
          const enterpriseAccount = doc.data()
          if (enterpriseAccount.subscriptionData?.subscriptionId) {
            dispatch({
              type: 'updateHasSubscription',
              hasSubscription: true
            })
            if (enterpriseAccount.subscriptionData.planType === 'business') {
              setInitialSetupComplete(true)
            }
          } else {
            dispatch({
              type: 'updateHasSubscription',
              hasSubscription: false
            })
          }
          if (enterpriseAccount.initialSetupMarkers?.setupComplete) {
            setInitialSetupComplete(true)
          }
        }
      )

      const membersQuery = query(
        collection(
          GlobalConfig.firestoreDb,
          'enterpriseAccounts',
          enterpriseAccountId,
          'members'
        )
      )
      unsubscribeMembers = onSnapshot(membersQuery, membersSnap => {
        membersSnap.size > 0
          ? dispatch({ type: 'updateHasMembers', hasMembers: true })
          : dispatch({ type: 'updateHasMembers', hasMembers: false })
      })

      const alertsQuery = query(
        collection(
          GlobalConfig.firestoreDb,
          'enterpriseAccounts',
          enterpriseAccountId,
          'alerts'
        )
      )
      unsubscribeAlerts = onSnapshot(alertsQuery, alertsSnap => {
        alertsSnap.size > 0
          ? dispatch({ type: 'updateHasAlerts', hasAlerts: true })
          : dispatch({ type: 'updateHasAlerts', hasAlerts: false })
      })
    }

    return () => {
      unsubscribeEnterpriseAccount?.()
      unsubscribeMembers?.()
      unsubscribeAlerts?.()
    }
  }, [enterpriseAccountId])

  console.log('AppHomePage', user.uid)

  if (initialSetupComplete) {
    console.log('Initial setup complete, redirecting to /app/org')
    return <Navigate to="/app/org" />
  }

  if (!state.hasSubscription) {
    return <BuySubscription />
  }

  return (
    <Box width="large">
      <GetStarted
        enterpriseAccountData={{
          hasMembers: state.hasMembers,
          hasAlerts: state.hasAlerts
        }}
      />
    </Box>
  )
}

export default AppHomePage
