import React, { useState } from 'react'
import Organizations from './Organizations'
import YourInformation from './YourInformation'
import ErrorProvider from './ErrorProvider'

const Account = () => {
  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  console.log('Rendering Account')

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <YourInformation />
      <Organizations showOrgOperations addError={addError} />
    </ErrorProvider>
  )
}

export default Account
