import { getFunctions, httpsCallable } from 'firebase/functions'
import { Spinner } from 'grommet'
import React, { useEffect, useState } from 'react'
import ErrorProvider from './ErrorProvider'
import { I18n } from 'galarm-config'
import { useLocation } from 'react-router-dom'

const BillingPortal = () => {
  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const location = useLocation()
  const customerId = location.state?.customerId

  useEffect(() => {
    async function createCustomerPortalSession() {
      if (!customerId) {
        addError(I18n.t('problemWithEnterpriseAccountSetup'))
        return
      }

      const functions = getFunctions()
      const stripeCreateCustomerPortalSession = httpsCallable(
        functions,
        'stripeCreateCustomerPortalSession'
      )

      try {
        const resultSession = await stripeCreateCustomerPortalSession({
          customerId: customerId
        })

        console.log('customerPortalSession', resultSession.data.session)
        window.location.href = resultSession.data.session.url
      } catch (error) {
        addError(I18n.t('problemWithCreatingCustomerPortalSession'))
        return
      }
    }
    createCustomerPortalSession()
  }, [])

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Spinner />
    </ErrorProvider>
  )
}

export default BillingPortal
