import { arrayRemove, deleteField, doc, updateDoc } from 'firebase/firestore'
import { StringUtils } from 'galarm-shared'
import { Box, Button, CheckBox, Heading, List, Text } from 'grommet'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import GlobalConfig from '../GlobalConfig'
import { update } from 'firebase/database'
import { Constants, I18n } from 'galarm-config'
import { getFunctions, httpsCallable } from 'firebase/functions'

const MemberSummary = ({ item, selected, index, onToggleMember }) => {
  return (
    <Box
      direction="row"
      justify="between"
      align="center"
      background={index % 2 ? 'lightTint' : 'white'}
      pad="small">
      <Text margin="small">
        {StringUtils.createDisplayName(item.firstName, item.lastName)}
      </Text>
      <CheckBox checked={selected} onChange={() => onToggleMember(item)} />
    </Box>
  )
}

const RevokeMembersKey = ({ onClose }) => {
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')
  const members = useSelector(state => state.accountInfo.members)
  const subscriptionKeys = useSelector(
    state => state.accountInfo.subscriptionKeys
  )

  const [selectedMembers, setSelectedMembers] = useState([])

  const onToggleMember = member => {
    const newSelectedMembers = [...selectedMembers]
    const memberIndex = newSelectedMembers.findIndex(
      selectedMember => selectedMember.id === member.id
    )

    if (memberIndex === -1) {
      newSelectedMembers.push(member)
    } else {
      newSelectedMembers.splice(memberIndex, 1)
    }

    setSelectedMembers(newSelectedMembers)
  }

  const revokeKeyFromMember = async member => {
    const memberId = member.id
    const memberAppUid = member.appUid
    const subscriptionKey = subscriptionKeys.find(
      subscriptionKey => subscriptionKey.assignedTo === memberId
    )

    // Return if not subscription key assigned to member
    if (!subscriptionKey) {
      console.log('No subscription key assigned to member')
      return
    }

    if (!isEmpty(member.teams)) {
      await Promise.all(
        member.teams.map(team =>
          updateDoc(
            doc(
              GlobalConfig.firestoreDb,
              'enterpriseAccounts',
              enterpriseAccountId,
              'teams',
              team.id
            ),
            {
              members: arrayRemove(member.id)
            }
          )
        )
      )
    }

    const firebaseUpdateObj = {}
    firebaseUpdateObj[
      'userInfos/' +
        memberAppUid +
        '/enterpriseSubscriptions/' +
        subscriptionKey.id
    ] = null

    return updateDoc(
      doc(
        GlobalConfig.firestoreDb,
        'enterpriseAccounts',
        enterpriseAccountId,
        'subscriptionKeys',
        subscriptionKey.id
      ),
      {
        assignedTo: deleteField()
      }
    )
      .then(() => {
        return updateDoc(
          doc(
            GlobalConfig.firestoreDb,
            'enterpriseAccounts',
            enterpriseAccountId,
            'members',
            memberId
          ),
          { subscriptionKey: deleteField() }
        )
      })
      .then(() => {
        return update(GlobalConfig.rootFirebaseRef, firebaseUpdateObj)
      })
      .then(async () => {
        const functions = getFunctions()
        const syncSubscriptionQtyAndKeys = httpsCallable(
          functions,
          'syncSubscriptionQtyAndKeys'
        )
        return syncSubscriptionQtyAndKeys({
          enterpriseAccountId
        })
      })
  }

  const revokeMembersKey = async () => {
    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: I18n.t('revokingKeyFromMember'),
      closeable: false
    })

    try {
      await Promise.all(
        selectedMembers.map(member => revokeKeyFromMember(member))
      )
    } catch (error) {
      console.error(error, "Unable to revoke members' key")
      GlobalConfig.showProgress({
        state: Constants.ProgressStates.ERROR,
        message: I18n.t('unableToRevokeMembersKey'),
        closeable: true
      })
      return
    }

    GlobalConfig.hideProgress()
  }

  const onRevokeMembersKey = async () => {
    await revokeMembersKey()
    onClose()
  }

  const filteredSortedMembers = members
    .filter(member => member.subscriptionKey)
    .sort((member1, member2) => {
      const member1Name = StringUtils.createDisplayName(
        member1.firstName,
        member1.lastName
      )
      const member2Name = StringUtils.createDisplayName(
        member2.firstName,
        member2.lastName
      )

      return member1Name.localeCompare(member2Name)
    })

  return (
    <Box>
      <Heading level={4}>{I18n.t('revokeMembersKey')}</Heading>
      <Text>{I18n.t('revokeMembersKeyDescription')}</Text>
      <List
        border={false}
        pad="none"
        margin="none"
        data={filteredSortedMembers}>
        {(item, index) => (
          <MemberSummary
            item={item}
            selected={selectedMembers.includes(item)}
            index={index}
            onToggleMember={onToggleMember}
          />
        )}
      </List>
      <Button
        margin={{ top: 'medium' }}
        alignSelf="center"
        label={I18n.t('revoke')}
        onClick={onRevokeMembersKey}
      />
    </Box>
  )
}

export default RevokeMembersKey
