import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  Layer,
  List,
  Menu,
  Text
} from 'grommet'
import React, { useContext, useState } from 'react'
import { Constants, I18n } from 'galarm-config'
import GlobalConfig from '../GlobalConfig'
import { Add, Close, MoreVertical } from 'grommet-icons'
import EditAlert from './EditAlert'
import { useSelector } from 'react-redux'
import ErrorProvider from './ErrorProvider'
import { isEmpty } from 'lodash'
import ViewApiCode from './ViewApiCode'
import ViewIncidents from './ViewIncidents'
import { collection, getDocs } from 'firebase/firestore'
import UserContext from './UserContext'
import WithRoles from './WithRoles'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { update } from 'firebase/database'
import { logEvent } from 'firebase/analytics'
import AlertLogs from './AlertLogs'

const AlertSummary = ({
  item,
  index,
  onEditAlert,
  onDeleteAlert,
  onViewCode,
  onViewIncidents,
  onTriggerAlert,
  onViewAlertLogs,
  userRoles
}) => {
  const menuItems = []

  if (
    [
      Constants.ENTERPRISE_USER_ROLES.OWNER,
      Constants.ENTERPRISE_USER_ROLES.ADMIN
    ].some(role => userRoles.includes(role))
  ) {
    menuItems.push({ label: I18n.t('edit'), onClick: () => onEditAlert(item) })
    menuItems.push({
      label: I18n.t('delete'),
      onClick: () => onDeleteAlert(item)
    })
  }
  menuItems.push({
    label: I18n.t('viewLogs'),
    onClick: () => onViewAlertLogs(item)
  })
  menuItems.push({ label: I18n.t('viewCode'), onClick: () => onViewCode(item) })
  menuItems.push({
    label: I18n.t('viewIncidents'),
    onClick: () => onViewIncidents(item)
  })
  menuItems.push({
    label: I18n.t('triggerAlert'),
    onClick: () => onTriggerAlert(item)
  })

  return (
    <Box
      direction="row"
      justify="between"
      align="center"
      background={index % 2 ? 'lightTint' : 'white'}
      pad="small">
      <Text margin="small">{item.name}</Text>
      <Menu
        dropBackground="lightTint"
        alignSelf="start"
        size="small"
        icon={<MoreVertical color="textColor" />}
        items={menuItems}
        dropAlign={{ top: 'bottom', right: 'right' }}
      />
    </Box>
  )
}

const Alerts = () => {
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')
  const { user, userRoles } = useContext(UserContext)

  const [showEditAlertDialog, setShowEditAlertDialog] = useState({})
  const displayAddAlertDialog = () =>
    setShowEditAlertDialog({
      isEdit: false,
      alert: {}
    })
  const displayEditAlertDialog = alert =>
    setShowEditAlertDialog({
      isEdit: true,
      alert
    })
  const hideEditAlertDialog = () => setShowEditAlertDialog({})

  const [showApiCodeDialog, setShowApiCodeDialog] = useState({})
  const displayApiCodeDialog = alert =>
    setShowApiCodeDialog({ alert, showIntroForNewAlert: false })
  const displayApiCodeDialogForNewAlert = alert =>
    setShowApiCodeDialog({ alert, showIntroForNewAlert: true })
  const hideApiCodeDialog = () => setShowApiCodeDialog({})

  const [viewIncidentsDialog, setViewIncidentsDialog] = useState({})
  const displayIncidentsDialog = alert => setViewIncidentsDialog({ alert })
  const hideIncidentsDialog = () => setViewIncidentsDialog({})

  const [viewAlertLogsDialog, setViewAlertLogsDialog] = useState({})
  const displayAlertLogsDialog = alert => setViewAlertLogsDialog({ alert })
  const hideAlertLogsDialog = () => setViewAlertLogsDialog({})

  const [errors, setErrors] = useState([])
  // eslint-disable-next-line no-unused-vars
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const alerts = useSelector(state => state.accountInfo.alerts)

  const onEditAlert = alert => {
    const alertToEdit = {
      ...alert,
      integrations: Object.keys(alert.integrations || {})
    }

    displayEditAlertDialog(alertToEdit)
  }

  const onViewCode = alert => {
    displayApiCodeDialog(alert)
  }

  const onViewIncidents = alert => {
    displayIncidentsDialog(alert)
  }

  const onViewAlertLogs = alert => {
    displayAlertLogsDialog(alert)
  }

  const onTriggerAlert = async alert => {
    try {
      GlobalConfig.showProgress({
        state: Constants.ProgressStates.IN_PROGRESS,
        message: I18n.t('triggeringAlert'),
        closeable: false
      })

      const functions = getFunctions()
      const triggerAlertInternal = httpsCallable(
        functions,
        'triggerAlertInternal'
      )
      await triggerAlertInternal({
        event: {
          alertId: alert.id,
          enterpriseAccountId,
          message: `Triggered by ${user.displayName} from the UI`
        }
      })

      GlobalConfig.hideProgress()

      logEvent(
        GlobalConfig.analytics,
        Constants.UserAnalyticsEvents.TRIGGER_ALERT_FROM_UI,
        {}
      )
    } catch (error) {
      console.error(error)
      addError(error.message)
      GlobalConfig.hideProgress()
    }
  }

  const onDeleteAlert = async alert => {
    try {
      GlobalConfig.showProgress({
        state: Constants.ProgressStates.IN_PROGRESS,
        message: I18n.t('deletingAlert'),
        closeable: false
      })
      // Remove the alarms sent for the incidents from the alert
      const firebaseUpdate = {}
      const alarmQuerySnapshot = await getDocs(
        collection(
          GlobalConfig.firestoreDb,
          'enterpriseAccounts',
          enterpriseAccountId,
          'alerts',
          alert.id,
          'alarms'
        )
      )
      alarmQuerySnapshot.forEach(async alarmDoc => {
        const alarmData = alarmDoc.data()
        firebaseUpdate['alarms/' + alarmData.id] = null
        Object.keys(alarmData.members || {}).forEach(member => {
          firebaseUpdate[
            'userInfos/' + member.appUid + '/backupForAlarms/' + alarmData.id
          ] = null
        })
      })

      await update(GlobalConfig.rootFirebaseRef, firebaseUpdate)

      const functions = getFunctions()
      // Remove the alert from the enterprise account
      const deleteFirestoreDocRecursively = httpsCallable(
        functions,
        'deleteFirestoreDocRecursively'
      )
      await deleteFirestoreDocRecursively({
        documentLocation: `enterpriseAccounts/${enterpriseAccountId}/alerts/${alert.id}`
      })

      GlobalConfig.hideProgress()

      logEvent(
        GlobalConfig.analytics,
        Constants.UserAnalyticsEvents.DELETE_ALERT,
        {}
      )
    } catch (error) {
      console.error(error)
      addError(error.message)
      GlobalConfig.hideProgress()
    }
  }

  const sortedAlerts = alerts.sort((alert1, alert2) =>
    alert1.name.localeCompare(alert2.name)
  )

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Box pad="small" gap="small" width="large">
        <Box direction="row" justify="between" flex={{ shrink: 0 }}>
          <Heading margin="small" level={3}>
            {I18n.t('listeners')}
          </Heading>
          <WithRoles
            requiredRoles={[
              Constants.ENTERPRISE_USER_ROLES.OWNER,
              Constants.ENTERPRISE_USER_ROLES.ADMIN
            ]}
            assignedRoles={userRoles}>
            <Button
              primary
              icon={<Add color="white" />}
              label={I18n.t('listener')}
              style={{ color: 'white' }}
              onClick={displayAddAlertDialog}
            />
          </WithRoles>
        </Box>
        <Card background="textBackgroundColor">
          <CardBody margin="none" overflow="auto">
            {alerts.length === 0 ? (
              <Text margin="small" style={{ whiteSpace: 'pre-line' }}>
                {I18n.t('noAlertsConfiguredInEnterpriseAccount')}
              </Text>
            ) : (
              <List border={false} pad="none" margin="none" data={sortedAlerts}>
                {(item, index) => (
                  <AlertSummary
                    item={item}
                    index={index}
                    onEditAlert={onEditAlert}
                    onDeleteAlert={onDeleteAlert}
                    onViewCode={onViewCode}
                    onViewIncidents={onViewIncidents}
                    onTriggerAlert={onTriggerAlert}
                    onViewAlertLogs={onViewAlertLogs}
                    userRoles={userRoles}
                  />
                )}
              </List>
            )}
          </CardBody>
        </Card>
      </Box>
      {!isEmpty(showEditAlertDialog) && (
        <Layer
          background="lightTint"
          onEsc={hideEditAlertDialog}
          onClickOutside={hideEditAlertDialog}
          margin="medium">
          <Box margin="medium">
            <EditAlert
              isEdit={showEditAlertDialog.isEdit}
              alert={showEditAlertDialog.alert}
              onClose={hideEditAlertDialog}
              displayApiCodeDialog={displayApiCodeDialogForNewAlert}
            />
            <Box style={{ position: 'absolute', top: 20, right: 20 }}>
              <Close onClick={hideEditAlertDialog} />
            </Box>
          </Box>
        </Layer>
      )}
      {!isEmpty(showApiCodeDialog) && (
        <Layer
          background="lightTint"
          onEsc={hideApiCodeDialog}
          onClickOutside={hideApiCodeDialog}
          margin="medium">
          <Box margin="medium">
            <ViewApiCode
              alert={showApiCodeDialog.alert}
              showIntroForNewAlert={showApiCodeDialog.showIntroForNewAlert}
            />
            <Box style={{ position: 'absolute', top: 20, right: 20 }}>
              <Close onClick={hideApiCodeDialog} />
            </Box>
          </Box>
        </Layer>
      )}
      {!isEmpty(viewIncidentsDialog) && (
        <Layer
          background="lightTint"
          onEsc={hideIncidentsDialog}
          onClickOutside={hideIncidentsDialog}
          margin="medium">
          <Box margin="medium">
            <ViewIncidents
              alert={viewIncidentsDialog.alert}
              onClose={hideIncidentsDialog}
            />
            <Box style={{ position: 'absolute', top: 20, right: 20 }}>
              <Close onClick={hideIncidentsDialog} />
            </Box>
          </Box>
        </Layer>
      )}
      {!isEmpty(viewAlertLogsDialog) && (
        <Layer
          background="lightTint"
          onEsc={hideAlertLogsDialog}
          onClickOutside={hideAlertLogsDialog}
          margin="medium">
          <Box margin="medium">
            <AlertLogs
              alert={viewAlertLogsDialog.alert}
              onClose={hideAlertLogsDialog}
            />
            <Box style={{ position: 'absolute', top: 20, right: 20 }}>
              <Close onClick={hideAlertLogsDialog} />
            </Box>
          </Box>
        </Layer>
      )}
    </ErrorProvider>
  )
}

export default Alerts
