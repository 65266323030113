import { Box, Spinner, Text } from 'grommet'
import React, { useContext, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import GlobalConfig from '../GlobalConfig'
import { Constants, I18n } from 'galarm-config'
import { collection, doc, getDoc, onSnapshot, query } from 'firebase/firestore'
import { useDispatch } from 'react-redux'
import ActionCreators from '../actions/creators'
import UserContext from './UserContext'
import { useSelector } from 'react-redux'
import FixSubscription from './FixSubscription'

const RequireActiveSubscription = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { user, setUserRoles } = useContext(UserContext)

  const [enterpriseAccountLoaded, setEnterpriseAccountLoaded] = useState(false)

  const accountInfo = useSelector(state => state.accountInfo)
  const subscriptionKeys = accountInfo.subscriptionKeys
  const subscriptionData = accountInfo.subscriptionData

  const numSeats = subscriptionData.quantity
  const numSeatsAssigned = subscriptionKeys.filter(key => key.assignedTo).length

  let isSubscriptionStatusNormal = true
  if (numSeatsAssigned > numSeats) {
    isSubscriptionStatusNormal = false
  }

  const isSubscriptionActive = enterpriseAccountData => {
    if (
      enterpriseAccountData?.subscriptionData?.status ===
        Constants.ENTERPRISE_SUBSCRIPTION_STATUSES.ACTIVE ||
      enterpriseAccountData?.subscriptionData?.status ===
        Constants.ENTERPRISE_SUBSCRIPTION_STATUSES.TRIALING
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    const unsubscribeCallbacks = []
    async function loadEnterpriseAccountData() {
      try {
        const enterpriseAccountId = window.localStorage.getItem(
          'enterpriseAccountId'
        )

        if (!enterpriseAccountId) {
          console.log('No enterprise account id, redirecting to /app')
          navigate('/app', { replace: true, state: { from: location } })
          return
        }

        const unsubscribeEnterpriseAccount = onSnapshot(
          doc(
            GlobalConfig.firestoreDb,
            'enterpriseAccounts',
            enterpriseAccountId
          ),
          async docSnap => {
            try {
              console.log('Updating enterprise account data')
              let enterpriseAccountData
              if (docSnap.exists()) {
                enterpriseAccountData = docSnap.data()
                console.log('Enterprise account:', enterpriseAccountData)

                dispatch(ActionCreators.setAccountInfo(enterpriseAccountData))

                // Set user roles
                const roles = []
                if (enterpriseAccountData.owner === user.uid) {
                  roles.push(Constants.ENTERPRISE_USER_ROLES.OWNER)
                }
                const member = await getDoc(
                  doc(
                    GlobalConfig.firestoreDb,
                    'enterpriseAccounts',
                    enterpriseAccountId,
                    'members',
                    user.uid
                  )
                ).then(memberSnapshot => memberSnapshot.data())
                if (member) {
                  roles.push(member.role)
                }
                setUserRoles(roles)

                const subscriptionId =
                  enterpriseAccountData.subscriptionData.subscriptionId

                if (!subscriptionId) {
                  console.log('No subscription found, redirecting to /app/buy')
                  navigate('/app/buy', {
                    state: { enterpriseAccountId }
                  })
                  return
                }

                if (
                  enterpriseAccountData.initialSetupMarkers?.setupComplete &&
                  !isSubscriptionActive(enterpriseAccountData)
                ) {
                  console.log(
                    'Subscription is not active, redirecting to /app/account'
                  )
                  navigate('/app/account', { state: { from: location } })
                  return
                }

                // Read subscription from Firestore subscriptions collection
                const subscription = await getDoc(
                  doc(GlobalConfig.firestoreDb, 'subscriptions', subscriptionId)
                ).then(subscriptionSnapshot => subscriptionSnapshot.data())

                console.log('subscription', subscription)

                dispatch(ActionCreators.setSubscription(subscription))

                const productId =
                  enterpriseAccountData.subscriptionData.productId

                const product = await getDoc(
                  doc(GlobalConfig.firestoreDb, 'products', productId)
                ).then(productSnapshot => productSnapshot.data())

                console.log('product', product)

                dispatch(ActionCreators.setProduct(product))

                // if location is /app/org, redirect to /app/org/planType
                if (location.pathname === '/app/org') {
                  navigate(
                    enterpriseAccountData.subscriptionData.planType ||
                      'enterprise',
                    {
                      replace: true
                    }
                  )
                }
              } else {
                console.log('No enterprise account found, redirecting to /app')
                navigate('/app', { state: { from: location } })
              }
              setEnterpriseAccountLoaded(true)
            } catch (error) {
              console.error(error)
              GlobalConfig.showTransientAlert({
                message: I18n.t('problemRetrievingSubscriptionData'),
                duration: Constants.AlertDurations.VERY_LONG
              })
              navigate('/app/account', { state: { from: location } })
            }
          }
        )
        unsubscribeCallbacks.push(unsubscribeEnterpriseAccount)

        const unsubscribeMembers = onSnapshot(
          query(
            collection(
              GlobalConfig.firestoreDb,
              'enterpriseAccounts',
              enterpriseAccountId,
              'members'
            )
          ),
          snapshot => {
            snapshot.docChanges().forEach(change => {
              if (change.type === 'added') {
                dispatch(ActionCreators.addMember(change.doc.data()))
              }
              if (change.type === 'modified') {
                dispatch(ActionCreators.editMember(change.doc.data()))
              }
              if (change.type === 'removed') {
                dispatch(ActionCreators.removeMember(change.doc.data()))
              }
            })
          }
        )
        unsubscribeCallbacks.push(unsubscribeMembers)

        const unsubscribeTeams = onSnapshot(
          query(
            collection(
              GlobalConfig.firestoreDb,
              'enterpriseAccounts',
              enterpriseAccountId,
              'teams'
            )
          ),
          snapshot => {
            snapshot.docChanges().forEach(change => {
              if (change.type === 'added') {
                dispatch(ActionCreators.addTeam(change.doc.data()))
              }
              if (change.type === 'modified') {
                dispatch(ActionCreators.editTeam(change.doc.data()))
              }
              if (change.type === 'removed') {
                dispatch(ActionCreators.removeTeam(change.doc.data()))
              }
            })
          }
        )
        unsubscribeCallbacks.push(unsubscribeTeams)

        const unsubscribeRotations = onSnapshot(
          query(
            collection(
              GlobalConfig.firestoreDb,
              'enterpriseAccounts',
              enterpriseAccountId,
              'rotations'
            )
          ),
          snapshot => {
            snapshot.docChanges().forEach(change => {
              if (change.type === 'added') {
                dispatch(ActionCreators.addRotation(change.doc.data()))
              }
              if (change.type === 'modified') {
                dispatch(ActionCreators.editRotation(change.doc.data()))
              }
              if (change.type === 'removed') {
                dispatch(ActionCreators.removeRotation(change.doc.data()))
              }
            })
          }
        )
        unsubscribeCallbacks.push(unsubscribeRotations)

        const unsubscribeAlerts = onSnapshot(
          query(
            collection(
              GlobalConfig.firestoreDb,
              'enterpriseAccounts',
              enterpriseAccountId,
              'alerts'
            )
          ),
          snapshot => {
            snapshot.docChanges().forEach(change => {
              if (change.type === 'added') {
                dispatch(ActionCreators.addAlert(change.doc.data()))
              }
              if (change.type === 'modified') {
                dispatch(ActionCreators.editAlert(change.doc.data()))
              }
              if (change.type === 'removed') {
                dispatch(ActionCreators.removeAlert(change.doc.data()))
              }
            })
          }
        )
        unsubscribeCallbacks.push(unsubscribeAlerts)

        const unsubscribeSubscriptionKeys = onSnapshot(
          query(
            collection(
              GlobalConfig.firestoreDb,
              'enterpriseAccounts',
              enterpriseAccountId,
              'subscriptionKeys'
            )
          ),
          snapshot => {
            snapshot.docChanges().forEach(change => {
              if (change.type === 'added') {
                dispatch(ActionCreators.addSubscriptionKey(change.doc.data()))
              }
              if (change.type === 'modified') {
                dispatch(ActionCreators.editSubscriptionKey(change.doc.data()))
              }
              if (change.type === 'removed') {
                dispatch(
                  ActionCreators.removeSubscriptionKey(change.doc.data())
                )
              }
            })
          }
        )
        unsubscribeCallbacks.push(unsubscribeSubscriptionKeys)
      } catch (error) {
        console.error('Error loading enterprise account data', error)
      }
    }
    loadEnterpriseAccountData()

    return () => {
      console.log('Unmounting RequireActiveSubscription')
      for (const unsubscribe of unsubscribeCallbacks) {
        unsubscribe()
      }
    }
  }, [])

  if (!enterpriseAccountLoaded) {
    return (
      <Box flex justify="center" align="center">
        <Spinner size="medium" />
        <Text>{I18n.t('loadingAccountInformation')}</Text>
      </Box>
    )
  }

  if (!isSubscriptionStatusNormal) {
    return <FixSubscription />
  }

  return <Outlet />
}

export default RequireActiveSubscription
