import React, { useEffect } from 'react'
import { Grid, Box, ResponsiveContext } from 'grommet'
import { Outlet } from 'react-router-dom'
import GalarmEnterpriseSideBar from './GalarmEnterpriseSideBar'
import { Constants, I18n, colors } from 'galarm-config'
import { collectionGroup, onSnapshot, query, where } from 'firebase/firestore'
import GlobalConfig from '../GlobalConfig'
import { useResize } from './useResize'

const BusinessAppLayout = () => {
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')

  const size = React.useContext(ResponsiveContext)
  const windowSize = useResize()

  useEffect(() => {
    const currDate = Date.now()
    const newlyOpenedIncidentsQuery = query(
      collectionGroup(GlobalConfig.firestoreDb, 'incidents'),
      where('enterpriseAccountId', '==', enterpriseAccountId),
      where('timestamp', '>', currDate)
    )

    const unsubscribe = onSnapshot(newlyOpenedIncidentsQuery, snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          const incident = change.doc.data()
          GlobalConfig.showTransientAlert({
            message: I18n.t('newIncidentForAlert', {
              alertName: incident.alertName
            }),
            duration: Constants.AlertDurations.LONG
          })
        }
      })
    })

    return () => {
      unsubscribe()
    }
  })

  const firstColumnWidths = {
    small: '75',
    medium: '100',
    large: '200',
    xlarge: '200'
  }
  const firstColumnWidth = firstColumnWidths[size]

  const mainWidth = windowSize.width - firstColumnWidth

  return (
    <Grid
      fill
      columns={[firstColumnWidth + 'px', mainWidth + 'px']}
      rows={['flex']}
      //[column,row] coordinates for start and end of areas
      areas={[
        { name: 'sidebar', start: [0, 0], end: [1, 0] },
        { name: 'main', start: [1, 0], end: [1, 0] }
      ]}>
      <Box gridArea="sidebar" background={colors.veryDarkBlueGrey}>
        <GalarmEnterpriseSideBar planType="business" />
      </Box>
      <Box gridArea="main" background="lightTint">
        <Outlet />
      </Box>
    </Grid>
  )
}

export default BusinessAppLayout
