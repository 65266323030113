import { Box, Heading, Text } from 'grommet'
import React from 'react'

const INTEGRATIONS_DATA = [
  {
    name: 'AWS SNS',
    description: 'Send alerts to Galarm from AWS SNS'
  },
  {
    name: 'DataDog',
    description: 'Send alerts to Galarm from DataDog'
  }
]
const Integrations = () => {
  return (
    <Box>
      {INTEGRATIONS_DATA.map((integration, index) => {
        return (
          <Box key={index}>
            <Heading level={3}>{integration.name}</Heading>
            <Text>{integration.description}</Text>
          </Box>
        )
      })}
    </Box>
  )
}

export default Integrations
