import React from 'react'
import {
  Anchor,
  Box,
  Button,
  Nav,
  ResponsiveContext,
  Sidebar,
  Text
} from 'grommet'
import {
  Configure,
  Dashboard,
  Document,
  Group,
  Plan,
  User
} from 'grommet-icons'
import { colors, I18n } from 'galarm-config'
import { useLocation, useNavigate } from 'react-router-dom'

// eslint-disable-next-line no-unused-vars
const Footer = ({ planType }) => {
  return (
    <Box gap="small">
      <Anchor
        style={{
          color: colors.lightBlue,
          fontSize: '14px'
        }}
        target="_blank"
        href="https://www.galarmapp.com/security-and-privacy-controls"
        rel="noopener noreferrer"
        label={I18n.t('dataSecurity')}
      />
      <Anchor
        style={{
          color: colors.lightBlue,
          fontSize: '14px'
        }}
        target="_blank"
        href="https://www.galarmapp.com/termsofservice"
        rel="noopener noreferrer"
        label={I18n.t('termsOfService')}
      />
      <Anchor
        style={{
          color: colors.lightBlue,
          fontSize: '14px'
        }}
        target="_blank"
        href="https://www.galarmapp.com/privacypolicy"
        rel="noopener noreferrer"
        label={I18n.t('privacyPolicy')}
      />
      <Box border={{ bottom: '0.5px' }} />
      <Text color="mediumTint" style={{ whiteSpace: 'pre-line' }} size="small">
        {I18n.t('acintyoTrademarkEnterprise')}
      </Text>
    </Box>
  )
}
const GalarmEnterpriseSideBar = ({ planType }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const size = React.useContext(ResponsiveContext)

  const sidebarItems =
    planType === 'enterprise'
      ? [
          {
            label: I18n.t('dashboard'),
            icon: Dashboard,
            path: '/app/org/enterprise'
          },
          {
            label: I18n.t('members'),
            icon: User,
            path: '/app/org/enterprise/members'
          },
          {
            label: I18n.t('teams'),
            icon: Group,
            path: '/app/org/enterprise/teams'
          },
          {
            label: I18n.t('rotations'),
            icon: Plan,
            path: '/app/org/enterprise/rotations'
          },
          {
            label: I18n.t('listeners'),
            icon: Configure,
            path: '/app/org/enterprise/alerts'
          },
          {
            label: I18n.t('docs'),
            icon: Document,
            url: 'https://docs.galarmapp.com/enterprise-docs/'
          }
        ]
      : [
          {
            label: I18n.t('members'),
            icon: User,
            path: '/app/org/business/members'
          }
        ]

  const onClickSidebarItem = item => {
    if (item.url) {
      window.open(item.url, '_blank')
    } else {
      navigate(item.path)
    }
  }

  const showLabelsForSidebarItems = {
    small: false,
    medium: false,
    large: true,
    xlarge: true
  }
  const showLabelsForSidebarItemsValue = showLabelsForSidebarItems[size]

  return (
    <Box overflow={{ vertical: 'auto' }} fill>
      <Sidebar
        flex={{ shrink: 0 }}
        round="none"
        align="center"
        footer={<Footer planType={planType} />}>
        <Nav gap="small">
          {sidebarItems.map(item => {
            const { label, icon: Icon, path } = item
            return (
              <Button
                key={label}
                alignSelf="start"
                plain
                label={
                  showLabelsForSidebarItemsValue && (
                    <Text
                      color={location.pathname === path ? 'primary' : 'white'}
                      margin="xsmall"
                      style={{
                        fontWeight:
                          location.pathname === path ? 'bold' : 'normal'
                      }}>
                      {label}
                    </Text>
                  )
                }
                icon={
                  <Box pad="xsmall">
                    <Icon
                      color={location.pathname === path ? 'primary' : 'white'}
                    />
                  </Box>
                }
                onClick={() => onClickSidebarItem(item)}
              />
            )
          })}
        </Nav>
      </Sidebar>
    </Box>
  )
}

export default GalarmEnterpriseSideBar
