import React, { useContext, useState } from 'react'
import GlobalConfig from '../GlobalConfig'
import { Constants, I18n } from 'galarm-config'
import { useLocation, useNavigate } from 'react-router-dom'
import ErrorProvider from './ErrorProvider'
import UserContext from './UserContext'
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc
} from 'firebase/firestore'
import ProvideOrganizationInformation from './ProvideOrganizationInformation'
import ChoosePlan from './ChoosePlan'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { logEvent } from 'firebase/analytics'

const BuySubscription = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [enterpriseAccountId, setEnterpriseAccountId] = useState(
    location.state?.enterpriseAccountId
  )

  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const { user } = useContext(UserContext)

  const onCreateOrganizationAccount = async organizationData => {
    console.log('onCreateOrganizationAccount', organizationData)
    try {
      GlobalConfig.showProgress({
        state: Constants.ProgressStates.IN_PROGRESS,
        message: I18n.t('creatingOrganizationAccount'),
        closeable: false
      })

      const { name } = organizationData

      // Create an enterprise account on db
      const enterpriseAccountDoc = doc(
        collection(GlobalConfig.firestoreDb, 'enterpriseAccounts')
      )
      const enterpriseAccountId = enterpriseAccountDoc.id
      await setDoc(enterpriseAccountDoc, {
        id: enterpriseAccountId,
        name: name,
        owner: user.uid,
        creationDate: Date.now()
      })

      const functions = getFunctions()
      // Create a customer account on Stripe
      const stripeCreateCustomer = httpsCallable(
        functions,
        'stripeCreateCustomer'
      )
      const result = await stripeCreateCustomer({
        name: name,
        email: user.email,
        metadata: {
          enterpriseAccountId: enterpriseAccountId,
          owner: user.uid
        }
      })

      const stripeCustomerId = result.data.customerId

      // Update the Stripe customer ID on the enterprise account
      await setDoc(
        enterpriseAccountDoc,
        {
          subscriptionData: {
            customerId: stripeCustomerId
          }
        },
        { merge: true }
      )

      await updateDoc(
        doc(GlobalConfig.firestoreDb, 'enterpriseUsers', user.uid),
        {
          organizations: arrayUnion(enterpriseAccountId)
        }
      )

      setEnterpriseAccountId(enterpriseAccountId)
      GlobalConfig.hideProgress()

      logEvent(
        GlobalConfig.analytics,
        Constants.UserAnalyticsEvents.CREATE_ENTERPRISE_ACCOUNT,
        {}
      )
    } catch (error) {
      console.error(error)
      addError(error.message)
      GlobalConfig.hideProgress()
    }
  }

  const onSubscribe = async ({ product, numItems, interval }) => {
    console.log('onBuy', product, numItems, interval)

    if (!user) {
      navigate('/login', { state: { from: location } })
      return
    }

    const enterpriseAccountDoc = await getDoc(
      doc(GlobalConfig.firestoreDb, 'enterpriseAccounts', enterpriseAccountId)
    )
    const enterpriseAccountInfo = enterpriseAccountDoc.data()

    if (!enterpriseAccountInfo?.subscriptionData?.customerId) {
      addError(I18n.t('problemWithEnterpriseAccountSetup'))
      return
    }

    const functions = getFunctions()
    const stripeCreateCheckoutSession = httpsCallable(
      functions,
      'stripeCreateCheckoutSession'
    )

    try {
      GlobalConfig.showProgress({
        state: Constants.ProgressStates.IN_PROGRESS,
        message: I18n.t('creatingCheckoutSession'),
        closeable: false
      })

      const resultSession = await stripeCreateCheckoutSession({
        customerId: enterpriseAccountInfo.subscriptionData.customerId,
        product,
        numItems,
        interval
      })

      window.location.href = resultSession.data.session.url

      logEvent(
        GlobalConfig.analytics,
        Constants.UserAnalyticsEvents.START_SUBSCRIPTION_CHECKOUT_SESSION,
        {}
      )
    } catch (error) {
      GlobalConfig.hideProgress()
      console.error(error)
      addError(I18n.t('problemWithCreatingCheckoutSession'))
      return
    }
  }

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      {!enterpriseAccountId && (
        <ProvideOrganizationInformation
          onCreateOrganizationAccount={onCreateOrganizationAccount}
        />
      )}
      {enterpriseAccountId && (
        <ChoosePlan
          enterpriseAccountId={enterpriseAccountId}
          onChoosePlan={onSubscribe}
        />
      )}
    </ErrorProvider>
  )
}

export default BuySubscription
