import React, { Component } from 'react'
import {
  Scheduler,
  SchedulerData,
  ViewType,
  wrapperFun
} from 'react-big-schedule'
import dayjs from 'dayjs'
import 'react-big-schedule/dist/css/style.css'
import { connect } from 'react-redux'
import { StringUtils } from 'galarm-shared'
import { I18n } from 'galarm-config'
import { Box, Layer } from 'grommet'
import EditShift from './EditShift'
import { Close } from 'grommet-icons'

const colors = [
  '#C2185B',
  '#0277BD',
  '#7E57C2',
  '#8D6E63',
  '#2E7D32',
  '#E64A19',
  '#00838F',
  '#5C6BC0',
  '#9E9D24',
  '#00695C'
]

class RotationCalendar extends Component {
  constructor(props) {
    super(props)

    let schedulerData = new SchedulerData(
      dayjs().format('YYYY-MM-DD'),
      ViewType.Week,
      false,
      false,
      {
        besidesWidth: window.innerWidth <= 1600 ? 100 : 350,
        dayMaxEvents: 99,
        weekMaxEvents: 9669,
        monthMaxEvents: 9669,
        quarterMaxEvents: 6599,
        yearMaxEvents: 9956,
        customMaxEvents: 9965,
        eventItemPopoverTrigger: 'click',
        schedulerContentHeight: '100%'
      }
    )

    schedulerData.setResources([
      {
        id: props.rotation.id,
        name: props.rotation.name
      }
    ])

    const shiftColors = props.rotation.members.reduce((acc, member, index) => {
      acc[member.id] = colors[index % 10]
      return acc
    }, {})

    const shiftEvents = props.rotation.shifts.map(shift => {
      const shiftMember = props.members.find(
        member => member.id === shift.assignee
      )
      const memberName = StringUtils.createDisplayName(
        shiftMember.firstName,
        shiftMember.lastName
      )
      return {
        id: shift.id,
        start: dayjs(new Date(shift.start)).format('YYYY-MM-DD HH:mm:ss'),
        end: dayjs(new Date(shift.end)).format('YYYY-MM-DD HH:mm:ss'),
        resourceId: props.rotation.id,
        title: memberName,
        bgColor: shiftColors[shift.assignee],
        showPopover: true
      }
    })
    schedulerData.setEvents(shiftEvents)
    this.state = {
      shiftEvents,
      viewModel: schedulerData
    }
  }

  prevClick = schedulerData => {
    schedulerData.prev()
    schedulerData.setEvents(this.state.shiftEvents)
    this.setState({ viewModel: schedulerData })
  }

  nextClick = schedulerData => {
    schedulerData.next()
    schedulerData.setEvents(this.state.shiftEvents)
    this.setState({ viewModel: schedulerData })
  }

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    )
    schedulerData.setEvents(this.state.shiftEvents)
    this.setState({ viewModel: schedulerData })
  }

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date)
    schedulerData.setEvents(this.state.shiftEvents)
    this.setState({
      viewModel: schedulerData
    })
  }

  eventClicked = (schedulerData, event) => {
    alert(`You just clicked an event: {id: ${event.id}, title: ${event.title}}`)
  }

  editShift = (schedulerData, event) => {
    this.displayEditShiftDialog(event.id)
  }

  displayEditShiftDialog = eventId => {
    this.setState({ editShift: { id: eventId } })
  }

  hideEditShiftDialog = () => {
    this.setState({ editShift: null })
  }

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewType.Day) {
      schedulerData.next()
      schedulerData.setEvents(this.state.shiftEvents)
      this.setState({
        viewModel: schedulerData
      })

      schedulerContent.scrollLeft = maxScrollLeft - 10
    }
  }

  onScrollLeft = (schedulerData, schedulerContent) => {
    if (schedulerData.ViewTypes === ViewType.Day) {
      schedulerData.prev()
      schedulerData.setEvents(this.state.shiftEvents)
      this.setState({
        viewModel: schedulerData
      })

      schedulerContent.scrollLeft = 10
    }
  }

  toggleExpandFunc = (schedulerData, slotId) => {
    console.log('toggleExpandFunc')
    schedulerData.toggleExpandStatus(slotId)
    this.setState({
      viewModel: schedulerData
    })
  }

  render() {
    const { viewModel } = this.state
    return (
      <Box>
        <Scheduler
          schedulerData={viewModel}
          prevClick={this.prevClick}
          nextClick={this.nextClick}
          onViewChange={this.onViewChange}
          viewEventClick={this.editShift}
          viewEventText={I18n.t('edit')}
          onScrollLeft={this.onScrollLeft}
          onScrollRight={this.onScrollRight}
          toggleExpandFunc={this.toggleExpandFunc}
        />
        {this.state.editShift && (
          <Layer
            background="lightTint"
            margin="medium"
            onClickOutside={this.hideEditShiftDialog}
            onEsc={this.hideEditShiftDialog}>
            <Box margin="medium">
              <EditShift
                isEdit={true}
                rotation={this.props.rotation}
                shiftId={this.state.editShift.id}
                onClose={() => {
                  this.setState({ editShift: null })
                  this.props.onClose()
                }}
              />
              <Box style={{ position: 'absolute', top: 10, right: 20 }}>
                <Close onClick={this.hideEditShiftDialog} />
              </Box>
            </Box>
          </Layer>
        )}
      </Box>
    )
  }
}

const mapStateToProps = state => {
  return {
    members: state.accountInfo.members
  }
}

export default wrapperFun(connect(mapStateToProps)(RotationCalendar))
