import { colors, Constants, I18n } from 'galarm-config'
import { Box, Spinner, Text } from 'grommet'
import React from 'react'

const ProgressDialog = ({ progressConfiguration }) => {
  return (
    <Box margin="medium" align="center" justify="center">
      {progressConfiguration.state === Constants.ProgressStates.IN_PROGRESS && (
        <Spinner />
      )}
      {progressConfiguration.state === Constants.ProgressStates.ERROR && (
        <Text weight="bold" color={colors.red}>
          {I18n.t('error')}
        </Text>
      )}
      {progressConfiguration.message && (
        <Text margin={{ top: 'medium' }}>{progressConfiguration.message}</Text>
      )}
    </Box>
  )
}

export default ProgressDialog
