import {
  NotificationManager,
  AlarmManager,
  StorageManager,
  LogUtils,
  FirebaseProxy
} from 'galarm-ps-api'
import DateTimeUtils from './DateTimeUtils'
import { GlobalConfig } from 'galarm-config'
import { Constants } from 'galarm-config'
import moment from 'moment-timezone'
import { I18n } from 'galarm-config'
import Utils from './Utils'
import objGet from 'lodash/get'
import TaskManager from '../api/TaskManager'
import memoize from 'fast-memoize'
import DeviceInfo from 'react-native-device-info'
import ActionCreators from '../actions/creators'
import isEmpty from 'lodash/isEmpty'
import * as RNLocalize from 'react-native-localize'
import stringTruncate from 'lodash/truncate'
import arrCompact from 'lodash/compact'
import objOmit from 'lodash/omit'
import objSet from 'lodash/set'
import { makeAlarmSelector } from '../store/selectors'
import GroupUtils from './GroupUtils'
import isObjEqual from 'fast-deep-equal'
import NetworkUtils from './NetworkUtils'
import { Platform } from 'react-native'
import { colorThemes } from 'galarm-config'
import stringCapitalize from 'lodash/capitalize'
import objCompact from 'lodash/compact'
import timezones from './data/timezones.json'

// This function is extracted out as a workaround to avoid circular dependency in NotificationManager

// Returns an object {prevDate, nextDate} which accounts for the prev and next occurrence
// of an alarm. If alarm hasn't ring before, the prevDate will be undefined and if the alarm
// will not ring in future, then the nextDate will be undefined. If origAlarmDate is still in
// future, the nextDate will be set to origAlarmDate, and prevDate will be undefined
export function getPrevAndNextDatesForAnAlarmWrtDate(
  date,
  origAlarmDate,
  alarmEndDate = GlobalConfig.defaultAlarmEndDate,
  repeatType,
  repeat,
  alarmCreatorTimezone = RNLocalize.getTimeZone()
) {
  if (!date) {
    return { prevDate: undefined, nextDate: undefined }
  }

  // Convert the ref date to mins such that the we can
  // memoize the values for a minute window
  const dateInMins = Math.floor(date / (1000 * 60))
  return memoizedGetPrevAndNextDatesForAnAlarmWrtDate(
    dateInMins,
    origAlarmDate,
    alarmEndDate,
    repeatType,
    repeat,
    alarmCreatorTimezone
  )
}

export function getRandomRingtone() {
  const ringtones = GlobalConfig.ringtones.filter(
    ringtone =>
      ringtone.value !== Constants.RANDOM_RINGTONE_VALUE &&
      ringtone.value !== Constants.SILENT_RINGTONE_VALUE
  )
  let randomInBuiltRingtone =
    ringtones[Math.floor(Math.random() * ringtones.length)]

  return randomInBuiltRingtone
}

const memoizedGetPrevAndNextDatesForAnAlarmWrtDate = memoize(
  getPrevAndNextDatesForAnAlarmWrtDateCore
)

// Create the previous and next dates in reference to the creator timezone
// to fix the issues with the timezone changes and DST changes. For simple
// timezone changes, it is suffice to change the alarm time to the new
// timezone. But for DST changes, the original alarm time in new timezone
// remains same. To solve those cases, we use the alarm creator timezone as
// a reference for all date calculations.
function getPrevAndNextDatesForAnAlarmWrtDateCore(
  dateInMins,
  origAlarmDate,
  alarmEndDate,
  repeatType,
  repeat,
  alarmCreatorTimezone
) {
  let prevDate, nextDate

  // Convert date back to msec
  const date = dateInMins * 1000 * 60

  const origAlarmDateHours = moment(origAlarmDate)
    .tz(alarmCreatorTimezone)
    .hours()
  const origAlarmDateMins = moment(origAlarmDate)
    .tz(alarmCreatorTimezone)
    .minutes()

  if (repeatType === '') {
    prevDate = date >= origAlarmDate ? origAlarmDate : undefined
    nextDate = date >= origAlarmDate ? undefined : origAlarmDate
  } else if (repeatType === Constants.RepeatTypes.DAYS_OF_WEEK) {
    const repeatArr = repeat.split(',').filter(e => e !== '')

    const firstDayInRepetition = repeatArr[0]

    // Jump to the nearest previous date to avoid unnecessary
    // calculatios to compute next date
    let currAlarmDate = moment(date)
      .tz(alarmCreatorTimezone)
      .subtract(2, 'weeks')
      .hours(origAlarmDateHours)
      .minutes(origAlarmDateMins)
      .seconds(0)
      .milliseconds(0)
      .day(Constants.RepeatDayToDayMapping[firstDayInRepetition])
      .valueOf()

    // If date happens to be earlier than origAlarmDate, move it forward to origAlarmDate
    // This will happen during starting of alarms
    if (currAlarmDate < origAlarmDate) {
      currAlarmDate = origAlarmDate
    }

    let datesFound = false

    // Loop for each repeating day for the alarm, till we find the next time the alarm
    // will ring and return the previous and that time
    // datesFound keep track of if we have found such dates
    // loop.stop will stop further calculations after we have found such dates in forEach
    while (!datesFound) {
      repeatArr.forEach(function loop(repeatDay) {
        if (loop.stop) return
        // Find the time on the day that the alarm will ring
        let alarmDateOnDay = moment(currAlarmDate)
          .tz(alarmCreatorTimezone)
          .day(Constants.RepeatDayToDayMapping[repeatDay])
          .valueOf()
        // If we have found a time which is ahead of the passed in date,
        // then we have found the the two dates
        // If the date is less than the origAlarmDate, then skip it. It
        // happens when the alarm is set on Tuesday to repeat on Monday
        // So, the first time, moment api will return the alarm time on
        // previous Monday.
        if (alarmDateOnDay >= origAlarmDate) {
          if (alarmEndDate && alarmDateOnDay > alarmEndDate) {
            // If the alarm is very old and the alarm end date
            // happens to be before the date we jump to to calculate
            // the previos and next dates, then both prevDate and nextDate
            // are returned as undefined resulting in current date for
            // alarm as undefined
            if (!prevDate && alarmEndDate < date) {
              const { prevDate: oldPrevDate } =
                getPrevAndNextDatesForAnAlarmWrtDateCore(
                  Math.floor(alarmEndDate / (1000 * 60)),
                  origAlarmDate,
                  alarmEndDate,
                  repeatType,
                  repeat,
                  alarmCreatorTimezone
                )
              prevDate = oldPrevDate
            }
            datesFound = true
            loop.stop = true
          } else if (alarmDateOnDay > date) {
            nextDate = alarmDateOnDay
            datesFound = true
            loop.stop = true
          } else {
            prevDate = alarmDateOnDay
          }
        }
      })
      // Move to the next week
      currAlarmDate = moment(currAlarmDate)
        .tz(alarmCreatorTimezone)
        .add(1, 'weeks')
        .valueOf()
    }
  } else if (repeatType === Constants.RepeatTypes.EVERY_N_HOURS) {
    const { startHours, startMins, endHours, hours, selectedDays } =
      getRepeatOptionsForHourlyRepetition(repeat)
    const daysOfWeek = Object.keys(Constants.RepeatDayToDayMapping)
    const selectedDaysIndices = selectedDays.map(selectedDay =>
      daysOfWeek.findIndex(day => day === selectedDay)
    )

    const firstDayInRepetition = selectedDays[0]

    // Jump to the nearest previous date to avoid unnecessary
    // calculatios to compute next date
    let currAlarmDate = moment(date)
      .tz(alarmCreatorTimezone)
      .subtract(2, 'weeks')
      .hours(origAlarmDateHours)
      .minutes(origAlarmDateMins)
      .seconds(0)
      .milliseconds(0)
      .day(Constants.RepeatDayToDayMapping[firstDayInRepetition])
      .valueOf()

    // If date happens to be earlier than origAlarmDate, move it forward to origAlarmDate
    // This will happen during starting of alarms
    if (currAlarmDate < origAlarmDate) {
      currAlarmDate = origAlarmDate
    }

    let datesFound = false

    while (!datesFound) {
      if (alarmEndDate && currAlarmDate > alarmEndDate) {
        // If the alarm is very old and the alarm end date
        // happens to be before the date we jump to to calculate
        // the previos and next dates, then both prevDate and nextDate
        // are returned as undefined resulting in current date for
        // alarm as undefined
        if (!prevDate && alarmEndDate < date) {
          const { prevDate: oldPrevDate } =
            getPrevAndNextDatesForAnAlarmWrtDateCore(
              Math.floor(alarmEndDate / (1000 * 60)),
              origAlarmDate,
              alarmEndDate,
              repeatType,
              repeat,
              alarmCreatorTimezone
            )
          prevDate = oldPrevDate
        }
        datesFound = true
      } else if (
        currAlarmDate > date &&
        selectedDaysIndices.includes(
          moment(currAlarmDate).tz(alarmCreatorTimezone).days()
        )
      ) {
        nextDate = currAlarmDate
        datesFound = true
      } else if (
        selectedDaysIndices.includes(
          moment(currAlarmDate).tz(alarmCreatorTimezone).days()
        )
      ) {
        prevDate = currAlarmDate
      }
      let nextAlarmDate = moment(currAlarmDate)
        .tz(alarmCreatorTimezone)
        .add(hours, 'hours')
      if (
        nextAlarmDate.hours() > endHours ||
        nextAlarmDate.days() !==
          moment(currAlarmDate).tz(alarmCreatorTimezone).days() ||
        !selectedDaysIndices.includes(nextAlarmDate.days())
      ) {
        nextAlarmDate = moment(currAlarmDate)
          .tz(alarmCreatorTimezone)
          .add(1, 'days')
          .hours(startHours)
          .minutes(startMins)
      }
      currAlarmDate = nextAlarmDate.valueOf()
    }
  } else if (repeatType === Constants.RepeatTypes.HOURS_AND_MINUTES) {
    const { startHours, startMins, endHours, endMins, minutes, selectedDays } =
      getRepeatOptionsForHoursAndMinutesRepetition(repeat)
    const daysOfWeek = Object.keys(Constants.RepeatDayToDayMapping)
    const selectedDaysIndices = selectedDays.map(selectedDay =>
      daysOfWeek.findIndex(day => day === selectedDay)
    )

    const firstDayInRepetition = selectedDays[0]

    // Jump to the nearest previous date to avoid unnecessary
    // calculatios to compute next date
    let currAlarmDate = moment(date)
      .tz(alarmCreatorTimezone)
      .subtract(2, 'weeks')
      .hours(origAlarmDateHours)
      .minutes(origAlarmDateMins)
      .seconds(0)
      .milliseconds(0)
      .day(Constants.RepeatDayToDayMapping[firstDayInRepetition])
      .valueOf()

    // If date happens to be earlier than origAlarmDate, move it forward to origAlarmDate
    // This will happen during starting of alarms
    if (currAlarmDate < origAlarmDate) {
      currAlarmDate = origAlarmDate
    }

    let datesFound = false

    while (!datesFound) {
      if (alarmEndDate && currAlarmDate > alarmEndDate) {
        // If the alarm is very old and the alarm end date
        // happens to be before the date we jump to to calculate
        // the previos and next dates, then both prevDate and nextDate
        // are returned as undefined resulting in current date for
        // alarm as undefined
        if (!prevDate && alarmEndDate < date) {
          const { prevDate: oldPrevDate } =
            getPrevAndNextDatesForAnAlarmWrtDateCore(
              Math.floor(alarmEndDate / (1000 * 60)),
              origAlarmDate,
              alarmEndDate,
              repeatType,
              repeat,
              alarmCreatorTimezone
            )
          prevDate = oldPrevDate
        }
        datesFound = true
      } else if (
        currAlarmDate > date &&
        selectedDaysIndices.includes(
          moment(currAlarmDate).tz(alarmCreatorTimezone).days()
        )
      ) {
        nextDate = currAlarmDate
        datesFound = true
      } else if (
        selectedDaysIndices.includes(
          moment(currAlarmDate).tz(alarmCreatorTimezone).days()
        )
      ) {
        prevDate = currAlarmDate
      }
      let nextAlarmDate = moment(currAlarmDate)
        .tz(alarmCreatorTimezone)
        .add(minutes, 'minutes')
      if (
        nextAlarmDate.hours() > endHours ||
        (nextAlarmDate.hours() === endHours &&
          nextAlarmDate.minutes() > endMins) ||
        nextAlarmDate.days() !==
          moment(currAlarmDate).tz(alarmCreatorTimezone).days() ||
        !selectedDaysIndices.includes(nextAlarmDate.days())
      ) {
        nextAlarmDate = moment(currAlarmDate)
          .tz(alarmCreatorTimezone)
          .add(1, 'days')
          .hours(startHours)
          .minutes(startMins)
      }
      currAlarmDate = nextAlarmDate.valueOf()
    }
  } else if (repeatType === Constants.RepeatTypes.EVERY_M_HOURS_AND_N_MINUTES) {
    const { hours, minutes } =
      getRepeatOptionsForEveryMHoursAndNMinutesRepetition(repeat)
    let datesFound = false
    let alarmNextDate = origAlarmDate

    while (!datesFound) {
      if (alarmEndDate && alarmNextDate > alarmEndDate) {
        datesFound = true
      } else if (alarmNextDate > date) {
        nextDate = alarmNextDate
        datesFound = true
      } else {
        prevDate = alarmNextDate
      }

      // Add number of hours and minutes to the date
      alarmNextDate = moment(alarmNextDate)
        .tz(alarmCreatorTimezone)
        .add(hours, 'hours')
        .add(minutes, 'minutes')
        .valueOf()
    }
  } else if (repeatType === Constants.RepeatTypes.MONTHLY) {
    let datesFound = false
    let alarmNextDate = origAlarmDate

    while (!datesFound) {
      if (alarmEndDate && alarmNextDate > alarmEndDate) {
        datesFound = true
      } else if (alarmNextDate > date) {
        nextDate = alarmNextDate
        datesFound = true
      } else {
        prevDate = alarmNextDate
      }
      // Keep getting the next day of the month until we find a date
      // that is greater than the passed in date
      alarmNextDate = getNextDateForDayAfterNMonths(
        1,
        alarmNextDate,
        alarmCreatorTimezone
      )
    }
  } else if (repeatType === Constants.RepeatTypes.YEARLY) {
    let datesFound = false
    let alarmNextDate = origAlarmDate

    while (!datesFound) {
      if (alarmEndDate && alarmNextDate > alarmEndDate) {
        datesFound = true
      } else if (alarmNextDate > date) {
        nextDate = alarmNextDate
        datesFound = true
      } else {
        prevDate = alarmNextDate
      }
      // Keep getting the next day of the year until we find a date
      // that is greater than the passed in date
      alarmNextDate = getNextDateForYearlyOption(
        alarmNextDate,
        alarmCreatorTimezone
      )
    }
  } else if (repeatType === Constants.RepeatTypes.DAYS) {
    if (
      repeat.includes(Constants.ODD_NUMBERED_DAYS_REPEAT_STRING) ||
      repeat.includes(Constants.EVEN_NUMBERED_DAYS_REPEAT_STRING)
    ) {
      // Odd even numbered days repeat type
      const { isOdd, selectedDays: repeatArr } =
        getRepeatOptionsForOddAndEvenNumberedDaysRepetition(repeat)

      let datesFound = false
      let currAlarmDate = origAlarmDate

      while (!datesFound) {
        for (let index = 0; index < repeatArr.length; index++) {
          if (datesFound) break
          const repeatDay = repeatArr[index]
          // Find the time on the day that the alarm will ring
          let alarmDateOnDay = moment(currAlarmDate)
            .tz(alarmCreatorTimezone)
            .day(Constants.RepeatDayToDayMapping[repeatDay])
            .valueOf()

          // If the date happens to be before the start date of the alarm, move ahead.
          if (alarmDateOnDay < origAlarmDate) {
            continue
          }

          const alarmDateOfMonthOnDay = moment(alarmDateOnDay).date()

          if (
            isOdd
              ? alarmDateOfMonthOnDay % 2 === 0
              : alarmDateOfMonthOnDay % 2 === 1
          ) {
            continue
          }

          if (alarmEndDate && alarmDateOnDay > alarmEndDate) {
            datesFound = true
          } else if (alarmDateOnDay > date) {
            nextDate = alarmDateOnDay
            datesFound = true
          } else {
            prevDate = alarmDateOnDay
          }
        }
        // Move to the next week
        currAlarmDate = moment(currAlarmDate)
          .tz(alarmCreatorTimezone)
          .add(1, 'weeks')
          .valueOf()
      }
    } else {
      // Every n days repeat type
      const days = parseInt(repeat)
      if (days) {
        let datesFound = false
        let alarmNextDate = origAlarmDate

        while (!datesFound) {
          if (alarmEndDate && alarmNextDate > alarmEndDate) {
            datesFound = true
          } else if (alarmNextDate > date) {
            nextDate = alarmNextDate
            datesFound = true
          } else {
            prevDate = alarmNextDate
          }

          // Add number of days to the date
          alarmNextDate = moment(alarmNextDate)
            .tz(alarmCreatorTimezone)
            .add(days, 'days')
            .valueOf()
        }
      }
    }
  } else if (repeatType === Constants.RepeatTypes.WEEKS) {
    const weeks = parseInt(repeat)
    if (weeks) {
      let datesFound = false
      let alarmNextDate = origAlarmDate

      while (!datesFound) {
        if (alarmEndDate && alarmNextDate > alarmEndDate) {
          datesFound = true
        } else if (alarmNextDate > date) {
          nextDate = alarmNextDate
          datesFound = true
        } else {
          prevDate = alarmNextDate
        }

        // Add number of weeks to the date
        alarmNextDate = moment(alarmNextDate)
          .tz(alarmCreatorTimezone)
          .add(weeks, 'weeks')
          .valueOf()
      }
    }
  } else if (repeatType === Constants.RepeatTypes.MONTHS) {
    if (repeat === Constants.LAST_DAY_OF_MONTH_REPEAT_STRING) {
      const hours = moment(origAlarmDate).tz(alarmCreatorTimezone).hours()
      const minutes = moment(origAlarmDate).tz(alarmCreatorTimezone).minutes()

      let datesFound = false
      let alarmNextDate = moment(origAlarmDate)
        .tz(alarmCreatorTimezone)
        .endOf('month')
        .hours(hours)
        .minutes(minutes)
        .seconds(0)
        .milliseconds(0)
        .valueOf()

      while (!datesFound) {
        if (alarmEndDate && alarmNextDate > alarmEndDate) {
          datesFound = true
        } else if (alarmNextDate > date) {
          nextDate = alarmNextDate
          datesFound = true
        } else {
          prevDate = alarmNextDate
        }

        alarmNextDate = moment(alarmNextDate)
          .tz(alarmCreatorTimezone)
          .add(1, 'days')
          .endOf('month')
          .hours(hours)
          .minutes(minutes)
          .seconds(0)
          .milliseconds(0)
          .valueOf()
      }
    } else if (
      repeat.startsWith(Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING)
    ) {
      const repeatOptionsArr = repeat.split(':')
      const weekNumberOfDayInMonth = Number(repeatOptionsArr[1])
      const dayOfWeek = moment(origAlarmDate).tz(alarmCreatorTimezone).day()
      let datesFound = false
      let alarmNextDate = origAlarmDate

      while (!datesFound) {
        if (alarmEndDate && alarmNextDate > alarmEndDate) {
          datesFound = true
        } else if (alarmNextDate > date) {
          nextDate = alarmNextDate
          datesFound = true
        } else {
          prevDate = alarmNextDate
        }

        // Find next date for nth day of week in month
        alarmNextDate = getNextDateForDayOfWeekInMonthRepeatOption(
          weekNumberOfDayInMonth,
          dayOfWeek,
          alarmNextDate,
          alarmCreatorTimezone
        )
      }
    } else if (
      repeat.startsWith(Constants.LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING)
    ) {
      let datesFound = false
      let alarmNextDate = origAlarmDate
      const dayOfWeek = moment(alarmNextDate).tz(alarmCreatorTimezone).day()

      while (!datesFound) {
        if (alarmEndDate && alarmNextDate > alarmEndDate) {
          datesFound = true
        } else if (alarmNextDate > date) {
          nextDate = alarmNextDate
          datesFound = true
        } else {
          prevDate = alarmNextDate
        }

        // Find next date for nth day of week in month
        alarmNextDate = getNextDateForLastDayOfWeekInMonth(
          dayOfWeek,
          alarmNextDate,
          alarmCreatorTimezone
        )
      }
    } else {
      const months = parseInt(repeat)
      if (months) {
        let datesFound = false
        let alarmNextDate = origAlarmDate

        while (!datesFound) {
          if (alarmEndDate && alarmNextDate > alarmEndDate) {
            datesFound = true
          } else if (alarmNextDate > date) {
            nextDate = alarmNextDate
            datesFound = true
          } else {
            prevDate = alarmNextDate
          }

          // Add number of weeks to the date
          alarmNextDate = getNextDateForDayAfterNMonths(
            months,
            alarmNextDate,
            alarmCreatorTimezone
          )
        }
      }
    }
  } else if (repeatType === Constants.RepeatTypes.YEARS) {
    if (repeat.startsWith(Constants.LAST_DAY_OF_MONTH_REPEAT_STRING)) {
      const hours = moment(origAlarmDate).tz(alarmCreatorTimezone).hours()
      const minutes = moment(origAlarmDate).tz(alarmCreatorTimezone).minutes()

      let datesFound = false
      let alarmNextDate = moment(origAlarmDate)
        .tz(alarmCreatorTimezone)
        .endOf('month')
        .hours(hours)
        .minutes(minutes)
        .seconds(0)
        .milliseconds(0)
        .valueOf()

      while (!datesFound) {
        if (alarmEndDate && alarmNextDate > alarmEndDate) {
          datesFound = true
        } else if (alarmNextDate > date) {
          nextDate = alarmNextDate
          datesFound = true
        } else {
          prevDate = alarmNextDate
        }

        alarmNextDate = moment(alarmNextDate)
          .tz(alarmCreatorTimezone)
          .add(1, 'years')
          .endOf('month')
          .hours(hours)
          .minutes(minutes)
          .seconds(0)
          .milliseconds(0)
          .valueOf()
      }
    } else if (
      repeat.startsWith(Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING)
    ) {
      const repeatOptionsArr = repeat.split(':')
      const weekNumberOfDayInMonth = Number(repeatOptionsArr[1])
      const dayOfWeek = moment(origAlarmDate).tz(alarmCreatorTimezone).day()

      let datesFound = false
      let alarmNextDate = origAlarmDate

      while (!datesFound) {
        if (alarmEndDate && alarmNextDate > alarmEndDate) {
          datesFound = true
        } else if (alarmNextDate > date) {
          nextDate = alarmNextDate
          datesFound = true
        } else {
          prevDate = alarmNextDate
        }

        // Find next date for nth day of week in month
        alarmNextDate = getNextDateForDayOfWeekInMonthNextYearRepeatOption(
          weekNumberOfDayInMonth,
          dayOfWeek,
          alarmNextDate,
          alarmCreatorTimezone
        )
      }
    } else if (
      repeat.startsWith(Constants.LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING)
    ) {
      let datesFound = false
      let alarmNextDate = origAlarmDate
      const dayOfWeek = moment(alarmNextDate).tz(alarmCreatorTimezone).day()

      while (!datesFound) {
        if (alarmEndDate && alarmNextDate > alarmEndDate) {
          datesFound = true
        } else if (alarmNextDate > date) {
          nextDate = alarmNextDate
          datesFound = true
        } else {
          prevDate = alarmNextDate
        }

        // Find next date for nth day of week in month
        alarmNextDate = getNextDateForLastDayOfWeekInMonthNextYear(
          dayOfWeek,
          alarmNextDate,
          alarmCreatorTimezone
        )
      }
    } else {
      const years = parseInt(repeat)
      if (years) {
        let datesFound = false
        let alarmNextDate = origAlarmDate

        while (!datesFound) {
          if (alarmEndDate && alarmNextDate > alarmEndDate) {
            datesFound = true
          } else if (alarmNextDate > date) {
            nextDate = alarmNextDate
            datesFound = true
          } else {
            prevDate = alarmNextDate
          }

          // Add number of weeks to the date
          alarmNextDate = getNextDateForDayAfterNYears(
            years,
            alarmNextDate,
            alarmCreatorTimezone
          )
        }
      }
    }
  }
  return {
    prevDate: prevDate,
    nextDate: nextDate
  }
}

function getNextDateForDayOfWeekInMonthNextYearRepeatOption(
  weekNumberOfDayInMonth,
  dayOfWeek,
  baseDate,
  alarmCreatorTimezone
) {
  let newDate = moment(baseDate).tz(alarmCreatorTimezone)
  let dateFound = false

  while (!dateFound) {
    newDate.add(1, 'years')
    // This is the case where we could have 1,2,3,4,5 day of week in month option
    newDate.set('date', 1)
    // Here we are finding the first occurrence of the day like Monday or Tuesday in the month
    for (let i = 0; i < 7; i++) {
      let newDayOfWeek = newDate.day()
      if (newDayOfWeek === dayOfWeek) {
        break
      }
      newDate.add(1, 'days')
    } // moment has an API to set the day of the week

    // Once we find the first occurrence of the day we are looking for, we add the number of weeks
    // needed to get to the correct date, e.g. for 1 occurrence we will add 0 days, for 2 occurrence
    // we will add 7 days, for 3 occurrence 14 days, for 4 occurrence 21 days, and for 5 occurrence 28 days
    let dateOfMonth = newDate.date() + (weekNumberOfDayInMonth - 1) * 7
    const year = newDate.year()
    const month = newDate.month()

    if (moment([year, month, dateOfMonth]).isValid()) {
      newDate.set('date', dateOfMonth)
      dateFound = true
    }
  }

  return newDate.valueOf()
}

function getNextDateForDayOfWeekInMonthRepeatOption(
  weekNumberOfDayInMonth,
  dayOfWeek,
  baseDate,
  alarmCreatorTimezone
) {
  let newDate = moment(baseDate).tz(alarmCreatorTimezone)
  let dateFound = false

  while (!dateFound) {
    newDate.add(1, 'months')
    // This is the case where we could have 1,2,3,4,5 day of week in month option
    newDate.set('date', 1)
    // Here we are finding the first occurrence of the day like Monday or Tuesday in the month
    for (let i = 0; i < 7; i++) {
      let newDayOfWeek = newDate.day()
      if (newDayOfWeek === dayOfWeek) {
        break
      }
      newDate.add(1, 'days')
    } // moment has an API to set the day of the week

    // Once we find the first occurrence of the day we are looking for, we add the number of weeks
    // needed to get to the correct date, e.g. for 1 occurrence we will add 0 days, for 2 occurrence
    // we will add 7 days, for 3 occurrence 14 days, for 4 occurrence 21 days, and for 5 occurrence 28 days
    let dateOfMonth = newDate.date() + (weekNumberOfDayInMonth - 1) * 7
    const year = newDate.year()
    const month = newDate.month()

    if (moment([year, month, dateOfMonth]).isValid()) {
      newDate.set('date', dateOfMonth)
      dateFound = true
    }
  }

  return newDate.valueOf()
}

function getNextDateForLastDayOfWeekInMonthNextYear(
  dayOfWeek,
  baseDate,
  alarmCreatorTimezone
) {
  const hours = moment(baseDate).tz(alarmCreatorTimezone).hours()
  const minutes = moment(baseDate).tz(alarmCreatorTimezone).minutes()

  // This is the case where we have the last day of week in month, like last Friday
  // We will start from the last day of the month and go backwards until we find the right day
  let newDate = moment(baseDate)
    .tz(alarmCreatorTimezone)
    .add(1, 'years')
    .endOf('month')
    .hours(hours)
    .minutes(minutes)
    .seconds(0)
    .milliseconds(0)

  for (let i = 0; i < 7; i++) {
    let newDayOfWeek = newDate.day()
    if (newDayOfWeek === dayOfWeek) {
      break
    }
    newDate.subtract(1, 'days')
  }
  return newDate.valueOf()
}

function getNextDateForLastDayOfWeekInMonth(
  dayOfWeek,
  baseDate,
  alarmCreatorTimezone
) {
  const hours = moment(baseDate).tz(alarmCreatorTimezone).hours()
  const minutes = moment(baseDate).tz(alarmCreatorTimezone).minutes()

  // This is the case where we have the last day of week in month, like last Friday
  // We will start from the last day of the month and go backwards until we find the right day
  let newDate = moment(baseDate)
    .tz(alarmCreatorTimezone)
    .add(1, 'months')
    .endOf('month')
    .hours(hours)
    .minutes(minutes)
    .seconds(0)
    .milliseconds(0)

  for (let i = 0; i < 7; i++) {
    let newDayOfWeek = newDate.day()
    if (newDayOfWeek === dayOfWeek) {
      break
    }
    newDate.subtract(1, 'days')
  }
  return newDate.valueOf()
}

function getNextDateForDayAfterNYears(
  numYears,
  baseDate,
  alarmCreatorTimezone
) {
  let dayOfMonth = moment(baseDate).tz(alarmCreatorTimezone).date()
  let newDate = moment(baseDate).tz(alarmCreatorTimezone)
  let dateFound = false

  while (!dateFound) {
    newDate.add(numYears, 'years')
    const year = newDate.year()
    const month = newDate.month()
    if (moment([year, month, dayOfMonth]).isValid()) {
      dateFound = true
    }
  }
  return newDate.valueOf()
}

function getNextDateForDayAfterNMonths(
  numMonths,
  baseDate,
  alarmCreatorTimezone
) {
  let dayOfMonth = moment(baseDate).tz(alarmCreatorTimezone).date()
  let newDate = moment(baseDate).tz(alarmCreatorTimezone)
  let dateFound = false

  while (!dateFound) {
    newDate.add(numMonths, 'months')
    const year = newDate.year()
    const month = newDate.month()
    if (moment([year, month, dayOfMonth]).isValid()) {
      dateFound = true
    }
  }
  return newDate.valueOf()
}

function getNextDateForYearlyOption(baseDate, alarmCreatorTimezone) {
  let dayOfMonth = moment(baseDate).tz(alarmCreatorTimezone).date()
  let newDate = moment(baseDate).tz(alarmCreatorTimezone)
  let dateFound = false
  while (!dateFound) {
    newDate.add(1, 'years')
    const year = newDate.year()
    const month = newDate.month()
    if (moment([year, month, dayOfMonth]).isValid()) {
      dateFound = true
    }
  }
  return newDate.valueOf()
}

export function getNextOccurrencesForHoursAndMinutesRepetition(
  date,
  hours,
  minutes,
  numberOfOccurences = 5
) {
  let nextOccurrences = []
  const timezone = RNLocalize.getTimeZone()
  let origAlarmDate = moment(date).tz(timezone)
  let alarmDate = origAlarmDate
  let i = 0

  while (i < numberOfOccurences) {
    // If alarm date is greater than current date, then we will add it to the list
    if (alarmDate > Date.now()) {
      nextOccurrences.push(
        DateTimeUtils.getDateTimeWoYearAsString(alarmDate.valueOf())
      )
      let nextAlarmDate = moment(alarmDate)
        .add(hours, 'hours')
        .add(minutes, 'minutes')
      alarmDate = nextAlarmDate
      i++
    } else {
      // Add hours and minutes to the date
      let nextAlarmDate = moment(alarmDate)
        .add(hours, 'hours')
        .add(minutes, 'minutes')
      nextOccurrences.push(
        DateTimeUtils.getDateTimeWoYearAsString(nextAlarmDate.valueOf())
      )
      alarmDate = nextAlarmDate
      i++
    }
  }
  return nextOccurrences
}

export function getNextDateForAlarm(
  alarmDate,
  alarmEndDate,
  alarmRepeatType,
  alarmRepeat,
  alarmCreatorTimezone
) {
  const { nextDate } = getPrevAndNextDatesForAnAlarmWrtDate(
    Date.now(),
    alarmDate,
    alarmEndDate,
    alarmRepeatType,
    alarmRepeat,
    alarmCreatorTimezone
  )
  return nextDate
}

export function getRepeatOptionsForOddAndEvenNumberedDaysRepetition(
  repeatString
) {
  const repeatOptions = repeatString.split(':')
  const isOdd = repeatOptions[0] === Constants.ODD_NUMBERED_DAYS_REPEAT_STRING
  const selectedDays = repeatOptions[1] || ''

  return {
    isOdd: isOdd,
    selectedDays: arrCompact(selectedDays.split(','))
  }
}

export function getRepeatOptionsForHourlyRepetition(repeatString) {
  const repeatOptions = repeatString.split(':')
  const startHours = repeatOptions[0]
  const startMins = repeatOptions[1]
  const endHours = repeatOptions[2]
  const endMins = repeatOptions[3]
  const hours = repeatOptions[4]
  const selectedDays = repeatOptions[5] || ''

  return {
    startHours: parseInt(startHours),
    startMins: parseInt(startMins),
    endHours: parseInt(endHours),
    endMins: parseInt(endMins),
    hours: parseInt(hours),
    selectedDays: arrCompact(selectedDays.split(','))
  }
}

export function getRepeatOptionsForHoursAndMinutesRepetition(repeatString) {
  const repeatOptions = repeatString.split(':')
  const startHours = repeatOptions[0]
  const startMins = repeatOptions[1]
  const endHours = repeatOptions[2]
  const endMins = repeatOptions[3]
  const minutes = parseInt(repeatOptions[4])
  const selectedDays = repeatOptions[5] || ''

  return {
    startHours: parseInt(startHours),
    startMins: parseInt(startMins),
    endHours: parseInt(endHours),
    endMins: parseInt(endMins),
    minutes: minutes,
    selectedDays: arrCompact(selectedDays.split(','))
  }
}
export function getRepeatOptionsForEveryMHoursAndNMinutesRepetition(
  repeatString
) {
  const repeatOptions = repeatString.split(':')

  let hours = 0
  let minutes = 0

  // For backward compatibility, if only hours is set - parse the hours string
  if (repeatOptions.length === 1) {
    if (!isEmpty(repeatOptions[0])) {
      // Parse hours if it's not empty
      hours = parseInt(repeatOptions[0])
    }
  }
  // If both hours and minutes are set
  else if (repeatOptions.length === 2) {
    if (!isEmpty(repeatOptions[0])) {
      // Parse hours if it's not empty
      hours = parseInt(repeatOptions[0])
    }
    if (!isEmpty(repeatOptions[1])) {
      // Parse minutes if it's not empty
      minutes = parseInt(repeatOptions[1])
    }
  }
  return {
    hours: hours,
    minutes: minutes
  }
}
export function checkIfDayOfMonthIsLastDayOfWeekInMonth(alarmDate) {
  const date = moment(alarmDate).date()
  const dateForLastDayOfWeekInMonth = computeDateForLastDayOfWeekInMonth(
    alarmDate,
    moment(alarmDate).day()
  )
  return date === dateForLastDayOfWeekInMonth
}

function computeDateForLastDayOfWeekInMonth(alarmDate, dayOfWeek) {
  const newDate = moment(alarmDate).endOf('month')

  for (let i = 0; i < 7; i++) {
    let newDayOfWeek = newDate.day()
    if (newDayOfWeek === dayOfWeek) {
      break
    }
    newDate.subtract(1, 'days')
  }
  return newDate.date()
}

export function computeDayOfWeekInMonthForDate(alarmDate) {
  let date = moment(alarmDate).date()
  let weekNumberOfDayInMonth = Math.ceil(date / 7)
  return weekNumberOfDayInMonth
}

const AlarmUtils = (function () {
  const ref = GlobalConfig.rootFirebaseRef

  // Create alarm from firebase alarm object
  const createAlarm = alarmData => {
    if (
      alarmData.id === undefined ||
      alarmData.name === undefined ||
      alarmData.status === undefined ||
      alarmData.date === undefined ||
      alarmData.repeatType === undefined ||
      alarmData.repeat === undefined
    ) {
      console.tron.log('Invalid alarm ' + JSON.stringify(alarmData))
      return undefined
    }

    let backupGroup = null
    let backupContacts = []

    if (alarmData.backups !== undefined) {
      if (alarmData.backups.contacts !== undefined) {
        backupContacts = createBackupContactsFromContactsObject(
          alarmData.backups.contacts
        )
      } else if (alarmData.backups.group !== undefined) {
        backupGroup = createBackupGroupFromGroupObject(alarmData.backups.group)
      }
    }

    let { backups, recipient, ...alarmProps } = alarmData // eslint-disable-line no-unused-vars

    let alarm = {
      ...alarmProps,
      backupGroup,
      backupContacts,
      recipient: recipient || null
    }

    const participants = getAlarmParticipants(
      alarm.type,
      alarm.backupGroup,
      alarm.backupContacts,
      alarm.recipient
    )

    const participant = Utils.getObjectWithId(participants, GlobalConfig.uid)

    if (participant) {
      alarm = {
        ...alarm,
        responseStatus: participant.responseStatus,
        order: participant.order || 0,
        state: participant.state || Constants.ParticipantStates.ACTIVE,
        blocked: participant.blocked || false
      }
    }

    return alarm
  }

  const createChecklist = checklistData => {
    if (checklistData.id === undefined) {
      console.tron.log('Invalid checklist ' + JSON.stringify(checklistData))
      return undefined
    }

    let items = []

    if (checklistData.items !== undefined) {
      items = Object.values(checklistData.items)
    }

    const checklist = {
      ...checklistData,
      items
    }

    return checklist
  }

  const adjustRepetitionStringIfParticipant = alarm => {
    const {
      backupGroup,
      backupContacts,
      repeat,
      repeatType,
      creator,
      creatorTimezone,
      type,
      date,
      recipientAlarmInterval,
      cascadingAlarmInterval
    } = alarm
    if (
      repeat === '' ||
      creator === GlobalConfig.uid ||
      (repeatType !== Constants.RepeatTypes.EVERY_N_HOURS &&
        repeatType !== Constants.RepeatTypes.HOURS_AND_MINUTES &&
        repeatType !== Constants.RepeatTypes.DAYS_OF_WEEK)
    ) {
      return repeat
    }

    let participantAlarmDate
    if (type === Constants.AlarmTypes.RECIPIENT) {
      participantAlarmDate =
        date - Constants.RecipientAlarmIntervals[recipientAlarmInterval].value
    } else if (type === Constants.AlarmTypes.CASCADING) {
      const participants = backupGroup ? backupGroup.members : backupContacts
      const participant = Utils.getObjectWithId(participants, GlobalConfig.uid)
      if (!participant) {
        participantAlarmDate = date
      } else {
        participantAlarmDate =
          date +
          participant.order *
            Constants.CascadingAlarmIntervals[cascadingAlarmInterval].value
      }
    } else if (type === Constants.AlarmTypes.SIMULTANEOUS) {
      participantAlarmDate = date
    }

    const differenceInDays = DateTimeUtils.differenceInDayFromTimezone(
      participantAlarmDate,
      creatorTimezone
    )

    if (differenceInDays === 0) {
      return repeat
    }

    if (repeatType === Constants.RepeatTypes.DAYS_OF_WEEK) {
      return differenceInDays === 1
        ? addDayToWeeklyRepetitionString(repeat)
        : subtractDayFromWeeklyRepetitionString(repeat)
    } else if (repeatType === Constants.RepeatTypes.EVERY_N_HOURS) {
      return differenceInDays === 1
        ? addDayToHourlyRepetitionString(repeat)
        : subtractDayFromHourlyRepetitionString(repeat)
    }
  }

  const createBackupsObject = (backupGroup, backupContacts) => {
    let backups = {}
    if (backupGroup && backupGroup.members) {
      backups.group = {}
      backups.group[backupGroup.id] = {}
      backups.group[backupGroup.id].name = backupGroup.name
      backups.group[backupGroup.id].members = {}
      backupGroup.members.forEach(member => {
        backups.group[backupGroup.id].members[member.id] = {
          ...member
        }
      })
    } else if (backupContacts && backupContacts.length > 0) {
      backups.contacts = {}
      backupContacts.forEach(backupContact => {
        backups.contacts[backupContact.id] = {
          ...backupContact
        }
      })
    }
    return backups
  }

  // Create alarm object to be stored on firebase
  const createAlarmObject = alarm => {
    const backups = createBackupsObject(alarm.backupGroup, alarm.backupContacts)

    let { backupGroup, backupContacts, ...alarmProps } = alarm // eslint-disable-line no-unused-vars

    const alarmObj = {
      ...alarmProps,
      backups: backups
    }

    return alarmObj
  }

  const retrieveAlarmByAlarmId = function (alarmId, useCachedValue = true) {
    if (!alarmId) {
      return Promise.resolve(null)
    }

    if (GlobalConfig.store && useCachedValue) {
      const state = GlobalConfig.store.getState()
      const alarmSelector = makeAlarmSelector()
      const alarm = alarmSelector(state, { alarmId })
      if (!isEmpty(alarm)) {
        return Promise.resolve(alarm)
      }
    }
    return ref
      .child('alarms')
      .child(alarmId)
      .once('value')
      .then(snapshot => {
        if (!snapshot.exists()) {
          return null
        }

        const alarmData = snapshot.val()
        const alarm = createAlarm(alarmData)
        return alarm
      })
  }

  const getPreviousOccurrencesBetweenTime = function (
    alarmId,
    startTime,
    endTime
  ) {
    const alarm = getAlarmFromStore(alarmId)

    if (!alarm) {
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]:
          'getPreviousOccurrencesBetweenTime'
      })
      return []
    }

    return getPreviousOccurrencesOfAlarmBetweenTime(alarm, startTime, endTime)
  }

  const getPreviousOccurrencesOfAlarmBetweenTime = function (
    alarm,
    startTime,
    endTime
  ) {
    return memoizedGetPreviousOccurrencesOfAlarmBetweenTime(
      alarm,
      startTime,
      endTime
    )
  }

  const findMissedOccurrencesOfAlarmBetweenTime = function (
    alarm,
    startTime,
    endTime
  ) {
    return findMissedOccurrencesOfAlarmBetweenTimeCore(
      alarm,
      startTime,
      endTime
    )
  }

  // Returns the done/confirm acknowledgements for this alarm in the given time period
  const findAlarmAcknowledgementsBetweenTime = (alarm, startTime, endTime) => {
    const state = GlobalConfig.store.getState()
    const alarmAcknowledgements = objGet(
      state,
      `alarmActions.alarmAcknowledgements[${alarm.id}]`,
      {}
    )

    let uid
    switch (alarm.type) {
      case Constants.AlarmTypes.CASCADING:
        uid = alarm.creator
        break
      case Constants.AlarmTypes.SIMULTANEOUS:
        uid = GlobalConfig.uid
        break
      case Constants.AlarmTypes.RECIPIENT:
        uid = alarm.recipient.id
        break
      default:
        LogUtils.logError(
          'Unknown alarm type while finding the acknowledgements of an alarm ' +
            alarm.type
        )
        return []
    }

    let alarmAcknowledgementsBetweenTime = []

    Object.keys(alarmAcknowledgements).forEach(occurrenceTimeString => {
      const occurrenceTime = DateTimeUtils.getDateFromFormattedDateString(
        occurrenceTimeString,
        RNLocalize.getTimeZone()
      )
      if (occurrenceTime > startTime && occurrenceTime < endTime) {
        let alarmAcknowledgement
        const alarmAcknowledgementsForUser = objGet(
          alarmAcknowledgements,
          `${occurrenceTimeString}.${uid}`,
          []
        )

        if (alarmAcknowledgementsForUser.length > 0) {
          alarmAcknowledgement = alarmAcknowledgementsForUser.reduce(
            (prev, current) =>
              prev.timestamp > current.timestamp ? prev : current
          )
        }

        if (
          alarmAcknowledgement &&
          (alarmAcknowledgement.response === Constants.PERSONAL_ALARM_DONE ||
            alarmAcknowledgement.response === Constants.GROUP_ALARM_YES ||
            alarmAcknowledgement.response === Constants.RECIPIENT_ALARM_DONE)
        ) {
          alarmAcknowledgementsBetweenTime.push(alarmAcknowledgement)
        }
      }
    })

    return alarmAcknowledgementsBetweenTime
  }

  const getPreviousOccurrencesString = async function (alarmId) {
    const alarm = getAlarmFromStore(alarmId)

    if (!alarm) {
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]:
          'getPreviousOccurrencesString'
      })
      return Promise.resolve(I18n.t('noPreviousOccurrences'))
    }

    const currDate = Date.now()

    if (alarm.firstAlarmDate > currDate) {
      return Promise.resolve(I18n.t('noPreviousOccurrences'))
    }

    const endTime = getCurrentDateForAlarm(alarm)
    const bucketSize =
      computeLoadAlarmPreviousOccurrencesBucketDurationFromRepetition(alarm)
    const startTime = endTime - bucketSize

    // If start time before alarm start time which is tracked
    // by historyStartDate, then move the startDate to historyStartDate
    // - a certain minimum duration such that we don't show like
    // None missed in 1 hour for daiily alarm for example
    const historyStartDate = alarm.historyStartDate
    let effectiveStartTime = startTime
    if (startTime < historyStartDate) {
      effectiveStartTime = historyStartDate
    }

    return TaskManager.addHttpsCloudTask(
      'getPreviousOccurrencesOfAlarmBetweenTime',
      {
        alarmId: alarmId,
        startTime: effectiveStartTime,
        endTime: endTime
      }
    ).then(taskResult => {
      const alarmOccurrencesStr = objGet(taskResult, 'alarmOccurrences', '[]')
      const alarmOccurrences = JSON.parse(alarmOccurrencesStr)
      const numAlarmOccurrences = alarmOccurrences.length

      const alarmAcknowledgements = findAlarmAcknowledgementsBetweenTime(
        alarm,
        effectiveStartTime,
        endTime
      )
      const numAcknowledgements = objCompact(alarmAcknowledgements).length

      const missedAlarmOccurrences = numAlarmOccurrences - numAcknowledgements
      return I18n.t('nMissedOccurrencesOfAlarmInLastNDays', {
        missedAlarmOccurrences: missedAlarmOccurrences,
        count: numAlarmOccurrences
      })
    })
  }

  const getPreviousOccurrencesOfAlarmBetweenTimeCore = function (
    alarm,
    startTime,
    endTime
  ) {
    console.tron.log(
      'Finding previous occurrences between time ' + startTime + ' ' + endTime
    )
    const {
      type: alarmType,
      firstAlarmDate = alarm.date,
      endDate: alarmEndDate,
      repeatType: alarmRepeatType,
      repeat: alarmRepeat,
      historyStartDate = Date.now()
    } = alarm

    if (alarmRepeatType === '') {
      return []
    }

    if (alarmType === Constants.AlarmTypes.RECIPIENT) {
      startTime =
        startTime +
        Constants.RecipientAlarmIntervals[alarm.recipientAlarmInterval].value
      endTime =
        endTime +
        Constants.RecipientAlarmIntervals[alarm.recipientAlarmInterval].value
    }

    let done = false
    let nextAlarmOccurrencesMap = {}
    let alarmOccurrences = []
    let effectiveStartTime = startTime
    while (!done) {
      const effectiveStartTimeString = effectiveStartTime.toString()
      let nextAlarmOccurrence =
        nextAlarmOccurrencesMap[effectiveStartTimeString]
      if (!nextAlarmOccurrence) {
        const { nextDate } = getPrevAndNextDatesForAnAlarmWrtDate(
          effectiveStartTime,
          firstAlarmDate,
          alarmEndDate,
          alarmRepeatType,
          alarmRepeat,
          alarm.creatorTimezone
        )
        nextAlarmOccurrence = nextDate
      }
      if (nextAlarmOccurrence && nextAlarmOccurrence < endTime) {
        if (nextAlarmOccurrence > historyStartDate) {
          nextAlarmOccurrencesMap[effectiveStartTimeString] =
            nextAlarmOccurrence
          alarmOccurrences.push(nextAlarmOccurrence)
        }
        effectiveStartTime = nextAlarmOccurrence + Constants.MSEC_IN_MINUTE
      } else {
        done = true
      }
    }

    // Remove the current alarm occurrence if present in the previous occurrences
    const currAlarmDate = getCurrentDateForAlarm(alarm)
    const currAlarmDateIndex = alarmOccurrences.findIndex(
      occurrence => occurrence === currAlarmDate
    )
    currAlarmDateIndex !== -1 && alarmOccurrences.splice(currAlarmDateIndex, 1)

    return alarmOccurrences
  }

  const memoizedGetPreviousOccurrencesOfAlarmBetweenTime = memoize(
    getPreviousOccurrencesOfAlarmBetweenTimeCore
  )

  const findMissedOccurrencesOfAlarmBetweenTimeCore = function (
    alarm,
    startTime,
    endTime
  ) {
    let missedAlarmOccurrences = []
    const alarmOccurrences = getPreviousOccurrencesOfAlarmBetweenTime(
      alarm,
      startTime,
      endTime
    )
    alarmOccurrences.forEach(alarmOccurrence => {
      const alarmAcknowledged =
        getAlarmAcknowledgementStatusForOccurrenceForAlarm(
          alarm,
          alarmOccurrence
        )
      if (!alarmAcknowledged) {
        missedAlarmOccurrences.push(alarmOccurrence)
      }
    })

    return missedAlarmOccurrences
  }

  const createFirebaseObjectForRestoreAlarm = alarm => {
    let firebaseUpdateObj = {}
    firebaseUpdateObj['alarms/' + alarm.id + '/date'] = alarm.date
    firebaseUpdateObj['alarms/' + alarm.id + '/isDeleted'] = null
    firebaseUpdateObj['alarms/' + alarm.id + '/alarmDeletedTimestamp'] = null
    firebaseUpdateObj['alarms/' + alarm.id + '/status'] = true
    firebaseUpdateObj['alarms/' + alarm.id + '/endDate'] = alarm.endDate
    firebaseUpdateObj['userInfos/' + alarm.creator + '/alarms/' + alarm.id] =
      alarm.date
    firebaseUpdateObj['recentlyDeletedAlarms/' + alarm.id] = null
    firebaseUpdateObj[
      'userInfos/' + alarm.creator + '/recentlyDeletedAlarms/' + alarm.id
    ] = null
    firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = Date.now()
    const backupGroup = alarm.backupGroup
    const backupContacts = alarm.backupContacts
    const recipient = alarm.recipient
    if (backupGroup) {
      firebaseUpdateObj[
        'groupInfos/' + backupGroup.id + '/backupForAlarms/' + alarm.id
      ] = alarm.date
    } else if (backupContacts && backupContacts.length > 0) {
      backupContacts.forEach(backup => {
        firebaseUpdateObj[
          'userInfos/' + backup.id + '/backupForAlarms/' + alarm.id
        ] = alarm.date
      })
    } else if (recipient) {
      firebaseUpdateObj[
        'userInfos/' + recipient.id + '/backupForAlarms/' + alarm.id
      ] = alarm.date
    }

    // If parent link exists, the reset the deletedByCreator flag
    if (alarm.link) {
      firebaseUpdateObj['alarms/' + alarm.id + '/link/deletedByCreator'] = null
    } else if (alarm.parentLink && alarm.parentLink.alarmId) {
      // If it is a personal alarm for which a parent link exists, then add the user as a subscriber to the alarm
      firebaseUpdateObj[
        'alarms/' +
          alarm.parentLink.alarmId +
          '/link/subscribers/' +
          GlobalConfig.uid
      ] = alarm.id

      firebaseUpdateObj[
        'alarms/' + alarm.parentLink.alarmId + '/lastUpdatedAt'
      ] = Date.now()
    }
    return firebaseUpdateObj
  }

  const restoreAlarm = alarm => {
    const firebaseUpdateObj = createFirebaseObjectForRestoreAlarm(alarm)
    return ref.update(firebaseUpdateObj)
  }

  const createFirebaseObjectForRecentlyDeletedAlarm = (
    alarm,
    firebaseUpdate
  ) => {
    const currDate = Date.now()
    firebaseUpdate['recentlyDeletedAlarms/' + alarm.id] = {
      id: alarm.id,
      creator: alarm.creator,
      alarmDeletedTimestamp: currDate
    }
    firebaseUpdate[
      'userInfos/' + alarm.creator + '/recentlyDeletedAlarms/' + alarm.id
    ] = alarm.date
    if (alarm.link) {
      firebaseUpdate['alarms/' + alarm.id + '/link/deletedByCreator'] = true
    } else if (alarm.parentLink && alarm.parentLink.alarmId) {
      // If it is a personal alarm for which a parent link exists, then remove the user as a subscriber to the alarm
      firebaseUpdate[
        'alarms/' +
          alarm.parentLink.alarmId +
          '/link/subscribers/' +
          GlobalConfig.uid
      ] = null

      firebaseUpdate['alarms/' + alarm.parentLink.alarmId + '/lastUpdatedAt'] =
        currDate
    }

    firebaseUpdate['userInfos/' + alarm.creator + '/alarms/' + alarm.id] = null
    firebaseUpdate['alarms/' + alarm.id + '/isDeleted'] = true
    firebaseUpdate['alarms/' + alarm.id + '/alarmDeletedTimestamp'] = currDate

    const backupGroup = alarm.backupGroup
    const backupContacts = alarm.backupContacts
    const recipient = alarm.recipient
    if (backupGroup) {
      firebaseUpdate[
        'groupInfos/' + backupGroup.id + '/backupForAlarms/' + alarm.id
      ] = null
    } else if (backupContacts && backupContacts.length > 0) {
      backupContacts.forEach(backup => {
        firebaseUpdate[
          'userInfos/' + backup.id + '/backupForAlarms/' + alarm.id
        ] = null
      })
    } else if (recipient) {
      firebaseUpdate[
        'userInfos/' + recipient.id + '/backupForAlarms/' + alarm.id
      ] = null
    }

    return firebaseUpdate
  }

  const createFirebaseObjectForAddAlarm = alarm => {
    let firebaseUpdate = {}
    const alarmObj = createAlarmObject(alarm)
    firebaseUpdate['alarms/' + alarmObj.id] = alarmObj
    firebaseUpdate['userInfos/' + alarmObj.creator + '/alarms/' + alarmObj.id] =
      alarmObj.date

    // Store the alarm reference on the backup group/contacts
    const backupGroup = alarm.backupGroup
    const backupContacts = alarm.backupContacts
    const recipient = alarm.recipient
    if (backupGroup) {
      firebaseUpdate[
        'groupInfos/' + backupGroup.id + '/backupForAlarms/' + alarm.id
      ] = alarm.date
    } else if (backupContacts && backupContacts.length > 0) {
      backupContacts.forEach(backup => {
        firebaseUpdate[
          'userInfos/' + backup.id + '/backupForAlarms/' + alarm.id
        ] = alarm.date
      })
    } else if (alarm.recipient) {
      firebaseUpdate[
        'userInfos/' + recipient.id + '/backupForAlarms/' + alarm.id
      ] = alarm.date
    }

    return firebaseUpdate
  }

  const createChecklistObj = checklist => {
    const itemsObj = {}
    checklist.items.forEach(item => {
      itemsObj[item.id] = {
        ...item
      }
    })
    // eslint-disable-next-line no-unused-vars
    const { items, ...restOfChecklist } = checklist

    return {
      ...restOfChecklist,
      items: itemsObj,
      persistedOnline: null
    }
  }

  const createFirebaseObjectForAddChecklist = checklist => {
    let firebaseUpdate = {}
    const checklistObj = createChecklistObj(checklist)
    firebaseUpdate['checklists/' + checklist.id] = checklistObj
    firebaseUpdate[
      'userInfos/' + checklist.creator + '/checklists/' + checklist.id
    ] = true
    return firebaseUpdate
  }

  const createFirebaseObjectForEditChecklist = checklist => {
    let firebaseUpdate = {}
    const checklistObj = createChecklistObj(checklist)
    firebaseUpdate['checklists/' + checklistObj.id] = checklistObj
    // Although this is not needed, but adding it here to fix a bug
    // where the checklists were not added to userInfos because of a
    // missing database rule
    firebaseUpdate[
      'userInfos/' + checklist.creator + '/checklists/' + checklist.id
    ] = true
    return firebaseUpdate
  }

  const createFirebaseObjectForDeleteChecklist = checklistId => {
    let firebaseUpdate = {}
    firebaseUpdate['checklists/' + checklistId] = null
    firebaseUpdate[
      'userInfos/' + GlobalConfig.uid + '/checklists/' + checklistId
    ] = null
    return firebaseUpdate
  }

  const createFirebaseObjectForDeleteAlarm = (alarm, firebaseUpdate = {}) => {
    if (alarm.creationMode === Constants.AlarmCreationModes.QUICK_ALARM) {
      createFirebaseObjectForDeleteAlarmCore(alarm, firebaseUpdate)
    } else {
      createFirebaseObjectForRecentlyDeletedAlarm(alarm, firebaseUpdate)
    }

    if (alarm.link && alarm.link.subscribers) {
      // If the alarm has been published, then push a system message to all subscribers
      addUserAlertsForDeleteAlarmToFirebaseUpdate(alarm, firebaseUpdate)
    }
    return firebaseUpdate
  }

  const createFirebaseObjectForDeleteAllAlarms = alarms => {
    let firebaseUpdate = {}
    alarms.forEach(alarm => {
      createFirebaseObjectForDeleteAlarm(alarm, firebaseUpdate)
    })
    return firebaseUpdate
  }

  const addUserAlertsForEditAlarmToFirebaseUpdate = (alarm, firebaseUpdate) => {
    const alarmSubscribers = objGet(alarm, 'link.subscribers', {})
    Object.keys(alarmSubscribers).forEach(alarmSubscriber => {
      const subscriberAlarmId = alarmSubscribers[alarmSubscriber]
      const alert = {
        type: Constants.AlertTypes.SUBSCRIBED_ALARM_EDITED,
        data: {
          subscriberAlarmId: subscriberAlarmId,
          alarmName: alarm.name,
          alarmCreatorName: alarm.creatorName
        }
      }
      const alertId = ref.push().key
      firebaseUpdate['userInfos/' + alarmSubscriber + '/alerts/' + alertId] =
        JSON.stringify(alert)
    })
    return firebaseUpdate
  }

  const addUserAlertsForDeleteAlarmToFirebaseUpdate = (
    alarm,
    firebaseUpdate
  ) => {
    const alarmSubscribers = objGet(alarm, 'link.subscribers', {})
    Object.keys(alarmSubscribers).forEach(alarmSubscriber => {
      const subscriberAlarmId = alarmSubscribers[alarmSubscriber]
      const alert = {
        type: Constants.AlertTypes.SUBSCRIBED_ALARM_DELETED,
        data: {
          subscriberAlarmId: subscriberAlarmId,
          alarmName: alarm.name,
          alarmCreatorName: alarm.creatorName
        }
      }
      const alertId = ref.push().key
      firebaseUpdate['userInfos/' + alarmSubscriber + '/alerts/' + alertId] =
        JSON.stringify(alert)
    })
    return firebaseUpdate
  }

  const createFirebaseObjectForDeleteAlarmCore = (
    alarm,
    firebaseUpdate,
    deleteAlarmChat = true,
    deleteAlarmActions = true,
    isForEdit = false
  ) => {
    if (isForEdit) {
      firebaseUpdate['alarms/' + alarm.id] = null
    } else {
      // If it is a personal alarm for which a link exists, then don't delete the alarm
      // Because we need the alarm when somebody subscribes to the same alarm link
      if (alarm.link) {
        firebaseUpdate['alarms/' + alarm.id + '/backupGroup'] = null
        firebaseUpdate['alarms/' + alarm.id + '/backupContacts'] = null
        firebaseUpdate['alarms/' + alarm.id + '/link/deletedByCreator'] = true
      } else if (alarm.parentLink && alarm.parentLink.alarmId) {
        // If it is a personal alarm for which a parent link exists, then delete the alarm
        // and also remove the user as a subscriber to the alarm
        firebaseUpdate['alarms/' + alarm.id] = null

        firebaseUpdate[
          'alarms/' +
            alarm.parentLink.alarmId +
            '/link/subscribers/' +
            GlobalConfig.uid
        ] = null

        firebaseUpdate[
          'alarms/' + alarm.parentLink.alarmId + '/lastUpdatedAt'
        ] = Date.now()
      } else {
        firebaseUpdate['alarms/' + alarm.id] = null
      }
    }

    firebaseUpdate['userInfos/' + alarm.creator + '/alarms/' + alarm.id] = null

    const backupGroup = alarm.backupGroup
    const backupContacts = alarm.backupContacts
    const recipient = alarm.recipient
    if (backupGroup) {
      firebaseUpdate[
        'groupInfos/' + backupGroup.id + '/backupForAlarms/' + alarm.id
      ] = null
    } else if (backupContacts && backupContacts.length > 0) {
      backupContacts.forEach(backup => {
        firebaseUpdate[
          'userInfos/' + backup.id + '/backupForAlarms/' + alarm.id
        ] = null
      })
    } else if (recipient) {
      firebaseUpdate[
        'userInfos/' + recipient.id + '/backupForAlarms/' + alarm.id
      ] = null
    }

    if (deleteAlarmChat) {
      firebaseUpdate['conversations/alarms/' + alarm.id] = null
      firebaseUpdate['conversations/unseenMessages/' + alarm.id] = null
      firebaseUpdate['conversations/currentlyTyping/' + alarm.id] = null
    }

    if (deleteAlarmActions) {
      firebaseUpdate['alarmActions/' + alarm.id] = null
    }

    return firebaseUpdate
  }

  const isAlarmEditedForSubscribers = (prevAlarm, editedAlarm) => {
    const prevAlarmWithoutParticipants = objOmit(prevAlarm, [
      'backups',
      'recipient'
    ])

    const editedAlarmWithoutParticipants = objOmit(editedAlarm, [
      'backups',
      'recipient'
    ])

    return !isObjEqual(
      prevAlarmWithoutParticipants,
      editedAlarmWithoutParticipants
    )
  }

  const createFirebaseObjectForEditAlarm = (
    editedAlarm,
    prevAlarm,
    removeSubscription
  ) => {
    let deleteAlarmActions = false
    if (hasAlarmPreviousOccurrencesChanged(prevAlarm, editedAlarm)) {
      deleteAlarmActions = true
    }
    let firebaseUpdateForDeleteAlarm = createFirebaseObjectForDeleteAlarmCore(
      prevAlarm,
      {},
      false,
      deleteAlarmActions,
      true
    )

    let firebaseUpdateForAddAlarm = createFirebaseObjectForAddAlarm(editedAlarm)

    const firebaseUpdateForEditAlarm = {
      ...firebaseUpdateForDeleteAlarm,
      ...firebaseUpdateForAddAlarm
    }

    const alarmEditedForSubscribers = isAlarmEditedForSubscribers(
      prevAlarm,
      editedAlarm
    )
    // If alarm has been published, then push a system message to all the subscribers
    if (alarmEditedForSubscribers) {
      if (prevAlarm.link && prevAlarm.link.subscribers) {
        addUserAlertsForEditAlarmToFirebaseUpdate(
          prevAlarm,
          firebaseUpdateForEditAlarm
        )
      }
    } else if (prevAlarm.parentLink) {
      firebaseUpdateForEditAlarm['alarms/' + prevAlarm.id + '/parentLink'] =
        prevAlarm.parentLink
    }

    if (removeSubscription) {
      // If a parent link exists for the alarm, the remove the user as a subscriber
      // to the alarm
      if (prevAlarm.parentLink && prevAlarm.parentLink.alarmId) {
        firebaseUpdateForEditAlarm[
          'alarms/' +
            prevAlarm.parentLink.alarmId +
            '/link/subscribers/' +
            GlobalConfig.uid
        ] = null

        firebaseUpdateForEditAlarm[
          'alarms/' + prevAlarm.parentLink.alarmId + '/lastUpdatedAt'
        ] = Date.now()
      }
    }

    return firebaseUpdateForEditAlarm
  }

  const getOccurrenceStatusSummaryString = occurrenceData => {
    const occurrenceStatus = occurrenceData.occurrenceStatus
    const alarmType = occurrenceData.alarm.type
    const isParticipantAlarm = occurrenceData.alarm.creator !== GlobalConfig.uid
    const occurrenceTime = occurrenceData.occurrenceTime
    const occurrenceDate = DateTimeUtils.getDateAsString(occurrenceTime)

    switch (alarmType) {
      case Constants.AlarmTypes.CASCADING:
        if (typeof occurrenceStatus === 'object') {
          const response = occurrenceStatus.response
          const summaryStringKey = isParticipantAlarm
            ? Constants.AlarmOccurrenceStatusesSummaryStringKey[alarmType][
                'participant'
              ][response]
            : Constants.AlarmOccurrenceStatusesSummaryStringKey[alarmType][
                'own'
              ][response]
          const occurrenceAcknowledgementDate = DateTimeUtils.getDateAsString(
            occurrenceStatus.timestamp
          )
          const summaryStringCount =
            occurrenceDate === occurrenceAcknowledgementDate ? 0 : 1
          return I18n.t(summaryStringKey, {
            count: summaryStringCount,
            timeString: DateTimeUtils.getTimeAsString(
              occurrenceStatus.timestamp
            ),
            dateString: DateTimeUtils.getDateAsString(
              occurrenceStatus.timestamp
            ),
            participantName: occurrenceData.alarm.creatorName
          })
        } else if (typeof occurrenceStatus === 'boolean') {
          const summaryStringKey = isParticipantAlarm
            ? Constants.AlarmOccurrenceStatusesSummaryStringKey[alarmType][
                'participant'
              ][occurrenceStatus.toString()]
            : Constants.AlarmOccurrenceStatusesSummaryStringKey[alarmType][
                'own'
              ][occurrenceStatus.toString()]
          return I18n.t(summaryStringKey, {
            participantName: occurrenceData.alarm.creatorName
          })
        } else {
          const summaryStringKey = isParticipantAlarm
            ? Constants.AlarmOccurrenceStatusesSummaryStringKey[alarmType][
                'participant'
              ]['true']
            : Constants.AlarmOccurrenceStatusesSummaryStringKey[alarmType][
                'own'
              ]['true']
          return I18n.t(summaryStringKey, {
            participantName: occurrenceData.alarm.creatorName
          })
        }
      case Constants.AlarmTypes.SIMULTANEOUS:
        if (typeof occurrenceStatus === 'object') {
          const response = occurrenceStatus.response
          const summaryStringKey =
            Constants.AlarmOccurrenceStatusesSummaryStringKey[alarmType][
              response
            ]
          const occurrenceAcknowledgementDate = DateTimeUtils.getDateAsString(
            occurrenceStatus.timestamp
          )
          const summaryStringCount =
            occurrenceDate === occurrenceAcknowledgementDate ? 0 : 1
          return I18n.t(summaryStringKey, {
            count: summaryStringCount,
            timeString: DateTimeUtils.getTimeAsString(
              occurrenceStatus.timestamp
            ),
            dateString: DateTimeUtils.getDateAsString(
              occurrenceStatus.timestamp
            )
          })
        } else if (typeof occurrenceStatus === 'boolean') {
          const summaryStringKey =
            Constants.AlarmOccurrenceStatusesSummaryStringKey[alarmType][
              occurrenceStatus.toString()
            ]
          return I18n.t(summaryStringKey)
        } else {
          return I18n.t(
            Constants.AlarmOccurrenceStatusesSummaryStringKey[alarmType].true
          )
        }
      case Constants.AlarmTypes.RECIPIENT:
        if (typeof occurrenceStatus === 'object') {
          const response = occurrenceStatus.response
          const summaryStringKey = isParticipantAlarm
            ? Constants.AlarmOccurrenceStatusesSummaryStringKey[alarmType][
                'participant'
              ][response]
            : Constants.AlarmOccurrenceStatusesSummaryStringKey[alarmType][
                'own'
              ][response]
          const occurrenceAcknowledgementDate = DateTimeUtils.getDateAsString(
            occurrenceStatus.timestamp
          )
          const summaryStringCount =
            occurrenceDate === occurrenceAcknowledgementDate ? 0 : 1
          return I18n.t(summaryStringKey, {
            count: summaryStringCount,
            timeString: DateTimeUtils.getTimeAsString(
              occurrenceStatus.timestamp
            ),
            dateString: DateTimeUtils.getDateAsString(
              occurrenceStatus.timestamp
            ),
            participantName: occurrenceData.alarm.recipient.name
          })
        } else if (typeof occurrenceStatus === 'boolean') {
          const summaryStringKey = isParticipantAlarm
            ? Constants.AlarmOccurrenceStatusesSummaryStringKey[alarmType][
                'participant'
              ][occurrenceStatus.toString()]
            : Constants.AlarmOccurrenceStatusesSummaryStringKey[alarmType][
                'own'
              ][occurrenceStatus.toString()]
          return I18n.t(summaryStringKey, {
            participantName: occurrenceData.alarm.recipient.name
          })
        } else {
          const summaryStringKey = isParticipantAlarm
            ? Constants.AlarmOccurrenceStatusesSummaryStringKey[alarmType][
                'participant'
              ]['true']
            : Constants.AlarmOccurrenceStatusesSummaryStringKey[alarmType][
                'own'
              ]['true']
          return I18n.t(summaryStringKey, {
            participantName: occurrenceData.alarm.recipient.name
          })
        }
    }
  }

  const hasAlarmPreviousOccurrencesChanged = (prevAlarm, editedAlarm) => {
    const {
      date: oldDate,
      repeatType: oldRepeatType,
      repeat: oldRepeat,
      creatorTimezone: oldCreatorTimezone
    } = prevAlarm
    const {
      date: newDate,
      repeatType: newRepeatType,
      repeat: newRepeat,
      creatorTimezone: newCreatorTimezone
    } = editedAlarm
    const oldNextDate = getNextDateForAlarm(
      oldDate,
      GlobalConfig.defaultAlarmEndDate,
      oldRepeatType,
      oldRepeat,
      oldCreatorTimezone
    )
    const newNextDate = getNextDateForAlarm(
      newDate,
      GlobalConfig.defaultAlarmEndDate,
      newRepeatType,
      newRepeat,
      newCreatorTimezone
    )
    return (
      oldNextDate !== newNextDate ||
      oldRepeatType !== newRepeatType ||
      oldRepeat !== newRepeat
    )
  }

  const addConnectionsForAlarmParticipants = alarm => {
    const participants = getAlarmParticipants(
      alarm.type,
      alarm.backupGroup,
      alarm.backupContacts,
      alarm.recipient
    )
    if (participants.length > 0) {
      const uids = participants.map(participant => participant.id)
      uids.push(alarm.creator)
      return TaskManager.addHttpsCloudTask('addConnections', {
        uids: uids.join(',')
      })
    }
    return Promise.resolve()
  }

  const addAlarm = function (alarm) {
    const firebaseUpdate = createFirebaseObjectForAddAlarm(alarm)

    return ref
      .update(firebaseUpdate)
      .then(() => addConnectionsForAlarmParticipants(alarm))
      .catch(error => LogUtils.logError(error))
  }

  const addChecklist = checklist => {
    const firebaseUpdate = createFirebaseObjectForAddChecklist(checklist)
    return ref.update(firebaseUpdate).catch(error => LogUtils.logError(error))
  }

  const editChecklist = checklist => {
    const firebaseUpdate = createFirebaseObjectForEditChecklist(checklist)
    return ref.update(firebaseUpdate).catch(error => LogUtils.logError(error))
  }

  const removeChecklist = checklistId => {
    const firebaseUpdate = createFirebaseObjectForDeleteChecklist(checklistId)
    return ref.update(firebaseUpdate).catch(error => LogUtils.logError(error))
  }

  const getNotificationInfoForParticipantAlarmDeleted = (
    alarm,
    participantOrder
  ) => {
    const backupAlarmDate = DateTimeUtils.getParticipantAlarmDate(
      alarm,
      participantOrder
    )
    return {
      alarmId: alarm.id,
      type: Constants.NotificationTypes.PARTICIPANT_ALARM_DELETED,
      alarmType: alarm.type,
      alarmCreatorName: alarm.creatorName,
      alarmName: alarm.name,
      alarmDate: backupAlarmDate.toString()
    }
  }

  const deleteAlarm = function (alarm) {
    const participants = getAlarmParticipants(
      alarm.type,
      alarm.backupGroup,
      alarm.backupContacts,
      alarm.recipient
    )

    // If the alarm is enabled, send a remote notification to the participants about alarm deletion
    if (alarm.status) {
      const filteredParticipants = participants.filter(participant => {
        return (
          participant.responseStatus !== Constants.REJECT_ALARM &&
          // Check the participant state also as on the server side
          // the alarm has already been deleted by the time remote
          // notification is sent. So, it ends up sending remote notifications
          // to inactive users also.
          participant.state !== Constants.ParticipantStates.INACTIVE
        )
      })
      filteredParticipants.forEach(participant => {
        const notificationInfo = getNotificationInfoForParticipantAlarmDeleted(
          alarm,
          participant.order
        )
        const notificationKey =
          Constants.NotificationKeys.ParticipantAlarmDeleted
        NotificationManager.sendRemoteNotification(
          participant.id,
          notificationKey,
          notificationInfo
        )
      })
    }

    if (alarm.link && alarm.link.subscribers) {
      const alarmSubscribers = objGet(alarm, 'link.subscribers', {})
      Object.keys(alarmSubscribers).forEach(alarmSubscriber => {
        const subscriberAlarmId = alarmSubscribers[alarmSubscriber]
        NotificationManager.sendRemoteNotification(
          alarmSubscriber,
          Constants.NotificationKeys.SubscribedAlarmDeletedNotification,
          {
            type: Constants.NotificationTypes.SUBSCRIBED_ALARM_DELETED,
            subscriberAlarmId: subscriberAlarmId,
            alarmName: alarm.name,
            alarmCreatorName: alarm.creatorName
          }
        )
      })
    }

    let firebaseUpdate = {}
    createFirebaseObjectForDeleteAlarm(alarm, firebaseUpdate)
    return ref.update(firebaseUpdate).then(() => {
      if (
        alarm.creationSource ===
        Constants.AlarmCreationSources.CREATED_BY_SLACK_BOT
      ) {
        return TaskManager.addHttpsCloudTask('notifyAlarmDeleted', {
          alarmId: alarm.id
        }).catch(error => {
          LogUtils.logError(error, 'Unable to notify slack bot')
        })
      }
    })
  }

  const deleteAllAlarms = function (alarms) {
    const firebaseUpdate = createFirebaseObjectForDeleteAllAlarms(alarms)
    return ref.update(firebaseUpdate)
  }

  const editAlarm = (editedAlarm, prevAlarm, removeSubscription = true) => {
    const firebaseUpdate = createFirebaseObjectForEditAlarm(
      editedAlarm,
      prevAlarm,
      removeSubscription
    )

    return ref
      .update(firebaseUpdate)
      .then(() => {
        if (prevAlarm.status === false) {
          let firebaseUpdateForStatusChange = {}
          // Add a status true for the alarm. It is needed for edit alarm which were previously disabled.
          const responseId = ref.push().key
          firebaseUpdateForStatusChange[
            'alarmActions/' +
              editedAlarm.id +
              '/alarmStatusChanges/' +
              responseId +
              '/timestamp'
          ] = Date.now()
          firebaseUpdateForStatusChange[
            'alarmActions/' +
              editedAlarm.id +
              '/alarmStatusChanges/' +
              responseId +
              '/status'
          ] = true
          return ref.update(firebaseUpdateForStatusChange)
        }
      })
      .then(() => addConnectionsForAlarmParticipants(editedAlarm))
      .then(() => {
        if (
          editedAlarm.creationSource ===
          Constants.AlarmCreationSources.CREATED_BY_SLACK_BOT
        ) {
          return TaskManager.addHttpsCloudTask('notifyAlarmEdited', {
            alarmId: editedAlarm.id,
            title: editedAlarm.name,
            date: editedAlarm.date,
            preReminderDuration: editedAlarm.preReminderDuration
            // Not sending the alarm category and notes as they are not
            // shown in the slack bot
          }).catch(error => {
            LogUtils.logError(error, 'Unable to notify slack bot')
          })
        }
      })
  }

  const skipPersonalAlarmCore = function (alarm, occurrenceTime, timestamp) {
    if (!GlobalConfig.uid) {
      LogUtils.logError(
        new Error('GlobalConfig.uid is null'),
        'Unable to skip personal alarm',
        { alarmId: alarm.id }
      )
      return Promise.resolve()
    }

    const {
      id: alarmId,
      name: alarmName,
      backupGroup,
      backupContacts,
      type: alarmType,
      creatorName: alarmCreatorName
    } = alarm

    const currDate = Date.now()
    const { nextDate } = getPrevAndNextDatesForAnAlarmWrtDate(
      timestamp,
      alarm.date,
      alarm.endDate,
      alarm.repeatType,
      alarm.repeat,
      alarm.creatorTimezone
    )

    // Send notification to the backups if any
    // Only send notifications if the alarm has not run into the next occurrence
    if (!nextDate || nextDate > currDate) {
      const notificationInfo = {
        type: Constants.NotificationTypes.ALARM_SKIPPED,
        alarmId,
        alarmType,
        alarmName,
        alarmCreatorName
      }
      const participants = backupGroup ? backupGroup.members : backupContacts
      participants
        .filter(participant => {
          return participant.responseStatus !== Constants.REJECT_ALARM
        })
        .forEach(participant => {
          NotificationManager.sendRemoteNotification(
            participant.id,
            Constants.NotificationKeys.PersonalAlarmSkippedNotification,
            notificationInfo
          )
        })
    }

    let firebaseUpdateObj = {}
    const responseId = ref.push().key
    const occurrenceTimeString = getOccurrenceTimeString(
      occurrenceTime,
      alarm.creatorTimezoneOffset,
      alarm.creatorTimezone
    )

    firebaseUpdateObj['alarms/' + alarmId + '/status'] = alarm.repeatType !== ''
    firebaseUpdateObj['alarms/' + alarmId + '/lastUpdatedAt'] = currDate
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/timestamp'
    ] = timestamp
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/response'
    ] = Constants.PERSONAL_ALARM_SKIP

    return ref.update(firebaseUpdateObj).then(() => {
      if (alarm.creationMode === Constants.AlarmCreationModes.QUICK_ALARM) {
        if (GlobalConfig.store) {
          // Only needed for web such that we can get out of the alarm detail screen
          // for the quick reminder
          GlobalConfig.store.dispatch(ActionCreators.hideAlarmDetailsScreen())
          GlobalConfig.store.dispatch(ActionCreators.deleteAlarm(alarm.id))
        }
      }
    })
  }

  const skipPersonalParticipantAlarmCore = function (
    alarm,
    occurrenceTime,
    timestamp
  ) {
    if (!GlobalConfig.uid) {
      LogUtils.logError(
        new Error('GlobalConfig.uid is null'),
        'Unable to skip personal alarm',
        { alarmId: alarm.id }
      )
      return Promise.resolve()
    }

    const { id: alarmId } = alarm
    let firebaseUpdateObj = {}
    const responseId = ref.push().key
    const occurrenceTimeString = getOccurrenceTimeString(
      occurrenceTime,
      alarm.creatorTimezoneOffset,
      alarm.creatorTimezone
    )

    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/timestamp'
    ] = timestamp
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/response'
    ] = Constants.PERSONAL_PARTICIPANT_ALARM_SKIP

    return ref.update(firebaseUpdateObj)
  }

  const skipRecipientAlarmCore = function (alarm, occurrenceTime, timestamp) {
    const { id: alarmId, name: alarmName, recipient, type: alarmType } = alarm
    const currDate = Date.now()
    const backupAlarmDate = DateTimeUtils.getParticipantAlarmDate(
      alarm,
      alarm.order
    )
    const { nextDate } = getPrevAndNextDatesForAnAlarmWrtDate(
      timestamp,
      backupAlarmDate,
      alarm.endDate,
      alarm.repeatType,
      alarm.repeat,
      alarm.creatorTimezone
    )

    // Only send notifications if the alarm has not run into the next occurrence
    if (!nextDate || nextDate > currDate) {
      const notificationInfo = {
        type: Constants.NotificationTypes.ALARM_SKIPPED,
        alarmId,
        alarmType,
        alarmName,
        alarmRecipientName: recipient.name
      }
      NotificationManager.sendRemoteNotification(
        alarm.creator,
        Constants.NotificationKeys.RecipientAlarmSkippedNotification,
        notificationInfo
      )
    }

    let firebaseUpdateObj = {}
    const responseId = ref.push().key
    const occurrenceTimeString = getOccurrenceTimeString(
      occurrenceTime,
      alarm.creatorTimezoneOffset,
      alarm.creatorTimezone
    )

    firebaseUpdateObj['alarms/' + alarmId + '/status'] = alarm.repeatType !== ''
    firebaseUpdateObj['alarms/' + alarmId + '/lastUpdatedAt'] = currDate
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/timestamp'
    ] = timestamp
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/response'
    ] = Constants.RECIPIENT_ALARM_SKIP

    return ref.update(firebaseUpdateObj)
  }

  const skipRecipientCreatorAlarmCore = function (
    alarm,
    occurrenceTime,
    timestamp
  ) {
    const { id: alarmId } = alarm
    let firebaseUpdateObj = {}
    const responseId = ref.push().key
    const occurrenceTimeString = getOccurrenceTimeString(
      occurrenceTime,
      alarm.creatorTimezoneOffset,
      alarm.creatorTimezone
    )

    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/timestamp'
    ] = timestamp
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/response'
    ] = Constants.RECIPIENT_CREATOR_ALARM_SKIP

    return ref.update(firebaseUpdateObj)
  }

  // Used to record I'm up action by creator for cascading alarms
  const markAlarmAsAcknowledgedCore = function (
    alarm,
    occurrenceTime,
    timestamp
  ) {
    if (!GlobalConfig.uid) {
      LogUtils.logError(
        new Error('GlobalConfig.uid is null'),
        'Unable to mark personal alarm as done',
        { alarmId: alarm.id }
      )
      return Promise.resolve()
    }

    const {
      id: alarmId,
      name: alarmName,
      backupGroup,
      backupContacts,
      type: alarmType,
      creatorName: alarmCreatorName
    } = alarm

    const currDate = Date.now()

    // Send notification to the backups if any
    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_ACKNOWLEDGED,
      alarmId,
      alarmType,
      alarmName,
      alarmCreatorName
    }
    const participants = backupGroup ? backupGroup.members : backupContacts
    participants
      .filter(participant => {
        return participant.responseStatus !== Constants.REJECT_ALARM
      })
      .forEach(participant => {
        NotificationManager.sendRemoteNotification(
          participant.id,
          Constants.NotificationKeys.CascadingAlarmAcknowledgedNotification,
          notificationInfo
        )
      })

    let firebaseUpdateObj = {}
    const responseId = ref.push().key
    const occurrenceTimeString = getOccurrenceTimeString(
      occurrenceTime,
      alarm.creatorTimezoneOffset,
      alarm.creatorTimezone
    )
    firebaseUpdateObj['alarms/' + alarmId + '/status'] = alarm.repeatType !== ''
    firebaseUpdateObj['alarms/' + alarmId + '/lastUpdatedAt'] = currDate
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/timestamp'
    ] = timestamp
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/response'
    ] = Constants.PERSONAL_ALARM_DONE

    // Disable the alarm if not repeating
    return ref.update(firebaseUpdateObj).then(() => {
      if (alarm.creationMode === Constants.AlarmCreationModes.QUICK_ALARM) {
        if (GlobalConfig.store) {
          // Only needed for web such that we can get out of the alarm detail screen
          // for the quick reminder
          GlobalConfig.store.dispatch(ActionCreators.hideAlarmDetailsScreen())
          GlobalConfig.store.dispatch(ActionCreators.deleteAlarm(alarm.id))
        }
      } else if (
        alarm.creationSource ===
        Constants.AlarmCreationSources.CREATED_BY_SLACK_BOT
      ) {
        TaskManager.addHttpsCloudTask('notifyAlarmMarkedDone', {
          alarmId: alarm.id
        }).catch(error => {
          LogUtils.logError(error, 'Unable to notify slack bot')
        })
      }
    })
  }

  const markAlarmAsAcknowledgedNotificationAction = function (
    alarmId,
    occurrenceTime,
    timestamp
  ) {
    return retrieveAlarmByAlarmId(alarmId)
      .then(alarm => {
        if (alarm === null) {
          LogUtils.logError(
            new Error(
              'Unable to mark a cascading alarm as acknowledged because alarm is null ' +
                alarmId
            )
          )
          return
        }

        // Support no firebase sync for unregistered users
        if (GlobalConfig.store) {
          const currAlarmDate = getCurrentDateForAlarm(alarm)
          const currDate = Date.now()
          GlobalConfig.store.dispatch(
            ActionCreators.addAlarmAcknowledgementAction(
              alarm,
              currAlarmDate,
              GlobalConfig.uid,
              currDate,
              Constants.PERSONAL_ALARM_DONE
            )
          )

          const softUpdatedAlarm = updateAlarm(alarm, {
            status: alarm.repeatType !== ''
          })
          GlobalConfig.store.dispatch(
            ActionCreators.softUpdateLocalAlarm(softUpdatedAlarm)
          )
        }
        return markAlarmAsAcknowledgedCore(alarm, occurrenceTime, timestamp)
      })
      .catch(error => {
        LogUtils.logError(
          error,
          'Unable to mark a cascading alarm as acknowledged'
        )
      })
  }

  const skipPersonalAlarmNotificationAction = function (
    alarmId,
    occurrenceTime,
    timestamp
  ) {
    return retrieveAlarmByAlarmId(alarmId)
      .then(alarm => {
        if (alarm === null) {
          LogUtils.logError(
            new Error(
              'Unable to mark a cascading alarm as skipped because alarm is null ' +
                alarmId
            )
          )
          return
        }

        // Support no firebase sync for unregistered users
        if (GlobalConfig.store) {
          const currAlarmDate = getCurrentDateForAlarm(alarm)
          const currDate = Date.now()
          GlobalConfig.store.dispatch(
            ActionCreators.addAlarmAcknowledgementAction(
              alarm,
              currAlarmDate,
              GlobalConfig.uid,
              currDate,
              Constants.PERSONAL_ALARM_SKIP
            )
          )

          const softUpdatedAlarm = updateAlarm(alarm, {
            status: alarm.repeatType !== ''
          })
          GlobalConfig.store.dispatch(
            ActionCreators.softUpdateLocalAlarm(softUpdatedAlarm)
          )
        }
        return skipPersonalAlarmCore(alarm, occurrenceTime, timestamp)
      })
      .catch(error => {
        LogUtils.logError(error, 'Unable to mark a cascading alarm as skipped')
      })
  }

  const skipPersonalParticipantAlarmNotificationAction = function (
    alarmId,
    occurrenceTime,
    timestamp
  ) {
    return retrieveAlarmByAlarmId(alarmId)
      .then(alarm => {
        if (alarm === null) {
          LogUtils.logError(
            new Error(
              'Unable to mark a cascading participant alarm as skipped because alarm is null ' +
                alarmId
            )
          )
          return
        }

        // Support no firebase sync for unregistered users
        if (GlobalConfig.store) {
          const currAlarmDate = getCurrentDateForAlarm(alarm)
          const currDate = Date.now()
          GlobalConfig.store.dispatch(
            ActionCreators.addAlarmAcknowledgementAction(
              alarm,
              currAlarmDate,
              GlobalConfig.uid,
              currDate,
              Constants.PERSONAL_PARTICIPANT_ALARM_SKIP
            )
          )
        }
        return skipPersonalParticipantAlarmCore(
          alarm,
          occurrenceTime,
          timestamp
        )
      })
      .catch(error => {
        LogUtils.logError(
          error,
          'Unable to mark a cascading participant alarm as skipped'
        )
      })
  }

  const skipRecipientAlarmNotificationAction = function (
    alarmId,
    occurrenceTime,
    timestamp
  ) {
    return retrieveAlarmByAlarmId(alarmId)
      .then(alarm => {
        if (alarm === null) {
          LogUtils.logError(
            new Error(
              'Unable to mark a recipient alarm as skipped because alarm is null ' +
                alarmId
            )
          )
          return
        }

        // Support no firebase sync for unregistered users
        if (GlobalConfig.store) {
          const currAlarmDate = getCurrentDateForAlarm(alarm)
          const currDate = Date.now()
          GlobalConfig.store.dispatch(
            ActionCreators.addAlarmAcknowledgementAction(
              alarm,
              currAlarmDate,
              GlobalConfig.uid,
              currDate,
              Constants.RECIPIENT_ALARM_SKIP
            )
          )

          const softUpdatedAlarm = updateAlarm(alarm, {
            status: alarm.repeatType !== ''
          })
          GlobalConfig.store.dispatch(
            ActionCreators.softUpdateLocalParticipantAlarm(softUpdatedAlarm)
          )
        }
        return skipRecipientAlarmCore(alarm, occurrenceTime, timestamp)
      })
      .catch(error => {
        LogUtils.logError(error, 'Unable to mark a recipient alarm as skipped')
      })
  }

  const skipRecipientCreatorAlarmNotificationAction = function (
    alarmId,
    occurrenceTime,
    timestamp
  ) {
    return retrieveAlarmByAlarmId(alarmId)
      .then(alarm => {
        if (alarm === null) {
          LogUtils.logError(
            new Error(
              'Unable to mark a recipient creator alarm as skipped because alarm is null ' +
                alarmId
            )
          )
          return
        }

        // Support no firebase sync for unregistered users
        if (GlobalConfig.store) {
          const currAlarmDate = getCurrentDateForAlarm(alarm)
          const currDate = Date.now()
          GlobalConfig.store.dispatch(
            ActionCreators.addAlarmAcknowledgementAction(
              alarm,
              currAlarmDate,
              GlobalConfig.uid,
              currDate,
              Constants.RECIPIENT_CREATOR_ALARM_SKIP
            )
          )
        }
        return skipRecipientCreatorAlarmCore(alarm, occurrenceTime, timestamp)
      })
      .catch(error => {
        LogUtils.logError(
          error,
          'Unable to mark a recipient creator alarm as skipped'
        )
      })
  }

  const markRecipientAlarmAsDoneCore = function (
    alarm,
    occurrenceTime,
    timestamp
  ) {
    const { id: alarmId, name: alarmName, recipient, type: alarmType } = alarm
    const currDate = Date.now()
    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_ACKNOWLEDGED,
      alarmId,
      alarmType,
      alarmName,
      alarmRecipientName: recipient.name
    }
    NotificationManager.sendRemoteNotification(
      alarm.creator,
      Constants.NotificationKeys.RecipientAlarmAcknowledgedNotification,
      notificationInfo
    )

    let firebaseUpdateObj = {}
    const responseId = ref.push().key
    const occurrenceTimeString = getOccurrenceTimeString(
      occurrenceTime,
      alarm.creatorTimezoneOffset,
      alarm.creatorTimezone
    )
    firebaseUpdateObj['alarms/' + alarmId + '/status'] = alarm.repeatType !== ''
    firebaseUpdateObj['alarms/' + alarmId + '/lastUpdatedAt'] = currDate
    firebaseUpdateObj['alarms/' + alarmId + '/recipient/responseStatus'] =
      Constants.ACCEPT_ALARM
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/timestamp'
    ] = timestamp
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/response'
    ] = Constants.RECIPIENT_ALARM_DONE

    // Disable the alarm if not repeating
    return ref.update(firebaseUpdateObj)
  }

  const markRecipientAlarmAsDone = function (
    alarmId,
    occurrenceTime,
    timestamp
  ) {
    return retrieveAlarmByAlarmId(alarmId)
      .then(alarm => {
        if (alarm === null) {
          LogUtils.logError(
            new Error(
              'Unable to mark a recipient alarm as acknowledged because alarm is null ' +
                alarmId
            )
          )
          return
        }
        return markRecipientAlarmAsDoneCore(alarm, occurrenceTime, timestamp)
      })
      .catch(error => {
        LogUtils.logError(
          error,
          'Unable to mark a recipient alarm as acknowledged'
        )
      })
  }

  const markPersonalAlarmAsUnskipped = function (
    alarm,
    occurrenceTime,
    timestamp
  ) {
    const {
      id: alarmId,
      name: alarmName,
      backupGroup,
      backupContacts,
      type: alarmType,
      creatorName: alarmCreatorName
    } = alarm

    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_UNSKIPPED,
      alarmId,
      alarmType,
      alarmName,
      alarmCreatorName
    }
    const participants = backupGroup ? backupGroup.members : backupContacts
    participants
      .filter(participant => {
        return participant.responseStatus !== Constants.REJECT_ALARM
      })
      .forEach(participant => {
        NotificationManager.sendRemoteNotification(
          participant.id,
          Constants.NotificationKeys.CascadingAlarmUnskippedNotification,
          notificationInfo
        )
      })

    let firebaseUpdateObj = {}
    const responseId = ref.push().key
    const occurrenceTimeString = getOccurrenceTimeString(
      occurrenceTime,
      alarm.creatorTimezoneOffset,
      alarm.creatorTimezone
    )
    firebaseUpdateObj['alarms/' + alarmId + '/status'] = true
    firebaseUpdateObj['alarms/' + alarmId + '/lastUpdatedAt'] = Date.now()
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/timestamp'
    ] = timestamp
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/response'
    ] = Constants.PERSONAL_ALARM_UNSKIP

    // Make sure alarm is enabled
    return ref.update(firebaseUpdateObj)
  }

  const markPersonalAlarmAsUndone = function (
    alarm,
    occurrenceTime,
    timestamp
  ) {
    const {
      id: alarmId,
      name: alarmName,
      backupGroup,
      backupContacts,
      type: alarmType,
      creatorName: alarmCreatorName
    } = alarm

    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_UNACKNOWLEDGED,
      alarmId,
      alarmType,
      alarmName,
      alarmCreatorName
    }
    const participants = backupGroup ? backupGroup.members : backupContacts
    participants
      .filter(participant => {
        return participant.responseStatus !== Constants.REJECT_ALARM
      })
      .forEach(participant => {
        NotificationManager.sendRemoteNotification(
          participant.id,
          Constants.NotificationKeys.CascadingAlarmUnacknowledgedNotification,
          notificationInfo
        )
      })

    let firebaseUpdateObj = {}
    const responseId = ref.push().key
    const occurrenceTimeString = getOccurrenceTimeString(
      occurrenceTime,
      alarm.creatorTimezoneOffset,
      alarm.creatorTimezone
    )
    firebaseUpdateObj['alarms/' + alarmId + '/status'] = true
    firebaseUpdateObj['alarms/' + alarmId + '/lastUpdatedAt'] = Date.now()
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/timestamp'
    ] = timestamp
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/response'
    ] = Constants.PERSONAL_ALARM_UNDONE

    // Make sure alarm is enabled
    return ref.update(firebaseUpdateObj)
  }

  const markRecipientCreatorAlarmAsUnskipped = function (
    alarm,
    occurrenceTime,
    timestamp
  ) {
    const { id: alarmId } = alarm

    // Make sure alarm is enabled
    let firebaseUpdateObj = {}
    const responseId = ref.push().key
    const occurrenceTimeString = getOccurrenceTimeString(
      occurrenceTime,
      alarm.creatorTimezoneOffset,
      alarm.creatorTimezone
    )
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/timestamp'
    ] = timestamp
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/response'
    ] = Constants.RECIPIENT_CREATOR_ALARM_UNSKIPPED

    return ref.update(firebaseUpdateObj)
  }

  const markPersonalParticipantAlarmAsUnskipped = function (
    alarm,
    occurrenceTime,
    timestamp
  ) {
    const { id: alarmId } = alarm

    // Make sure alarm is enabled
    let firebaseUpdateObj = {}
    const responseId = ref.push().key
    const occurrenceTimeString = getOccurrenceTimeString(
      occurrenceTime,
      alarm.creatorTimezoneOffset,
      alarm.creatorTimezone
    )
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/timestamp'
    ] = timestamp
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/response'
    ] = Constants.PERSONAL_PARTICIPANT_ALARM_UNSKIP

    return ref.update(firebaseUpdateObj)
  }

  const markRecipientAlarmAsUnskipped = function (
    alarm,
    occurrenceTime,
    timestamp
  ) {
    const { id: alarmId, name: alarmName, recipient, type: alarmType } = alarm

    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_UNSKIPPED,
      alarmId,
      alarmType,
      alarmName,
      alarmRecipientName: recipient.name
    }
    NotificationManager.sendRemoteNotification(
      alarm.creator,
      Constants.NotificationKeys.RecipientAlarmUnskippedNotification,
      notificationInfo
    )

    // Make sure alarm is enabled
    let firebaseUpdateObj = {}
    const responseId = ref.push().key
    const occurrenceTimeString = getOccurrenceTimeString(
      occurrenceTime,
      alarm.creatorTimezoneOffset,
      alarm.creatorTimezone
    )
    firebaseUpdateObj['alarms/' + alarmId + '/status'] = true
    firebaseUpdateObj['alarms/' + alarmId + '/lastUpdatedAt'] = Date.now()
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/timestamp'
    ] = timestamp
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/response'
    ] = Constants.RECIPIENT_ALARM_UNSKIP

    return ref.update(firebaseUpdateObj)
  }

  const markRecipientAlarmAsUndone = function (
    alarm,
    occurrenceTime,
    timestamp
  ) {
    const { id: alarmId, name: alarmName, recipient, type: alarmType } = alarm

    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_UNACKNOWLEDGED,
      alarmId,
      alarmType,
      alarmName,
      alarmRecipientName: recipient.name
    }
    NotificationManager.sendRemoteNotification(
      alarm.creator,
      Constants.NotificationKeys.RecipientAlarmUnacknowledgedNotification,
      notificationInfo
    )

    // Make sure alarm is enabled
    let firebaseUpdateObj = {}
    const responseId = ref.push().key
    const occurrenceTimeString = getOccurrenceTimeString(
      occurrenceTime,
      alarm.creatorTimezoneOffset,
      alarm.creatorTimezone
    )
    firebaseUpdateObj['alarms/' + alarmId + '/status'] = true
    firebaseUpdateObj['alarms/' + alarmId + '/lastUpdatedAt'] = Date.now()
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/timestamp'
    ] = timestamp
    firebaseUpdateObj[
      'alarmActions/' +
        alarmId +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/response'
    ] = Constants.RECIPIENT_ALARM_UNDONE

    return ref.update(firebaseUpdateObj)
  }

  // Used to record response for a simultaneous alarm by creator
  const markCreatorResponseForSimultaneousAlarmCore = function (
    alarm,
    occurrenceTime,
    timestamp,
    response,
    rescheduleAlarm
  ) {
    let firebaseUpdateObj = {}
    const responseId = ref.push().key
    const occurrenceTimeString = getOccurrenceTimeString(
      occurrenceTime,
      alarm.creatorTimezoneOffset,
      alarm.creatorTimezone
    )
    firebaseUpdateObj[
      'alarmActions/' +
        alarm.id +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/timestamp'
    ] = timestamp
    firebaseUpdateObj[
      'alarmActions/' +
        alarm.id +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/response'
    ] = response
    firebaseUpdateObj[
      'alarmActions/' +
        alarm.id +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/rescheduleAlarm'
    ] = rescheduleAlarm
    firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = Date.now()

    return ref.update(firebaseUpdateObj).then(() => {
      sendRemoteNotificationsForSimultaneousAlarm(alarm, response)
    })
  }

  // Used to record response for a simultaneous alarm by a participant other than creator
  const markParticipantResponseForSimultaneousAlarmCore = function (
    alarm,
    occurrenceTime,
    timestamp,
    response,
    alsoUpdateAlarmResponseStatus = false,
    rescheduleAlarm
  ) {
    let firebaseUpdateObj = {}

    if (alarm.backupGroup) {
      if (alsoUpdateAlarmResponseStatus) {
        const groupId = alarm.backupGroup.id
        firebaseUpdateObj[
          'alarms/' +
            alarm.id +
            '/backups/group/' +
            groupId +
            '/members/' +
            GlobalConfig.uid +
            '/responseStatus'
        ] =
          response === Constants.GROUP_ALARM_YES
            ? Constants.ACCEPT_ALARM
            : Constants.REJECT_ALARM
      }
    } else {
      if (alsoUpdateAlarmResponseStatus) {
        firebaseUpdateObj[
          'alarms/' +
            alarm.id +
            '/backups/contacts/' +
            GlobalConfig.uid +
            '/responseStatus'
        ] =
          response === Constants.GROUP_ALARM_YES
            ? Constants.ACCEPT_ALARM
            : Constants.REJECT_ALARM
      }
    }

    const responseId = ref.push().key
    const occurrenceTimeString = getOccurrenceTimeString(
      occurrenceTime,
      alarm.creatorTimezoneOffset,
      alarm.creatorTimezone
    )
    firebaseUpdateObj[
      'alarmActions/' +
        alarm.id +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/timestamp'
    ] = timestamp
    firebaseUpdateObj[
      'alarmActions/' +
        alarm.id +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/response'
    ] = response
    firebaseUpdateObj[
      'alarmActions/' +
        alarm.id +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        GlobalConfig.uid +
        '/' +
        responseId +
        '/rescheduleAlarm'
    ] = rescheduleAlarm
    firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = Date.now()

    return ref.update(firebaseUpdateObj).then(() => {
      sendRemoteNotificationsForSimultaneousAlarm(alarm, response)
      if (
        alarm.source ===
        Constants.AlarmCreationSources.CREATED_BY_ENTERPRISE_ALERT_API
      ) {
        const enterpriseAlertIncident = alarm.enterpriseAlertIncident
        if (enterpriseAlertIncident) {
          const { enterpriseAccountId, alertId, incidentId } =
            enterpriseAlertIncident
          TaskManager.addHttpsCloudTask('handleAlertIncidentAcknowledged', {
            uid: GlobalConfig.uid,
            username: GlobalConfig.username,
            enterpriseAccountId,
            alertId,
            incidentId,
            acknowledgedAt: Date.now(),
            acknowledgedBy: GlobalConfig.uid
          })
        }
      }
    })
  }

  const sendRemoteNotificationsForSimultaneousAlarm = function (
    alarm,
    response
  ) {
    const notificationInfo = {
      alarmId: alarm.id,
      type: Constants.NotificationTypes.SIMULTANEOUS_ALARM_RESPONSE,
      alarmName: alarm.name,
      participantName: GlobalConfig.username
    }
    const notificationKey =
      response === Constants.GROUP_ALARM_YES
        ? Constants.NotificationKeys
            .SimultaneousAlarmInstanceConfirmedNotification
        : Constants.NotificationKeys
            .SimultaneousAlarmInstanceDeclinedNotification
    const participantIds = getAlarmParticipants(
      alarm.type,
      alarm.backupGroup,
      alarm.backupContacts,
      alarm.recipient
    ).map(participant => participant.id)

    // If number of participants in the alarm are less than the max limit
    // or the person is the alarm creator, send the push notification to
    // all the participants.
    // If it is for an instant alerts, send it only to creator
    if (
      (participantIds.length <=
        GlobalConfig.maxNumParticipantsForFullNotifications ||
        alarm.creator === GlobalConfig.uid) &&
      alarm.creationMode !== Constants.AlarmCreationModes.INSTANT_ALARM
    ) {
      participantIds.push(alarm.creator)
      participantIds.forEach(participantId => {
        if (participantId !== GlobalConfig.uid) {
          NotificationManager.sendRemoteNotification(
            participantId,
            notificationKey,
            notificationInfo
          )
        }
      })
    } else {
      NotificationManager.sendRemoteNotification(
        alarm.creator,
        notificationKey,
        notificationInfo
      )
    }
  }

  // Used to record response for a simultaneous alarm by creator
  const markCreatorResponseForSimultaneousAlarm = function (
    alarmId,
    occurrenceTime,
    timestamp,
    response,
    rescheduleAlarm
  ) {
    return retrieveAlarmByAlarmId(alarmId)
      .then(alarm => {
        if (alarm === null) {
          LogUtils.logError(
            new Error(
              'Unable to mark response by creator for a simultaneous alarm because alarm is null ' +
                alarmId
            )
          )
          return
        }
        return markCreatorResponseForSimultaneousAlarmCore(
          alarm,
          occurrenceTime,
          timestamp,
          response,
          rescheduleAlarm
        )
      })
      .catch(error => {
        LogUtils.logError(
          error,
          'Unable to mark response by creator for a simultaneous alarm'
        )
      })
  }

  // Used to record response for a simultaneous alarm by a participant other than creator
  const markParticipantResponseForSimultaneousAlarm = function (
    alarmId,
    occurrenceTime,
    timestamp,
    response,
    rescheduleAlarm
  ) {
    return retrieveAlarmByAlarmId(alarmId)
      .then(alarm => {
        if (alarm === null) {
          LogUtils.logError(
            new Error(
              'Unable to mark response by a participant for a simultaneous alarm because alarm is null ' +
                alarmId
            )
          )
          return
        }
        if (GlobalConfig.uid) {
          const participants = alarm.backupGroup
            ? alarm.backupGroup.members
            : alarm.backupContacts
          const participant = Utils.getObjectWithId(
            participants,
            GlobalConfig.uid
          )
          const alsoUpdateAlarmResponseStatus =
            alarm.repeatType === '' ||
            participant.responseStatus === Constants.ALARM_RESPONSE_PENDING
          return markParticipantResponseForSimultaneousAlarmCore(
            alarm,
            occurrenceTime,
            timestamp,
            response,
            alsoUpdateAlarmResponseStatus,
            rescheduleAlarm
          )
        } else {
          LogUtils.logError(
            new Error('GlobalConfig.uid is null'),
            'Unable to set participant response for group alarm occurrence',
            { alarmId: alarm.id }
          )
        }
      })
      .catch(error => {
        LogUtils.logError(
          error,
          'Unable to mark response by a participant for a simultaneous alarm'
        )
      })
  }

  // Create a firebase update object which will perform following changes
  // Set the responseStatus to Rejected
  // Set the member order to last
  // Bump other active members' orders which lie after the passed member by 1
  const createFirebaseObjectForRejectedStatus = function (
    alarm,
    uid,
    backupsType,
    backupOrder,
    currDate
  ) {
    let firebaseUpdateObj = {}
    if (backupsType === Constants.ParticipantTypes.CONTACT) {
      firebaseUpdateObj[
        'alarms/' + alarm.id + '/backups/contacts/' + uid + '/responseStatus'
      ] = Constants.REJECT_ALARM
      firebaseUpdateObj[
        'alarms/' + alarm.id + '/backups/contacts/' + uid + '/seenOn'
      ] = currDate
      const numBackups = alarm.backupContacts.length
      firebaseUpdateObj[
        'alarms/' + alarm.id + '/backups/contacts/' + uid + '/order'
      ] = numBackups
      alarm.backupContacts.forEach(contact => {
        if (contact.order > backupOrder) {
          firebaseUpdateObj[
            'alarms/' + alarm.id + '/backups/contacts/' + contact.id + '/order'
          ] = contact.order - 1
        }
      })
    } else {
      const groupId = alarm.backupGroup.id
      firebaseUpdateObj[
        'alarms/' +
          alarm.id +
          '/backups/group/' +
          groupId +
          '/members/' +
          uid +
          '/responseStatus'
      ] = Constants.REJECT_ALARM
      firebaseUpdateObj[
        'alarms/' +
          alarm.id +
          '/backups/group/' +
          groupId +
          '/members/' +
          uid +
          '/seenOn'
      ] = currDate
      const numActiveBackups = alarm.backupGroup.members.length
      firebaseUpdateObj[
        'alarms/' +
          alarm.id +
          '/backups/group/' +
          groupId +
          '/members/' +
          uid +
          '/order'
      ] = numActiveBackups
      alarm.backupGroup.members.forEach(contact => {
        if (contact.order > backupOrder) {
          firebaseUpdateObj[
            'alarms/' +
              alarm.id +
              '/backups/group/' +
              groupId +
              '/members/' +
              contact.id +
              '/order'
          ] = contact.order - 1
        }
      })
    }
    firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = Date.now()
    return firebaseUpdateObj
  }

  const createBackupResponseChangeFirebaseObj = function (
    alarm,
    uid,
    previousResponseStatus,
    newResponseStatus,
    backupOrder
  ) {
    const currDate = Date.now()
    let firebaseUpdateObj = {}
    const backupsType = alarm.backupGroup
      ? Constants.ParticipantTypes.GROUP
      : Constants.ParticipantTypes.CONTACT
    switch (previousResponseStatus) {
      case Constants.ALARM_RESPONSE_PENDING:
        // If backup has accepted the alarm, then just set the response status to Accepted on firebase
        if (newResponseStatus === Constants.ACCEPT_ALARM) {
          if (backupsType === Constants.ParticipantTypes.CONTACT) {
            firebaseUpdateObj[
              'alarms/' +
                alarm.id +
                '/backups/contacts/' +
                uid +
                '/responseStatus'
            ] = newResponseStatus
            firebaseUpdateObj[
              'alarms/' + alarm.id + '/backups/contacts/' + uid + '/seenOn'
            ] = currDate
          } else {
            const groupId = alarm.backupGroup.id
            firebaseUpdateObj[
              'alarms/' +
                alarm.id +
                '/backups/group/' +
                groupId +
                '/members/' +
                uid +
                '/responseStatus'
            ] = newResponseStatus
            firebaseUpdateObj[
              'alarms/' +
                alarm.id +
                '/backups/group/' +
                groupId +
                '/members/' +
                uid +
                '/seenOn'
            ] = currDate
          }
          firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = currDate
        }
        // If backup has rejected the alarm, then set the response status to Rejected on firebase
        // Update the order of the backup to last and bump the order of backups lying after this
        // backup by 1
        else {
          firebaseUpdateObj = createFirebaseObjectForRejectedStatus(
            alarm,
            uid,
            backupsType,
            backupOrder,
            currDate
          )
        }
        break
      case Constants.ACCEPT_ALARM:
        // The if check is obvious but just in case. If the previous status was Accepted, then only new
        // response status that is possible is Rejected. Set Rejected status on firebase and
        // bump the order of backups lying after this backup by 1
        if (newResponseStatus === Constants.REJECT_ALARM) {
          firebaseUpdateObj = createFirebaseObjectForRejectedStatus(
            alarm,
            uid,
            backupsType,
            backupOrder,
            currDate
          )
        }
        break
      case Constants.REJECT_ALARM:
        // The if check is obvious but just in case. If the previous status was Rejected, then only new
        // response status that is possible is Accepted. Set Accepted status on firebase and
        // find the member with Rejected status and the lowest order and exchange order. If no other
        // member found with Rejected status, keep the order as is
        if (newResponseStatus === Constants.ACCEPT_ALARM) {
          if (backupsType === Constants.ParticipantTypes.CONTACT) {
            firebaseUpdateObj[
              'alarms/' +
                alarm.id +
                '/backups/contacts/' +
                uid +
                '/responseStatus'
            ] = Constants.ACCEPT_ALARM
            firebaseUpdateObj[
              'alarms/' + alarm.id + '/backups/contacts/' + uid + '/seenOn'
            ] = currDate
            let lowestOrderContactIdWithRejectedStatus = null,
              lowestOrderWithRejectedStatus = Number.MAX_SAFE_INTEGER
            alarm.backupContacts.forEach(contact => {
              if (
                contact.responseStatus === Constants.REJECT_ALARM &&
                contact.order < lowestOrderWithRejectedStatus
              ) {
                lowestOrderContactIdWithRejectedStatus = contact.id
                lowestOrderWithRejectedStatus = contact.order
              }
            })
            if (
              lowestOrderContactIdWithRejectedStatus !== null &&
              lowestOrderContactIdWithRejectedStatus !== uid
            ) {
              firebaseUpdateObj[
                'alarms/' + alarm.id + '/backups/contacts/' + uid + '/order'
              ] = lowestOrderWithRejectedStatus
              firebaseUpdateObj[
                'alarms/' +
                  alarm.id +
                  '/backups/contacts/' +
                  lowestOrderContactIdWithRejectedStatus +
                  '/order'
              ] = backupOrder
            }
          } else {
            const groupId = alarm.backupGroup.id
            firebaseUpdateObj[
              'alarms/' +
                alarm.id +
                '/backups/group/' +
                groupId +
                '/members/' +
                uid +
                '/responseStatus'
            ] = Constants.ACCEPT_ALARM
            firebaseUpdateObj[
              'alarms/' +
                alarm.id +
                '/backups/group/' +
                groupId +
                '/members/' +
                uid +
                '/seenOn'
            ] = currDate
            let lowestOrderContactIdWithRejectedStatus = null,
              lowestOrderWithRejectedStatus = Number.MAX_SAFE_INTEGER
            alarm.backupGroup.members.forEach(contact => {
              if (
                contact.responseStatus === Constants.REJECT_ALARM &&
                contact.order < lowestOrderWithRejectedStatus
              ) {
                lowestOrderContactIdWithRejectedStatus = contact.id
                lowestOrderWithRejectedStatus = contact.order
              }
            })
            if (
              lowestOrderContactIdWithRejectedStatus !== null &&
              lowestOrderContactIdWithRejectedStatus !== uid
            ) {
              firebaseUpdateObj[
                'alarms/' +
                  alarm.id +
                  '/backups/group/' +
                  groupId +
                  '/members/' +
                  uid +
                  '/order'
              ] = lowestOrderWithRejectedStatus
              firebaseUpdateObj[
                'alarms/' +
                  alarm.id +
                  '/backups/group/' +
                  groupId +
                  '/members/' +
                  lowestOrderContactIdWithRejectedStatus +
                  '/order'
              ] = backupOrder
            }
          }
          firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = currDate
        }
        break
      default:
        LogUtils.logError(
          'Unable to process backup response. Error: Unknown previous response state ' +
            previousResponseStatus
        )
        break
    }
    return firebaseUpdateObj
  }

  const setBackupResponseStatusForAlarm = function (
    alarmId,
    previousResponseStatus,
    responseStatus
  ) {
    if (GlobalConfig.uid) {
      return retrieveAlarmByAlarmId(alarmId).then(alarm => {
        // Sanity checks
        if (alarm === null) {
          LogUtils.logError(
            new Error(
              "Can't set participant response for personal alarm because alarm is null " +
                alarmId
            )
          )
          return
        }
        return setBackupResponseStatusForAlarmCore(
          alarm,
          previousResponseStatus,
          responseStatus,
          alarm.order || 0
        )
      })
    } else {
      LogUtils.logError(
        new Error('GlobalConfig.uid is null'),
        'Unable to set participant response',
        { alarmId }
      )
      return Promise.resolve()
    }
  }

  const setBackupResponseStatusForAlarmCore = function (
    alarm,
    previousResponseStatus,
    responseStatus,
    backupOrder,
    persistOnFirebase = true
  ) {
    // In case user has already responded to the alarm, don't record the same status again
    if (previousResponseStatus === responseStatus) {
      return Promise.resolve()
    }

    const currDate = Date.now()
    const backupAlarmDate = DateTimeUtils.getParticipantAlarmDate(
      alarm,
      backupOrder
    )
    const alarmShouldTrigger =
      alarm.status === true &&
      (currDate < backupAlarmDate || alarm.repeatType !== '')
    if (responseStatus === Constants.ACCEPT_ALARM && alarmShouldTrigger) {
      scheduleNotificationForParticipantAlarm(alarm)
    } else {
      AlarmManager.cancelNotifications(alarm.id)
    }

    if (persistOnFirebase) {
      // Make sure that GlobalConfig.uid is not undefined
      let firebaseUpdateObj = {}
      if (GlobalConfig.uid) {
        firebaseUpdateObj = createBackupResponseChangeFirebaseObj(
          alarm,
          GlobalConfig.uid,
          previousResponseStatus,
          responseStatus,
          backupOrder
        )
      } else {
        LogUtils.logError(
          new Error('GlobalConfig.uid is null'),
          'Unable to set participant response',
          { alarmId: alarm.id }
        )
      }

      return ref
        .update(firebaseUpdateObj)
        .then(() => {
          // Send push notification to the creator only if the alarm will still trigger
          if (alarmShouldTrigger) {
            const notificationInfo = {
              alarmId: alarm.id,
              type: Constants.NotificationTypes.BACKUP_RESPONDED,
              participantName: GlobalConfig.username,
              alarmName: alarm.name
            }
            const notificationKey =
              responseStatus === Constants.ACCEPT_ALARM
                ? Constants.NotificationKeys
                    .ParticipantAlarmAcceptedNotification
                : Constants.NotificationKeys
                    .ParticipantAlarmDeclinedNotification
            NotificationManager.sendRemoteNotification(
              alarm.creator,
              notificationKey,
              notificationInfo
            )
          }
        })
        .catch(error => {
          LogUtils.logError(error, 'Unable to process backup response')
        })
    }

    return Promise.resolve()
  }

  const setRecipientResponseStatusForAlarm = function (
    alarmId,
    responseStatus
  ) {
    return retrieveAlarmByAlarmId(alarmId).then(alarm => {
      // Sanity checks
      if (alarm === null) {
        LogUtils.logError(
          new Error(
            "Can't set participant response for recipient alarm because alarm is null " +
              alarmId
          )
        )
        return
      }

      return setRecipientResponseStatusForAlarmCore(alarm, responseStatus)
    })
  }

  const setRecipientResponseStatusForAlarmCore = function (
    alarm,
    responseStatus,
    persistOnFirebase = true
  ) {
    // In case user has already responded to the alarm, don't record the same status again
    if (alarm.responseStatus === responseStatus) {
      return Promise.resolve()
    }

    const currDate = Date.now()
    const recipientAlarmDate = DateTimeUtils.getParticipantAlarmDate(
      alarm,
      alarm.order
    )
    const alarmShouldTrigger =
      alarm.status === true &&
      (currDate < recipientAlarmDate || alarm.repeatType !== '')
    if (responseStatus === Constants.ACCEPT_ALARM && alarmShouldTrigger) {
      scheduleNotificationForParticipantAlarm(alarm)
    } else {
      AlarmManager.cancelNotifications(alarm.id)
    }

    if (persistOnFirebase) {
      // Make sure that GlobalConfig.uid is not undefined
      let firebaseUpdateObj = {}
      if (GlobalConfig.uid) {
        firebaseUpdateObj['alarms/' + alarm.id + '/recipient/responseStatus'] =
          responseStatus
        firebaseUpdateObj['alarms/' + alarm.id + '/recipient/seenOn'] = currDate
        firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = currDate
      } else {
        LogUtils.logError(
          new Error('GlobalConfig.uid is null'),
          'Unable to set participant response',
          { alarmId: alarm.id }
        )
      }

      return ref
        .update(firebaseUpdateObj)
        .then(() => {
          // Send push notification to the creator only if the alarm will still trigger
          if (alarmShouldTrigger) {
            const notificationInfo = {
              alarmId: alarm.id,
              type: Constants.NotificationTypes.BACKUP_RESPONDED,
              participantName: GlobalConfig.username,
              alarmName: alarm.name
            }
            const notificationKey =
              responseStatus === Constants.ACCEPT_ALARM
                ? Constants.NotificationKeys
                    .ParticipantAlarmAcceptedNotification
                : Constants.NotificationKeys
                    .ParticipantAlarmDeclinedNotification
            NotificationManager.sendRemoteNotification(
              alarm.creator,
              notificationKey,
              notificationInfo
            )
          }
        })
        .catch(error => {
          LogUtils.logError(error, 'Unable to process backup response')
        })
    }

    return Promise.resolve()
  }

  const getAlarmParticipant = function (alarm) {
    const participants = getAlarmParticipants(
      alarm.type,
      alarm.backupGroup,
      alarm.backupContacts,
      alarm.recipient
    )
    const participant = Utils.getObjectWithId(participants, GlobalConfig.uid)

    return participant
  }

  const getAlarmParticipantRingerSettings = function (alarm) {
    const participants = getAlarmParticipants(
      alarm.type,
      alarm.backupGroup,
      alarm.backupContacts,
      alarm.recipient
    )
    const participant = Utils.getObjectWithId(participants, GlobalConfig.uid)

    return computeRingerSettings(participant?.ringerSettings)
  }

  const getAlarmParticipantPreReminderDuration = function (alarm) {
    const participants = getAlarmParticipants(
      alarm.type,
      alarm.backupGroup,
      alarm.backupContacts,
      alarm.recipient
    )
    const participant = Utils.getObjectWithId(participants, GlobalConfig.uid)

    return (
      participant?.preReminderDuration ||
      GlobalConfig.defaultPreReminderDuration
    )
  }

  const createFirebaseObjForUpdatingAlarmPreReminderDuration = function (
    alarm,
    newPreReminderDuration
  ) {
    const currDate = Date.now()

    let firebaseUpdateObj = {}

    firebaseUpdateObj['alarms/' + alarm.id + '/preReminderDuration'] =
      newPreReminderDuration

    firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = currDate

    return firebaseUpdateObj
  }

  const createFirebaseObjForUpdatingAlarmRingerSettings = function (
    alarm,
    newRingerSettings
  ) {
    const currDate = Date.now()

    let firebaseUpdateObj = {}

    firebaseUpdateObj['alarms/' + alarm.id + '/ringerSettings'] =
      newRingerSettings

    firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = currDate

    return firebaseUpdateObj
  }

  const createFirebaseObjForUpdatingParticipantAlarmPreReminderDuration =
    function (alarm, preReminderDuration) {
      const currDate = Date.now()

      let firebaseUpdateObj = {}

      if (alarm.type !== Constants.AlarmTypes.RECIPIENT) {
        if (alarm.backupGroup) {
          firebaseUpdateObj[
            'alarms/' +
              alarm.id +
              '/backups/group/' +
              alarm.backupGroup.id +
              '/members/' +
              GlobalConfig.uid +
              '/preReminderDuration'
          ] = preReminderDuration
        } else {
          firebaseUpdateObj[
            'alarms/' +
              alarm.id +
              '/backups/contacts/' +
              GlobalConfig.uid +
              '/preReminderDuration'
          ] = preReminderDuration
        }
      } else if (alarm.recipient) {
        firebaseUpdateObj[
          'alarms/' + alarm.id + '/recipient/preReminderDuration'
        ] = preReminderDuration
      }
      firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = currDate
      return firebaseUpdateObj
    }

  const createFirebaseObjForUpdatingParticipantAlarmRingerSettings = function (
    alarm,
    newRingerSettings
  ) {
    const currDate = Date.now()

    let firebaseUpdateObj = {}

    if (alarm.type !== Constants.AlarmTypes.RECIPIENT) {
      if (alarm.backupGroup) {
        firebaseUpdateObj[
          'alarms/' +
            alarm.id +
            '/backups/group/' +
            alarm.backupGroup.id +
            '/members/' +
            GlobalConfig.uid +
            '/ringerSettings'
        ] = newRingerSettings
      } else {
        firebaseUpdateObj[
          'alarms/' +
            alarm.id +
            '/backups/contacts/' +
            GlobalConfig.uid +
            '/ringerSettings'
        ] = newRingerSettings
      }
    } else if (alarm.recipient) {
      firebaseUpdateObj['alarms/' + alarm.id + '/recipient/ringerSettings'] =
        newRingerSettings
    }
    firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = currDate
    return firebaseUpdateObj
  }

  const getParticipantAlarmNotificationInfo = function (
    alarm,
    alarmEffectiveDate,
    creatorName = ''
  ) {
    const cascadingAlarmInterval = alarm.cascadingAlarmInterval
      ? Constants.CascadingAlarmIntervals[alarm.cascadingAlarmInterval].value
      : 0
    const recipientAlarmInterval = alarm.recipientAlarmInterval
      ? Constants.RecipientAlarmIntervals[alarm.recipientAlarmInterval].value
      : 0

    const ringerSettings = getAlarmParticipantRingerSettings(alarm)

    const preReminderDuration = getAlarmParticipantPreReminderDuration(alarm)

    const alarmRingtone = ringerSettings.alarmRingtone
    const vibrate = ringerSettings.vibrate ? 'true' : 'false'
    const volume = ringerSettings.volume
    const ringtoneDuration = ringerSettings.ringtoneDuration
    const autoSnooze = ringerSettings.autoSnooze ? 'true' : 'false'
    const autoSnoozeDuration = ringerSettings.autoSnoozeDuration
    const autoSnoozeCount = ringerSettings.autoSnoozeCount
    const ringOnVibrate = ringerSettings.ringOnVibrate ? 'true' : 'false'
    const announceAlarmName = ringerSettings.announceAlarmName
      ? 'true'
      : 'false'
    const criticalAlerts = ringerSettings.criticalAlerts ? 'true' : 'false'
    const criticalAlertsVolume = ringerSettings.criticalAlertsVolume

    return {
      alarmId: alarm.id,
      alarmName: alarm.name,
      alarmCreationMode: alarm.creationMode,
      type: Constants.NotificationTypes.BACKUP_ALARM,
      alarmType: alarm.type,
      alarmDate: alarmEffectiveDate,
      alarmAlert: getParticipantAlarmNotificationAlertMessage(
        alarm.type,
        alarm.name,
        alarm.creationMode,
        creatorName
      ),
      shortAlarmAlert: getParticipantAlarmShortAlertMessage(
        alarm.type,
        alarm.creationMode,
        creatorName
      ),
      cascadingAlarmInterval,
      recipientAlarmInterval,
      alarmRingtone: alarmRingtone,
      vibrate: vibrate,
      volume: volume,
      version: GlobalConfig.alarmVersion,
      ringtoneDuration: ringtoneDuration,
      alarmEndDate: alarm.endDate,
      autoSnooze: autoSnooze,
      autoSnoozeDuration: autoSnoozeDuration,
      autoSnoozeCount: autoSnoozeCount,
      ringOnVibrate: ringOnVibrate,
      announceAlarmName: announceAlarmName,
      creatorName: creatorName,
      preReminderDuration:
        preReminderDuration || GlobalConfig.defaultPreReminderDuration,
      criticalAlerts: criticalAlerts || GlobalConfig.defaultCriticalAlerts,
      criticalAlertsVolume:
        criticalAlertsVolume || GlobalConfig.defaultCriticalAlertsVolume
    }
  }

  const getMyAlarmNotificationInfo = function (alarm, alarmEffectiveDate) {
    const cascadingAlarmInterval = alarm.cascadingAlarmInterval
      ? Constants.CascadingAlarmIntervals[alarm.cascadingAlarmInterval].value
      : 0

    // Handle no reminder case for buddy alarm
    const recipientAlarmInterval =
      alarm.recipientAlarmInterval ===
      Constants.NO_REMINDER_RECIPIENT_INTERVAL_KEY
        ? alarm.recipientAlarmInterval
        : alarm.recipientAlarmInterval
        ? Constants.RecipientAlarmIntervals[alarm.recipientAlarmInterval].value
        : 0

    const ringerSettings = computeRingerSettings(alarm.ringerSettings)

    const alarmRingtone = ringerSettings.alarmRingtone
    const vibrate = ringerSettings.vibrate ? 'true' : 'false'
    const volume = ringerSettings.volume
    const ringtoneDuration = ringerSettings.ringtoneDuration
    const autoSnooze = ringerSettings.autoSnooze ? 'true' : 'false'
    const autoSnoozeDuration = ringerSettings.autoSnoozeDuration
    const autoSnoozeCount = ringerSettings.autoSnoozeCount
    const ringOnVibrate = ringerSettings.ringOnVibrate ? 'true' : 'false'
    const announceAlarmName = ringerSettings.announceAlarmName
      ? 'true'
      : 'false'
    const criticalAlerts = ringerSettings.criticalAlerts ? 'true' : 'false'
    const criticalAlertsVolume = ringerSettings.criticalAlertsVolume

    return {
      alarmId: alarm.id,
      alarmName: alarm.name,
      alarmCreationMode: alarm.creationMode,
      type: Constants.NotificationTypes.ALARM,
      alarmType: alarm.type,
      alarmDate: alarmEffectiveDate,
      alarmAlert: getMyAlarmNotificationAlertMessage(
        alarm.type,
        alarm.name,
        objGet(alarm, 'recipient.name', '')
      ),
      shortAlarmAlert: getMyAlarmShortAlertMessage(
        alarm.type,
        objGet(alarm, 'recipient.name', '')
      ),
      cascadingAlarmInterval,
      recipientAlarmInterval,
      alarmRingtone: alarmRingtone,
      vibrate: vibrate,
      volume: volume,
      version: GlobalConfig.alarmVersion,
      preReminderDuration:
        alarm.preReminderDuration || GlobalConfig.defaultPreReminderDuration,
      ringtoneDuration: ringtoneDuration,
      alarmEndDate: alarm.endDate,
      autoSnooze: autoSnooze,
      autoSnoozeDuration: autoSnoozeDuration,
      autoSnoozeCount: autoSnoozeCount,
      ringOnVibrate: ringOnVibrate,
      announceAlarmName: announceAlarmName,
      criticalAlerts: criticalAlerts || GlobalConfig.defaultCriticalAlerts,
      criticalAlertsVolume:
        criticalAlertsVolume || GlobalConfig.defaultCriticalAlertsVolume
    }
  }

  const getParticipantAlarmNotificationAlertMessage = function (
    alarmType,
    alarmName,
    alarmCreationMode,
    alarmCreatorName
  ) {
    let alarmAlert
    if (alarmCreationMode === Constants.AlarmCreationModes.INSTANT_ALARM) {
      alarmAlert = I18n.t(Constants.NotificationKeys.InstantAlarmNotification, {
        alarmName
      })
    } else if (alarmType === Constants.AlarmTypes.SIMULTANEOUS) {
      alarmAlert = I18n.t(
        Constants.NotificationKeys.ParticipantSimultaneousAlarmNotification,
        { alarmName }
      )
    } else if (alarmType === Constants.AlarmTypes.CASCADING) {
      alarmAlert = I18n.t(
        Constants.NotificationKeys.ParticipantCascadingAlarmNotification,
        { alarmName, alarmCreatorName }
      )
    } else if (alarmType === Constants.AlarmTypes.RECIPIENT) {
      alarmAlert = I18n.t(
        Constants.NotificationKeys.RecipientAlarmNotification,
        { alarmName, alarmCreatorName }
      )
    }
    return alarmAlert
  }

  const getParticipantAlarmShortAlertMessage = function (
    alarmType,
    alarmCreationMode,
    alarmCreatorName
  ) {
    let alarmAlert
    if (alarmCreationMode === Constants.AlarmCreationModes.INSTANT_ALARM) {
      alarmAlert = I18n.t(
        Constants.NotificationKeys.InstantAlarmNotificationShort
      )
    } else if (alarmType === Constants.AlarmTypes.SIMULTANEOUS) {
      alarmAlert = I18n.t(
        Constants.NotificationKeys.ParticipantSimultaneousAlarmNotificationShort
      )
    } else if (alarmType === Constants.AlarmTypes.CASCADING) {
      alarmAlert = I18n.t(
        Constants.NotificationKeys.ParticipantCascadingAlarmNotificationShort,
        { alarmCreatorName }
      )
    } else if (alarmType === Constants.AlarmTypes.RECIPIENT) {
      alarmAlert = I18n.t(
        Constants.NotificationKeys.RecipientAlarmNotificationShort,
        { alarmCreatorName }
      )
    }
    return alarmAlert
  }

  const getMyAlarmNotificationAlertMessage = function (
    alarmType,
    alarmName,
    alarmRecipientName
  ) {
    let alarmAlert
    if (alarmType === Constants.AlarmTypes.SIMULTANEOUS) {
      alarmAlert = I18n.t(
        Constants.NotificationKeys.ParticipantSimultaneousAlarmNotification,
        { alarmName }
      )
    } else if (alarmType === Constants.AlarmTypes.CASCADING) {
      alarmAlert = I18n.t(Constants.NotificationKeys.MyAlarmNotification, {
        alarmName
      })
    } else if (alarmType === Constants.AlarmTypes.RECIPIENT) {
      alarmAlert = I18n.t(
        Constants.NotificationKeys.MyRecipientAlarmNotification,
        { alarmName, alarmRecipientName }
      )
    }
    return alarmAlert
  }

  const getMyAlarmShortAlertMessage = function (alarmType, alarmRecipientName) {
    let alarmAlert
    if (alarmType === Constants.AlarmTypes.SIMULTANEOUS) {
      alarmAlert = I18n.t(
        Constants.NotificationKeys.ParticipantSimultaneousAlarmNotificationShort
      )
    } else if (alarmType === Constants.AlarmTypes.CASCADING) {
      alarmAlert = I18n.t(Constants.NotificationKeys.MyAlarmNotificationShort)
    } else if (alarmType === Constants.AlarmTypes.RECIPIENT) {
      alarmAlert = I18n.t(
        Constants.NotificationKeys.MyRecipientAlarmNotificationShort,
        { alarmRecipientName }
      )
    }
    return alarmAlert
  }

  const createBackupContactsFromContactsObject = function (contactsObj) {
    let backupContacts = []
    Object.keys(contactsObj).forEach(backupId => {
      const backupData = contactsObj[backupId]
      if (backupData.id) {
        backupContacts.push({
          ...backupData
        })
      } else {
        FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.INVALID_CONTACT_PARTICIPANT,
          {}
        )
      }
    })

    return backupContacts
  }

  const createBackupGroupFromGroupObject = function (
    groupObj,
    forBackupAlarm = false
  ) {
    const numGroups = Object.keys(groupObj).length

    // There should be just one group
    if (numGroups > 1) {
      FirebaseProxy.logEvent(
        Constants.UserAnalyticsEvents.INVALID_GROUP_PARTICIPANT,
        {}
      )
    }

    let backupGroup = null
    Object.keys(groupObj).forEach(backupGroupKey => {
      const backupGroupData = groupObj[backupGroupKey]
      const backupGroupName = backupGroupData.name

      // If a user removes every other member in the group, then for the alarm,
      // there won't be any members in the backup group at that instant of time
      // To handle such cases, default the members to empty array
      const backupGroupMembers = backupGroupData.members || {}
      let members = []

      Object.keys(backupGroupMembers).forEach(memberKey => {
        const memberData = backupGroupMembers[memberKey]
        if (memberData.id) {
          members.push({
            ...memberData
          })
        } else {
          FirebaseProxy.logEvent(
            Constants.UserAnalyticsEvents.INVALID_GROUP_PARTICIPANT,
            {}
          )
        }
      })

      if (forBackupAlarm) {
        members = members.filter(member => {
          return member.state !== Constants.ParticipantStates.INACTIVE
        })
      }

      backupGroup = {
        id: backupGroupKey,
        name: backupGroupName,
        members: members
      }
    })

    return backupGroup
  }

  const createAlarmRepetitionStringForAlarmSummary = function (
    repeatType,
    repeat
  ) {
    if (repeatType === Constants.RepeatTypes.EVERY_N_HOURS) {
      const { endHours, endMins, hours } =
        getRepeatOptionsForHourlyRepetition(repeat)
      return I18n.t('everyNHoursRepetitionStringForAlarmSummary', {
        endTimeString: createTimeString(endHours, endMins),
        count: hours
      })
    } else if (repeatType === Constants.RepeatTypes.HOURS_AND_MINUTES) {
      const { endHours, endMins, minutes } =
        getRepeatOptionsForHoursAndMinutesRepetition(repeat)
      const hoursAndMinutesString =
        computeHoursAndMinutesStringFromMinutes(minutes)
      return I18n.t('everyNHoursAndMinutesRepetitionStringForAlarmSummary', {
        endTimeString: createTimeString(endHours, endMins),
        hoursAndMinutes: hoursAndMinutesString
      })
    } else if (repeatType === Constants.RepeatTypes.YEARS) {
      if (repeat.startsWith(Constants.LAST_DAY_OF_MONTH_REPEAT_STRING)) {
        const repeatOptionsArr = repeat.split(':')
        const monthName = repeatOptionsArr[1]
        return I18n.t('lastDayOfMonthInYearSummary', {
          monthName: I18n.t(monthName)
        })
      } else if (
        repeat.startsWith(Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING)
      ) {
        const repeatOptionsArr = repeat.split(':')
        const weekNumberOfDayInMonth = repeatOptionsArr[1]
        const dayName = repeatOptionsArr[2]
        const monthName = repeatOptionsArr[3]
        return I18n.t('dayOfWeekInMonthInYearSummary', {
          weekNumberOfDayInMonth: DateTimeUtils.getDayOfWeekInMonthOrdinal(
            weekNumberOfDayInMonth
          ),
          dayName: I18n.t(dayName),
          monthName: I18n.t(monthName)
        })
      } else if (
        repeat.startsWith(Constants.LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING)
      ) {
        const repeatOptionsArr = repeat.split(':')
        const dayName = repeatOptionsArr[1]
        const monthName = repeatOptionsArr[2]
        return I18n.t('lastDayOfWeekInMonthInYearSummary', {
          dayName: I18n.t(dayName),
          monthName: I18n.t(monthName)
        })
      } else {
        const years = parseInt(repeat) || 0
        return I18n.t('everyNYears', { count: years })
      }
    } else {
      return createAlarmRepetitionString(repeatType, repeat)
    }
  }

  const createAlarmRepetitionString = function (repeatType, repeat) {
    if (repeatType === Constants.RepeatTypes.MONTHLY) {
      var repeatString = I18n.t('monthly')
      return repeatString
    } else if (repeatType === Constants.RepeatTypes.YEARLY) {
      repeatString = I18n.t('yearly')
      return repeatString
    } else if (repeatType === undefined || repeatType === '') {
      return Constants.ONE_TIME_ALARM_STRING
    } else if (repeatType === Constants.RepeatTypes.DAYS_OF_WEEK) {
      const repeatArr = repeat.split(',').filter(e => e !== '')
      if (repeatArr.length === 7) {
        return Constants.EVERY_DAY_STRING
      } else if (repeatArr.length === 1) {
        return (
          I18n.t('every') +
          ' ' +
          Constants.RepeatDayToDayMappingI18n[repeatArr[0]]
        )
      } else if (
        repeatArr.length === Constants.Weekdays.length &&
        repeatArr.filter(day =>
          Constants.Weekdays.includes(Constants.RepeatDayToShortDayMapping[day])
        ).length === Constants.Weekdays.length
      ) {
        return Constants.WEEKDAYS_STRING
      } else if (
        repeatArr.length === Constants.Weekends.length &&
        repeatArr.filter(day =>
          Constants.Weekends.includes(Constants.RepeatDayToShortDayMapping[day])
        ).length === Constants.Weekends.length
      ) {
        return Constants.WEEKENDS_STRING
      } else {
        return (
          I18n.t('every') +
          ' ' +
          repeatArr
            .map(repeatVal => {
              return Constants.RepeatDayToShortDayMappingI18n[repeatVal]
            })
            .join(', ')
        )
      }
    } else if (repeatType === Constants.RepeatTypes.EVERY_N_HOURS) {
      const { startHours, startMins, endHours, endMins, hours, selectedDays } =
        getRepeatOptionsForHourlyRepetition(repeat)
      const daysString = createSelectedDaysString(selectedDays)
      return I18n.t('everyNHoursRepetitionString', {
        startTimeString: createTimeString(startHours, startMins),
        endTimeString: createTimeString(endHours, endMins),
        daysString: daysString,
        count: hours
      })
    } else if (
      repeatType === Constants.RepeatTypes.EVERY_M_HOURS_AND_N_MINUTES
    ) {
      const { hours, minutes } =
        getRepeatOptionsForEveryMHoursAndNMinutesRepetition(repeat)
      const parts = []
      parts.push(I18n.t('hoursString', { count: hours }))
      parts.push(I18n.t('minutesString', { count: minutes }))
      return I18n.t('everyMHoursAndNMinutesRepetitionString', {
        repeatString: parts.join(' ').trim()
      })
    } else if (repeatType === Constants.RepeatTypes.HOURS_AND_MINUTES) {
      const {
        startHours,
        startMins,
        endHours,
        endMins,
        minutes,
        selectedDays
      } = getRepeatOptionsForHoursAndMinutesRepetition(repeat)
      const daysString = createSelectedDaysString(selectedDays)
      const hoursAndMinutesString =
        computeHoursAndMinutesStringFromMinutes(minutes)
      return I18n.t('everyNHoursAndMinutesRepetitionString', {
        startTimeString: createTimeString(startHours, startMins),
        endTimeString: createTimeString(endHours, endMins),
        daysString: daysString,
        hoursAndMinutes: hoursAndMinutesString,
        minutes: minutes
      })
    } else if (repeatType === Constants.RepeatTypes.DAYS) {
      if (
        repeat.includes(Constants.ODD_NUMBERED_DAYS_REPEAT_STRING) ||
        repeat.includes(Constants.EVEN_NUMBERED_DAYS_REPEAT_STRING)
      ) {
        const { isOdd, selectedDays } =
          getRepeatOptionsForOddAndEvenNumberedDaysRepetition(repeat)
        const daysString = createSelectedDaysString(selectedDays)
        const count = selectedDays.length < 7 ? 1 : selectedDays.length
        return I18n.t(
          isOdd ? 'daysRepetitionOddString' : 'daysRepetitionEvenString',
          { count: count, daysString: daysString }
        )
      } else {
        const days = parseInt(repeat) || 0
        return I18n.t('everyNDays', { count: days })
      }
    } else if (repeatType === Constants.RepeatTypes.WEEKS) {
      const weeks = parseInt(repeat) || 0
      return I18n.t('everyNWeeks', { count: weeks })
    } else if (repeatType === Constants.RepeatTypes.MONTHS) {
      if (repeat === Constants.LAST_DAY_OF_MONTH_REPEAT_STRING) {
        return I18n.t('lastDayOfMonth')
      } else if (
        repeat.startsWith(Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING)
      ) {
        const repeatOptionsArr = repeat.split(':')
        const weekNumberOfDayInMonth = repeatOptionsArr[1]
        const dayName = repeatOptionsArr[2]
        return I18n.t('dayOfWeekInMonth', {
          weekNumberOfDayInMonth: DateTimeUtils.getDayOfWeekInMonthOrdinal(
            weekNumberOfDayInMonth
          ),
          dayName: I18n.t(dayName)
        })
      } else if (
        repeat.startsWith(Constants.LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING)
      ) {
        const repeatOptionsArr = repeat.split(':')
        const dayName = repeatOptionsArr[1]
        return I18n.t('lastDayOfWeekInMonth', {
          dayName: I18n.t(dayName)
        })
      } else {
        const months = parseInt(repeat) || 0
        return I18n.t('everyNMonths', { count: months })
      }
    } else if (repeatType === Constants.RepeatTypes.YEARS) {
      if (repeat.startsWith(Constants.LAST_DAY_OF_MONTH_REPEAT_STRING)) {
        const repeatOptionsArr = repeat.split(':')
        const monthName = repeatOptionsArr[1]
        return I18n.t('lastDayOfMonthInYear', { monthName: I18n.t(monthName) })
      } else if (
        repeat.startsWith(Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING)
      ) {
        const repeatOptionsArr = repeat.split(':')
        const weekNumberOfDayInMonth = repeatOptionsArr[1]
        const dayName = repeatOptionsArr[2]
        const monthName = repeatOptionsArr[3]
        return I18n.t('dayOfWeekInMonthInYear', {
          weekNumberOfDayInMonth: DateTimeUtils.getDayOfWeekInMonthOrdinal(
            weekNumberOfDayInMonth
          ),
          dayName: I18n.t(dayName),
          monthName: I18n.t(monthName)
        })
      } else if (
        repeat.startsWith(Constants.LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING)
      ) {
        const repeatOptionsArr = repeat.split(':')
        const dayName = repeatOptionsArr[1]
        const monthName = repeatOptionsArr[2]
        return I18n.t('lastDayOfWeekInMonthInYear', {
          dayName: I18n.t(dayName),
          monthName: I18n.t(monthName)
        })
      } else {
        const years = parseInt(repeat) || 0
        return I18n.t('everyNYears', { count: years })
      }
    }
  }

  // Return empty string if repetition is valid, else return the
  // error message
  const validateRepetition = function (repeatType, repeat) {
    if (repeatType === Constants.RepeatTypes.DAYS_OF_WEEK) {
      const repeatArr = repeat.split(',').filter(e => e !== '')
      if (repeatArr.length === 0) {
        return I18n.t('selectAtLeastOneDayForRepetition')
      }
    } else if (repeatType === Constants.RepeatTypes.EVERY_N_HOURS) {
      const { selectedDays } = getRepeatOptionsForHourlyRepetition(repeat)
      if (selectedDays.length === 0) {
        return I18n.t('selectAtLeastOneDayForRepetition')
      }
    } else if (repeatType === Constants.RepeatTypes.HOURS_AND_MINUTES) {
      const { selectedDays } =
        getRepeatOptionsForHoursAndMinutesRepetition(repeat)
      if (selectedDays.length === 0) {
        return I18n.t('selectAtLeastOneDayForRepetition')
      }
    } else if (repeatType === Constants.RepeatTypes.DAYS) {
      if (
        repeat.includes(Constants.ODD_NUMBERED_DAYS_REPEAT_STRING) ||
        repeat.includes(Constants.EVEN_NUMBERED_DAYS_REPEAT_STRING)
      ) {
        const { selectedDays } =
          getRepeatOptionsForOddAndEvenNumberedDaysRepetition(repeat)
        if (selectedDays.length === 0) {
          return I18n.t('selectAtLeastOneDayForRepetition')
        }
      } else {
        const days = parseInt(repeat)
        if (!days) {
          return I18n.t('chooseNumberOfDaysForAlarmRepetition')
        }
      }
    } else if (repeatType === Constants.RepeatTypes.WEEKS) {
      const weeks = parseInt(repeat)
      if (!weeks) {
        return I18n.t('chooseNumberOfWeeksForAlarmRepetition')
      }
    } else if (repeatType === Constants.RepeatTypes.MONTHS) {
      if (
        repeat === Constants.LAST_DAY_OF_MONTH_REPEAT_STRING ||
        repeat.startsWith(Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING) ||
        repeat.startsWith(Constants.LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING)
      ) {
        return ''
      } else {
        const months = parseInt(repeat)
        if (!months) {
          return I18n.t('chooseNumberOfMonthsForAlarmRepetition')
        }
      }
    } else if (repeatType === Constants.RepeatTypes.YEARS) {
      if (
        repeat.startsWith(Constants.LAST_DAY_OF_MONTH_REPEAT_STRING) ||
        repeat.startsWith(Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING) ||
        repeat.startsWith(Constants.LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING)
      ) {
        return ''
      } else {
        const years = parseInt(repeat)
        if (!years) {
          return I18n.t('chooseNumberOfYearsForAlarmRepetition')
        }
      }
    }

    return ''
  }

  const computeHoursAndMinutesStringFromMinutes = totalMinutes => {
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60
    const hoursString = hours ? I18n.t('hours', { count: hours }) : ''
    const minutesString = minutes ? I18n.t('minutes', { count: minutes }) : ''
    const hoursAndMinutesString = arrCompact([hoursString, minutesString]).join(
      ' '
    )
    return hoursAndMinutesString
  }

  const getHoursAndMinutesFromMinutes = totalMinutes => {
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60
    return {
      hours,
      minutes
    }
  }

  const createParticipantAlarmRepetitionStringForAlarmSummary = function (
    alarm
  ) {
    const {
      repeat,
      repeatType,
      date,
      cascadingAlarmInterval,
      recipientAlarmInterval
    } = alarm
    let tbpa = 0
    if (alarm.type === Constants.AlarmTypes.CASCADING) {
      tbpa =
        alarm.order *
        Constants.CascadingAlarmIntervals[cascadingAlarmInterval].value
    } else if (alarm.type === Constants.AlarmTypes.RECIPIENT) {
      tbpa = -Constants.RecipientAlarmIntervals[recipientAlarmInterval].value
    }

    if (repeatType === Constants.RepeatTypes.EVERY_N_HOURS) {
      const {
        startHours: startHoursForCreator,
        endHours: endHoursForCreator,
        hours
      } = getRepeatOptionsForHourlyRepetition(repeat)
      const deltaHours = endHoursForCreator - startHoursForCreator
      const startHours = moment(date).hours()
      const startMins = moment(date).minutes()
      const endHours = startHours + deltaHours
      const endMins = startMins

      return I18n.t('everyNHoursRepetitionStringForAlarmSummary', {
        endTimeString: createTimeStringForParticipantAlarm(
          endHours,
          endMins,
          tbpa
        ),
        count: hours
      })
    } else if (repeatType === Constants.RepeatTypes.HOURS_AND_MINUTES) {
      const {
        startHours: startHoursForCreator,
        endHours: endHoursForCreator,
        startMins: startMinsForCreator,
        endMins: endMinsForCreator,
        minutes
      } = getRepeatOptionsForHoursAndMinutesRepetition(repeat)
      const deltaHours = endHoursForCreator - startHoursForCreator
      const deltaMins = endMinsForCreator - startMinsForCreator
      const startHours = moment(date).hours()
      const startMins = moment(date).minutes()
      const endHours = startHours + deltaHours
      const endMins = startMins + deltaMins
      const hoursAndMinutesString =
        computeHoursAndMinutesStringFromMinutes(minutes)
      return I18n.t('everyNHoursAndMinutesRepetitionStringForAlarmSummary', {
        endTimeString: createTimeStringForParticipantAlarm(
          endHours,
          endMins,
          tbpa
        ),
        hoursAndMinutes: hoursAndMinutesString
      })
    } else {
      return createParticipantAlarmRepetitionString(alarm)
    }
  }

  const createParticipantAlarmRepetitionString = function (alarm) {
    const { repeatType, cascadingAlarmInterval, recipientAlarmInterval } = alarm
    let tbpa = 0
    if (alarm.type === Constants.AlarmTypes.CASCADING) {
      tbpa =
        alarm.order *
        Constants.CascadingAlarmIntervals[cascadingAlarmInterval].value
    } else if (alarm.type === Constants.AlarmTypes.RECIPIENT) {
      tbpa = -Constants.RecipientAlarmIntervals[recipientAlarmInterval].value
    }

    const adjustedRepeat = adjustRepetitionStringIfParticipant(alarm)

    let repeatString = createAlarmRepetitionString(repeatType, adjustedRepeat)

    if (repeatType === Constants.RepeatTypes.EVERY_N_HOURS) {
      const {
        startHours: startHoursForCreator,
        endHours: endHoursForCreator,
        hours,
        selectedDays
      } = getRepeatOptionsForHourlyRepetition(adjustedRepeat)
      const deltaHours = endHoursForCreator - startHoursForCreator
      const startHours = moment(alarm.date).hours()
      const startMins = moment(alarm.date).minutes()
      const endHours = startHours + deltaHours
      const endMins = startMins
      const daysString = createSelectedDaysString(selectedDays)
      repeatString = I18n.t('everyNHoursRepetitionString', {
        startTimeString: createTimeStringForParticipantAlarm(
          startHours,
          startMins,
          tbpa
        ),
        endTimeString: createTimeStringForParticipantAlarm(
          endHours,
          endMins,
          tbpa
        ),
        daysString: daysString,
        count: hours
      })
    } else if (repeatType === Constants.RepeatTypes.HOURS_AND_MINUTES) {
      const {
        startHours: startHoursForCreator,
        endHours: endHoursForCreator,
        startMins: startMinsForCreator,
        endMins: endMinsForCreator,
        minutes,
        selectedDays
      } = getRepeatOptionsForHoursAndMinutesRepetition(adjustedRepeat)
      const deltaHours = endHoursForCreator - startHoursForCreator
      const deltaMins = endMinsForCreator - startMinsForCreator
      const startHours = moment(alarm.date).hours()
      const startMins = moment(alarm.date).minutes()
      const endHours = startHours + deltaHours
      const endMins = startMins + deltaMins
      const hoursAndMinutesString =
        computeHoursAndMinutesStringFromMinutes(minutes)
      const daysString = createSelectedDaysString(selectedDays)
      repeatString = I18n.t('everyNHoursAndMinutesRepetitionString', {
        startTimeString: createTimeStringForParticipantAlarm(
          startHours,
          startMins,
          tbpa
        ),
        endTimeString: createTimeStringForParticipantAlarm(
          endHours,
          endMins,
          tbpa
        ),
        daysString: daysString,
        hoursAndMinutes: hoursAndMinutesString
      })
    }

    return repeatString
  }

  const createTimeString = (hours, minutes) => {
    return DateTimeUtils.getTimeAsString(
      moment().hours(hours).minutes(minutes).valueOf()
    )
  }

  const createTimeStringForParticipantAlarm = (hours, minutes, tbpa) => {
    return DateTimeUtils.getTimeAsString(
      moment().hours(hours).minutes(minutes).add(tbpa, 'milliseconds').valueOf()
    )
  }

  const willParticipantAlarmRing = alarm => {
    return (
      alarm.status &&
      (alarm.responseStatus === Constants.ACCEPT_ALARM ||
        (GlobalConfig.ringParticipantAlarmsByDefault !== false &&
          alarm.responseStatus === Constants.ALARM_RESPONSE_PENDING)) &&
      alarm.state !== Constants.ParticipantStates.INACTIVE
    )
  }

  const getCurrentDateForAlarmId = alarmId => {
    const alarm = getAlarmFromStore(alarmId)

    if (!alarm) {
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]:
          'getCurrentDateForAlarmId'
      })
      return undefined
    }

    return getCurrentDateForAlarm(alarm)
  }

  const removeAlarmIdFromAlarmDatesMap = alarmId => {
    const alarmDatesMapKeys = GlobalConfig.alarmDatesMap.keys()
    const keysForAlarmId = []
    for (const key of alarmDatesMapKeys) {
      if (key.startsWith(alarmId)) {
        keysForAlarmId.push(key)
      }
    }
    keysForAlarmId.forEach(key => GlobalConfig.alarmDatesMap.delete(key))
  }

  const getCurrentDateForAlarm = alarm => {
    // If we have already computed currentDate for this alarm, get
    // it from the map. We know that the currentDate is still valid
    // by checking the nextDate. If the nextDate is in future, that
    // would mean that we have already found out the currentDate for
    // this alarm. Once the currentDate changes to the next occurrence,
    // the nextDate would no longer be in the future and hence it will
    // be computed again.
    const currDate = Date.now()
    const alarmDates = GlobalConfig.alarmDatesMap.get(alarm.id + alarm.date)
    if (
      alarmDates &&
      alarmDates.currentDate &&
      (!alarmDates.nextDate || alarmDates.nextDate > currDate)
    ) {
      const currentDate = alarmDates.currentDate
      if (currentDate > currDate) {
        return currentDate
      }

      // If the current date is in the past, it may have been marked
      // done. So, we need to check that and if it is not marked done,
      // returned the cached date
      const alarmAcknowledgement =
        getAlarmAcknowledgementStatusForOccurrenceForAlarm(alarm, currentDate)
      if (!alarmAcknowledgement) {
        return currentDate
      }
    }

    let dates
    if (alarm.type === Constants.AlarmTypes.RECIPIENT) {
      dates = getCurrentDateForBuddyAlarm(alarm)
    } else {
      let currentDate

      const { prevDate, nextDate } = getPrevAndNextDatesForAnAlarmWrtDate(
        currDate,
        alarm.date,
        alarm.endDate,
        alarm.repeatType,
        alarm.repeat,
        alarm.creatorTimezone
      )

      if (prevDate) {
        let alarmPreviousOccurrenceThreshold =
          GlobalConfig.alarmPreviousOccurrenceThreshold
        if (
          nextDate &&
          (nextDate - prevDate) / 2 <
            GlobalConfig.alarmPreviousOccurrenceThreshold
        ) {
          alarmPreviousOccurrenceThreshold = (nextDate - prevDate) / 2
        }
        if (
          alarm.type === Constants.AlarmTypes.SIMULTANEOUS &&
          prevDate + alarmPreviousOccurrenceThreshold > currDate
        ) {
          currentDate = prevDate
        } else {
          const prevOccurrenceAcknowledgement =
            getAlarmAcknowledgementStatusForOccurrenceForAlarm(alarm, prevDate)
          if (prevOccurrenceAcknowledgement && nextDate) {
            currentDate = nextDate
          } else {
            currentDate = prevDate
          }
        }
      } else {
        currentDate = nextDate
      }
      dates = { currentDate, nextDate }
    }

    GlobalConfig.alarmDatesMap.set(alarm.id + alarm.date, dates)
    return dates.currentDate
  }

  const getCurrentDateForBuddyAlarm = alarm => {
    const participantAlarmDate = DateTimeUtils.getParticipantAlarmDate(
      alarm,
      alarm.order
    )
    const { prevDate: prevDateForRecipient, nextDate: nextDateForRecipient } =
      getPrevAndNextDatesForAnAlarmWrtDate(
        Date.now(),
        participantAlarmDate,
        alarm.endDate,
        alarm.repeatType,
        alarm.repeat,
        alarm.creatorTimezone
      )
    const prevDate =
      prevDateForRecipient +
      Constants.RecipientAlarmIntervals[alarm.recipientAlarmInterval].value
    const nextDate =
      nextDateForRecipient +
      Constants.RecipientAlarmIntervals[alarm.recipientAlarmInterval].value

    let currentDate
    if (prevDate) {
      const prevOccurrenceAcknowledgement =
        getAlarmAcknowledgementStatusForOccurrenceForAlarm(alarm, prevDate)
      if (prevOccurrenceAcknowledgement && nextDate) {
        currentDate = nextDate
      } else {
        currentDate = prevDate
      }
    } else {
      currentDate = nextDate
    }

    return { currentDate, nextDate }
  }

  const getNextRingDateForAlarm = alarm => {
    const currAlarmDate = getCurrentDateForAlarm(alarm)
    if (currAlarmDate < Date.now()) {
      return getNextDateForAlarm(
        currAlarmDate,
        alarm.endDate,
        alarm.repeatType,
        alarm.repeat,
        alarm.creatorTimezone
      )
    } else {
      const acknowledgement =
        getAlarmAcknowledgementStatusForOccurrenceForAlarm(alarm, currAlarmDate)

      const rescheduleAlarm =
        acknowledgement &&
        (acknowledgement.rescheduleAlarm === undefined ||
          acknowledgement.rescheduleAlarm)
      if (rescheduleAlarm) {
        const { nextDate } = getPrevAndNextDatesForAnAlarmWrtDate(
          currAlarmDate + Constants.MSEC_IN_MINUTE,
          alarm.date,
          alarm.endDate,
          alarm.repeatType,
          alarm.repeat,
          alarm.creatorTimezone
        )
        return nextDate
      }
    }
    return currAlarmDate
  }

  const getPrevDateForAlarm = (
    alarmDate,
    alarmEndDate,
    alarmRepeatType,
    alarmRepeat,
    alarmCreatorTimezone
  ) => {
    const { prevDate } = getPrevAndNextDatesForAnAlarmWrtDate(
      Date.now(),
      alarmDate,
      alarmEndDate,
      alarmRepeatType,
      alarmRepeat,
      alarmCreatorTimezone
    )
    return prevDate
  }

  const snoozeAlarmForCreatorNotificationAction = (
    alarmInfo,
    snoozeInterval,
    snoozeDate
  ) => {
    const snoozeAlarmDate =
      DateTimeUtils.shaveSecondsFromDate(snoozeDate) + snoozeInterval

    AlarmManager.scheduleSnoozeNotification(
      snoozeAlarmDate,
      alarmInfo.alarmAlert,
      alarmInfo
    )

    if (GlobalConfig.uid) {
      return retrieveAlarmByAlarmId(alarmInfo.alarmId)
        .then(alarm => {
          if (alarm === null) {
            LogUtils.logError(
              new Error(
                'Unable to store last snooze time because alarm is null ' +
                  alarmInfo.alarmId
              )
            )
            return
          }

          // To support no firebase sync for unregistered users
          if (GlobalConfig.store) {
            const softUpdatedAlarm = updateAlarm(alarm, {
              lastSnoozeByCreatorOn: snoozeDate,
              lastSnoozeByCreatorFor: snoozeInterval
            })

            GlobalConfig.store.dispatch(
              ActionCreators.softUpdateLocalAlarm(softUpdatedAlarm)
            )
          }

          let firebaseUpdateObj = createFirebaseObjForSnoozingCreatorAlarm(
            alarm.id,
            snoozeInterval,
            snoozeDate
          )
          return ref.update(firebaseUpdateObj)
        })
        .catch(error => {
          LogUtils.logError(error, 'Unable to store last snooze time')
        })
    } else {
      LogUtils.logError(
        new Error('GlobalConfig.uid is null'),
        'Unable to snooze own alarm',
        { alarmId: alarmInfo.alarmId }
      )
      return Promise.resolve()
    }
  }

  const snoozeAlarmForCreator = (
    alarm,
    snoozeInterval,
    snoozeDate,
    persistOnFirebase = true
  ) => {
    const alarmAlert = getMyAlarmNotificationAlertMessage(
      alarm.type,
      alarm.name,
      objGet(alarm, 'recipient.name', '')
    )

    const alarmDate = getPrevDateForAlarm(
      alarm.date,
      alarm.endDate,
      alarm.repeatType,
      alarm.repeat,
      alarm.creatorTimezone
    )
    const myAlarmDate = DateTimeUtils.getMyAlarmDate(alarmDate)

    // Use effective alarm date here
    const notificationInfo = getMyAlarmNotificationInfo(alarm, myAlarmDate)

    const snoozeAlarmDate =
      DateTimeUtils.shaveSecondsFromDate(snoozeDate) + snoozeInterval

    AlarmManager.scheduleSnoozeNotification(
      snoozeAlarmDate,
      alarmAlert,
      notificationInfo
    )

    if (persistOnFirebase) {
      const firebaseUpdateObj = createFirebaseObjForSnoozingCreatorAlarm(
        alarm.id,
        snoozeInterval,
        snoozeDate
      )
      return ref.update(firebaseUpdateObj)
    }

    return Promise.resolve()
  }

  const snoozeAlarmForParticipantNotificationAction = (
    alarmInfo,
    snoozeInterval,
    snoozeDate
  ) => {
    const snoozeAlarmDate =
      DateTimeUtils.shaveSecondsFromDate(snoozeDate) + snoozeInterval

    AlarmManager.scheduleSnoozeNotification(
      snoozeAlarmDate,
      alarmInfo.alarmAlert,
      alarmInfo
    )

    if (GlobalConfig.uid) {
      return retrieveAlarmByAlarmId(alarmInfo.alarmId)
        .then(alarm => {
          if (alarm === null) {
            LogUtils.logError(
              new Error(
                'Unable to store last snooze time because alarm is null ' +
                  alarmInfo.alarmId
              )
            )
            return
          }
          let firebaseUpdateObj = createFirebaseObjForSnoozingParticipantAlarm(
            alarm,
            snoozeInterval,
            snoozeDate
          )
          return ref.update(firebaseUpdateObj)
        })
        .catch(error => {
          LogUtils.logError(error, 'Unable to store last snooze time')
        })
    } else {
      LogUtils.logError(
        new Error('GlobalConfig.uid is null'),
        'Unable to snooze own alarm',
        { alarmId: alarmInfo.alarmId }
      )
      return Promise.resolve()
    }
  }

  const snoozeAlarmForParticipant = (
    alarm,
    creatorName,
    snoozeInterval,
    snoozeDate,
    persistOnFirebase = true
  ) => {
    const alarmAlert = getParticipantAlarmNotificationAlertMessage(
      alarm.type,
      alarm.name,
      alarm.creationMode,
      creatorName
    )
    const participantAlarmDate = DateTimeUtils.getParticipantAlarmDate(
      alarm,
      alarm.order
    )
    const alarmDate = getPrevDateForAlarm(
      participantAlarmDate,
      alarm.endDate,
      alarm.repeatType,
      alarm.repeat,
      alarm.creatorTimezone
    )

    // Use effective alarm date here
    const notificationInfo = getParticipantAlarmNotificationInfo(
      alarm,
      alarmDate,
      creatorName
    )

    const snoozeAlarmDate =
      DateTimeUtils.shaveSecondsFromDate(snoozeDate) + snoozeInterval

    AlarmManager.scheduleSnoozeNotification(
      snoozeAlarmDate,
      alarmAlert,
      notificationInfo
    )

    if (persistOnFirebase) {
      const firebaseUpdateObj = createFirebaseObjForSnoozingParticipantAlarm(
        alarm,
        snoozeInterval,
        snoozeDate
      )
      ref.update(firebaseUpdateObj)
    }
  }

  const updateSnoozeStatusForParticipantAlarm = (
    alarmId,
    snoozeDate,
    snoozeDuration
  ) => {
    if (GlobalConfig.uid) {
      retrieveAlarmByAlarmId(alarmId)
        .then(alarm => {
          if (alarm === null) {
            LogUtils.logError(
              new Error(
                'Unable to store last snooze time because alarm is null ' +
                  alarmId
              )
            )
            return
          }

          updateSnoozeStatusForParticipantAlarmCore(
            alarm,
            snoozeDate,
            snoozeDuration
          )
        })
        .catch(error => {
          LogUtils.logError(error, 'Unable to store last snooze time')
        })
    } else {
      LogUtils.logError(
        new Error('GlobalConfig.uid is null'),
        'Unable to snooze participant alarm',
        { alarmId: alarmId }
      )
    }
  }

  const updateSnoozeStatusForParticipantAlarmCore = (
    alarm,
    snoozeDate,
    snoozeDuration = GlobalConfig.defaultSnoozeInterval
  ) => {
    let firebaseUpdateObj = createFirebaseObjForSnoozingParticipantAlarm(
      alarm,
      snoozeDuration,
      snoozeDate
    )
    ref.update(firebaseUpdateObj)
  }

  const updateSnoozeStatusForCreatorAlarm = (
    alarmId,
    snoozeDate,
    snoozeDuration = GlobalConfig.defaultSnoozeInterval
  ) => {
    const firebaseUpdateObj = createFirebaseObjForSnoozingCreatorAlarm(
      alarmId,
      snoozeDuration,
      snoozeDate
    )
    ref.update(firebaseUpdateObj)
  }

  const stopSnoozeForCreatorAlarm = (alarmId, persistOnFirebase = true) => {
    AlarmManager.cancelSnoozeNotification(alarmId)
    if (persistOnFirebase) {
      const firebaseUpdateObj =
        createFirebaseObjForStoppingSnoozeForCreatorAlarm(alarmId)
      ref.update(firebaseUpdateObj)
    }
  }

  const stopSnoozeForParticipantAlarm = (alarm, persistOnFirebase = true) => {
    AlarmManager.cancelSnoozeNotification(alarm.id)
    if (persistOnFirebase) {
      const firebaseUpdateObj =
        createFirebaseObjForStoppingSnoozeForParticipantAlarm(alarm)
      ref.update(firebaseUpdateObj)
    }
  }

  const createFirebaseObjForSnoozingCreatorAlarm = (
    alarmId,
    snoozeInterval,
    snoozeDate
  ) => {
    let firebaseUpdateObj = {}
    const currDate = Date.now()
    firebaseUpdateObj['alarms/' + alarmId + '/lastSnoozeByCreatorOn'] =
      snoozeDate
    firebaseUpdateObj['alarms/' + alarmId + '/lastSnoozeByCreatorFor'] =
      snoozeInterval
    firebaseUpdateObj['alarms/' + alarmId + '/lastUpdatedAt'] = currDate
    return firebaseUpdateObj
  }

  const createFirebaseObjForStoppingSnoozeForCreatorAlarm = alarmId => {
    let firebaseUpdateObj = {}
    firebaseUpdateObj['alarms/' + alarmId + '/lastSnoozeByCreatorOn'] = null
    firebaseUpdateObj['alarms/' + alarmId + '/lastSnoozeByCreatorFor'] = null
    firebaseUpdateObj['alarms/' + alarmId + '/lastUpdatedAt'] = Date.now()
    return firebaseUpdateObj
  }

  const createFirebaseObjForSnoozingParticipantAlarm = (
    alarm,
    snoozeInterval,
    snoozeDate
  ) => {
    let firebaseUpdateObj = {}
    if (alarm.backupGroup) {
      const groupId = alarm.backupGroup.id
      firebaseUpdateObj[
        'alarms/' +
          alarm.id +
          '/backups/group/' +
          groupId +
          '/members/' +
          GlobalConfig.uid +
          '/lastSnoozeOn'
      ] = snoozeDate
      firebaseUpdateObj[
        'alarms/' +
          alarm.id +
          '/backups/group/' +
          groupId +
          '/members/' +
          GlobalConfig.uid +
          '/lastSnoozeFor'
      ] = snoozeInterval
    } else if (alarm.backupContacts && alarm.backupContacts.length > 0) {
      firebaseUpdateObj[
        'alarms/' +
          alarm.id +
          '/backups/contacts/' +
          GlobalConfig.uid +
          '/lastSnoozeOn'
      ] = snoozeDate
      firebaseUpdateObj[
        'alarms/' +
          alarm.id +
          '/backups/contacts/' +
          GlobalConfig.uid +
          '/lastSnoozeFor'
      ] = snoozeInterval
    } else if (alarm.recipient) {
      firebaseUpdateObj['alarms/' + alarm.id + '/recipient/lastSnoozeOn'] =
        snoozeDate
      firebaseUpdateObj['alarms/' + alarm.id + '/recipient/lastSnoozeFor'] =
        snoozeInterval
    }
    firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = Date.now()

    return firebaseUpdateObj
  }

  const createFirebaseObjForStoppingSnoozeForParticipantAlarm = alarm => {
    let firebaseUpdateObj = {}
    if (alarm.backupGroup) {
      const groupId = alarm.backupGroup.id
      firebaseUpdateObj[
        'alarms/' +
          alarm.id +
          '/backups/group/' +
          groupId +
          '/members/' +
          GlobalConfig.uid +
          '/lastSnoozeOn'
      ] = null
      firebaseUpdateObj[
        'alarms/' +
          alarm.id +
          '/backups/group/' +
          groupId +
          '/members/' +
          GlobalConfig.uid +
          '/lastSnoozeFor'
      ] = null
    } else if (alarm.backupContacts && alarm.backupContacts.length > 0) {
      firebaseUpdateObj[
        'alarms/' +
          alarm.id +
          '/backups/contacts/' +
          GlobalConfig.uid +
          '/lastSnoozeOn'
      ] = null
      firebaseUpdateObj[
        'alarms/' +
          alarm.id +
          '/backups/contacts/' +
          GlobalConfig.uid +
          '/lastSnoozeFor'
      ] = null
    } else if (alarm.recipient) {
      firebaseUpdateObj['alarms/' + alarm.id + '/recipient/lastSnoozeOn'] = null
      firebaseUpdateObj['alarms/' + alarm.id + '/recipient/lastSnoozeFor'] =
        null
    }
    firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = Date.now()

    return firebaseUpdateObj
  }

  const getAlarmFromStore = alarmId => {
    if (!GlobalConfig.store) {
      LogUtils.logError(
        'GlobalConfig.store not initialized while finding the alarm ' + alarmId
      )
      return undefined
    }

    const state = GlobalConfig.store.getState()
    const alarmSelector = makeAlarmSelector()
    const alarm = alarmSelector(state, { alarmId })

    if (isEmpty(alarm)) {
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]: 'getAlarmFromStore'
      })
      return undefined
    }

    return alarm
  }

  const hasAlarmExpired = alarmId => {
    const alarm = getAlarmFromStore(alarmId)

    if (!alarm) {
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]: 'hasAlarmExpired'
      })
      return false
    }

    const prevDate = getPrevDateForAlarm(
      alarm.date,
      alarm.endDate,
      alarm.repeatType,
      alarm.repeat,
      alarm.creatorTimezone
    )

    if (prevDate && alarm.status === true) {
      const prevOccurrenceAcknowledgement =
        getAlarmAcknowledgementStatusForOccurrenceForAlarm(alarm, prevDate)
      return !prevOccurrenceAcknowledgement
    }
    return false
  }

  const isLastSnoozeTimeInSnoozeDurationForMyAlarm = alarm => {
    const currDate = Date.now()
    return (
      alarm.lastSnoozeByCreatorOn &&
      alarm.lastSnoozeByCreatorFor &&
      currDate - alarm.lastSnoozeByCreatorOn < alarm.lastSnoozeByCreatorFor
    )
  }

  const isLastSnoozeTimeInSnoozeDurationForParticipantAlarm = alarm => {
    const participants = getAlarmParticipants(
      alarm.type,
      alarm.backupGroup,
      alarm.backupContacts,
      alarm.recipient
    )
    const participant = Utils.getObjectWithId(participants, GlobalConfig.uid)

    if (
      !participant ||
      !participant.lastSnoozeOn ||
      !participant.lastSnoozeFor
    ) {
      return false
    }
    const currDate = Date.now()
    return currDate - participant.lastSnoozeOn < participant.lastSnoozeFor
  }

  const hasParticipantAlarmExpired = alarmId => {
    const alarm = getAlarmFromStore(alarmId)

    if (!alarm) {
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]:
          'hasParticipantAlarmExpired'
      })
      return false
    }

    const backupAlarmDate = DateTimeUtils.getParticipantAlarmDate(
      alarm,
      alarm.order
    )

    const prevDate = getPrevDateForAlarm(
      backupAlarmDate,
      alarm.endDate,
      alarm.repeatType,
      alarm.repeat,
      alarm.creatorTimezone
    )

    let prevAlarmDate = prevDate

    // If it is a recipient alarm, then add the recipient interval to get the occurrence time
    // Add cascading interval for personal alarm
    if (alarm.type === Constants.AlarmTypes.RECIPIENT) {
      prevAlarmDate =
        prevDate +
        Constants.RecipientAlarmIntervals[alarm.recipientAlarmInterval].value
    } else if (alarm.type === Constants.AlarmTypes.CASCADING) {
      prevAlarmDate =
        prevDate -
        Constants.CascadingAlarmIntervals[alarm.cascadingAlarmInterval].value
    }
    if (prevDate && alarm.status === true) {
      const prevOccurrenceAcknowledgement =
        getAlarmAcknowledgementStatusForOccurrenceForAlarm(alarm, prevAlarmDate)
      return !prevOccurrenceAcknowledgement
    }
    return false
  }

  const isCurrentAlarmOccurrenceAcknowledged = alarm => {
    const currAlarmDate = getCurrentDateForAlarm(alarm)

    let uid

    switch (alarm.type) {
      case Constants.AlarmTypes.CASCADING:
        uid = alarm.creator
        break
      case Constants.AlarmTypes.SIMULTANEOUS:
        uid = GlobalConfig.uid
        break
      case Constants.AlarmTypes.RECIPIENT:
        uid = alarm.recipient.id
        break
      default:
        LogUtils.logError(
          'Unknown alarm type while finding the acknowledgement status of an alarm ' +
            alarm.type
        )
        return false
    }

    const alarmAcknowledgementStatus =
      getUserAlarmAcknowledgementStatusForOccurrenceForAlarm(
        alarm,
        currAlarmDate,
        uid
      )
    return !!alarmAcknowledgementStatus
  }

  const getAlarmAcknowledgementStatusForOccurrence = (
    alarmId,
    occurrenceTime
  ) => {
    const alarm = getAlarmFromStore(alarmId)

    if (!alarm) {
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]:
          'getAlarmAcknowledgementStatusForOccurrence'
      })
      return undefined
    }

    return getAlarmAcknowledgementStatusForOccurrenceForAlarm(
      alarm,
      occurrenceTime
    )
  }

  const getAlarmAcknowledgementStatusForOccurrenceForAlarm = (
    alarm,
    occurrenceTime
  ) => {
    if (!GlobalConfig.uid) {
      LogUtils.logError(
        'GlobalConfig.uid not initialized while finding the acknowledgement status of an alarm'
      )
      return undefined
    }

    let uid

    switch (alarm.type) {
      case Constants.AlarmTypes.CASCADING:
        uid = alarm.creator
        break
      case Constants.AlarmTypes.SIMULTANEOUS:
        uid = GlobalConfig.uid
        break
      case Constants.AlarmTypes.RECIPIENT:
        uid = alarm.recipient.id
        break
      default:
        LogUtils.logError(
          'Unknown alarm type while finding the acknowledgement status of an alarm ' +
            alarm.type
        )
        return undefined
    }

    let alarmAcknowledgement =
      getUserAlarmAcknowledgementStatusForOccurrenceForAlarm(
        alarm,
        occurrenceTime,
        uid
      )

    if (!alarmAcknowledgement && GlobalConfig.uid !== uid) {
      alarmAcknowledgement =
        getUserAlarmAcknowledgementStatusForOccurrenceForAlarm(
          alarm,
          occurrenceTime,
          GlobalConfig.uid
        )
    }

    if (!alarmAcknowledgement) {
      return alarmAcknowledgement
    }

    switch (alarm.type) {
      case Constants.AlarmTypes.CASCADING:
        return alarmAcknowledgement.response ===
          Constants.PERSONAL_ALARM_DONE ||
          alarmAcknowledgement.response === Constants.PERSONAL_ALARM_SKIP ||
          alarmAcknowledgement.response ===
            Constants.PERSONAL_PARTICIPANT_ALARM_SKIP
          ? alarmAcknowledgement
          : undefined
      case Constants.AlarmTypes.RECIPIENT:
        return alarmAcknowledgement.response ===
          Constants.RECIPIENT_ALARM_DONE ||
          alarmAcknowledgement.response === Constants.RECIPIENT_ALARM_SKIP ||
          alarmAcknowledgement.response ===
            Constants.RECIPIENT_CREATOR_ALARM_SKIP
          ? alarmAcknowledgement
          : undefined
      default:
        return alarmAcknowledgement
    }
  }

  const getUserAlarmAcknowledgementStatusForOccurrence = (
    alarmId,
    occurrenceTime,
    uid
  ) => {
    const alarm = getAlarmFromStore(alarmId)

    if (!alarm) {
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]:
          'getUserAlarmAcknowledgementStatusForOccurrence'
      })
      return undefined
    }

    return getUserAlarmAcknowledgementStatusForOccurrenceForAlarm(
      alarm,
      occurrenceTime,
      uid
    )
  }

  const getAlarmAcknowledgementsForOccurrenceForAlarm = (
    alarm,
    occurrenceTime
  ) => {
    if (!GlobalConfig.store) {
      LogUtils.logError(
        'GlobalConfig.store not initialized while finding the acknowledgements of an alarm'
      )
      return undefined
    }

    const state = GlobalConfig.store.getState()
    const occurrenceTimeString = getOccurrenceTimeString(
      occurrenceTime,
      alarm.creatorTimezoneOffset,
      alarm.creatorTimezone
    )

    const alarmAcknowledgements = objGet(
      state,
      `alarmActions.alarmAcknowledgements[${alarm.id}][${occurrenceTimeString}]`,
      []
    )

    return alarmAcknowledgements
  }

  const getUserAlarmAcknowledgementStatusForOccurrenceForAlarm = (
    alarm,
    occurrenceTime,
    uid
  ) => {
    if (!GlobalConfig.store) {
      LogUtils.logError(
        'GlobalConfig.store not initialized while finding the acknowledgement status of an alarm'
      )
      return undefined
    }

    const state = GlobalConfig.store.getState()
    const occurrenceTimeString = getOccurrenceTimeString(
      occurrenceTime,
      alarm.creatorTimezoneOffset,
      alarm.creatorTimezone
    )

    // console.tron.log(
    //   'Finding alarm acknowledgement for ' +
    //     occurrenceTimeString +
    //     ', alarm name: ' +
    //     alarm.name
    // )

    const alarmAcknowledgements = objGet(
      state,
      `alarmActions.alarmAcknowledgements[${alarm.id}][${occurrenceTimeString}][${uid}]`,
      []
    )

    if (alarmAcknowledgements.length === 0) {
      return undefined
    }

    const alarmAcknowledgement = alarmAcknowledgements.reduce((prev, current) =>
      prev.timestamp > current.timestamp ? prev : current
    )

    return alarmAcknowledgement
  }

  const getAlarmStatusForOccurrence = (alarmId, occurrenceTime) => {
    if (!GlobalConfig.store) {
      LogUtils.logError(
        'GlobalConfig.store not initialized while finding the status of an alarm'
      )
      return undefined
    }

    const state = GlobalConfig.store.getState()
    const alarmStatusChanges = objGet(
      state,
      `alarmActions.alarmStatusChanges[${alarmId}]`,
      []
    )
    const sortedAlarmStatusChanges = alarmStatusChanges.sort(
      (alarmStatusChange1, alarmStatusChange2) =>
        alarmStatusChange1.timestamp - alarmStatusChange2.timestamp
    )

    let found = false
    let index = 0
    let status = true
    while (!found) {
      const alarmStatusChange = sortedAlarmStatusChanges[index]
      if (!alarmStatusChange || alarmStatusChange.timestamp > occurrenceTime) {
        found = true
      } else {
        status = alarmStatusChange.status
        index++
      }
    }
    return status
  }

  const configureWelcomeAlarms = (alarms, username, mobileNumber) => {
    let welcomeAlarms = []

    alarms.forEach(alarm => {
      let alarmDate = getNextDateForAlarm(
        alarm.date,
        GlobalConfig.defaultAlarmEndDate,
        alarm.repeatType,
        alarm.repeat,
        RNLocalize.getTimeZone()
      )
      const dateString = DateTimeUtils.getFormattedDateAsString(alarmDate)
      const creatorTimezoneOffset = DateTimeUtils.getTimezoneOffsetForTimezone(
        RNLocalize.getTimeZone()
      )
      const creatorTimezone = RNLocalize.getTimeZone()
      const currDate = Date.now()

      const welcomeAlarm = {
        alarm: {
          id: ref.push().key,
          name: alarm.name,
          date: alarmDate,
          firstAlarmDate: alarmDate,
          backupGroup: null,
          backupContacts: [],
          notes: alarm.notes || '',
          status: true,
          repeatType: alarm.repeatType,
          repeat: alarm.repeat,
          type: Constants.AlarmTypes.CASCADING,
          cascadingAlarmInterval: GlobalConfig.defaultCascadingAlarmInterval,
          creator: GlobalConfig.uid,
          creatorName: username,
          creatorMobileNumber: mobileNumber,
          lastUpdatedAt: currDate,
          recipient: null,
          creatorTimezoneOffset: creatorTimezoneOffset,
          timezoneSetting: Constants.TIMEZONE_SETTINGS.DEVICE,
          preReminderDuration: GlobalConfig.defaultPreReminderDuration,
          creatorTimezone: creatorTimezone,
          dateString: dateString,
          historyStartDate: currDate,
          ringerSettings: getDefaultRingerSettings(),
          endDate: GlobalConfig.defaultAlarmEndDate,
          release: GlobalConfig.release,
          source: Constants.AlarmCreationSources.CREATED_MANUALLY
        },
        selected: alarm.selected
      }
      welcomeAlarms.push(welcomeAlarm)
    })
    return welcomeAlarms
  }

  const getWelcomeAlarmsFromClient = (uid, username, mobileNumber) => {
    let alarms = [
      {
        name: I18n.t('wakeupAlarmName'),
        date: moment()
          .set({ hour: 7, minute: 0, second: 0, millisecond: 0 })
          .valueOf(),
        repeatType: 'daysOfWeek',
        repeat: 'every_mon,every_tue,every_wed,every_thu,every_fri',
        selected: true
      },
      {
        name: I18n.t('exerciseAlarmName'),
        date: moment()
          .set({ hour: 18, minute: 0, second: 0, millisecond: 0 })
          .valueOf(),
        repeatType: 'daysOfWeek',
        repeat:
          'every_sun,every_mon,every_tue,every_wed,every_thu,every_fri,every_sat',
        selected: false
      },
      {
        name: I18n.t('drinkWaterAlarmName'),
        date: moment()
          .set({ hour: 9, minute: 0, second: 0, millisecond: 0 })
          .valueOf(),
        repeatType: 'everyNHours',
        repeat:
          '9:0:19:0:2:every_sun,every_mon,every_tue,every_wed,every_thu,every_fri,every_sat',
        selected: true
      },
      {
        name: I18n.t('payUtilityBills'),
        date: getNextDateForDayOfWeekInMonthRepeatOption(
          1,
          moment().day('Sunday').day(),
          moment()
            .set({ hour: 11, minute: 0, second: 0, millisecond: 0 })
            .subtract(1, 'day')
            .valueOf(),
          RNLocalize.getTimeZone()
        ),
        repeatType: 'months',
        repeat: 'dayOfWeekInMonth:1:sunday',
        selected: false
      }
    ]

    let welcomeAlarms = configureWelcomeAlarms(alarms, username, mobileNumber)
    return welcomeAlarms
  }

  const getWelcomeAlarms = (
    isConnected,
    isAuthenticated,
    dispatch,
    uid,
    username,
    mobileNumber
  ) => {
    return new Promise(function (resolve) {
      StorageManager.retrieveData(['welcomeAlarms']).then(data => {
        if (data.welcomeAlarms) {
          let welcomeAlarms = configureWelcomeAlarms(
            JSON.parse(data.welcomeAlarms),
            username,
            mobileNumber
          )
          resolve(welcomeAlarms)
        } else {
          // If we don't have alarms in the storage manager, this means there was no connectivity issue
          // while fetching alarms, in that rare case fetch alarms stored locally on client side
          resolve(getWelcomeAlarmsFromClient(uid, username, mobileNumber))
        }
      })
    })
  }

  const addDayToWeeklyRepetitionString = repetitionString => {
    const newRepeatArr = repetitionString
      .split(',')
      .map(repetition => addDayToRepetition(repetition))
    const sortedRepeatArr = []
    Object.keys(Constants.RepeatDayToDayMapping).forEach(repeatDay => {
      if (
        newRepeatArr.findIndex(repetition => repetition === repeatDay) !== -1
      ) {
        sortedRepeatArr.push(repeatDay)
      }
    })
    return sortedRepeatArr.join(',')
  }

  const subtractDayFromWeeklyRepetitionString = repetitionString => {
    const newRepeatArr = repetitionString
      .split(',')
      .map(repetition => subtractDayFromRepetition(repetition))
    const sortedRepeatArr = []
    Object.keys(Constants.RepeatDayToDayMapping).forEach(repeatDay => {
      if (
        newRepeatArr.findIndex(repetition => repetition === repeatDay) !== -1
      ) {
        sortedRepeatArr.push(repeatDay)
      }
    })
    return sortedRepeatArr.join(',')
  }

  const addDayToHourlyRepetitionString = repetitionString => {
    const { startHours, startMins, endHours, endMins, hours, selectedDays } =
      getRepeatOptionsForHourlyRepetition(repetitionString)
    const newSelectedDaysString = addDayToWeeklyRepetitionString(
      selectedDays.join(',')
    )
    const newRepeat = `${startHours}:${startMins}:${endHours}:${endMins}:${hours}:${newSelectedDaysString}`
    return newRepeat
  }

  const subtractDayFromHourlyRepetitionString = repetitionString => {
    const { startHours, startMins, endHours, endMins, hours, selectedDays } =
      getRepeatOptionsForHourlyRepetition(repetitionString)
    const newSelectedDaysString = subtractDayFromWeeklyRepetitionString(
      selectedDays.join(',')
    )
    const newRepeat = `${startHours}:${startMins}:${endHours}:${endMins}:${hours}:${newSelectedDaysString}`
    return newRepeat
  }

  const addDayToRepetition = repetition => {
    switch (repetition) {
      case 'every_sun':
        return 'every_mon'
      case 'every_mon':
        return 'every_tue'
      case 'every_tue':
        return 'every_wed'
      case 'every_wed':
        return 'every_thu'
      case 'every_thu':
        return 'every_fri'
      case 'every_fri':
        return 'every_sat'
      case 'every_sat':
        return 'every_sun'
      default:
        return repetition
    }
  }

  const subtractDayFromRepetition = repetition => {
    switch (repetition) {
      case 'every_sun':
        return 'every_sat'
      case 'every_mon':
        return 'every_sun'
      case 'every_tue':
        return 'every_mon'
      case 'every_wed':
        return 'every_tue'
      case 'every_thu':
        return 'every_wed'
      case 'every_fri':
        return 'every_thu'
      case 'every_sat':
        return 'every_fri'
      default:
        return repetition
    }
  }

  const resolveAlarmCreatorName = (
    contacts,
    creatorId,
    creatorMobileNumber,
    creatorName
  ) => {
    const contact = Utils.getObjectWithId(contacts, creatorId) || {}
    return contact.name || creatorMobileNumber || creatorName
  }

  const updateAlarm = (alarm, update) => {
    return {
      ...alarm,
      ...update,
      lastUpdatedAt: Date.now()
    }
  }

  // Copied to backupAlarms. Keep in sync
  const updateAlarmParticipant = (alarm, participantId, update) => {
    if (
      alarm.backupGroup &&
      Utils.getObjectWithId(alarm.backupGroup.members, participantId)
    ) {
      const newMembers = alarm.backupGroup.members.slice()
      const member = Utils.getObjectWithId(newMembers, participantId)
      const memberIndex = Utils.getIndexOfObjectWithId(
        newMembers,
        participantId
      )
      const newMember = {
        ...member,
        ...update
      }
      newMembers.splice(memberIndex, 1, newMember)
      return {
        ...alarm,
        backupGroup: {
          ...alarm.backupGroup,
          members: newMembers
        },
        lastUpdatedAt: Date.now()
      }
    } else if (Utils.getObjectWithId(alarm.backupContacts, participantId)) {
      const newBackupContacts = alarm.backupContacts.slice()
      const backupContact = Utils.getObjectWithId(
        newBackupContacts,
        participantId
      )
      const backupContactIndex = Utils.getIndexOfObjectWithId(
        newBackupContacts,
        participantId
      )
      const newBackupContact = {
        ...backupContact,
        ...update
      }
      newBackupContacts.splice(backupContactIndex, 1, newBackupContact)
      return {
        ...alarm,
        backupContacts: newBackupContacts,
        lastUpdatedAt: Date.now()
      }
    } else if (alarm.recipient && alarm.recipient.id === participantId) {
      return {
        ...alarm,
        recipient: {
          ...alarm.recipient,
          ...update
        },
        lastUpdatedAt: Date.now()
      }
    } else {
      LogUtils.logError(
        new Error('Participant not found while updating alarm participant')
      )
      return {
        ...alarm
      }
    }
  }

  const getDefaultAlarmName = alarmType => {
    switch (alarmType) {
      case Constants.AlarmTypes.CASCADING:
        return I18n.t('defaultPersonalAlarmName')
      case Constants.AlarmTypes.SIMULTANEOUS:
        return I18n.t('defaultGroupAlarmName')
      case Constants.AlarmTypes.RECIPIENT:
        return I18n.t('defaultRecipientAlarmName')
    }
  }

  const getAlarmParticipants = (
    alarmType,
    backupGroup,
    backupContacts,
    recipient
  ) => {
    if (alarmType !== Constants.AlarmTypes.RECIPIENT) {
      const participants = backupGroup ? backupGroup.members : backupContacts
      return participants || []
    } else if (recipient) {
      return [recipient]
    } else {
      return []
    }
  }

  const getLastAlarmParticipantDate = alarm => {
    const participants = getAlarmParticipants(
      alarm.type,
      alarm.backupGroup,
      alarm.backupContacts,
      alarm.recipient
    )
    const participantOrder = participants.length
    const lastParticipantDate = DateTimeUtils.getParticipantAlarmDate(
      alarm,
      participantOrder
    )
    return lastParticipantDate
  }

  const triggerInstantNotificationForParticipantAlarm = alarm => {
    if (GlobalConfig.uid) {
      const backupAlarmDate = DateTimeUtils.getParticipantAlarmDate(
        alarm,
        alarm.order
      )
      const alarmAlert = getParticipantAlarmNotificationAlertMessage(
        alarm.type,
        alarm.name,
        alarm.creationMode,
        alarm.creatorName
      )
      const notificationInfo = getParticipantAlarmNotificationInfo(
        alarm,
        backupAlarmDate,
        alarm.creatorName
      )

      AlarmManager.ringInstantAlarm(
        backupAlarmDate,
        alarm.endDate,
        alarm.repeatType,
        alarm.repeat,
        alarm.creatorTimezone,
        alarmAlert,
        notificationInfo
      )
    } else {
      LogUtils.logError(
        new Error('GlobalConfig.uid is null'),
        'Unable to trigger instant notifications for participant',
        { alarmId: alarm.id }
      )
    }
  }

  const scheduleNotificationForParticipantAlarm = alarm => {
    if (GlobalConfig.uid) {
      const backupAlarmDate = DateTimeUtils.getParticipantAlarmDate(
        alarm,
        alarm.order
      )
      const alarmAlert = getParticipantAlarmNotificationAlertMessage(
        alarm.type,
        alarm.name,
        alarm.creationMode,
        alarm.creatorName
      )
      if (backupAlarmDate > Date.now() || alarm.repeatType !== '') {
        const alarmDate = getNextDateForAlarm(
          backupAlarmDate,
          alarm.endDate,
          alarm.repeatType,
          alarm.repeat,
          alarm.creatorTimezone
        )
        const notificationInfo = getParticipantAlarmNotificationInfo(
          alarm,
          alarmDate,
          alarm.creatorName
        )
        AlarmManager.scheduleNotification(
          backupAlarmDate,
          alarm.endDate,
          alarm.repeatType,
          alarm.repeat,
          alarm.creatorTimezone,
          alarmAlert,
          notificationInfo,
          true,
          false
        )
      }
      // We don't check for snoozing alarms here. Use the action creator for that
    } else {
      LogUtils.logError(
        new Error('GlobalConfig.uid is null'),
        'Unable to schedule notifications for participant alarm',
        { alarmId: alarm.id }
      )
    }
  }

  const scheduleNotificationForOwnAlarm = alarm => {
    const myAlarmDate = DateTimeUtils.getMyAlarmDate(alarm.date)
    const alarmAlert = getMyAlarmNotificationAlertMessage(
      alarm.type,
      alarm.name,
      objGet(alarm, 'recipient.name', '')
    )
    if (myAlarmDate > Date.now() || alarm.repeatType !== '') {
      const alarmDate = getNextDateForAlarm(
        myAlarmDate,
        alarm.endDate,
        alarm.repeatType,
        alarm.repeat,
        alarm.creatorTimezone
      )
      const notificationInfo = getMyAlarmNotificationInfo(alarm, alarmDate)
      AlarmManager.scheduleNotification(
        alarmDate,
        alarm.endDate,
        alarm.repeatType,
        alarm.repeat,
        alarm.creatorTimezone,
        alarmAlert,
        notificationInfo,
        true,
        false
      )
    }
    // We don't check for snoozing alarms here. Use the action creator for that
  }

  const dismissOwnAlarm = alarmId => {
    return retrieveAlarmByAlarmId(alarmId)
      .then(alarm => {
        if (alarm === null) {
          LogUtils.logError(
            new Error(
              'Unable to dismiss own alarm because alarm is null ' + alarmId
            )
          )
          return
        }
        if (alarm.repeatType === '') {
          AlarmManager.cancelNotifications(alarm.id)
        } else {
          scheduleNotificationForOwnAlarm(alarm)
        }
      })
      .catch(error => {
        LogUtils.logError(error, 'Unable to dismiss own alarm')
      })
  }

  const dismissParticipantAlarm = alarmId => {
    return retrieveAlarmByAlarmId(alarmId)
      .then(alarm => {
        if (alarm === null) {
          LogUtils.logError(
            new Error(
              'Unable to dismiss participant alarm because alarm is null ' +
                alarmId
            )
          )
          return
        }
        if (alarm.repeatType === '') {
          AlarmManager.cancelNotifications(alarm.id)
        } else {
          scheduleNotificationForParticipantAlarm(alarm)
        }
      })
      .catch(error => {
        LogUtils.logError(error, 'Unable to dismiss participant alarm')
      })
  }

  const setPastAlarmOccurrenceResponse = (
    alarm,
    occurrenceTime,
    timestamp,
    response
  ) => {
    return setPastAlarmOccurrenceResponseForUser(
      alarm,
      occurrenceTime,
      timestamp,
      response,
      GlobalConfig.uid
    )
  }

  const setPastAlarmOccurrenceResponseForUser = (
    alarm,
    occurrenceTime,
    timestamp,
    response,
    uid
  ) => {
    let firebaseUpdateObj = {}
    const responseId = ref.push().key
    const occurrenceTimeString = getOccurrenceTimeString(
      occurrenceTime,
      alarm.creatorTimezoneOffset,
      alarm.creatorTimezone
    )
    firebaseUpdateObj[
      'alarmActions/' +
        alarm.id +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        uid +
        '/' +
        responseId +
        '/timestamp'
    ] = timestamp
    firebaseUpdateObj[
      'alarmActions/' +
        alarm.id +
        '/alarmAcknowledgements/' +
        occurrenceTimeString +
        '/' +
        uid +
        '/' +
        responseId +
        '/response'
    ] = response

    return ref.update(firebaseUpdateObj)
  }

  const getOccurrenceTimeStringCore = (
    occurrenceTime,
    creatorTimezoneOffset,
    creatorTimezone
  ) => {
    if (!creatorTimezone) {
      const userTimezoneOffset = DateTimeUtils.getTimezoneOffsetForTimezone(
        RNLocalize.getTimeZone()
      )
      const occurrenceTimeString = DateTimeUtils.getFormattedDateAsString(
        occurrenceTime -
          creatorTimezoneOffset * 60 * 1000 +
          userTimezoneOffset * 60 * 1000
      )
      return occurrenceTimeString
    } else {
      const occurrenceTimeString =
        DateTimeUtils.getFormattedDateAsStringInTimezone(
          occurrenceTime,
          creatorTimezone
        )
      return occurrenceTimeString
    }
  }

  const memoizedGetOccurrenceTimeString = memoize(getOccurrenceTimeStringCore)

  // Defaulting the timezone offsets to 0 in case they are not present
  const getOccurrenceTimeString = (
    occurrenceTime,
    creatorTimezoneOffset = 0,
    creatorTimezone
  ) => {
    return memoizedGetOccurrenceTimeString(
      occurrenceTime,
      creatorTimezoneOffset,
      creatorTimezone
    )
  }

  const computeLoadAlarmPreviousOccurrencesBucketDurationFromRepetition =
    alarm => {
      const msecsInADay = 24 * 60 * 60 * 1000
      switch (alarm.repeatType) {
        case Constants.RepeatTypes.EVERY_N_HOURS:
        case Constants.RepeatTypes.HOURS_AND_MINUTES:
          return 7 * msecsInADay
        case Constants.RepeatTypes.DAYS:
        case Constants.RepeatTypes.ODD_NUMBERED_DAYS:
        case Constants.RepeatTypes.EVEN_NUMBERED_DAYS:
        case Constants.RepeatTypes.DAYS_OF_WEEK:
          return 30 * msecsInADay
        case Constants.RepeatTypes.WEEKS:
        case Constants.RepeatTypes.MONTHLY:
        case Constants.RepeatTypes.MONTHS:
          return 366 * msecsInADay // in case it is a leap year
        case Constants.RepeatTypes.YEARS:
        case Constants.RepeatTypes.YEARLY:
          return 5 * 365 * msecsInADay + msecsInADay
        default:
          return 30 * msecsInADay
      }
    }

  const createPreviewDataForHourlyRepetition = (
    startTime,
    endTime,
    hours,
    selectedDays
  ) => {
    let alarmTimesInDay = []
    let currTime = startTime
    while (currTime <= endTime) {
      alarmTimesInDay.push(DateTimeUtils.getTimeAsString(currTime))
      currTime = moment(currTime).add(hours, 'hours')
    }
    const previewString = alarmTimesInDay.join(', ')
    const sortedSelectedDays = []
    Object.keys(Constants.RepeatDayToDayMapping).forEach(repeatDay => {
      if (
        selectedDays.findIndex(selectedDay => selectedDay === repeatDay) !== -1
      ) {
        sortedSelectedDays.push(repeatDay)
      }
    })
    const daysString = createSelectedDaysString(sortedSelectedDays)
    return {
      previewString,
      count: alarmTimesInDay.length,
      alarmTimesInDay,
      daysString
    }
  }

  const createPreviewDataForHoursAndMinutesRepetition = (
    startTime,
    endTime,
    hours,
    minutes,
    selectedDays
  ) => {
    let alarmTimesInDay = []
    let currTime = startTime
    while (currTime <= endTime) {
      alarmTimesInDay.push(DateTimeUtils.getTimeAsString(currTime))
      currTime = moment(currTime).add(hours, 'hours').add(minutes, 'minutes')
    }
    const previewString = alarmTimesInDay.join(', ')
    const sortedSelectedDays = []
    Object.keys(Constants.RepeatDayToDayMapping).forEach(repeatDay => {
      if (
        selectedDays.findIndex(selectedDay => selectedDay === repeatDay) !== -1
      ) {
        sortedSelectedDays.push(repeatDay)
      }
    })
    const daysString = createSelectedDaysString(sortedSelectedDays)
    return {
      previewString,
      count: alarmTimesInDay.length,
      alarmTimesInDay,
      daysString
    }
  }

  const createSelectedDaysString = selectedDays => {
    if (selectedDays.length === 7) {
      return I18n.t('everyDaySmall')
    } else if (selectedDays.length === 1) {
      return I18n.t('everyGivenDay', {
        day: Constants.RepeatDayToDayMappingI18n[selectedDays[0]]
      })
    } else {
      const days = selectedDays
        .map(day => {
          return Constants.RepeatDayToShortDayMappingI18n[day]
        })
        .join(', ')
      return I18n.t('everyGivenDays', { days: days })
    }
  }

  const getLastOccurrenceOfHourlyAlarm = repeat => {
    const { startHours, startMins, endHours, endMins, hours } =
      getRepeatOptionsForHourlyRepetition(repeat)
    let startTime = moment().hours(startHours).minutes(startMins).valueOf()
    const endTime = moment().hours(endHours).minutes(endMins).valueOf()
    let lastOccurrenceTime = startTime
    while (startTime <= endTime) {
      lastOccurrenceTime = startTime
      startTime = moment(startTime).add(hours, 'hours').valueOf()
    }
    return {
      endHours: moment(lastOccurrenceTime).hours(),
      endMins: moment(lastOccurrenceTime).minutes()
    }
  }

  const getLastOccurrenceOfHourAndMinutesAlarm = repeat => {
    const { startHours, startMins, endHours, endMins, minutes } =
      getRepeatOptionsForHoursAndMinutesRepetition(repeat)
    let startTime = moment().hours(startHours).minutes(startMins).valueOf()
    const endTime = moment().hours(endHours).minutes(endMins).valueOf()
    let lastOccurrenceTime = startTime
    while (startTime <= endTime) {
      lastOccurrenceTime = startTime
      startTime = moment(startTime).add(minutes, 'minutes').valueOf()
    }
    return {
      endHours: moment(lastOccurrenceTime).hours(),
      endMins: moment(lastOccurrenceTime).minutes()
    }
  }

  const markAdditionalSetupAlreadyDone = (
    additionalSetupInstructions,
    dispatch
  ) => {
    const newAdditionalSetupInstructions = {
      ...additionalSetupInstructions,
      isAlreadyDone: true
    }

    StorageManager.storeData({
      additionalSetupInstructions: JSON.stringify(
        newAdditionalSetupInstructions
      )
    })

    dispatch(
      ActionCreators.setAdditionalSetupInstructions(
        newAdditionalSetupInstructions
      )
    )
  }

  const getAdditionalSetupInstructionsFromClientSide = () => {
    const model = DeviceInfo.getModel().toLowerCase()
    const apiLevel = Platform.Version

    if (Platform.OS === 'ios') {
      const iosVersion = parseInt(apiLevel)
      const additionalSetupConfigurationsForIos = [
        {
          additionalSetupInstructions: {
            title: I18n.t('setBannerStyle'),
            instructions: I18n.t('iosSetupInstructions15AndBelow'),
            actions: {
              buttons: [
                {
                  buttonName: I18n.t('openSettings')
                }
              ]
            }
          },
          includes: { apiLevel: [11, 12, 13, 14, 15] },
          excludes: {}
        },
        {
          additionalSetupInstructions: {
            title: I18n.t('setBannerStyle'),
            instructions: I18n.t('iosSetupInstructions'),
            actions: {
              buttons: [
                {
                  buttonName: I18n.t('openSettings')
                }
              ]
            }
          },
          includes: {},
          excludes: {}
        }
      ]

      const additionalSetupInstructionsForIos =
        findAdditionalSetupInstructionsAfterApplyingFilters(
          additionalSetupConfigurationsForIos,
          model,
          iosVersion
        )

      return additionalSetupInstructionsForIos
    }

    const deviceManufacturer = GlobalConfig.store
      .getState()
      .userInfo.deviceManufacturer.toLowerCase()

    const additionalSetupConfigurations = [
      {
        deviceManufacturer: 'xiaomi',
        additionalSetupInstructions: {
          title: I18n.t('yourAlarmsMayNotRing'),
          instructions: I18n.t('xiaomiSetupInstructions'),
          actions: {
            buttons: [
              {
                buttonName: I18n.t('enableAutoStart'),
                componentName: {
                  packageName: 'com.miui.securitycenter',
                  className:
                    'com.miui.permcenter.autostart.AutoStartManagementActivity'
                }
              }
            ]
          }
        },
        includes: {},
        excludes: { model: ['mi a1', 'mi a2', 'mi a3'] }
      },
      {
        deviceManufacturer: 'oppo',
        additionalSetupInstructions: {
          title: I18n.t('yourAlarmsMayNotRing'),
          instructions: I18n.t('oppo8AndLowerSetupInstructions'),
          actions: {
            buttons: [
              {
                buttonName: I18n.t('enableAutoStart'),
                actionName: 'android.settings.APPLICATION_DETAILS_SETTINGS'
              }
            ]
          }
        },
        includes: { apiLevel: [21, 22, 23, 24, 25, 26, 27] },
        excludes: {}
      },
      {
        deviceManufacturer: 'oppo',
        additionalSetupInstructions: {
          title: I18n.t('yourAlarmsMayNotRing'),
          instructions: I18n.t('oppoSetupInstructions'),
          actions: {
            buttons: [
              {
                buttonName: I18n.t('enableAutoStart'),
                actionName: 'android.settings.APPLICATION_DETAILS_SETTINGS'
              }
            ]
          }
        },
        includes: {},
        excludes: { apiLevel: [21, 22, 23, 24, 25, 26, 27] }
      },
      {
        deviceManufacturer: 'vivo',
        additionalSetupInstructions: {
          title: I18n.t('yourAlarmsMayNotRing'),
          instructions: I18n.t('vivo8AndLowerSetupInstructions'),
          actions: {
            buttons: [
              {
                buttonName: I18n.t('enableAutoStart'),
                componentName: {
                  packageName: 'com.vivo.permissionmanager',
                  className:
                    'com.vivo.permissionmanager.activity.BgStartUpManagerActivity'
                },
                fallbacks: [
                  {
                    packageName: 'com.iqoo.secure',
                    className:
                      'com.iqoo.secure.ui.phoneoptimize.AddWhiteListActivity'
                  }
                ]
              },
              {
                buttonName: I18n.t('batteryUsage'),
                componentName: {
                  packageName: 'com.iqoo.powersaving',
                  className: 'com.iqoo.powersaving.PowerSavingManagerActivity'
                }
              }
            ]
          }
        },
        includes: { apiLevel: [21, 22, 23, 24, 25, 26, 27] },
        excludes: {}
      },
      {
        deviceManufacturer: 'vivo',
        additionalSetupInstructions: {
          title: I18n.t('yourAlarmsMayNotRing'),
          instructions: I18n.t('vivoSetupInstructions'),
          actions: {
            buttons: [
              {
                buttonName: I18n.t('enableAutoStart'),
                componentName: {
                  packageName: 'com.vivo.permissionmanager',
                  className:
                    'com.vivo.permissionmanager.activity.BgStartUpManagerActivity'
                },
                fallbacks: [
                  {
                    packageName: 'com.iqoo.secure',
                    className:
                      'com.iqoo.secure.ui.phoneoptimize.AddWhiteListActivity'
                  }
                ]
              },
              {
                buttonName: I18n.t('batteryUsage'),
                componentName: {
                  packageName: 'com.iqoo.powersaving',
                  className: 'com.iqoo.powersaving.PowerSavingManagerActivity'
                }
              }
            ]
          }
        },
        includes: {},
        excludes: { apiLevel: [21, 22, 23, 24, 25, 26, 27] }
      },
      {
        deviceManufacturer: 'huawei',
        additionalSetupInstructions: {
          title: I18n.t('yourAlarmsMayNotRing'),
          instructions: I18n.t('huawei7AndLowerAdditionalSetupInstructions'),
          actions: {
            buttons: [
              {
                buttonName: I18n.t('enableProtected'),
                componentName: {
                  packageName: 'com.huawei.systemmanager',
                  className:
                    'com.huawei.systemmanager.optimize.process.ProtectActivity'
                }
              }
            ]
          }
        },
        includes: { apiLevel: [21, 22, 23, 24, 25] },
        excludes: {}
      },
      {
        deviceManufacturer: 'huawei',
        additionalSetupInstructions: {
          title: I18n.t('yourAlarmsMayNotRing'),
          instructions: I18n.t('huaweiAdditionalSetupInstructions'),
          actions: {
            buttons: [
              {
                buttonName: I18n.t('manageAppLaunch'),
                componentName: {
                  packageName: 'com.huawei.systemmanager',
                  className:
                    'com.huawei.systemmanager.startupmgr.ui.StartupNormalAppListActivity'
                }
              }
            ]
          }
        },
        includes: {},
        excludes: { apiLevel: [21, 22, 23, 24, 25] }
      },
      {
        deviceManufacturer: 'meizu',
        additionalSetupInstructions: {
          title: I18n.t('yourAlarmsMayNotRing'),
          instructions: I18n.t('meizuSetupInstructions')
        },
        includes: {},
        excludes: {}
      },
      {
        deviceManufacturer: 'tecno',
        additionalSetupInstructions: {
          title: I18n.t('yourAlarmsMayNotRing'),
          instructions: I18n.t('tecnoSetupInstructions')
        },
        includes: {},
        excludes: {}
      },
      {
        deviceManufacturer: 'tecno mobile limited',
        additionalSetupInstructions: {
          title: I18n.t('yourAlarmsMayNotRing'),
          instructions: I18n.t('tecnoSetupInstructions')
        },
        includes: {},
        excludes: {}
      },
      {
        deviceManufacturer: 'infinix',
        additionalSetupInstructions: {
          title: I18n.t('yourAlarmsMayNotRing'),
          instructions: I18n.t('infinixSetupInstructions')
        },
        includes: {},
        excludes: {}
      },
      {
        deviceManufacturer: 'infinix mobility limited',
        additionalSetupInstructions: {
          title: I18n.t('yourAlarmsMayNotRing'),
          instructions: I18n.t('infinixSetupInstructions')
        },
        includes: {},
        excludes: {}
      },
      {
        deviceManufacturer: 'asus',
        additionalSetupInstructions: {
          title: I18n.t('yourAlarmsMayNotRing'),
          instructions: I18n.t('asusSetupInstructions'),
          actions: {
            buttons: [
              {
                buttonName: I18n.t('enableAutoStart'),
                componentName: {
                  packageName: 'com.asus.mobilemanager',
                  className:
                    'com.asus.mobilemanager.powersaver.PowerSaverSettings'
                },
                fallbacks: [
                  {
                    packageName: 'com.asus.mobilemanager',
                    className:
                      'com.asus.mobilemanager.autostart.AutoStartActivity'
                  }
                ]
              }
            ]
          }
        },
        includes: {},
        excludes: { model: ['nexus'] }
      },
      {
        deviceManufacturer: 'hmd global',
        additionalSetupInstructions: {
          title: I18n.t('yourAlarmsMayNotRing'),
          instructions: I18n.t('nokia8AndLowerAdditionalSetupInstructions'),
          actions: {
            buttons: [
              {
                buttonName: I18n.t('enableAutoStart'),
                componentName: {
                  packageName: 'com.evenwell.powersaving.g3',
                  className:
                    'com.evenwell.powersaving.g3.exception.PowerSaverExceptionActivity'
                }
              }
            ]
          }
        },
        includes: { apiLevel: [21, 22, 23, 24, 25, 26, 27] },
        excludes: {}
      },
      {
        deviceManufacturer: 'hmd global',
        additionalSetupInstructions: {
          title: I18n.t('yourAlarmsMayNotRing'),
          instructions: I18n.t('nokiaAdditionalSetupInstructions'),
          actions: {
            buttons: [
              {
                buttonName: I18n.t('restrictedApps'),
                componentName: {
                  packageName: 'com.evenwell.powersaving.g3',
                  className:
                    'com.evenwell.powersaving.g3.exception.PowerSaverExceptionActivity'
                }
              }
            ]
          }
        },
        includes: {},
        excludes: { apiLevel: [21, 22, 23, 24, 25, 26, 27] }
      },
      {
        deviceManufacturer: 'samsung',
        additionalSetupInstructions: {
          title: I18n.t('yourAlarmsMayNotRing'),
          instructions: I18n.t('samsungAdditionalSetupInstructions'),
          actions: {
            buttons: [
              {
                buttonName: I18n.t('unmonitoredApps'),
                componentName: {
                  packageName: 'com.samsung.android.lool',
                  className: 'com.samsung.android.sm.ui.battery.BatteryActivity'
                },
                fallbacks: [
                  {
                    packageName: 'com.samsung.android.sm',
                    className:
                      'com.samsung.android.sm.ui.battery.BatteryActivity'
                  }
                ]
              }
            ]
          }
        },
        includes: { apiLevel: [24, 25, 26, 27] },
        excludes: {}
      },
      {
        deviceManufacturer: 'samsung',
        additionalSetupInstructions: {
          title: I18n.t('setNotificationsStyle'),
          instructions: I18n.t(
            'samsungAdditionalSetupInstructionsAndroid11AndAbove'
          )
        },
        includes: {},
        excludes: { apiLevel: [21, 22, 23, 24, 25, 26, 27, 28, 29] }
      },
      {
        deviceManufacturer: 'lenovo',
        additionalSetupInstructions: {
          title: I18n.t('yourAlarmsMayNotRing'),
          instructions: I18n.t('lenovoSetupInstructions')
        },
        includes: {},
        excludes: {}
      }
    ]

    let additionalSetupInstructions = null

    let additionalSetupConfigsForManufacturer =
      additionalSetupConfigurations.filter(
        config => config.deviceManufacturer === deviceManufacturer
      )

    if (additionalSetupConfigsForManufacturer) {
      additionalSetupInstructions =
        findAdditionalSetupInstructionsAfterApplyingFilters(
          additionalSetupConfigsForManufacturer,
          model,
          apiLevel
        )
    }

    return { additionalSetupInstructions: additionalSetupInstructions }
  }

  const findAdditionalSetupInstructionsAfterApplyingFilters = (
    additionalSetupConfigsForManufacturer,
    model,
    apiLevel
  ) => {
    let additionalSetupInstructions
    for (var additionalSetupConfigForManufacturer of additionalSetupConfigsForManufacturer) {
      let includesFilter = objGet(
        additionalSetupConfigForManufacturer,
        'includes',
        {}
      )
      // Includes filter will be given the first priority, if
      // we have an includes filter check it first, if there is no
      // includes filter only then consider the excludes filter
      if (!isEmpty(includesFilter)) {
        const result = applyFilters(includesFilter, model, apiLevel)
        // This means the model or apiLevel is an included one
        if (result) {
          additionalSetupInstructions = objGet(
            additionalSetupConfigForManufacturer,
            'additionalSetupInstructions'
          )
          break
        }
      } else {
        let excludesFilter = objGet(
          additionalSetupConfigForManufacturer,
          'excludes',
          {}
        )
        const result = applyFilters(excludesFilter, model, apiLevel)
        // This means the model or apiLevel is not an excluded one
        if (!result) {
          additionalSetupInstructions = objGet(
            additionalSetupConfigForManufacturer,
            'additionalSetupInstructions'
          )
          break
        }
      }
    }
    return additionalSetupInstructions
  }

  const applyFilters = (filter, model, apiLevel) => {
    const modelFilter = objGet(filter, 'model')
    const apiLevelFilter = objGet(filter, 'apiLevel')
    let result = false
    if (modelFilter) {
      result =
        modelFilter.find(item => model.indexOf(item) !== -1) !== undefined
    } else if (apiLevelFilter) {
      result = apiLevelFilter.find(item => apiLevel === item) !== undefined
    }
    return result
  }

  const getAdditionalSetupInstructions = (
    isConnected,
    isAuthenticated,
    dispatch
  ) => {
    NetworkUtils.isConnectedAndAuthenticated(
      isConnected,
      isAuthenticated,
      dispatch
    ).then(connected => {
      if (connected) {
        getAdditionalSetupInstructionsFromServer(dispatch)
      } else {
        getAdditionalSetupInstructionsFromClient(dispatch)
      }
    })
  }

  const getAdditionalSetupInstructionsFromServer = dispatch => {
    StorageManager.retrieveData(['additionalSetupInstructionsFetched']).then(
      data => {
        // Only if there are no setup instructions in storage manager,
        // get it from client side
        if (!data.additionalSetupInstructionsFetched) {
          console.tron.log(
            'Fetching additional setup instructions from server side',
            DeviceInfo.getModel(),
            Platform.Version
          )
          TaskManager.addHttpsCloudTask('getAdditionalSetupInstructions', {
            manufacturer: GlobalConfig.store
              .getState()
              .userInfo.deviceManufacturer.toLowerCase(),
            model: DeviceInfo.getModel(),
            apiLevel: Platform.Version,
            os: Platform.OS,
            locale: I18n.currentLocale()
          })
            .then(result => {
              StorageManager.storeData({
                additionalSetupInstructionsFetched: 'true'
              })
              if (result.additionalSetupInstructions) {
                const title =
                  result.additionalSetupInstructions.title ||
                  I18n.t('yourAlarmsMayNotRing')
                const instructions =
                  result.additionalSetupInstructions.instructions
                const actions = result.additionalSetupInstructions.actions

                const newAdditionalSetupInstructions = {
                  title: title,
                  instructions: instructions,
                  actions: actions,
                  isAlreadyDone: false
                }

                StorageManager.storeData({
                  additionalSetupInstructions: JSON.stringify(
                    newAdditionalSetupInstructions
                  )
                })

                dispatch(
                  ActionCreators.setAdditionalSetupInstructions(
                    newAdditionalSetupInstructions
                  )
                )
              }
            })
            .catch(error => {
              LogUtils.logError(
                error,
                'Unable to find out set up instructions '
              )
            })
        }
      }
    )
  }

  const getAdditionalSetupInstructionsFromClient = dispatch => {
    StorageManager.retrieveData(['additionalSetupInstructionsFetched']).then(
      data => {
        // Only if there are no setup instructions in storage manager,
        // get it from client side
        if (!data.additionalSetupInstructionsFetched) {
          console.tron.log(
            'Fetching additional setup instructions from client side',
            DeviceInfo.getModel(),
            Platform.Version
          )
          StorageManager.storeData({
            additionalSetupInstructionsFetched: 'true'
          })
          const result = getAdditionalSetupInstructionsFromClientSide()
          if (result.additionalSetupInstructions) {
            const additionalSetupInstructions = {
              title:
                result.additionalSetupInstructions.title ||
                I18n.t('yourAlarmsMayNotRing'),
              instructions: result.additionalSetupInstructions.instructions,
              actions: result.additionalSetupInstructions.actions,
              isAlreadyDone: false
            }
            StorageManager.storeData({
              additionalSetupInstructions: JSON.stringify(
                additionalSetupInstructions
              )
            })
            dispatch(
              ActionCreators.setAdditionalSetupInstructions(
                additionalSetupInstructions
              )
            )
          }
        }
      }
    )
  }

  const getAdditionalSetupInstructionsFromStorageManager = () => {
    const retval = new Promise(resolve => {
      StorageManager.retrieveData(['additionalSetupInstructions']).then(
        data => {
          let additionalSetupInstructionsString =
            data.additionalSetupInstructions || '{}'
          const additionalSetupInstructions = JSON.parse(
            additionalSetupInstructionsString
          )
          resolve(additionalSetupInstructions)
        }
      )
    })
    return retval
  }

  const getAdditionalSetupInstructionsSummary = additionalSetupInstructions => {
    const additionalSetupInstructionSummaries = {
      [I18n.t('yourAlarmsMayNotRing')]: I18n.t('yourAlarmsMayNotRingTapToFix'),
      [I18n.t('setNotificationsStyle')]: I18n.t(
        'setNotificationsStyleToDetailed'
      ),
      [I18n.t('setBannerStyle')]: I18n.t('setBannerStyleToPersistent')
    }

    const title =
      additionalSetupInstructions.title || I18n.t('yourAlarmsMayNotRing')
    const summary =
      additionalSetupInstructionSummaries[title] ||
      I18n.t('yourAlarmsMayNotRingTapToFix')
    return summary
  }

  const getDefaultRingerSettings = () => {
    const alarmRingtone =
      GlobalConfig.alarmRingtone || GlobalConfig.defaultAlarmRingtone
    const vibrate =
      GlobalConfig.vibrate !== undefined
        ? GlobalConfig.vibrate
        : GlobalConfig.defaultVibrate
    const volume =
      GlobalConfig.volume !== undefined
        ? GlobalConfig.volume
        : GlobalConfig.defaultVolume
    const ringtoneDuration =
      GlobalConfig.ringtoneDuration || GlobalConfig.defaultRingtoneDuration
    const autoSnooze =
      GlobalConfig.autoSnooze !== undefined
        ? GlobalConfig.autoSnooze
        : GlobalConfig.defaultAutoSnooze
    const autoSnoozeDuration =
      GlobalConfig.autoSnoozeDuration !== undefined
        ? GlobalConfig.autoSnoozeDuration
        : GlobalConfig.defaultAutoSnoozeDuration
    const autoSnoozeCount =
      GlobalConfig.autoSnoozeCount !== undefined
        ? GlobalConfig.autoSnoozeCount
        : GlobalConfig.defaultAutoSnoozeCount
    const ringOnVibrate =
      GlobalConfig.ringOnVibrate !== undefined
        ? GlobalConfig.ringOnVibrate
        : GlobalConfig.defaultRingOnVibrate
    const announceAlarmName =
      GlobalConfig.announceAlarmName !== undefined
        ? GlobalConfig.announceAlarmName
        : GlobalConfig.defaultAnnounceAlarmName
    const criticalAlerts =
      GlobalConfig.criticalAlerts !== undefined
        ? GlobalConfig.criticalAlerts
        : GlobalConfig.defaultCriticalAlerts
    const criticalAlertsVolume =
      GlobalConfig.criticalAlertsVolume !== undefined
        ? GlobalConfig.criticalAlertsVolume
        : GlobalConfig.defaultCriticalAlertsVolume

    return {
      alarmRingtone,
      vibrate,
      volume,
      ringtoneDuration,
      autoSnooze,
      autoSnoozeDuration,
      autoSnoozeCount,
      ringOnVibrate,
      announceAlarmName,
      criticalAlerts,
      criticalAlertsVolume
    }
  }

  const computeRingerSettings = ringerSettings => {
    const defaultRingerSettings = getDefaultRingerSettings()
    return {
      ...defaultRingerSettings,
      ...ringerSettings
    }
  }

  const getRingerSettingsLabel = ringerSettings => {
    return Platform.OS === 'android'
      ? getAndroidRingerSettingsLabel(ringerSettings)
      : getIosRingerSettingsLabel(ringerSettings)
  }

  const getAndroidRingerSettingsLabel = ringerSettings => {
    const alarmRingtone = ringerSettings.alarmRingtone
    const ringtoneLabel = alarmRingtone.label

    if (ringerSettings.volume === 0 && ringerSettings.vibrate) {
      return I18n.t('vibrate')
    }

    if (ringerSettings.volume === 0 && !ringerSettings.vibrate) {
      return I18n.t('silent')
    }

    return stringTruncate(
      I18n.t('soundAndVibrationSummaryString', {
        count: ringerSettings.announceAlarmName ? 1 : 0,
        volume: ringerSettings.volume,
        ringtoneName: ringtoneLabel
      }),
      {
        length: 40
      }
    )
  }

  const getIosRingerSettingsLabel = ringerSettings => {
    const alarmRingtone = ringerSettings.alarmRingtone
    const ringtoneLabel = alarmRingtone.label
    const criticalAlerts = ringerSettings.criticalAlerts
    const criticalAlertsVolume = ringerSettings.criticalAlertsVolume

    return stringTruncate(
      I18n.t('soundAndVibrationSummaryStringIos', {
        ringtoneName: ringtoneLabel,
        count: criticalAlerts ? 1 : 0,
        volume: criticalAlertsVolume
      }),
      {
        length: 40
      }
    )
  }

  const getChangedParametersForRingerSettings = (
    oldRingerSettings,
    newRingerSettings
  ) => {
    let parameters = {}
    if (
      oldRingerSettings.alarmRingtone.value !==
      newRingerSettings.alarmRingtone.value
    ) {
      parameters[Constants.UserAnalyticsEventParameters.RINGTONE] =
        newRingerSettings.alarmRingtone.type === 'inbuilt'
          ? newRingerSettings.alarmRingtone.value
          : Constants.CUSTOM_MUSIC_RINGTONE_NAME
    }
    if (oldRingerSettings.vibrate !== newRingerSettings.vibrate) {
      parameters[Constants.UserAnalyticsEventParameters.VIBRATE] =
        newRingerSettings.vibrate.toString()
    }
    if (oldRingerSettings.volume !== newRingerSettings.volume) {
      parameters[Constants.UserAnalyticsEventParameters.VOLUME] =
        newRingerSettings.volume.toString()
    }
    if (
      oldRingerSettings.ringtoneDuration !== newRingerSettings.ringtoneDuration
    ) {
      parameters[Constants.UserAnalyticsEventParameters.RINGTONE_DURATION] =
        newRingerSettings.ringtoneDuration.toString()
    }
    if (oldRingerSettings.ringOnVibrate !== newRingerSettings.ringOnVibrate) {
      parameters[Constants.UserAnalyticsEventParameters.RING_ON_VIBRATE] =
        newRingerSettings.ringOnVibrate.toString()
    }
    if (
      oldRingerSettings.announceAlarmName !==
      newRingerSettings.announceAlarmName
    ) {
      parameters[Constants.UserAnalyticsEventParameters.ANNOUNCE_ALARM_NAME] =
        newRingerSettings.announceAlarmName.toString()
    }
    return parameters
  }

  const getNewStatusForAlarm = alarm => {
    const alarmNextDate = getNextRingDateForAlarm(alarm)
    return !!alarmNextDate
  }

  const getAlarmTitle = (alarmType, isEdit = true) => {
    let alarmTitle = ''
    switch (alarmType) {
      case Constants.AlarmTypes.CASCADING:
        alarmTitle = isEdit
          ? I18n.t('editPersonalAlarm')
          : I18n.t('newPersonalAlarm')
        break
      case Constants.AlarmTypes.SIMULTANEOUS:
        alarmTitle = isEdit ? I18n.t('editGroupAlarm') : I18n.t('newGroupAlarm')
        break
      case Constants.AlarmTypes.RECIPIENT:
        alarmTitle = isEdit
          ? I18n.t('editRecipientAlarm')
          : I18n.t('newRecipientAlarm')
        break
      default:
        alarmTitle = isEdit ? I18n.t('editAlarm') : I18n.t('newAlarm')
        break
    }
    return alarmTitle
  }

  const getAlarmCategoryForAlarm = (alarmCategories, alarmId) => {
    let selectedAlarmCategory = Constants.UNCATEGORIZED_ALARM_CATEGORY
    for (const alarmCategory in alarmCategories) {
      const alarmIds = objGet(alarmCategories, `${alarmCategory}.alarmIds`, [])
      if (alarmIds.includes(alarmId)) {
        selectedAlarmCategory = alarmCategories[alarmCategory]
      }
    }

    return selectedAlarmCategory
  }

  const getSnoozeDurationAsString = snoozeDuration => {
    const snoozeValues = [600000, 3600000, 14400000, 86400000]
    let snoozeDurationString = snoozeDuration.toString()
    if (snoozeValues.indexOf(snoozeDuration) === -1) {
      snoozeDurationString = 'custom'
    }
    return snoozeDurationString
  }

  const createFirebaseObjectForAddingUserAsContactParticipantToGroupAlarm = (
    alarm,
    userInfo
  ) => {
    let firebaseUpdate = {}

    firebaseUpdate[
      'userInfos/' + GlobalConfig.uid + '/backupForAlarms/' + alarm.id
    ] = alarm.date

    firebaseUpdate['alarms/' + alarm.id + '/lastUpdatedAt'] = Date.now()

    firebaseUpdate[
      'alarms/' + alarm.id + '/backups/contacts/' + GlobalConfig.uid
    ] = {
      id: GlobalConfig.uid,
      mobileNumber: userInfo.mobileNumber,
      name: userInfo.name,
      order: 1,
      responseStatus: Constants.ACCEPT_ALARM
    }

    return firebaseUpdate
  }

  const addUserAsGroupParticipantToGroupAlarm = (alarm, userInfo) => {
    // Add the participant to the group and make the participant an active member
    // only for this alarm
    const backupGroup = alarm.backupGroup
    const newMemberToAdd = {
      id: GlobalConfig.uid,
      mobileNumber: userInfo.mobileNumber,
      name: userInfo.name
    }
    TaskManager.addHttpsCloudTask('addGroupMembers', {
      id: backupGroup.id,
      uid: alarm.creator,
      username: alarm.creatorName,
      newMembers: [newMemberToAdd],
      name: backupGroup.name
    })
      .then(() => {
        makeMemberActiveInGroupAlarm(backupGroup.id, GlobalConfig.uid, alarm.id)
      })
      .catch(error => {
        GlobalConfig.store.dispatch(
          ActionCreators.setProgress(
            Constants.ProgressStates.ERROR,
            I18n.t('unableToAddMembers', { error: error.error }),
            true
          )
        )
        LogUtils.logError(new Error(error.error), 'Unable to add members')
      })

    return GroupUtils.addConnectionsForGroupMembers([newMemberToAdd])
  }

  const addUserAsContactParticipantToGroupAlarm = (alarm, userInfo) => {
    const firebaseUpdate =
      createFirebaseObjectForAddingUserAsContactParticipantToGroupAlarm(
        alarm,
        userInfo
      )
    return ref.update(firebaseUpdate)
  }

  const addUserAsParticipantToGroupAlarm = (alarm, userInfo) => {
    const backupGroup = alarm.backupGroup
    if (backupGroup) {
      addUserAsGroupParticipantToGroupAlarm(alarm, userInfo)
    } else {
      addUserAsContactParticipantToGroupAlarm(alarm, userInfo)
    }
  }

  // Makes a person who joins a group alarm with group as participant
  // using a link.
  const makeMemberActiveInGroupAlarm = (groupId, memberId, alarmId) => {
    let firebaseUpdate = {}
    firebaseUpdate[
      'alarms/' +
        alarmId +
        '/backups/group/' +
        groupId +
        '/members/' +
        memberId +
        '/state'
    ] = 'active'
    firebaseUpdate['alarms/' + alarmId + '/lastUpdatedAt'] = Date.now()
    ref.update(firebaseUpdate)
  }

  const getColorForAlarmCategory = (colorScheme, color) => {
    const alarmCategoryColor = Utils.getObjectWithId(
      colorThemes.getColorTheme(colorScheme).alarmCategoryColors,
      color
    )

    if (!alarmCategoryColor) {
      return colorThemes.getColorTheme(colorScheme).textBackgroundColor
    }

    return alarmCategoryColor.code
  }

  const addAlarmToAlarmCategory = (alarmCategoryId, alarmId) => {
    return ref
      .child('userInfos')
      .child(GlobalConfig.uid)
      .child('alarmCategories')
      .child(alarmCategoryId)
      .child('alarmIds')
      .child(alarmId)
      .set(true)
  }

  const removeAlarmFromAlarmCategory = (alarmCategoryId, alarmId) => {
    return ref
      .child('userInfos')
      .child(GlobalConfig.uid)
      .child('alarmCategories')
      .child(alarmCategoryId)
      .child('alarmIds')
      .child(alarmId)
      .remove()
  }

  const deleteAlarmCategory = alarmCategoryId => {
    return ref
      .child('userInfos')
      .child(GlobalConfig.uid)
      .child('alarmCategories')
      .child(alarmCategoryId)
      .remove()
  }

  const updateAlarmCategory = (alarmCategoryId, alarmCategory) => {
    return ref
      .child('userInfos')
      .child(GlobalConfig.uid)
      .child('alarmCategories')
      .child(alarmCategoryId)
      .update(alarmCategory)
  }

  const setWebColorScheme = colorScheme => {
    return ref
      .child('userSettings')
      .child(GlobalConfig.uid)
      .child('webColorScheme')
      .set(colorScheme)
  }

  const createAlarmCategory = (alarmCategoryId, alarmCategory) => {
    return ref
      .child('userInfos')
      .child(GlobalConfig.uid)
      .child('alarmCategories')
      .child(alarmCategoryId)
      .set(alarmCategory)
  }

  const getAlarmAcknowledString = (alarmType, alarmAcknowledgement) => {
    switch (alarmType) {
      case Constants.AlarmTypes.CASCADING:
        return stringCapitalize(
          I18n.t(
            Constants.PersonalAlarmInstanceResponseNotificationTextMapping[
              alarmAcknowledgement.response
            ]
          )
        )
      case Constants.AlarmTypes.SIMULTANEOUS:
        return stringCapitalize(
          I18n.t(
            Constants.GroupAlarmInstanceResponseNotificationTextMapping[
              alarmAcknowledgement.response
            ]
          )
        )
      case Constants.AlarmTypes.RECIPIENT:
        return stringCapitalize(
          I18n.t(
            Constants.RecipientAlarmInstanceResponseNotificationTextMapping[
              alarmAcknowledgement.response
            ]
          )
        )
    }
  }

  const createEventSummaryForAlarmRepeatingMultipleTimesADay = (
    repeatType,
    repeat
  ) => {
    if (repeatType === Constants.RepeatTypes.EVERY_N_HOURS) {
      const { startHours, startMins, endHours, endMins, hours } =
        getRepeatOptionsForHourlyRepetition(repeat)
      return I18n.t('everyNHoursRepetitionStringForCalendar', {
        startTimeString: createTimeString(startHours, startMins),
        endTimeString: createTimeString(endHours, endMins),
        count: hours
      })
    } else if (repeatType === Constants.RepeatTypes.HOURS_AND_MINUTES) {
      const { startHours, startMins, endHours, endMins, minutes } =
        getRepeatOptionsForHoursAndMinutesRepetition(repeat)
      const hoursAndMinutesString =
        computeHoursAndMinutesStringFromMinutes(minutes)
      return I18n.t('everyNHoursAndMinutesRepetitionStringForCalendar', {
        startTimeString: createTimeString(startHours, startMins),
        endTimeString: createTimeString(endHours, endMins),
        hoursAndMinutes: hoursAndMinutesString,
        minutes: minutes
      })
    } else {
      return createAlarmRepetitionString(repeatType, repeat)
    }
  }

  const getAllAlarmOccurencesInRangeForAgendaView = (alarm, start, end) => {
    if (!alarm.repeatType) {
      if (alarm.date < start || alarm.date > end) {
        return []
      } else {
        return [
          {
            ...alarm,
            eventDateString: moment(alarm.date).format('YYYY-MM-DD')
          }
        ]
      }
    } else {
      if (alarm.date > end || (alarm.endDate && alarm.endDate < start)) {
        return []
      }

      const isParticipantAlarm =
        alarm.alarmCategory === Constants.AlarmCategories.PARTICIPANT_ALARM

      let repeat = alarm.repeat,
        date = alarm.date
      if (isParticipantAlarm) {
        repeat = adjustRepetitionStringIfParticipant(alarm)
        date = DateTimeUtils.getParticipantAlarmDate(alarm, alarm.order || 0)
      }

      let alarms = []
      let done = false
      let refDate = start

      // Move the alarm date to the last event date such that we don't
      // keep tracing from the alarm start date saving time
      let alarmDate = date
      while (!done) {
        const { nextDate: eventDate } = getPrevAndNextDatesForAnAlarmWrtDate(
          refDate,
          alarmDate,
          alarm.endDate,
          alarm.repeatType,
          repeat
        )

        if (!eventDate || eventDate > end) {
          done = true
          continue
        }

        alarms.push({
          ...alarm,
          date: eventDate,
          eventDateString: moment(eventDate).format('YYYY-MM-DD')
        })

        refDate = eventDate
        alarmDate = eventDate
      }

      return alarms
    }
  }

  // For a given start and end date in ms, return all the events
  // for the alarm in the range.
  const getEventsForAnAlarmInRange = (alarm, start, end, calendarView) => {
    if (!alarm.repeatType) {
      if (alarm.date < start || alarm.date > end) {
        return []
      } else {
        return [
          {
            id: alarm.id,
            title: alarm.name,
            tooltip: alarm.name,
            allDay: false,
            start: new Date(alarm.date),
            end: new Date(alarm.date)
          }
        ]
      }
    } else {
      if (alarm.date > end || (alarm.endDate && alarm.endDate < start)) {
        return []
      }

      const isParticipantAlarm =
        alarm.alarmCategory === Constants.AlarmCategories.PARTICIPANT_ALARM

      let repeat = alarm.repeat,
        date = alarm.date
      if (isParticipantAlarm) {
        repeat = adjustRepetitionStringIfParticipant(alarm)
        date = DateTimeUtils.getParticipantAlarmDate(alarm, alarm.order || 0)
      }

      let events = []
      let done = false
      let refDate = start
      let numEventsMap = {}

      // Move the alarm date to the last event date such that we don't
      // keep tracing from the alarm start date saving time
      let alarmDate = date
      while (!done) {
        const { nextDate: eventDate } = getPrevAndNextDatesForAnAlarmWrtDate(
          refDate,
          alarmDate,
          alarm.endDate,
          alarm.repeatType,
          repeat
        )

        if (!eventDate || eventDate > end) {
          done = true
          continue
        }

        const eventDateString = DateTimeUtils.getDateAsString(eventDate)

        events.push({
          id: alarm.id,
          title: alarm.name,
          tooltip: alarm.name,
          allDay: false,
          start: new Date(eventDate),
          end: new Date(eventDate),
          eventDateString: eventDateString
        })

        // If for a given day, number of events are over the allowed
        // threshold, add a single all-day event for the alarm and
        // remove the individual events on that day.
        let numEventsOnDate = objGet(numEventsMap, eventDateString, 0)
        numEventsOnDate++
        objSet(numEventsMap, eventDateString, numEventsOnDate)

        if (
          numEventsOnDate >
          objGet(GlobalConfig.eventsThresholdForCalendarView, calendarView, 3)
        ) {
          const alarmRepetitionString =
            createEventSummaryForAlarmRepeatingMultipleTimesADay(
              alarm.repeatType,
              repeat
            )
          events = events.filter(
            event => event.eventDateString !== eventDateString
          )
          events.push({
            id: alarm.id,
            title: alarm.name,
            tooltip: `${alarm.name} - ${alarmRepetitionString}`,
            allDay: true,
            start: new Date(eventDate),
            end: new Date(eventDate)
          })
          objSet(numEventsMap, date, 1)

          // Jump to next day
          refDate = moment(eventDate)
            .add(1, 'day')
            .hours(0)
            .minutes(0)
            .seconds(0)
            .milliseconds(0)
            .valueOf()
          alarmDate = eventDate
          continue
        }

        refDate = eventDate
        alarmDate = eventDate
      }

      return events
    }
  }

  const memoizedGetEventsForAnAlarmInRange = memoize(getEventsForAnAlarmInRange)

  const memoizedGetAlarmsInRangeForAgendaView = memoize(
    getAllAlarmOccurencesInRangeForAgendaView
  )

  const getAlarmsInRangeForAgendaView = (alarms, start, end) => {
    let events = []

    alarms.forEach(alarm => {
      const eventsForAlarm = memoizedGetAlarmsInRangeForAgendaView(
        alarm,
        start,
        end
      )
      events = events.concat(eventsForAlarm)
    })

    return events
  }

  const getEventsForAlarmsInRange = (alarms, start, end, calendarView) => {
    let events = []

    alarms.forEach(alarm => {
      const eventsForAlarm = memoizedGetEventsForAnAlarmInRange(
        alarm,
        start,
        end,
        calendarView
      )
      events = events.concat(eventsForAlarm)
    })

    return events
  }

  const getCreatorSummaryForInstantAlarm = (
    alarmAcknowledgements,
    numParticipants
  ) => {
    const numAcknowledgements = Object.keys(alarmAcknowledgements).length
    let numConfirmed = 0,
      numDeclined = 0,
      numPending = numParticipants - numAcknowledgements

    Object.keys(alarmAcknowledgements).forEach(uid => {
      const userAcknowledgements = alarmAcknowledgements[uid]
      const userAcknowledgement = userAcknowledgements.reduce((prev, current) =>
        prev.timestamp > current.timestamp ? prev : current
      )
      if (userAcknowledgement.response === Constants.GROUP_ALARM_YES) {
        numConfirmed++
      } else if (userAcknowledgement.response === Constants.GROUP_ALARM_NO) {
        numDeclined++
      }
    })

    if (numPending === 0) {
      if (numConfirmed === numParticipants) {
        return I18n.t('everyoneConfirmed')
      } else if (numDeclined === numParticipants) {
        return I18n.t('everyoneDeclined')
      }
    }

    let acknowledgedStringArr = []
    if (numConfirmed) {
      acknowledgedStringArr.push(I18n.t('nConfirmed', { count: numConfirmed }))
    }
    if (numDeclined) {
      acknowledgedStringArr.push(I18n.t('nDeclined', { count: numDeclined }))
    }
    if (numPending) {
      acknowledgedStringArr.push(I18n.t('nPending', { count: numPending }))
    }

    const acknowledgedString = acknowledgedStringArr.join(', ')
    return acknowledgedString
  }

  const getTimezoneDisplayString = timezone => {
    return I18n.t('timezoneDisplayString', {
      offset: moment.tz(timezone).format('Z'),
      timezone: timezone.replace(/_/g, ' ')
    })
  }

  const getTimezoneAbbr = timezone => {
    return moment.tz(timezone).zoneAbbr()
  }

  const getTimezoneDisplayStringForAlarm = (timezone, timezoneSetting) => {
    return timezoneSetting === Constants.TIMEZONE_SETTINGS.EXPLICIT
      ? getTimezoneDisplayString(timezone)
      : I18n.t('deviceTimezone')
  }

  // Function to check if a timezone is supported
  const isSupportedTimeZone = timeZone => {
    try {
      new Intl.DateTimeFormat('en-US', { timeZone })
      return true
    } catch (error) {
      console.error(`Timezone not supported: ${timeZone}`, error)
      return false
    }
  }

  const getAllTimezones = () => {
    // Get the current date
    const currDate = Date.now()

    // Filter out invalid timezones and sort valid ones
    const validTimezones = timezones
      .filter(isSupportedTimeZone)
      .sort(
        (a, b) =>
          moment.tz.zone(b).utcOffset(currDate) -
          moment.tz.zone(a).utcOffset(currDate)
      )
      .map((timezone, index) => ({
        id: index,
        value: timezone,
        label: AlarmUtils.getTimezoneDisplayStringForAlarm(
          timezone,
          Constants.TIMEZONE_SETTINGS.EXPLICIT
        ),
        abbr: moment.tz(timezone).zoneAbbr()
      }))
    return validTimezones
  }

  const getPreReminderLabel = duration => {
    const durationString = DateTimeUtils.getDurationAsString(
      duration,
      I18n.t('none')
    )
    return durationString
  }

  const getPreReminderDurationAsString = duration => {
    const durationString = DateTimeUtils.getDurationAsString(
      duration,
      I18n.t('none')
    )
    return durationString
  }

  const getPreReminderHelperText = duration => {
    return duration ? I18n.t('helperTextForPreReminder') : null
  }

  const getDisplayTextForPreReminderDuration = (date, duration) => {
    const preReminderDurationMins = moment.duration(duration).asMinutes()

    const dateHours = moment(date).hours()
    const dateMins = moment(date).minutes()
    const minsPassedInCurrentDay = dateHours * 60 + dateMins

    // Find the difference in dates between date and preReminderTime
    const numDays = Math.ceil(
      (preReminderDurationMins - minsPassedInCurrentDay) / 1440
    )

    return I18n.t('preReminderWillRingAt', {
      timeString: DateTimeUtils.getTimeAsString(date - duration),
      count: numDays
    })
  }

  const getAlarmDetailsFooterText = alarmInfo => {
    const {
      alarmExpired,
      alarmAcknowledgement,
      alarmType,
      myAlarmDate,
      recipient,
      numParticipants,
      creationMode,
      alarmAcknowledgements,
      alarmStatus
    } = alarmInfo
    if (!alarmStatus) {
      return ''
    }

    if (creationMode === Constants.AlarmCreationModes.INSTANT_ALARM) {
      return AlarmUtils.getCreatorSummaryForInstantAlarm(
        alarmAcknowledgements,
        numParticipants
      )
    } else if (alarmExpired && alarmAcknowledgement) {
      const durationString =
        DateTimeUtils.getShortDurationTillDateAsString(myAlarmDate)
      let acknowledgedAsString
      switch (alarmType) {
        case Constants.AlarmTypes.CASCADING:
          return alarmAcknowledgement.response === Constants.PERSONAL_ALARM_SKIP
            ? I18n.t('skippedPersonalAlarmForCreator', { durationString })
            : I18n.t('donePersonalAlarmForCreator', { durationString })
        case Constants.AlarmTypes.SIMULTANEOUS:
          acknowledgedAsString = I18n.t(
            Constants.GroupAlarmInstanceResponseNotificationTextMapping[
              alarmAcknowledgement.response
            ]
          )
          return I18n.t('acknowledgedExpiredGroupAlarm', {
            durationString,
            acknowledgedAsString
          })
        case Constants.AlarmTypes.RECIPIENT:
          return alarmAcknowledgement.response ===
            Constants.RECIPIENT_ALARM_DONE
            ? I18n.t('acknowledgedExpiredRecipientAlarmForCreator', {
                durationString,
                recipientName: recipient.name
              })
            : ''
      }
    } else if (alarmExpired) {
      const durationString =
        DateTimeUtils.getShortDurationTillDateAsString(myAlarmDate)
      switch (alarmType) {
        case Constants.AlarmTypes.CASCADING:
          return I18n.t('expiredPersonalAlarmForCreator', {
            durationString,
            count: numParticipants
          })
        case Constants.AlarmTypes.SIMULTANEOUS:
          return I18n.t('expiredGroupAlarm', { durationString })
        case Constants.AlarmTypes.RECIPIENT:
          return I18n.t('expiredRecipientAlarmForCreator', {
            durationString,
            recipientName: recipient.name
          })
      }
    } else if (alarmAcknowledgement) {
      let durationString, acknowledgedAsString
      switch (alarmType) {
        case Constants.AlarmTypes.CASCADING:
          durationString = DateTimeUtils.getShortDurationTillDateAsString(
            alarmAcknowledgement.timestamp
          )
          return alarmAcknowledgement.response === Constants.PERSONAL_ALARM_SKIP
            ? I18n.t('skippedPersonalAlarm', { durationString })
            : I18n.t('acknowledgedPersonalAlarm', { durationString })
        case Constants.AlarmTypes.SIMULTANEOUS:
          durationString = DateTimeUtils.getShortDurationTillDateAsString(
            alarmAcknowledgement.timestamp
          )
          acknowledgedAsString = I18n.t(
            Constants.GroupAlarmInstanceResponseNotificationTextMapping[
              alarmAcknowledgement.response
            ]
          )
          return I18n.t('acknowledgedGroupAlarm', {
            durationString,
            acknowledgedAsString
          })
        case Constants.AlarmTypes.RECIPIENT:
          durationString = DateTimeUtils.getShortDurationTillDateAsString(
            alarmAcknowledgement.timestamp
          )
          return alarmAcknowledgement.response ===
            Constants.RECIPIENT_ALARM_DONE
            ? I18n.t('acknowledgedRecipientAlarmForCreator', {
                durationString,
                recipientName: recipient.name
              })
            : ''
      }
    }

    return ''
  }

  const getBackupAlarmDetailsFooterText = alarmInfo => {
    const {
      alarmExpired,
      alarmAcknowledgement,
      alarmType,
      backupAlarmEffectiveDate,
      creator,
      responseStatus
    } = alarmInfo

    if (responseStatus === Constants.REJECT_ALARM) {
      return ''
    }

    if (alarmExpired && alarmAcknowledgement) {
      const durationString = DateTimeUtils.getShortDurationTillDateAsString(
        backupAlarmEffectiveDate
      )
      let acknowledgedAsString
      switch (alarmType) {
        case Constants.AlarmTypes.CASCADING:
          return alarmAcknowledgement.response === Constants.PERSONAL_ALARM_DONE
            ? I18n.t('acknowledgedExpiredPersonalAlarmForParticipant', {
                durationString,
                creatorName: creator.name
              })
            : ''
        case Constants.AlarmTypes.SIMULTANEOUS:
          acknowledgedAsString = I18n.t(
            Constants.GroupAlarmInstanceResponseNotificationTextMapping[
              alarmAcknowledgement.response
            ]
          )
          return I18n.t('acknowledgedExpiredGroupAlarm', {
            durationString,
            acknowledgedAsString
          })
        case Constants.AlarmTypes.RECIPIENT:
          return alarmAcknowledgement.response ===
            Constants.RECIPIENT_ALARM_SKIP
            ? I18n.t('skippedExpiredRecipientAlarm', { durationString })
            : I18n.t('acknowledgedExpiredRecipientAlarm', { durationString })
      }
    } else if (alarmExpired) {
      const durationString = DateTimeUtils.getShortDurationTillDateAsString(
        backupAlarmEffectiveDate
      )
      switch (alarmType) {
        case Constants.AlarmTypes.CASCADING:
          return I18n.t('expiredPersonalAlarmForParticipant', {
            durationString,
            creatorName: creator.name
          })
        case Constants.AlarmTypes.SIMULTANEOUS:
          return I18n.t('expiredGroupAlarm', { durationString })
        case Constants.AlarmTypes.RECIPIENT:
          return I18n.t('expiredRecipientAlarm', {
            durationString,
            creatorName: creator.name
          })
      }
    } else if (alarmAcknowledgement) {
      let durationString, acknowledgedAsString
      switch (alarmType) {
        case Constants.AlarmTypes.CASCADING:
          durationString = DateTimeUtils.getShortDurationTillDateAsString(
            alarmAcknowledgement.timestamp
          )
          return alarmAcknowledgement.response === Constants.PERSONAL_ALARM_DONE
            ? I18n.t('acknowledgedPersonalAlarmForParticipant', {
                durationString,
                creatorName: creator.name
              })
            : ''
        case Constants.AlarmTypes.SIMULTANEOUS:
          durationString = DateTimeUtils.getShortDurationTillDateAsString(
            alarmAcknowledgement.timestamp
          )
          acknowledgedAsString = I18n.t(
            Constants.GroupAlarmInstanceResponseNotificationTextMapping[
              alarmAcknowledgement.response
            ]
          )
          return I18n.t('acknowledgedGroupAlarm', {
            durationString,
            acknowledgedAsString
          })
        case Constants.AlarmTypes.RECIPIENT:
          durationString = DateTimeUtils.getShortDurationTillDateAsString(
            alarmAcknowledgement.timestamp
          )
          return alarmAcknowledgement.response ===
            Constants.RECIPIENT_ALARM_SKIP
            ? I18n.t('skippedRecipientAlarm', { durationString })
            : I18n.t('acknowledgedRecipientAlarm', { durationString })
      }
    }

    return ''
  }

  const getAlarmDetailsFooterLink = alarmInfo => {
    const { alarmAcknowledgement, myAlarmDate, alarmType } = alarmInfo
    // Undo action for "Done" or "Skip" for Personal alarm creator for buddy alarm or person alarm
    if (
      alarmType === Constants.AlarmTypes.CASCADING &&
      alarmAcknowledgement &&
      myAlarmDate > Date.now()
    ) {
      return I18n.t('undo')
    }

    return ''
  }

  const getBackupAlarmDetailsFooterLinkText = alarmInfo => {
    const { alarmAcknowledgement, alarmType, backupAlarmEffectiveDate } =
      alarmInfo

    if (
      alarmType === Constants.AlarmTypes.RECIPIENT &&
      alarmAcknowledgement &&
      backupAlarmEffectiveDate > Date.now()
    ) {
      return I18n.t('undo')
    }

    return ''
  }

  return {
    addAlarm,
    deleteAlarm,
    editAlarm,
    markAlarmAsAcknowledgedCore,
    markAlarmAsAcknowledgedNotificationAction,
    setBackupResponseStatusForAlarm,
    createBackupContactsFromContactsObject,
    createBackupGroupFromGroupObject,
    createAlarmRepetitionString,
    getPrevAndNextDatesForAnAlarmWrtDate,
    createAlarm,
    markCreatorResponseForSimultaneousAlarm,
    markCreatorResponseForSimultaneousAlarmCore,
    markParticipantResponseForSimultaneousAlarm,
    markParticipantResponseForSimultaneousAlarmCore,
    willParticipantAlarmRing,
    getCurrentDateForAlarm,
    snoozeAlarmForCreator,
    snoozeAlarmForParticipant,
    getParticipantAlarmNotificationAlertMessage,
    hasAlarmExpired,
    getNextDateForAlarm,
    getPrevDateForAlarm,
    stopSnoozeForParticipantAlarm,
    snoozeAlarmForParticipantNotificationAction,
    stopSnoozeForCreatorAlarm,
    getWelcomeAlarms,
    getMyAlarmNotificationAlertMessage,
    deleteAllAlarms,
    isLastSnoozeTimeInSnoozeDurationForMyAlarm,
    isLastSnoozeTimeInSnoozeDurationForParticipantAlarm,
    isCurrentAlarmOccurrenceAcknowledged,
    getParticipantAlarmShortAlertMessage,
    getMyAlarmShortAlertMessage,
    resolveAlarmCreatorName,
    retrieveAlarmByAlarmId,
    setBackupResponseStatusForAlarmCore,
    createFirebaseObjForSnoozingCreatorAlarm,
    createFirebaseObjForStoppingSnoozeForCreatorAlarm,
    createFirebaseObjForSnoozingParticipantAlarm,
    createFirebaseObjForStoppingSnoozeForParticipantAlarm,
    updateAlarm,
    updateAlarmParticipant,
    getDefaultAlarmName,
    getAlarmParticipants,
    getAlarmParticipant,
    setRecipientResponseStatusForAlarm,
    setRecipientResponseStatusForAlarmCore,
    markRecipientAlarmAsDone,
    markRecipientAlarmAsDoneCore,
    markPersonalAlarmAsUndone,
    markPersonalAlarmAsUnskipped,
    markRecipientAlarmAsUndone,
    scheduleNotificationForParticipantAlarm,
    updateSnoozeStatusForParticipantAlarm,
    updateSnoozeStatusForCreatorAlarm,
    updateSnoozeStatusForParticipantAlarmCore,
    addDayToWeeklyRepetitionString,
    subtractDayFromWeeklyRepetitionString,
    getLastAlarmParticipantDate,
    getParticipantAlarmNotificationInfo,
    getMyAlarmNotificationInfo,
    scheduleNotificationForOwnAlarm,
    getPreviousOccurrencesBetweenTime,
    getPreviousOccurrencesOfAlarmBetweenTime,
    findMissedOccurrencesOfAlarmBetweenTime,
    getAlarmAcknowledgementStatusForOccurrence,
    getAlarmAcknowledgementStatusForOccurrenceForAlarm,
    getUserAlarmAcknowledgementStatusForOccurrence,
    getNextRingDateForAlarm,
    getCurrentDateForAlarmId,
    hasParticipantAlarmExpired,
    getAlarmStatusForOccurrence,
    setPastAlarmOccurrenceResponse,
    computeLoadAlarmPreviousOccurrencesBucketDurationFromRepetition,
    hasAlarmPreviousOccurrencesChanged,
    getPreviousOccurrencesString,
    getOccurrenceTimeString,
    getRepeatOptionsForHourlyRepetition,
    getRepeatOptionsForHoursAndMinutesRepetition,
    createPreviewDataForHourlyRepetition,
    getLastOccurrenceOfHourlyAlarm,
    createAlarmRepetitionStringForAlarmSummary,
    markAdditionalSetupAlreadyDone,
    getAdditionalSetupInstructionsFromClientSide,
    getAdditionalSetupInstructionsFromStorageManager,
    dismissOwnAlarm,
    dismissParticipantAlarm,
    createParticipantAlarmRepetitionStringForAlarmSummary,
    createParticipantAlarmRepetitionString,
    createFirebaseObjForUpdatingParticipantAlarmRingerSettings,
    createFirebaseObjForUpdatingAlarmRingerSettings,
    getAlarmParticipantRingerSettings,
    getAlarmParticipantPreReminderDuration,
    getAndroidRingerSettingsLabel,
    getIosRingerSettingsLabel,
    getDefaultRingerSettings,
    getChangedParametersForRingerSettings,
    createPreviewDataForHoursAndMinutesRepetition,
    getLastOccurrenceOfHourAndMinutesAlarm,
    computeHoursAndMinutesStringFromMinutes,
    getHoursAndMinutesFromMinutes,
    getNewStatusForAlarm,
    getAlarmTitle,
    computeRingerSettings,
    getRandomRingtone,
    getSnoozeDurationAsString,
    addUserAsParticipantToGroupAlarm,
    makeMemberActiveInGroupAlarm,
    isAlarmEditedForSubscribers,
    getAdditionalSetupInstructions,
    snoozeAlarmForCreatorNotificationAction,
    skipPersonalAlarmCore,
    skipPersonalParticipantAlarmCore,
    skipRecipientAlarmCore,
    skipRecipientCreatorAlarmCore,
    createFirebaseObjectForAddAlarm,
    getColorForAlarmCategory,
    computeDayOfWeekInMonthForDate,
    checkIfDayOfMonthIsLastDayOfWeekInMonth,
    addAlarmToAlarmCategory,
    removeAlarmFromAlarmCategory,
    deleteAlarmCategory,
    updateAlarmCategory,
    createAlarmCategory,
    validateRepetition,
    getAlarmAcknowledString,
    createSelectedDaysString,
    createTimeString,
    getAdditionalSetupInstructionsSummary,
    setWebColorScheme,
    getOccurrenceStatusSummaryString,
    markRecipientCreatorAlarmAsUnskipped,
    markRecipientAlarmAsUnskipped,
    markPersonalParticipantAlarmAsUnskipped,
    getEventsForAnAlarmInRange,
    getEventsForAlarmsInRange,
    getAlarmsInRangeForAgendaView,
    getAlarmCategoryForAlarm,
    skipPersonalAlarmNotificationAction,
    skipPersonalParticipantAlarmNotificationAction,
    skipRecipientAlarmNotificationAction,
    skipRecipientCreatorAlarmNotificationAction,
    triggerInstantNotificationForParticipantAlarm,
    getAlarmAcknowledgementsForOccurrenceForAlarm,
    getCreatorSummaryForInstantAlarm,
    removeAlarmIdFromAlarmDatesMap,
    getAllTimezones,
    getTimezoneAbbr,
    getTimezoneDisplayStringForAlarm,
    getPreReminderLabel,
    createFirebaseObjForUpdatingParticipantAlarmPreReminderDuration,
    getDisplayTextForPreReminderDuration,
    getPreReminderDurationAsString,
    getPreReminderHelperText,
    createFirebaseObjForUpdatingAlarmPreReminderDuration,
    getRepeatOptionsForOddAndEvenNumberedDaysRepetition,
    getRingerSettingsLabel,
    getNextOccurrencesForHoursAndMinutesRepetition,
    createAlarmObject,
    restoreAlarm,
    addChecklist,
    removeChecklist,
    editChecklist,
    createChecklist,
    getAlarmDetailsFooterText,
    getBackupAlarmDetailsFooterText,
    getAlarmDetailsFooterLink,
    getBackupAlarmDetailsFooterLinkText
  }
})()

export default AlarmUtils
