import { Box, Spinner, Text } from 'grommet'
import React, { useContext, useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import UserContext from './UserContext'
import GlobalConfig from '../GlobalConfig'
import { I18n } from 'galarm-config'
import { doc, getDoc } from 'firebase/firestore'

const RequireAuth = () => {
  const { user, userLoaded } = useContext(UserContext)

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    async function checkOrganizationData() {
      if (userLoaded) {
        try {
          const docRef = doc(
            GlobalConfig.firestoreDb,
            'enterpriseUsers',
            user.uid
          )
          const docSnap = await getDoc(docRef)
          let enterpriseUserData
          if (docSnap.exists()) {
            enterpriseUserData = docSnap.data()
            console.log('Enterprise user:', enterpriseUserData)
            if (
              !enterpriseUserData.organizations ||
              enterpriseUserData.organizations.length === 0
            ) {
              console.log('No organizations, showing GetStarted')
            } else if (enterpriseUserData.organizations.length === 1) {
              console.log(
                'One organization, setting enterpriseAccountId in local storage as ' +
                  enterpriseUserData.organizations[0]
              )
              window.localStorage.setItem(
                'enterpriseAccountId',
                enterpriseUserData.organizations[0]
              )
              // If the location is /app, redirect to /app/org
              if (location.pathname === '/app') {
                navigate('/app/org', { replace: true })
              }
            } else {
              console.log(
                'Multiple organizations, use last one or redirect to /app/chooseorg'
              )

              if (window.localStorage.getItem('enterpriseAccountId')) {
                // If the location is /app, redirect to /app/org
                if (location.pathname === '/app') {
                  navigate('/app/org', { replace: true })
                }
              } else {
                navigate('/app/chooseorg', {
                  replace: true
                })
              }
            }
          } else {
            setError(I18n.t('noUserRecordFound'))
          }

          setLoading(false)
        } catch (error) {
          console.error('Error loading user data', error)
        }
      }
    }
    checkOrganizationData()
  }, [userLoaded])

  if (!userLoaded) {
    return (
      <Box flex justify="center" align="center">
        <Spinner size="medium" />
        <Text>{I18n.t('loadingUser')}</Text>
      </Box>
    )
  }

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location.pathname }} />
  }

  // If user hasn't verified their email, ask them to verify before continuing
  if (!user.emailVerified) {
    return <Navigate to="/verifyEmail" state={{ from: location.pathname }} />
  }

  if (loading) {
    return (
      <Box flex justify="center" align="center">
        <Spinner size="medium" />
      </Box>
    )
  }

  if (error) {
    return (
      <Box flex justify="center" align="center">
        <Text size="medium">{error}</Text>
      </Box>
    )
  }

  return <Outlet />
}

export default RequireAuth
