import { I18n } from 'galarm-config'
import { Box, Card, CheckBox, Heading, Text, ThemeContext } from 'grommet'
import { Checkmark } from 'grommet-icons'
import React, { useState } from 'react'

const PLANS = [
  {
    name: 'Business Plan',
    features: {
      [I18n.t('coreFeatures')]: [
        I18n.t('centralBilling'),
        I18n.t('unlimitedAlarmsPremiumFeature'),
        I18n.t('alarmRepetitionsPremiumFeature'),
        I18n.t('preReminderPremiumFeature'),
        I18n.t('alarmPhotoPremiumFeature'),
        I18n.t('taskListPremiumFeature'),
        I18n.t('ringtonesPremiumFeature'),
        I18n.t('galarmWebPremiumFeature'),
        I18n.t('alarmCategoriesPremiumFeature'),
        I18n.t('shareableAlarmLinksPremiumFeature')
      ]
    },
    price: {
      monthly: 2,
      yearly: 20
    },
    style: {
      background: 'light-3',
      textColor: 'black'
    }
  },
  {
    name: 'Enterprise Plan',
    features: {
      [I18n.t('everythingInBusiness')]: [
        I18n.t('apiSupport'),
        I18n.t('accessToConsoleToViewAndCloseIncidents'),
        I18n.t('triggerInstantAlertsFromTheConsole')
      ]
    },
    price: {
      monthly: 5,
      yearly: 50
    },
    style: {
      background: 'light-3',
      textColor: 'black'
    }
  }
]

const PlanFeatures = ({ features }) => {
  return (
    <Box overflow="auto">
      <Box flex={{ shrink: 0 }}>
        {Object.keys(features).map((title, index) => (
          <Box key={index} margin="medium" gap="small">
            <Heading level={5} margin="none">
              {title}
            </Heading>
            {features[title].map((feature, index) => (
              <Box key={index} direction="row" gap="xsmall">
                <Checkmark color="brand" />
                <Text key={index}>{feature}</Text>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

const ComparePlans = ({ plans = PLANS }) => {
  const [priceInterval, setPriceInterval] = useState('yearly')

  const priceIntervalString =
    priceInterval === 'yearly'
      ? I18n.t('perYearPerUser')
      : I18n.t('perMonthPerUser')
  // Create two cards, one for Business plan and the other for Enterprise plan
  // Add 5 features to each card
  return (
    <Box width="large">
      <Box direction="row" gap="large" margin="medium">
        {plans.map((plan, index) => (
          <Card flex key={index}>
            <Box>
              <Box
                background={{ color: plan.style.background }}
                pad="medium"
                flex={{ shrink: 0 }}>
                <Heading level={4} margin="none" color={plan.style.textColor}>
                  {plan.name}
                </Heading>
                <Box direction="row" align="center" gap="xsmall">
                  <Text
                    size="medium"
                    weight="bold"
                    color={plan.style.textColor}>
                    ${plan.price[priceInterval]}
                  </Text>
                  <Text size="small" color={plan.style.textColor}>
                    {priceIntervalString}
                  </Text>
                </Box>
              </Box>
              <PlanFeatures features={plan.features} />
            </Box>
          </Card>
        ))}
      </Box>
      <Box
        direction="row"
        align="center"
        gap="small"
        margin={{ horizontal: 'medium' }}>
        <Text size="small" color="textColor">
          {I18n.t('monthly')}
        </Text>
        <ThemeContext.Extend
          value={{
            checkBox: {
              toggle: {
                color: 'primary'
              }
            }
          }}>
          <CheckBox
            toggle
            checked={priceInterval === 'yearly'}
            onChange={event =>
              setPriceInterval(event.target.checked ? 'yearly' : 'monthly')
            }
          />
        </ThemeContext.Extend>
        <Text size="small" color="textColor">
          {I18n.t('yearly')}
        </Text>
        {priceInterval === 'yearly' && (
          <Text size="small" weight="bold" color="textColor">
            {I18n.t('saveTwoMonths')}
          </Text>
        )}
      </Box>
    </Box>
  )
}

export default ComparePlans
