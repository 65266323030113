import React, { useContext, useEffect, useState } from 'react'
import RemarksList from './RemarksList'
import { Box, Button, Heading, Text, TextArea } from 'grommet'
import { GlobalConfig, I18n } from 'galarm-config'
import UserContext from './UserContext'
import { arrayUnion, doc, onSnapshot, updateDoc } from 'firebase/firestore'
import { Send } from 'grommet-icons'

const IncidentNotes = ({ incident }) => {
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')
  const { user } = useContext(UserContext)

  const [incidentData, setIncidentData] = useState(incident)

  useEffect(() => {
    // Keep the latest copy of incident
    const incidentDoc = doc(
      GlobalConfig.firestoreDb,
      'enterpriseAccounts',
      enterpriseAccountId,
      'alerts',
      incident.alertId,
      'incidents',
      incident.id
    )

    const unsubscribe = onSnapshot(incidentDoc, doc => {
      const incidentData = doc.data()
      setIncidentData(incidentData)
    })

    return () => unsubscribe()
  }, [])

  const [noteText, setNoteText] = React.useState('')

  const addNoteToIncident = async () => {
    const newNote = {
      id: `note-${Date.now()}`,
      text: noteText,
      timestamp: Date.now(),
      user: {
        id: user.uid,
        displayName: user.displayName
      }
    }

    console.log('addNoteToIncident', newNote)

    await updateDoc(
      doc(
        GlobalConfig.firestoreDb,
        'enterpriseAccounts',
        enterpriseAccountId,
        'alerts',
        incident.alertId,
        'incidents',
        incident.id
      ),
      {
        additionalNotes: arrayUnion(newNote)
      }
    )

    setNoteText('')
  }

  const sortedNotes =
    incidentData.additionalNotes?.sort((a, b) => b.timestamp - a.timestamp) ||
    []

  return (
    <Box align="center">
      <Heading level={4}>{I18n.t('incidentNotes')}</Heading>
      <Box overflow="auto">
        <Box pad="small" width="large" flex={{ shrink: 0 }}>
          <Text size="small" color="darkTint" weight={500}>
            {I18n.t('fromIncident')}
          </Text>
          <TextArea
            value={incident.message}
            disabled
            style={{
              resize: 'none',
              fieldSizing: 'content'
            }}
          />
        </Box>
        <Box pad="small" width="large">
          {sortedNotes.length > 0 && (
            <Text size="small" color="darkTint" weight={500}>
              {I18n.t('fromUsers')}
            </Text>
          )}
          <RemarksList remarks={sortedNotes} />
        </Box>
      </Box>
      <Box
        overflow="auto"
        pad="small"
        margin={{ top: 'small' }}
        background="light-3"
        width="medium"
        round="small"
        flex={{ shrink: 0 }}>
        <Text size="small" weight={500}>
          {I18n.t('addNote')}
        </Text>
        <Box direction="row" gap="small">
          <TextArea
            value={noteText}
            onChange={e => setNoteText(e.target.value)}
          />
          <Button icon={<Send />} onClick={addNoteToIncident} />
        </Box>
      </Box>
    </Box>
  )
}

export default IncidentNotes
